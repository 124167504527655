const useWSProduct = (product, type = 'listen', suffix = '', callback) => {
  const channel = `Platform.Products.${product}${suffix}`
  console.log('useWSProduct', channel, type)
  if (type === 'listen') {
    console.log('useWSProduct', channel, product, type, `ProductRefresh`)
    window.Echo.channel(channel).listen(`ProductRefresh`, (data) => {
      console.log('RECEIVED', channel, `ProductRefresh`, ':', 'Reloading Page...')
      if (callback) return callback(data)
      window.location.reload();
    })
  } else {
    console.log('Unregister')
    window.Echo.leave(channel)
  }
}

export default useWSProduct;
