import React from 'react';
import './help.scss';
import Modal from 'react-modal';

export default function HelpVideo ({ video, ...props})
{
    const [showVideo, setShowVideo] = React.useState(false);

    const closeVideo = () => {
        setShowVideo(false);
    }

    return (
        <>
            { video ?
                <>
                    <div className="help-button" onClick={ () => { setShowVideo(true) }}>
                        <i className="fas fa-question"></i>
                    </div>
                    <Modal
                        style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
                        className="card help-video-modal"
                        isOpen={showVideo}
                        onRequestClose={closeVideo}
                        contentLabel={'Help Video'}
                        appElement={document.getElementById('root')}
                    >
                        <video controls width="100%">
                            <source src={video} type="video/mp4"/>
                            Your browser doesn't support embedding videos like this. Please contact IDenteq for support.
                        </video>
                        <div className="button background-primary colour-white" onClick={ closeVideo }>
                            Close
                        </div>
                    </Modal>
                </>
            : '' }
        </>
    )
}
