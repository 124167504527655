import React, { useState, useEffect } from 'react'
import { view } from '@risingstack/react-easy-state'
import Navigation from '../../../components/navigation/navigation';
import Container from '../../../components/container/container';
import Footer from '../../../components/footer/footer';
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import './ltapAnalysis.scss';
import { Link, withRouter } from 'react-router-dom';
import request from '../../../helpers/request';
import hasPermission from '../../../helpers/permissions';
import endpoints from '../../../helpers/endpoints';
import { BarChart, XAxis, YAxis, Legend, Bar, ResponsiveContainer, CartesianGrid, Tooltip, Text } from 'recharts';
import S3Modal from '../../../components/s3Modal/S3Modal';
import session from '../../../stores/session';
import BulkAllocate from '../../../components/cot-alert-bulk-allocate/bulkAllocate';
import HelpVideo from '../../../components/help-video/helpVideo';
import useWSProduct from '../../../helpers/useWSProduct';
import ProductChartInfo from '../../../components/product-chart-info/product-info-chart';
import ProductDataFilter from '../../../components/product-data-filter/product-data-filter';
import DataGroupings from '../../../components/data-groupings/data-groupings';
import Modal from 'react-modal';
import PageTitle from '../../../components/page-header/pageTitle';
import GenericDataChart from '../../../GenericPageElements/GenericDataChart'
import GenericProductFilterContainer from '../../../GenericPageElements/GenericProductFilterContainer'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import "../../../components/cot-alert-bulk-buy/bulkBuy.scss"

const localised = Intl.NumberFormat('en-GB');

const CustomizedAxisTickMultiLine = ({ x, y, payload }) => {
    return (
        <Text x={x} y={y} width={80} fontSize={14} textAnchor="middle" verticalAnchor="start">{payload.value}</Text>
    )
};

export default function LTAPAnalysis() {
    const [dataReady, setDataReady] = useState(false)
    const [dates, setDates] = useState([])
    const [date, setDate] = useState(undefined)
    const [data, setData] = useState(null)
    const [group, setGroup] = useState('')
    const [filters, setFilters] = useState({})
    const [bulkBuyModalIsOpen, setBulkBuyModalIsOpen] = useState(false)
    const [bulkAllocateModalIsOpen, setBulkAllocateModalIsOpen] = useState(false)
    const [translations, setTranslations] = useState({})

    const toggleGroup = (field) => {
        if (group == field) {
            setGroup('')
        } else {
            setGroup(field)
        }
    }

    const filter = (field, value) => {
        setFilters({
            ...filters,
            [field]: value
        })
    }
    
    useEffect(() => {
        query()
    }, [group, filters])

    const query = () => {
        setDataReady(false)
        request(true).get(endpoints.LTAP_ANALYSIS_SUMMARY, {
            params: {
                filter: filters,
                group: group?.target_column,
            },
            doesCancel: true,
        }).then(r => {
            setData(r.data)
            setDataReady(true)
        });
    }
    
    const clearFilters = () => {
        setFilters({})
    }

    useEffect(() => {
        request(true).get('/translate?key=lta.').then(r => {
            setTranslations(r.data)
        }).catch(e => {
            console.error(e);
        })
    }, [])
    
    useEffect(() => {
        query()
    }, [])

    const openBulkBuyModal = () => {
        setBulkBuyModalIsOpen(true)
    }

    const closeBulkBuyModal = () => {
        setBulkBuyModalIsOpen(false)
    }

    const openBulkAllocateModal = () => {
        setBulkAllocateModalIsOpen(true)
    }

    const closeBulkAllocateModal = () => {
        setBulkAllocateModalIsOpen(false)
    }

    return (
        <GenericPageContainer title="LTAP Analysis" titleRight={
            <div style={{ display: 'flex' }}>
                <Link className="navigate" to="/portal/ltap-analysis/search">
                    To Search
                </Link>
                {(hasPermission('LTAP_ANALYSIS', 'WORKFLOW')) ?
                    <Link className="navigate" to="/portal/ltap-analysis/workflow">
                        To Workflow
                    </Link>
                    : null}
            </div>
        }>
            <Container>
                <div className="lta">
                    <div className="data-row file-date">
                        <div className="data-row-title">
                            {translations ? translations['lta.file_date'] : ''}
                        </div>
                        <div className="data-row-field">
                            <select onChange={e => setDate(e.target.value)} value={''}>
                                {dates.map(item => {
                                    return (
                                        <option key={item.raw} value={item.raw}>
                                            {item.pretty}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className='grid-overview'>
                        <GenericDataChart
                            dataReady={dataReady}
                            data={data}
                            selectedGroupBy={group}
                            setSelectectGroupBy={toggleGroup}
                            groupingsEndpoint={`${endpoints.LTAP_ANALYSIS_GROUPINGS}`}
                            sizes={{ chart: 500, chartContainer: 500, chartXAxisFontSize: 12, wrapperStyle: { paddingTop: '40px' } }}
                            xAxisProps={{ interval: 0 }}
                            displayModeInitial='graph'
                            chartColours={["#445469"]}
                            customBarTick={CustomizedAxisTickMultiLine}
                        />
                        <GenericProductFilterContainer clearFilters={clearFilters} style={{ height: 'unset', position: 'sticky', top: 10 }}>
                            <ProductDataFilter applyFilter={filter} filterState={filters} dataFilterEndpoint={endpoints.LTAP_ANALYSIS_DATA_FILTERS} />
                            <div className='grid grid-columns-2 grid-gap-5 mta'>
                                {(hasPermission('LTAP_ANALYSIS', 'BULK-BUY')) ?
                                    <button className="button bulk-buy-button" onClick={openBulkBuyModal} style={{ marginTop: 15 }}>
                                        Bulk Buy
                                    </button>
                                    : ''}
                                {(hasPermission('LTAP_ANALYSIS', 'ALLOCATE')) ?
                                    <button className="button bulk-buy-button" onClick={openBulkAllocateModal} style={{ marginTop: 15 }}>
                                        Allocate
                                    </button>
                                    : ''}
                            </div>
                        </GenericProductFilterContainer>
                    </div>
                </div>
            </Container>
            <BulkBuy stepOneType="gone away accounts" stepTwoType="confidence" stepThreeType="gone away" workflowUsersEndpoint={endpoints.LTAP_ANALYSIS_WORKFLOW_USERS} bulkEndpoint={endpoints.LTAP_ANALYSIS_SUMMARY_BULK} modalIsOpen={bulkBuyModalIsOpen} closeModal={closeBulkBuyModal} filters={filters} />
            <BulkAllocate workflowUsersEndpoint={endpoints.LTAP_ANALYSIS_WORKFLOW_USERS} bulkEndpoint={endpoints.LTAP_ANALYSIS_SUMMARY_BULK} modalIsOpen={bulkAllocateModalIsOpen} closeModal={closeBulkAllocateModal} filters={filters} />
            <HelpVideo video={session.company.LTA_video_url} />
        </GenericPageContainer>
    )
}
function BulkBuy({ modalIsOpen, closeModal, filters, date, workflowUsersEndpoint, bulkEndpoint, stepOneType, stepTwoType, stepThreeType, ...props }) {
    const [step, setStep] = React.useState(1);
    const [bulkCount, setBulkCount] = React.useState(0);
    const [newOccCount, setNewOccCount] = React.useState(0)
    const [bulkLimit, setBulkLimit] = React.useState(0);
    const [newOccLimit, setNewOccLimit] = React.useState(0);
    const [buyNowEnabled, setBuyNowEnabled] = React.useState(true);
    const [error, setError] = React.useState(undefined);

    useEffect(() => {
        setStep(1);
    }, [modalIsOpen, setStep]);

    useEffect(() => {
        if (step === 1 && modalIsOpen) {
            request(true).get(bulkEndpoint, {
                params: {
                    date: date,
                    filter: filters,
                }
            }).then(r => {
                setBulkCount(r.data[0]);
                setBulkLimit(r.data[0]);
                setNewOccCount(r.data[1]);
                setNewOccLimit(r.data[1]);
            }).catch(e => {
                console.log(e);
            })
        }
    }, [step, modalIsOpen, filters, date, bulkEndpoint]);

    const confirmPurchase = () => {
        console.log('click');
        setBuyNowEnabled(false);
        buy();
    }

    const buy = () => {
        if (bulkLimit <= 0) return;
        request(true).post(bulkEndpoint, {
            date: date,
            filter: filters,
            // limit: [bulkLimit,newOccLimit]
            limit: bulkLimit
        }).then(r => {
            window.location.reload();
        }).catch(e => {
            console.log(e);
        });
    }

    const formChange = (value, source, dest) => {
        value = Number(value)
        if (value === "") return dest(0)

        if (value > source) {
            value = source;
        }

        dest(value);
    }

    const firstBuyStep = () => {
        console.log('firstBuyStep', bulkLimit, bulkLimit === 0, bulkLimit === '0')
        if (bulkLimit === 0) return setError('You cannot buy 0 customers, you must buy 1 or more.')
        setError(undefined)

        if (newOccCount >= bulkLimit) {
            setNewOccLimit(bulkLimit)
        }

        setStep(3)
    }

    const secondBuyStep = () => {
        if (newOccLimit > newOccCount) return setError('You cannot buy more new occupiers than are available.')
        setStep(3);
    }

    return (
        <Modal
            style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
            className="card bulk-buy"
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Bulk Buy COT Alert Data"
            appElement={document.getElementById('root')}
        >
            {step === 1 ?
                <div key="bb_step1">
                    <p>
                        The selected data set includes {bulkCount} {stepOneType || 'customers'}. How many would you like to purchase?
                    </p>
                    <small>
                        If you reduce this number then {stepOneType || 'customers'} with higher {stepTwoType || 'occupier'} scores will be purchased first.
                    </small>
                    <small>
                        Customers with an {stepThreeType || 'occupier'} score of 0 cannot be purchased.
                    </small>
                    {error ? <p className="colour-yellow"><strong>{error}</strong></p> : null}
                    <div className="input">
                        <label>Purchase Limit:</label>
                        <input type="number" name="bulk-limit" max={bulkCount} value={bulkLimit} onChange={(e) => formChange(e.target.value, bulkCount, setBulkLimit)} />
                    </div>
                    <div className="grid-2">
                        <button className="button cancel" onClick={closeModal}>Cancel</button>
                        <button className="button confirm" onClick={() => firstBuyStep()}>Confirm</button>
                    </div>
                </div>
                : null}

            {
                step === 2 ?
                    <div key="bb_step1">
                        <p>
                            The selected data set includes {newOccCount > bulkLimit ? bulkLimit : newOccCount} new occupiers. How many would you like to purchase?
                        </p>
                        <div className="input">
                            <label>Purchase Limit:</label>
                            <input type="number" name="bulk-limit" max={newOccCount > bulkLimit ? bulkLimit : newOccCount} value={newOccLimit} onChange={(e) => formChange(e.target.value, newOccCount > bulkLimit ? bulkLimit : newOccCount, setNewOccLimit)} />
                        </div>
                        <div className="grid-2">
                            <button className="button cancel" onClick={closeModal}>Cancel</button>
                            <button className="button confirm" onClick={() => secondBuyStep()}>Confirm</button>
                        </div>
                    </div> : null
            }

            {step === 3 ?
                <>
                    <p>
                        You have selected {bulkLimit} account{bulkLimit > 1 ? 's' : ''} for purchase, <br />please press 'Buy Now' to complete this transaction.
                    </p>
                    <div className="grid-2">
                        <button className="button cancel" onClick={closeModal}>Cancel</button>
                        <button className="button confirm" onClick={confirmPurchase} disabled={!buyNowEnabled}>
                            {
                                !buyNowEnabled ?
                                    <div className="spinner">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </div>
                                    : 'Buy Now'
                            }
                        </button>
                    </div>
                </> : null
            }
        </Modal>
    )
}
