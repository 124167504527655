import React, { useMemo, useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import Container from '../../components/container/container'
import Navigation from '../../components/navigation/navigation'
import ProductSelect from '../../components/product-select/productSelect'
import request from '../../helpers/request'
import session from '../../stores/session'

import {
    ColumnDef,
    Row,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table';
  
import {
DndContext,
KeyboardSensor,
MouseSensor,
TouchSensor,
closestCenter,
useSensor,
useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
arrayMove,
SortableContext,
verticalListSortingStrategy,
} from '@dnd-kit/sortable';
  
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export default function DataFilters() {
  const [dataFilters, setDataFilters] = useState([])
  const dataIds = useMemo(() => dataFilters.map(({ id }) => id), [dataFilters]);
  const [company] = useState(undefined)
  const [product, setProduct] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const OrderCell = ({ row }) => {
    const [input, setInput] = useState(row.original.order);
    return (
      <input
      size={3}
        onChange={(event) => {
          console.log(row)
          if (!event.target.value.match(/^[0-9]*$/)) return
          setInput(Number(event.target.value))
        }}
        onBlur={() => {
          const index = dataFilters.findIndex(item => item.id === row.original.id)
          const newDataFilters = [...dataFilters];
          newDataFilters[index].order = input;
          newDataFilters.sort((a, b) => a.order - b.order);
          setDataFilters(newDataFilters);
          submitNewOrder([dataFilters[index]]);
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            const index = dataFilters.findIndex(item => item.id === row.original.id)
            const newDataFilters = [...dataFilters];
            newDataFilters[index].order = input;
            newDataFilters.sort((a, b) => a.order - b.order);
            setDataFilters(newDataFilters);
            submitNewOrder([dataFilters[index]]);
          }
        }}
        value={input} 
        className="button bulk-buy-button compact" 
        style={{ margin: '0px', paddingLeft: '0px', paddingRight: '0px', textAlign: 'center' }}
      />
    )
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'internal_name',
        header: () => 'Internal Name',
      },
      {
        accessorKey: 'title',
        header: () => 'Title',
      },
      {
        accessorKey: 'target_column',
        header: "Targets"
      },
      {
        cell: ({ row }) =>
          <div className="grid grid-columns-2 grid-gap-10">
            <Link to={`/admin/portal/filters/${row.id}`}><button className="button compact background-primary colour-white">Edit</button></Link>
            <button onClick={() => deleteFilter(row.id)} className="button compact background-yellow colour-white">Delete</button>
          </div>,
        header: 'Actions',
      },
      {
        cell: ({ row }) => <OrderCell row={row} />,
        header: 'Order',
      },
      {
        id: 'copy',
        cell: ({ row }) => 
          <button className='button compact' onClick={() => copyFilter(row.id)}><span className='fas fa-copy'></span></button>
      },
      {
        id: 'drag-handle',
        header: '',
        cell: ({ row }) => <RowDragHandleCell rowId={row.id} />,
      },
    ],
    [dataFilters]
  );

  useEffect(() => {
    setProduct(session.filterSelectedProduct)
  }, [])

  const getDataFilters = () => {
    const filterQuery = new URLSearchParams()
    if (company) filterQuery.append('company_id', company)
    if (product) filterQuery.append('real_product_id', product)
    setLoading(true)

    request(true).get('/datafilters?'+filterQuery.toString()).then(data => {
      if (data.status === 200) {
        setDataFilters(data.data.sort((a, b) => a.order - b.order));
        setLoading(false)
      }
    }).catch(error => {
      console.log(error)
      setDataFilters([])
      setLoading(false)
      window.alert("Could not retrieve filters")
    })
  }

  const addQueryData = useEffect(() => {
      session.filterSelectedProduct = product
      if (product !== undefined) {
        getDataFilters();
      } else {
        setDataFilters([])
      }
  }, [product, company])

  const deleteFilter = (id) => {
    if (!window.confirm('Delete filter?')) return
    request(true).delete('/datafilters/'+id).then(() => {
      getDataFilters()
    })
  }

  const copyFilter = (id) => {
    request(true).post(`/datafilters/${id}/copy`, {
      newName: window.prompt('Copy Name Key')
    }).then(() => {
      getDataFilters()
      window.alert('Coppied')
    }).catch(() => {
      window.alert('Failed to copy')
    })
  }

  const submitNewOrder = (rows) => {
    const newDataFilters = [...dataFilters];
    rows.forEach(row => {
      newDataFilters[dataFilters.findIndex(dataFilter => dataFilter.id === row.id)] = row
    })
    setDataFilters(newDataFilters.sort((a, b) => a.order - b.order));

    rows.forEach(row => {
      request(true).post(`/datafilters/${row.id}`, {
        ...row
      })
    });

  }

  const table = useReactTable({
    data: dataFilters,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: row => row.id,
    autoResetAll: false
  });

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const activeIndex = dataFilters.findIndex(item => item.id === active.id);
      const overIndex = dataFilters.findIndex(item => item.id === over.id);

      const activeItem = dataFilters[activeIndex];
      const overItem = dataFilters[overIndex];

      if (activeItem.order <= overItem.order) {
        activeItem.order = overItem.order + 1;
      } else if (activeItem.order > overItem.order) {
        activeItem.order = overItem.order - 1;
        if (activeItem.order === -1) {
          activeItem.order = 0
          overItem.order = 1
        }
      }

      submitNewOrder([activeItem, overItem])
    }
  }
  
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor)
  );

  const RowDragHandleCell = React.memo(({ rowId }) => {
    const { attributes, listeners } = useSortable({ id: rowId });
    return (
      <i {...attributes} {...listeners} class="fa-solid fa-bars fa-lg"></i>
    );
  });
  
  const DraggableRow = ({ row }) => {
    const { transform, transition, setNodeRef, isDragging } = useSortable({
      id: row.original.id,
    });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition: transition,
      opacity: isDragging ? 0.8 : 1,
      zIndex: isDragging ? 1 : 0,
      position: 'relative',
    };

    return (
      <tr
        className={product ? 'tr-drag-bars' : ''}
        ref={setNodeRef}
        style={style}
      >
        {row.getVisibleCells().map(cell => (
          <td
            key={cell.id}
            className={cell.column.id === 'drag-handle' ? 'drag-bars' : ''}
          >
            {cell.column.id === 'drag-handle' ? (
                <RowDragHandleCell rowId={row.original.id} />
              )
            : (
              flexRender(cell.column.columnDef.cell, cell.getContext())
            )}
          </td>
        ))}
      </tr>
    );
  }
  
  return (
    <div className="grid grid-gap-20">
      <Navigation />
      <Container>
          <div className="flex middle">
              <p className="colour-secondary font-weight-600" style={{fontSize: 20}}>Data Filters</p>
              <div className="flex" style={{ marginLeft: 'auto', gap: '10px' }}>
                <div>
                  <Link to="/admin/portal/filters/new"><button className="button compact background-primary colour-white">New Data Filter</button></Link>
                </div>
                <div style={{display: 'flex'}}>
                  <ProductSelect product={product} onChange={(e) => setProduct(e ? e.value : undefined)} />
                </div>
              </div>
          </div>
      </Container>
      <Container>
        <DndContext
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={handleDragEnd}
          sensors={sensors}
        >
          <table className="table">
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    header.id !== "drag-handle" &&
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              { loading && <tr><td colSpan={'6'}><i className="fa fa-spinner fa-spin fa-lg" /></td></tr> }
              <SortableContext items={dataIds} strategy={verticalListSortingStrategy}>
                {table.getRowModel().rows.map(row => (
                  <DraggableRow key={row.id} row={row} />
                ))}
              </SortableContext>
            </tbody>
          </table>
        </DndContext>
      </Container>
    </div>
  );
}