import React, { useCallback, useEffect } from 'react'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import { DatePicker } from '@material-ui/pickers';
import request from '../../../helpers/request';
import hasPermission from '../../../helpers/permissions';
import endpoints from '../../../helpers/endpoints'
import { useState } from 'react';
import session from '../../../stores/session';
import { Link } from 'react-router-dom';
import { view } from '@risingstack/react-easy-state';
import ReactModal from 'react-modal';
import moment from 'moment'

const FilterButton = ({ onFilterChange, filter }) => {
  const [showFilter, setShowFilter] = useState(false)

  const [fromDate, setFromDate] = useState(filter.fromDate ? moment(filter.fromDate) : moment().startOf('month').toDate());
  const [toDate, setToDate] = useState(filter.toDate ? moment(filter.toDate) : moment().endOf('month').toDate());

  const onClick = useCallback(() => {
    setShowFilter(_ => !_)
  }, [setShowFilter])

  useEffect(() => {
    const data = {
      fromDate,
      toDate
    }
    console.log(data)
    if (!onFilterChange) return
    onFilterChange(data)
  }, [fromDate, toDate, onFilterChange])

  return [
    <span style={{cursor: 'pointer'}} onClick={onClick}><span className='fas fa-filter-list'></span> Date Filter</span>,
    <ReactModal
      className="card bulk-buy"
      isOpen={showFilter}
      onRequestClose={onClick}>
        <div className='flex-column grid-gap-15'>

          <h2>Filter</h2>
          
          <DatePicker label="From Date" onChange={_ => setFromDate(_.toDate())} value={fromDate} />
          <DatePicker label="To Date" onChange={_ => setToDate(_.toDate())} value={toDate} />

          <button className='button compact background-primary colour-white' onClick={onClick}>Confirm</button>

        </div>
    </ReactModal>
  ]
}

const LoadingSpinner = () => {
  return (
    <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', margin: '0 auto'}}>
      <i className="fas fa-spinner fa-pulse fa-2x colour-gray"></i>
    </div>
  );
};

const GSATeamWorkflowAllocation = () => {
  const [data, setData] = useState({
    grouped: [],
    totals: {}
  })
  const [dataReady, setDataReady] = useState(false)
  const showBulkMeter = session.company.gsa_include_bulk_meter === 1;
  useEffect(() => {
    request(true).get(endpoints.GAP_SITES_ALERTS_DASHBOARD_TEAM_WORKFLOW_ALLOCATION)
    .then(e => {
      setData(e.data)
      setDataReady(true);
    })
  }, [])
  return (
    <div>
      { !dataReady ? <LoadingSpinner /> :
        <table className='table squish smaller-text'>
          <thead>
            <tr>
              <th colSpan={12}>Current Team Workflow Allocation</th>
            </tr>
            <tr>
              <th>
                User Name
              </th>
              {showBulkMeter ? <th>Bulk Meter</th> : <></>}
              <th>New Connections</th>
              <th>Split Property</th>
              <th>Flat Above Shop</th>
              <th>Potential Gap</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {
              data.grouped.map(_row => {
                return (
                  <tr>
                    <td>{_row.Assigned_To_Name}</td>
                    {showBulkMeter ? <td>{_row['Bulk_Meter']}</td> : <></>}
                    <td>{_row['New_Connections']}</td>
                    <td>{_row['Split_Property']}</td>
                    <td>{_row['Flat_Above_Shop']}</td>
                    <td>{_row['Potential_Gap']}</td>
                    <td>{_row['Total']}</td>
                  </tr>
                )
              })
            }
            <tr>
              <td>Total</td>
              {showBulkMeter ? <td>{data.totals['Bulk_Meter']}</td> : <></>}
              <td>{data.totals['New_Connections']}</td>
              <td>{data.totals['Split_Property']}</td>
              <td>{data.totals['Flat_Above_Shop']}</td>
              <td>{data.totals['Potential_Gap']}</td>
              <td>{data.totals['Total']}</td>
            </tr>
          </tbody>
        </table>
      }
      
    </div>
  )
}

const GSAGapsProcessedByCategoryYTD = () => {
  const [data, setData] = useState({
    grouped: [],
    totals: {}
  })
  const [dataReady, setDataReady] = useState(false)
  const showBulkMeter = session.company.gsa_include_bulk_meter === 1;
  useEffect(() => {
    request(true).get(endpoints.GAP_SITES_ALERTS_DASHBOARD_GAPS_PROCESSED_YTD)
    .then(e => {
      setData(e.data)
      setDataReady(true)
    })
  }, [])
  return (
    <div>
      { !dataReady ? <LoadingSpinner /> :
        <div>
          <table className='table squish smaller-text'>
            <thead>
              <tr>
                <th colSpan={8}>Gaps Processed by Category YTD</th>
              </tr>
              <tr>
                <th>Gap State</th>
                {showBulkMeter ? <th>Bulk Meter</th> : <></>}
                <th>New Connections</th>
                <th>Split Property</th>
                <th>Flat Above Shop</th>
                <th>Potential Gap</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {
                data.grouped.map(_row => {
                  return (
                    <tr>
                      <td>{_row.state}</td>
                      {showBulkMeter ? <td>{_row['Bulk_Meter']}</td> : <></>}
                      <td>{_row['New_Connections']}</td>
                      <td>{_row['Split_Property']}</td>
                      <td>{_row['Flat_Above_Shop']}</td>
                      <td>{_row['Potential_Gap']}</td>
                      <td>{_row['Total']}</td>
                    </tr>
                  )
                })
              }
              <tr>
                <td>Total</td>
                {showBulkMeter ? <td>{data.totals['Bulk_Meter']}</td> : <></>}
                <td>{data.totals['New_Connections']}</td>
                <td>{data.totals['Split_Property']}</td>
                <td>{data.totals['Flat_Above_Shop']}</td>
                <td>{data.totals['Potential_Gap']}</td>
                <td>{data.totals['Total']}</td>
              </tr>
            </tbody>
          </table>
        </div>
      }
        
    </div>
  )
}

const GSADashboardTeamPerformance = () => {
  const [data, setData] = useState({
    grouped: [],
    totals: {}
  })
  const [dataReady, setDataReady] = useState(false)
  useEffect(() => {
    request(true).get(endpoints.GAP_SITES_ALERTS_DASHBOARD_TEAM_PERFORMANCE_YTD)
    .then(e => {
      setData(e.data)
      setDataReady(true)
    })
  }, [])
  return (
    <div>
      { !dataReady ? <LoadingSpinner /> :
        <div>
          <table className='table squish smaller-text'>
            <thead>
              <tr>
                <th colSpan={6}>Team Performance YTD</th>
              </tr>
              <tr>
                <th>
                  User Name
                </th>
                <th>Gap Accepted</th>
                <th>Gap Rejected</th>
                <th>Completed</th>
                <th>Days Worked (i)</th>
                <th>Average Daily Completions</th>
              </tr>
            </thead>
            <tbody>
              {
                data.grouped.map(_row => {
                  return (
                    <tr>
                      <td>{_row['Worked_By']}</td>
                      <td>{_row['Gap_Accepted_Number']}<br />({_row['Gap_Accepted_Split']}%)</td>
                      <td>{_row['Gap_Rejected_Number']}<br />({_row['Gap_Rejected_Split']}%)</td>
                      <td>{_row['Completed_Number']}<br />({_row['Completed_Split']}%)</td>
                      <td>{_row['Working_Days']}</td>
                      <td>{_row['Avg_Daily_Completions']}</td>
                    </tr>
                  )
                })
              }
              <tr>
                <td>Total</td>
                <td>{data.totals['Gap_Accepted_Number']}<br />({data.totals['Gap_Accepted_Split']}%)</td>
                <td>{data.totals['Gap_Rejected_Number']}<br />({data.totals['Gap_Rejected_Split']}%)</td>
                <td>{data.totals['Completed_Number']}<br />({data.totals['Completed_Split']}%)</td>
                <td>{data.totals['Working_Days']}</td>
                <td>{data.totals['Avg_Daily_Completions']}</td>
              </tr>
            </tbody>
          </table>
        </div>
      }
        
    </div>
  )
}

const GSAGapsProcessedByCategoryFiltered = ({filter, setFilter}) => {
  const [data, setData] = useState({
    grouped: [],
    totals: {}
  })
  const [dataReady, setDataReady] = useState(false)
  const showBulkMeter = session.company.gsa_include_bulk_meter === 1;
  useEffect(() => {
    request(true).get(endpoints.GAP_SITES_ALERTS_GAPS_PROCESSED_FILTERED, {
      params: filter
    }).then(e => {
      setData(e.data)
      setDataReady(true)
    }).catch(e => {
      window.alert('Please adjust your filter for Gaps Processed by Category')
    })
  }, [filter])
  return (
    <div>
      { !dataReady ? <LoadingSpinner /> :
        <div>
          <table className='table squish smaller-text'>
            <thead>
              <tr>
                <th>
                  <FilterButton key={'gaps_dashboard_filter'} onFilterChange={setFilter} filter={filter} />
                </th>
                <th colSpan={8}>Gaps Processed by Category</th>
              </tr>
              <tr>
                <th>Gap State</th>
                {showBulkMeter ? <th>Bulk Meter</th> : <></>}
                <th>New Connections</th>
                <th>Split Property</th>
                <th>Flat Above Shop</th>
                <th>Potential Gap</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {
                data.grouped.map(_row => {
                  return (
                    <tr>
                      <td>{_row.state}</td>
                      {showBulkMeter ? <td>{_row['Bulk_Meter']}</td> : <></>}
                      <td>{_row['New_Connections']}</td>
                      <td>{_row['Split_Property']}</td>
                      <td>{_row['Flat_Above_Shop']}</td>
                      <td>{_row['Potential_Gap']}</td>
                      <td>{_row['Total']}</td>
                    </tr>
                  )
                })
              }
              <tr>
                <td>Total</td>
                {showBulkMeter ? <td>{data.totals['Bulk_Meter']}</td> : <></>}
                <td>{data.totals['New_Connections']}</td>
                <td>{data.totals['Split_Property']}</td>
                <td>{data.totals['Flat_Above_Shop']}</td>
                <td>{data.totals['Potential_Gap']}</td>
                <td>{data.totals['Total']}</td>
              </tr>
            </tbody>
          </table>
        </div>
      }
    </div>
  )
}

const GSADashboardTeamPerformanceFiltered = ({filter}) => {
  const [data, setData] = useState({
    grouped: [],
    totals: {}
  })
  const [dataReady, setDataReady] = useState(false)
  useEffect(() => {
    request(true).get(endpoints.GAP_SITES_ALERTS_DASHBOARD_TEAM_PERFORMANCE_FILTERED, {
      params: filter
    }).then(e => {
      setData(e.data)
      setDataReady(true)
    }).catch(e => {
      window.alert('Please adjust your filter for Team Performance (lower)')
    })
  }, [filter])
  return (
    <div>
      { !dataReady ? <LoadingSpinner /> :
        <div>
          <table className='table squish smaller-text'>
            <thead>
              <tr>
                <th colSpan={6}>Team Performance</th>
              </tr>
              <tr>
                <th>
                  User Name
                </th>
                <th>Gap Accepted</th>
                <th>Gap Rejected</th>
                <th>Completed</th>
                <th>Days Worked (i)</th>
                <th>Average Daily Completions</th>
              </tr>
            </thead>
            <tbody>
              {
                data.grouped.map(_row => {
                  return (
                    <tr>
                      <td>{_row['Worked_By']}</td>
                      <td>{_row['Gap_Accepted_Number']}<br />({_row['Gap_Accepted_Split']}%)</td>
                      <td>{_row['Gap_Rejected_Number']}<br />({_row['Gap_Rejected_Split']}%)</td>
                      <td>{_row['Completed_Number']}<br />({_row['Completed_Split']}%)</td>
                      <td>{_row['Working_Days']}</td>
                      <td>{_row['Avg_Daily_Completions']}</td>
                    </tr>
                  )
                })
              }
              <tr>
                <td>Total</td>
                <td>{data.totals['Gap_Accepted_Number']}<br />({data.totals['Gap_Accepted_Split']}%)</td>
                <td>{data.totals['Gap_Rejected_Number']}<br />({data.totals['Gap_Rejected_Split']}%)</td>
                <td>{data.totals['Completed_Number']}<br />({data.totals['Completed_Split']}%)</td>
                <td>{data.totals['Working_Days']}</td>
                <td>{data.totals['Avg_Daily_Completions']}</td>
              </tr>
            </tbody>
          </table>
        </div>
      }
        
    </div>
  )
}

export default view(function GapSitesAlertsDashboard() {
  const [filter, setFilter] = useState({})
  return (
    <GenericPageContainer
    title={'Gap Sites Alerts - Dashboard'}
    titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
    containerWidth={'90vw'}
    titleRight={<>
        <Link className="navigate" to="/portal/gap-sites-alerts/">
            To Overview
        </Link>
        <Link className="navigate" to="/portal/gap-sites-alerts/search">
            To Search
        </Link>
        { (hasPermission('GSA', 'WORKFLOW')) ?
            <Link className="navigate" to="/portal/gap-sites-alerts/workflow">
                To Workflow
            </Link>
        : null }
    </>}
    >

      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20 }}>
        <div style={{gridColumnStart: 1, gridColumnEnd: 3}}>
          <GSATeamWorkflowAllocation />
        </div>
        <GSAGapsProcessedByCategoryYTD />
        <GSADashboardTeamPerformance />
        <GSAGapsProcessedByCategoryFiltered filter={filter} setFilter={setFilter} />
        <GSADashboardTeamPerformanceFiltered filter={filter} />
      </div>
    </GenericPageContainer>
  )
})
