import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './portalCard.scss';
import {ReactComponent as HouseIcon} from '../../assets/House Icon.svg';

export default class PortalCard extends Component {
    render() {
        return (
            <Link className={`portal-card ${this.props.available ? 'available' : 'not-available'}`} to={this.props.available ? this.props.link ?? '' : ''}>
                <div className="grid center middle grid-gap-25" onClick={this.props.onClick}>
                    <div className="icon">
                        <span className="fa-stack fa-lg">
                            {
                                this.props.icon ? <i className={`${this.props.icon} fa-3x`}></i> : ''
                            }
                        </span>
                    </div>
                    <p className="title">{this.props.title}</p>
                    <p className="description">{this.props.description}</p>
                    { this.props.floatHouse ? <HouseIcon className="float-house"/> : '' }
                </div>
            </Link>
        )
    }
}
