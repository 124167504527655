import React, { Component } from 'react'
import Navigation from '../../../components/navigation/navigation'
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import Container from '../../../components/container/container'
import Footer from '../../../components/footer/footer'
import request from '../../../helpers/request'
import endpoints from '../../../helpers/endpoints'
import { view } from '@risingstack/react-easy-state'
import './vacantCOTAlerts.scss';

import { Link, withRouter, matchPath } from 'react-router-dom'
import session from '../../../stores/session'
import HelpVideo from '../../../components/help-video/helpVideo'
import useWSProduct from '../../../helpers/useWSProduct'

// import {DeedPDFViewer} from './voidSalesAlertsSearch'
import {GoogleEvidence,AddressEvidence,YellComEvidence,CompaniesHouseEvidence,RightColumn, LeftColumn, FoodStandardsEvidence, BTDirectoryEvidence, CharityCommisionEvidence, DVSAEvidence, CQCEvidence, JustEatEvidence, One92Evidence, FacebookEvidence, TitleOwnerRow} from './sharedComponents'
import ProductIcons from '../../../helpers/productIcons'
import ReactModal from 'react-modal'
import PropertyMap from '../../../components/property-map/property-map'
import PageTitle from '../../../components/page-header/pageTitle'
import DatePicker from 'react-datepicker'
import Select from "react-select";

export default view(
    withRouter(
        class VacantCOTAlertsWorkflow extends Component {
            constructor(props) {
                super(props)

                this.state = {
                    translations: null,
                    postcodeModal: false,
                    confirmationModal: false,
                    abpIndex: 0,
                    accountNumber: null,
                    customer: null,
                    moveInDate: null,
                    SIC_Code: null,
                    customPostcode: null,
                    ABPAddresses: null,
                    addUserDisplay: {indexToDisplay: null, customContacts: {}},
                    loading: false,

                    pathname: null,
                    workflowIndex: null,
                    wholesaler: null,
                    adcGroup: null,
                    date: null,

                    errors: {moveIn: null, SIC_Code: null, directors: null, confirmation: null},
                }
            }

            async componentDidMount() {
                useWSProduct('void_sales_alerts');// eslint-disable-line
                request(true).get('/translate?key=vacant_cot_alerts.').then(r => {
                    this.setState({
                        translations: r.data
                    });
                }).catch(e => {
                    console.error(e);
                })


                const {history} = this.props;

                const spid = history.location?.state?.spid;
                const index = history.location?.state?.index;
                const wholesaler = history.location?.state?.wholesaler;
                const adcGroup = history.location?.state?.adcGroup;
                const date = history.location?.state?.date;
                const pathname = history.location?.state?.prevPath;

                if (!spid) {
                    this.props.history.goBack();
                }
                
                this.setState({accountNumber: spid, pathname: pathname, workflowIndex: index, wholesaler: wholesaler, adcGroup: adcGroup, date: date}, () => {
                    request(true).get(`${endpoints.VACANT_COT_ALERTS_GET_CUSTOMER}?search=${this.state.accountNumber}`).then(r => {
                        this.setState(prevState => ({
                            customer: r.data.customer,
                            addUserDisplay: {
                                customContacts: {
                                    ...prevState.addUserDisplay.customContacts,
                                    ...[1, 2, 3].reduce((acc, groupNumber) => {
                                        const firstName = r.data.customer[`CH_Director_First_Name_${groupNumber}`];
                                        const lastName = r.data.customer[`CH_Director_Second_Name_${groupNumber}`];
                                        
                                        if (!firstName && !lastName) {
                                            acc[groupNumber] = false;
                                        }
                                        
                                        return acc;
                                    }, {})
                                }
                            }
                        }))
                    })
                })
            }

            getABPPostcodeData = () => {
                if (!this.state.customPostcode) return
                request(true).get(endpoints.VACANT_COT_ALERTS_ABP_BY_POSTCODE, {
                    params: {
                        postcode: this.state.customPostcode || this.state.customer.CL_Postcode || this.state.customer.ABP_Postcode
                    }
                }).then(r => {
                    const index = r.data.findIndex((address) => address.UPRN == this.state.customer.UPRN) 
                    this.setState({
                        ABPAddresses: r.data,
                        abpIndex: index !== -1 ? index : 0
                    })
                }).catch(error => {
                    console.log(error)
                })
            }

            submit = () => {
                let moveInError;
                const now = new Date();
                const eighteenMonthsAgo = new Date(now.setMonth(now.getMonth() - 18));
                if (!this.state.moveInDate) {
                    moveInError = "Move in date is required";
                } else if (this.state.moveInDate <= eighteenMonthsAgo) {
                    moveInError = "Move in must be completed manually due to RF errors";
                }
                this.setState({
                    errors: {
                        moveIn: moveInError ?? null,
                        SIC_Code: !this.state.customer.GTV_SIC_Code && !this.state.SIC_Code ? "SIC Code is required" : null,
                        directors: !this.state.customer.CH_Director_Second_Name_1 ? "At least one contact is required" : null,
                    }
                }, () => {
                    if (this.state.errors.moveIn || this.state.errors.SIC_Code || this.state.errors.directors) {
                        console.log(Object.values(this.state.errors).filter(error => error !== ''))
                        window.alert(Object.values(this.state.errors).filter(error => error !== '' && error !== null).join('\n'))
                        return;
                    }

                    this.setState({loading: 'true'})

                    request(true).post(endpoints.VACANT_COT_ALERTS_CONFIRMATION, {
                        "customer": this.state.customer,
                        "occupier_purchase_type": "IDenteq",
                        "moveInDate": this.state.moveInDate.toISOString(),
                        "SIC_Code": this.state.SIC_Code,
                    }).then(r => {
                        this.setState({loading: false, confirmationModal: true})
                    }).catch(error => {
                        console.log(error)
                        this.setState({loading: false})
                        if (error.response && error.response.status == 404 || error.response.status == 400)  {
                            this.setState({errors: {...this.state.errors, confirmation: error.response.data}, confirmationModal: true})
                        } else {
                            window.alert("Submission error")
                        }
                    })
                })
            }

            handleGoBack = () => {
                let pathname = this.state.pathname
                if (pathname && pathname.indexOf('confirmation') !== -1) {
                    pathname = '/portal/vacant-cot-alerts/'
                }
                this.props.history.push({
                    pathname: this.state.pathname ? this.state.pathname : '/portal/vacant-cot-alerts/', 
                    state: {spid: this.state.accountNumber, workflowIndex: this.state.workflowIndex, wholesaler: this.state.wholesaler, adcGroup: this.state.adcGroup, date: this.state.date}
                }) 
            }

            updateSICCode = (value) => {
                this.setState((prevState) => ({
                        SIC_Code: value, 
                        errors: {...prevState.errors, SIC_Code: null}
                    }
                ))
            }

            updateMoveInDate = (date) => {
                const formattedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
                this.setState(prevState => ({
                        moveInDate: formattedDate, 
                        errors: {...prevState.errors, moveIn: null}
                    }
                ))
            }

            cycleIndex = (change = 0) => {
                if (this.state.abpIndex + change <= -1) return
                if (this.state.abpIndex + change >= this.state.ABPAddresses.length) return
                this.setState({abpIndex: this.state.abpIndex + change})
            }

            customFilterOption = (option, inputValue) => {
                if (!inputValue) {
                    return true;
                }

                return option.value.startsWith(inputValue);
            };

            componentDidUpdate(prevProps, prevState) {
                if (this.state.customer !== prevState.customer) {
                    const updatedCustomContacts = {};
                    let updatedIndexToDisplay = null;
                
                    Object.keys(this.state.addUserDisplay.customContacts).forEach(groupNumber => {
                        const firstName = this.state.customer[`CH_Director_First_Name_${groupNumber}`];
                        const lastName = this.state.customer[`CH_Director_Second_Name_${groupNumber}`];
                
                        if (firstName || lastName) {
                            updatedCustomContacts[groupNumber] = true;
                        } else {
                            updatedCustomContacts[groupNumber] = false;
                        }
                
                        if (firstName || lastName) {
                            updatedIndexToDisplay = null;
                        }
                    });
                
                    this.setState(prevState => ({
                        customer: this.state.customer,
                        addUserDisplay: {
                            customContacts: {
                                ...prevState.addUserDisplay.customContacts,
                                ...updatedCustomContacts
                            },
                            indexToDisplay: updatedIndexToDisplay
                        }
                    }));
                }
            }

            render() {
                const customerDataFields = [
                    { title: 'vacant-cot-alerts.unit_number', property: 'CL_Primary_Addressable_Object' },
                    { title: 'vacant-cot-alerts.house_number', property: 'CL_Secondary_Addressable_Object' },
                    { title: 'vacant-cot-alerts.building', property: 'CL_Address_Line_1' },
                    { title: 'vacant-cot-alerts.estate', property: 'CL_Address_Line_3' },
                    { title: 'vacant-cot-alerts.street_name', property: 'CL_Address_Line_2' },
                    { title: 'vacant-cot-alerts.locality', property: 'CL_Address_Line_4' },
                    { title: 'vacant-cot-alerts.post_town', property: 'CL_Address_Line_5' },
                    { title: 'vacant-cot-alerts.postcode', property: 'CL_Postcode' },
                ];
                const abpDataFields = [
                    { title: 'vacant-cot-alerts.unit_number', property: this.state.ABPAddresses?.length > 0 ? 'SUB_BUILDING' : 'ABP_Sub_Building' },
                    { title: 'vacant-cot-alerts.house_number', property: this.state.ABPAddresses?.length > 0 ? 'BUILDING_NUMBER' : 'ABP_Building_Number' },
                    { title: 'vacant-cot-alerts.building', property: this.state.ABPAddresses?.length > 0 ? 'BUILDING_NAME' : 'ABP_Building_Name' },
                    { title: 'vacant-cot-alerts.estate', property: '' }, 
                    { title: 'vacant-cot-alerts.street_name', property: this.state.ABPAddresses?.length > 0 ? 'STREET_NAME' : 'ABP_Street_Name' },
                    { title: 'vacant-cot-alerts.locality', property: this.state.ABPAddresses?.length > 0 ? 'LOCALITY' : 'ABP_Locality' },
                    { title: 'vacant-cot-alerts.post_town', property: this.state.ABPAddresses?.length > 0 ? 'TOWN_NAME' : 'ABP_Town_Name' },
                    { title: 'vacant-cot-alerts.postcode', property: this.state.ABPAddresses?.length > 0 ? 'POSTCODE' : 'ABP_Postcode' },
                ]

                const sic_codes = [
                    {value: '0100', label: '0100 - Agriculture & horticulture'},
                    {value: '0200', label: '0200 - Forestry'},
                    {value: '0300', label: '0300 - Fishing'},
                    {value: '1113', label: '1113 - Deep coal mines'},
                    {value: '1114', label: '1114 - Opencast coal working'},
                    {value: '111', label: '111 - Unknown'},
                    {value: '1300', label: '1300 - Extraction of mineral oil & nat. gas'},
                    {value: '2100', label: '2100 - Prod. & dist. of met. ores'},
                    {value: '2396', label: '2396 - Extraction of other minerals not elsewhere specified'},
                    {value: '2310', label: '2310 - Ext. of stone, clay, sand etc.'},
                    {value: '2330', label: '2330 - Salt extraction & refining'},
                    {value: '4126', label: '4126 - Animal by-product processing'},
                    {value: '4123', label: '4123 - Poultry slaughter and processing'},
                    {value: '4122', label: '4122 - Bacon curing and meat processing'},
                    {value: '4150', label: '4150 - Fish processing'},
                    {value: '4147', label: '4147 - Processing of fruit and vegetables'},
                    {value: '4283', label: '4283 - Soft drinks'},
                    {value: '4116', label: '4116 - Processing organic oils and fats (other than crude animal fat production)'},
                    {value: '4115', label: '4115 - Margarine and compound cooking fats'},
                    {value: '4130', label: '4130 - Prep. of milk & milk prods.'},
                    {value: '4213', label: '4213 - Ice cream'},
                    {value: '4160', label: '4160 - Grain milling'},
                    {value: '4239', label: '4239 - Miscellaneous foods'},
                    {value: '4196', label: '4196 - Bread and flour confectionery'},
                    {value: '4200', label: '4200 - Sugar & sugar by-products'},
                    {value: '4214', label: '4214 - Cocoa, chocolate and sugar confectionery'},
                    {value: '4221', label: '4221 - Compound animal feeds'},
                    {value: '4222', label: '4222 - Pet foods and non-compound animal feeds'},
                    {value: '4240', label: '4240 - Spirit distilling etc.'},
                    {value: '4261', label: '4261 - Wines, cider and perry'},
                    {value: '4270', label: '4270 - Brewing & malting'},
                    {value: '4290', label: '4290 - Tobacco industry'},
                    {value: '4321', label: '4321 - Spinning and doubling on the cotton system'},
                    {value: '4322', label: '4322 - Weaving of cotton, silk and manmade fibres'},
                    {value: '4370', label: '4370 - Textile finishing'},
                    {value: '4364', label: '4364 - Warp knitted goods'},
                    {value: '4399', label: '4399 - Other miscellaneous textiles'},
                    {value: '4555', label: '4555 - Soft furnishings'},
                    {value: '4556', label: '4556 - Canvas goods, sacks and other made-up textiles'},
                    {value: '4557', label: '4557 - Household textiles'},
                    {value: '4384', label: '4384 - Woven carpets and rugs,'},
                    {value: '4385', label: '4385 - Needled and bonded carpets, carpeting and rugs'},
                    {value: '4396', label: '4396 - Rope, twine and net'},
                    {value: '4532', label: '4532 - Men\'s and boys\' tailored outerwear'},
                    {value: '4534', label: '4534 - Work clothing and men\'s and boys\' jeans'},
                    {value: '4531', label: '4531 - Weatherproof outerwear'},
                    {value: '4535', label: '4535 - Men\'s and boys\' shirts, underwear and nightwear'},
                    {value: '4536', label: '4536 - Women\'s and girls\' light outerwear, lingerie and infants\' wear'},
                    {value: '4537', label: '4537 - Felt hats, caps and millinery'},
                    {value: '4560', label: '4560 - Fur goods'},
                    {value: '4363', label: '4363 - Hosiery and other weft knitted goods and fabrics'},
                    {value: '4410', label: '4410 - Leather (tanning and dressing) & fellmongery'},
                    {value: '4420', label: '4420 - Leather goods'},
                    {value: '4510', label: '4510 - Footwear'},
                    {value: '4610', label: '4610 - Sawmilling, planing etc. of wood'},
                    {value: '4620', label: '4620 - Man. of semi-finished wood etc'},
                    {value: '4630', label: '4630 - Builders\' carpentry & joinery'},
                    {value: '4640', label: '4640 - Wooden containers'},
                    {value: '4650', label: '4650 - Other wooden articles'},
                    {value: '4710', label: '4710 - Pulp, paper & board'},
                    {value: '4724', label: '4724 - Packaging products of paper and pulp'},
                    {value: '4725', label: '4725 - Packaging products of board'},
                    {value: '4722', label: '4722 - Household and personal hygiene products of paper'},
                    {value: '4723', label: '4723 - Stationery'},
                    {value: '4721', label: '4721 - Wall coverings'},
                    {value: '4728', label: '4728 - Other paper and board products'},
                    {value: '4751', label: '4751 - Publishers of newspapers'},
                    {value: '4754', label: '4754 - Other publishers'},
                    {value: '3452', label: '3452 - Gramophone records and pre-recorded tapes'},
                    {value: '1200', label: '1200 - Coke ovens'},
                    {value: '1401', label: '1401 - Mineral oil refining'},
                    {value: '1402', label: '1402 - Other treatment of petroleum products (excluding petrochemicals manufacture)'},
                    {value: '2567', label: '2567 - Miscellaneous chemical products for industrial use'},
                    {value: '2516', label: '2516 - Dyestuffs and pigments'},
                    {value: '2511', label: '2511 - Inorganic chemicals except industrial gases'},
                    {value: '2512', label: '2512 - Basic organic chemicals except specialised pharmaceutical chemicals'},
                    {value: '2513', label: '2513 - Fertilisers'},
                    {value: '2514', label: '2514 - Synthetic resins and plastics materials'},
                    {value: '2515', label: '2515 - Synthetic rubber'},
                    {value: '2568', label: '2568 - Formulated pesticides'},
                    {value: '2551', label: '2551 - Paints, varnishes and painters\' fillings'},
                    {value: '2552', label: '2552 - Printing ink'},
                    {value: '2581', label: '2581 - Soap and synthetic detergents'},
                    {value: '2599', label: '2599 - Chemical products, not elsewhere specified'},
                    {value: '2565', label: '2565 - Explosives'},
                    {value: '2562', label: '2562 - Formulated adhesives and sealants'},
                    {value: '2564', label: '2564 - Essential oils and flavouring materials'},
                    {value: '2570', label: '2570 - Pharmaceutical products'},
                    {value: '4811', label: '4811 - Rubber tyres and inner tubes'},
                    {value: '4812', label: '4812 - Other rubber products'},
                    {value: '4832', label: '4832 - Plastics semi-manufactures'},
                    {value: '4835', label: '4835 - Plastics packaging products'},
                    {value: '4833', label: '4833 - Plastics floorcoverings'},
                    {value: '4836', label: '4836 - Plastic products not elsewher specified'},
                    {value: '2471', label: '2471 - Flat glass'},
                    {value: '2478', label: '2478 - Glass containers'},
                    {value: '2479', label: '2479 - Other glass products'},
                    {value: '2481', label: '2481 - Refractory goods'},
                    {value: '2489', label: '2489 - Ceramic goods'},
                    {value: '2410', label: '2410 - Structural clay products'},
                    {value: '2420', label: '2420 - Cememt, lime & plaster'},
                    {value: '2437', label: '2437 - Other building products of concrete, cement or plaster'},
                    {value: '2426', label: '2426 - Unknown'},
                    {value: '2450', label: '2450 - Working stone & non-met.mins.'},
                    {value: '2460', label: '2460 - Abrasive products'},
                    {value: '2210', label: '2210 - Iron & steel industry'},
                    {value: '2220', label: '2220 - Steel tubes'},
                    {value: '2235', label: '2235 - Other drawing, cold rolling and cold forming of steel'},
                    {value: '2234', label: '2234 - Drawing and manufacture of steel wire and steel wire products'},
                    {value: '2247', label: '2247 - Other non-ferrous metals and their alloys'},
                    {value: '2245', label: '2245 - Aluminium and aluminium alloys'},
                    {value: '2246', label: '2246 - Copper, brass and other copper alloys'},
                    {value: '1520', label: '1520 - Nuclear fuel production'},
                    {value: '3111', label: '3111 - Ferrous metal foundries'},
                    {value: '3112', label: '3112 - Non-ferrous metal foundries'},
                    {value: '3204', label: '3204 - Fabricated constructional steelwork'},
                    {value: '3142', label: '3142 - Metal doors, windows, etc'},
                    {value: '3284', label: '3284 - Refrigerating machinery, space heating, ventilating and air conditioning equipment'},
                    {value: '3163', label: '3163 - Metal storage vessels (mainly non-industrial)'},
                    {value: '3205', label: '3205 - Boilers and process plant fabrications'},
                    {value: '3290', label: '3290 - Ordnance, small arms etc.'},
                    {value: '3120', label: '3120 - Forging pressing, stamping'},
                    {value: '3138', label: '3138 - Heat and surface treatment of metals, including sintering'},
                    {value: '3162', label: '3162 - Cutlery, spoons, forks and similar tableware; razors'},
                    {value: '3169', label: '3169 - Finished metal products, not elsewhere specified'},
                    {value: '3161', label: '3161 - Hand tools and implements'},
                    {value: '3164', label: '3164 - Packaging products of metal'},
                    {value: '3137', label: '3137 - Bolts, nuts, washers, rivets, springs and non-precision chains'},
                    {value: '3302', label: '3302 - Electronic data processing equipment'},
                    {value: '3441', label: '3441 - Telegraph and telephone apparatus and equipment'},
                    {value: '3454', label: '3454 - Electronic consumer goods and other electronic equipment not elsewhere specified'},
                    {value: '3443', label: '3443 - Radio and electronic capital goods'},
                    {value: '3442', label: '3442 - Electrical instruments and control systems'},
                    {value: '3710', label: '3710 - Precision instruments'},
                    {value: '3740', label: '3740 - Clocks, timing devices'},
                    {value: '3732', label: '3732 - Optical precision instruments'},
                    {value: '3420', label: '3420 - Basic electrical equipment'},
                    {value: '3432', label: '3432 - Batteries and accumulators'},
                    {value: '3410', label: '3410 - Insulated wires & cables'},
                    {value: '3470', label: '3470 - Lighting equipment etc.'},
                    {value: '3460', label: '3460 - Domestic-type electric apps.'},
                    {value: '3165', label: '3165 - Domestic heating and cooking appliances (non-electrical)'},
                    {value: '3281', label: '3281 - Internal combustion engines (except for road vehicles, wheeled tractors primarily for agricultural purposes and aircraft) and other prime movers'},
                    {value: '3283', label: '3283 - Compressors and other fluid power equipment'},
                    {value: '3287', label: '3287 - Pumps'},
                    {value: '3261', label: '3261 - Precision chains and other mechanical power transmission equipment'},
                    {value: '3245', label: '3245 - Chemical industry machinery; furnaces and kilns; gas, water and waste treatment plant'},
                    {value: '3255', label: '3255 - Mechanical lifting and handling equipment'},
                    {value: '3285', label: '3285 - Scales, weighing machinery and portable power tools'},
                    {value: '3212', label: '3212 - Wheeled tractors'},
                    {value: '3211', label: '3211 - Agricultural machinery'},
                    {value: '3221', label: '3221 - Metal working machine tools'},
                    {value: '3275', label: '3275 - Machinery for working wood, rubber, plastics, leather and making paper, glass, bricks and similar materials; laundry and dry cleaning machinery'},
                    {value: '3251', label: '3251 - Mining machinery'},
                    {value: '3254', label: '3254 - Construction and earth moving equipment'},
                    {value: '3244', label: '3244 - Food, drink and tobacco processing machinery; packaging and bottling machinery'},
                    {value: '3230', label: '3230 - Textile machinery'},
                    {value: '3510', label: '3510 - Motor vehicles & engines'},
                    {value: '3521', label: '3521 - Motor vehicle bodies'},
                    {value: '3522', label: '3522 - Trailers and semi-trailers'},
                    {value: '3523', label: '3523 - Caravans'},
                    {value: '3434', label: '3434 - Electrical equipment for motor vehicles, cycles and aircraft'},
                    {value: '3530', label: '3530 - Motor vehicle parts'},
                    {value: '3610', label: '3610 - Shipbuilding & repairing'},
                    {value: '3620', label: '3620 - Railway & tramway vehicles'},
                    {value: '3640', label: '3640 - Aerospace equip. & manufacture'},
                    {value: '3633', label: '3633 - Motor cycles and parts'},
                    {value: '3634', label: '3634 - Pedal cycles and parts'},
                    {value: '3650', label: '3650 - Other vehicles'},
                    {value: '4671', label: '4671 - Wooden and upholstered furniture'},
                    {value: '4910', label: '4910 - Jewellery & coins'},
                    {value: '4942', label: '4942 - Sports goods'},
                    {value: '4941', label: '4941 - Toys and games'},
                    {value: '3169', label: '3169 - Finished metal products, not elsewhere specified'},
                    {value: '3221', label: '3221 - Metal working machine tools'},
                    {value: '1610', label: '1610 - Prod. & dist. of electricity'},
                    {value: '1620', label: '1620 - Public gas supply'},
                    {value: '1630', label: '1630 - Prod. & dist. of other energy'},
                    {value: '1700', label: '1700 - Water supply industry'},
                    {value: '9212', label: '9212 - Sewage disposal'},
                    {value: '9211', label: '9211 - Refuse disposal'},
                    {value: '6210', label: '6210 - Dealing in scrap metals'},
                    {value: '8500', label: '8500 - Owning'},
                    {value: '5010', label: '5010 - Constr. & repair of buildings'},
                    {value: '5020', label: '5020 - Civil engineering'},
                    {value: '5000', label: '5000 - Gen. construction, demo work'},
                    {value: '5030', label: '5030 - Installation, fix. & fittings'},
                    {value: '5040', label: '5040 - Building completion work'},
                    {value: '6510', label: '6510 - Retail dist. motor vehicles'},
                    {value: '6710', label: '6710 - Repair of motor vehicles etc.'},
                    {value: '6148', label: '6148 - Wholesale distribution of motor vehicles and parts and accessories'},
                    {value: '6300', label: '6300 - Commission agents'},
                    {value: '6110', label: '6110 - Wholesale dist. raw mats.etc.'},
                    {value: '6170', label: '6170 - Wholesale dist. food, drink'},
                    {value: '6160', label: '6160 - Wholesale dist. textiles etc.'},
                    {value: '6150', label: '6150 - Wholesale dist. hardware etc.'},
                    {value: '6180', label: '6180 - Wholesale dist. medical goods'},
                    {value: '6190', label: '6190 - Other w\'sale dist. w\'salers.'},
                    {value: '6420', label: '6420 - Confectioners, newsagents etc.'},
                    {value: '6560', label: '6560 - Mixed retail businesses'},
                    {value: '6410', label: '6410 - Food retailing'},
                    {value: '6450', label: '6450 - Retail dist. of clothing'},
                    {value: '6460', label: '6460 - Retail dist. of footwear etc.'},
                    {value: '6430', label: '6430 - Dispensing chemists & others'},
                    {value: '6530', label: '6530 - Retail dist. stationery etc.'},
                    {value: '6480', label: '6480 - Retail dist. hardware etc.'},
                    {value: '7700', label: '7700 - Mis. trans. servs. & storage'},
                    {value: '7901', label: '7901 - Postal services'},
                    {value: '6650', label: '6650 - Hotel trade'},
                    {value: '6670', label: '6670 - Short-stay accommodation'},
                    {value: '6611', label: '6611 - Eating places supplying food for consumption on the premises'},
                    {value: '6612', label: '6612 - Take-away food shops'},
                    {value: '6640', label: '6640 - Canteens & messes'},
                    {value: '6620', label: '6620 - Public houses & bars'},
                    {value: '6630', label: '6630 - Night clubs & licensed clubs'},
                    {value: '4753', label: '4753 - Publishers of books'},
                    {value: '4751', label: '4751 - Publishers of newspapers'},
                    {value: '4752', label: '4752 - Publishers of periodicals'},
                    {value: '4754', label: '4754 - Other publishers'},
                    {value: '8394', label: '8394 - Computer services'},
                    {value: '9711', label: '9711 - Film production distribution and exhibition'},
                    {value: '9741', label: '9741 - Radio and television services'},
                    {value: '7902', label: '7902 - Telecommunications'},
                    {value: '8140', label: '8140 - Banking & bill-discounting'},
                    {value: '8150', label: '8150 - Other financial institutions'},
                    {value: '8200', label: '8200 - Insurance'},
                    {value: '8310', label: '8310 - Activities'},
                    {value: '8320', label: '8320 - Activities aux. to insurance'},
                    {value: '8340', label: '8340 - House & estate agents'},
                    {value: '8350', label: '8350 - Legal services'},
                    {value: '8360', label: '8360 - Accountants'},
                    {value: '8396', label: '8396 - Central offices not allocable elsewhere'},
                    {value: '8370', label: '8370 - Professional'},
                    {value: '9400', label: '9400 - Research & development'},
                    {value: '8380', label: '8380 - Advertising'},
                    {value: '9760', label: '9760 - Authors'},
                    {value: '9890', label: '9890 - Personal servs. not elsewhere'},
                    {value: '4930', label: '4930 - Photo processing labs.'},
                    {value: '9560', label: '9560 - Veterinary practices etc.'},
                    {value: '8480', label: '8480 - Hiring out transport equip.'},
                    {value: '8460', label: '8460 - Hiring out consumer goods'},
                    {value: '8410', label: '8410 - Hiring out ag. & hort. equip.'},
                    {value: '8420', label: '8420 - Hiring constr. mach. & equip.'},
                    {value: '8430', label: '8430 - Hiring office mach. furniture'},
                    {value: '8490', label: '8490 - Hiring out other movables'},
                    {value: '7700', label: '7700 - Mis. trans. servs. & storage'},
                    {value: '9230', label: '9230 - Cleaning services'},
                    {value: '9111', label: '9111 - National government service not elsewhere specified'},
                    {value: '9150', label: '9150 - National defence'},
                    {value: '9120', label: '9120 - Justice'},
                    {value: '9130', label: '9130 - Police'},
                    {value: '9140', label: '9140 - Fire services'},
                    {value: '9190', label: '9190 - Social security'},
                    {value: '9320', label: '9320 - School education'},
                    {value: '9330', label: '9330 - Education not elsewhere spec\'d'},
                    {value: '9310', label: '9310 - Higher education'},
                    {value: '9791', label: '9791 - Sport and other recreational services'},
                    {value: '9360', label: '9360 - Driving & flying schools'},
                    {value: '9510', label: '9510 - Hospitals'},
                    {value: '9530', label: '9530 - Medical practices'},
                    {value: '9540', label: '9540 - Dental practices'},
                    {value: '9550', label: '9550 - Agency & private midwives etc.'},
                    {value: '9611', label: '9611 - Social welfare'},
                    {value: '9631', label: '9631 - Trade unions'},
                    {value: '9660', label: '9660 - Religious organisations etc.'},
                    {value: '9690', label: '9690 - Tourist offices & services'},
                    {value: '6730', label: '6730 - Repair of other consumer goods'},
                    {value: '9812', label: '9812 - Dry cleaning and allied services'},
                    {value: '9820', label: '9820 - Hair & beauty parlours etc.'},
                    {value: '9900', label: '9900 - Domestic services'},
                    {value: '0000', label: '0000 - Diplomatic representation, international organisations, allied armed forces'},
                ];
                
                return (
                    <div className="grid grid-gap-15" id="vaca-confirmation-page">
                        {
                            this.state.loading ? 
                                <i style={{position: 'absolute', left: '50%', top: '20%'}} className='fas fa-loader fa-fw fa-spin fa-2x'></i>
                            : null
                        }
                        <Navigation/>
                        <Container>
                            <PageTitle title="Vacant COT Alerts - Confirmation" iconClass={ProductIcons.BusinessIcon.props.className} />
                        </Container>
                        <Container>
                            <div className="vacantCOTAlerts">
                                <div className="primary-grid" style={{gridTemplateColumns: '1fr'}}>
                                    <h4 style={{backgroundColor: 'light-grey'}}>Are you sure you want to submit the following details?</h4>
                                    <div className="left-grid" style={{gridTemplateColumns: '1fr 1fr 1fr', zIndex: '1'}}>
                                        <div className="data-row datepicker">
                                            <div className="data-row-title" style={this.state.errors.moveIn ? {color: 'red'} : {}}>
                                                { this.state.translations ? this.state.translations['vacant-cot-alerts.move_in_date'] : '' }
                                            </div>
                                            <DatePicker customInput={<CustomInput value={this.state.moveInDate } />} dateFormat="ddMMMyyyy" showYearDropdown yearDropdownItemNumber={100} scrollableYearDropdown maxDate={new Date()} showIcon={true} selected={this.state.moveInDate} onChange={this.updateMoveInDate}></DatePicker>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['vacant-cot-alerts.spid'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Full_SPID : '' }/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title" style={this.state.errors.SIC_Code ? {color: 'red'} : {}}>
                                                { this.state.translations ? this.state.translations['vacant-cot-alerts.sic_code'] : '' }
                                            </div>
                                            {
                                                this.state.customer && !this.state.customer.GTV_SIC_Code ? 
                                                    <Select
                                                        options={sic_codes.map((option) => {
                                                            return (
                                                                {value: option.value, label: option.label}
                                                            )
                                                        })}
                                                        onChange={(selectedOption) => this.updateSICCode(selectedOption)}
                                                        value={this.state.SIC_Code}
                                                        isSearchable
                                                        placeholder={'Please select a SIC Code'}
                                                        filterOption={this.customFilterOption}
                                                        styles={{
                                                            control: base => ({
                                                                ...base,
                                                                height: 34,
                                                                minHeight: 34,
                                                                paddingTop: 0
                                                            }),
                                                            ValueContainer: base => ({
                                                                ...base,
                                                                paddingTop: 0
                                                            })
                                                          }}
                                                    />
                                                :
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.GTV_SIC_Code : '' }/>
                                            }
                                        </div>
                                        <div className="data-row" style={{zIndex: '-1'}}>
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['vacant-cot-alerts.company_name'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Company_Name : '' }/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['vacant-cot-alerts.company_number'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Company_Number : '' }/>
                                        </div>
                                        {
                                            !this.state.customer || !this.state.customer.Company_Number ? 
                                                <div className="data-row" style={{zIndex: '-1'}}>
                                                    <div className="data-row-title">
                                                        { this.state.translations ? this.state.translations['vacant-cot-alerts.telephone'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" value={ this.state.customer ? this.state.customer.Landline ? this.state.customer.Landline : this.state.customer.Mobile ? this.state.customer.Mobile : '' : '' } onChange={(event) => this.setState({customer: {...this.state.customer, Landline: event.target.value }})} />
                                                </div>
                                            :
                                                ''
                                        }
                                    </div>
                                    <div className="left-grid" style={{gridTemplateColumns: '1fr 1fr'}}>
                                        <div className="data-row" style={{gridTemplateColumns: 'unset'}}>
                                            <div className="data-row-title" style={{width: 'auto', borderRadius: '5px'}}>
                                                { this.state.translations ? this.state.translations['vacant-cot-alerts.supply_address'] : '' }
                                            </div>
                                        </div>
                                        <div className="data-row" style={{ gridTemplateColumns: 'unset' }}>
                                            <div className="data-row-title" style={{ width: 'auto', borderRadius: '5px', gap: '10px' }}>
                                                {this.state.translations ? this.state.translations['vacant-cot-alerts.billing_address'] : ''}
                                                <div style={{ display: 'flex', position: 'absolute', right: '10%', gap: '10px' }}>
                                                    <div style={{ visibility: this.state.customPostcode !== this.state.customer?.ABP_Postcode && this.state.ABPAddresses ? 'visible' : 'hidden', flexDirection: 'row', alignItems: 'center' }}>
                                                        <span style={{ cursor: 'pointer', marginLeft: '5px' }} className='fas fa-trash-undo' onClick={() => { this.setState({ customPostcode: null, ABPAddresses: null }) }} title="Restore original postcode search"></span>
                                                    </div>
                                                    <div style={{ visibility: 'visible', flexDirection: 'row', alignItems: 'center' }}>
                                                        <span style={{ cursor: 'pointer' }} className='fas fa-search' onClick={() => { this.setState({ postcodeModal: true }) }} title="Search for a different postcode"></span>
                                                    </div>
                                                    <div style={{ visibility: this.state.ABPAddresses?.length ? 'visible' : 'hidden', flexDirection: 'row', alignItems: 'center' }}>
                                                        <span style={{ cursor: 'pointer' }} className='fas fa-angle-left' onClick={() => this.cycleIndex(-1)}></span>
                                                        <span style={{ minWidth: 60, textAlign: 'center' }}>{this.state.abpIndex + 1}/{this.state.ABPAddresses?.length}</span>
                                                        <span style={{ cursor: 'pointer' }} className='fas fa-angle-right' onClick={() => this.cycleIndex(1)}></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='left-grid'>
                                            {
                                                customerDataFields.map(field => {
                                                    return (
                                                        <div className="data-row" style={{zIndex: '-1'}}>
                                                        <div className="data-row-title">
                                                            { this.state.translations ? this.state.translations[field.title] : '' }
                                                        </div>
                                                        <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer[field.property] : '' }/>
                                                    </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='left-grid'>
                                            {
                                                abpDataFields.map(field => {
                                                    return (
                                                        <div className="data-row" style={{zIndex: '-1'}}>
                                                        <div className="data-row-title">
                                                            { this.state.translations ? this.state.translations[field.title] : '' }
                                                        </div>
                                                        <input type="text" className="data-row-field" disabled={true} value={
                                                            this.state.ABPAddresses?.length ? 
                                                                this.state.ABPAddresses[this.state.abpIndex][field.property]
                                                            :
                                                                this.state.customer ? this.state.customer[field.property] : '' 
                                                        }/>
                                                    </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    {
                                        this.state.customer ?
                                                <div className='left-grid' style={{gridTemplateColumns: '1fr 1fr 1fr', borderTop: '4px dashed #e8e8e8', paddingTop: '15px'}}>
                                                    {[
                                                        ...[1, 2, 3].filter(number => !Object.keys(this.state.addUserDisplay.customContacts).includes(String(number))),
                                                        ...Object.keys(this.state.addUserDisplay.customContacts).sort((a, b) => a - b)
                                                    ].map(groupNumber => {
                                                    const firstName = this.state.customer[`CH_Director_First_Name_${groupNumber}`];
                                                    const lastName = this.state.customer[`CH_Director_Second_Name_${groupNumber}`];

                                                    if (!Object.keys(this.state.addUserDisplay.customContacts).includes(groupNumber) || this.state.addUserDisplay.indexToDisplay === groupNumber || this.state.addUserDisplay.customContacts[groupNumber] === true ) {
                                                        return (
                                                            <div style={{display: 'flex', gap: '5px'}}>
                                                                <div style={{width: '100%'}} className='left-grid' id={groupNumber}>
                                                                    <div className="data-row">
                                                                        <div className="data-row-title" style={this.state.errors.directors ? {color: 'red'} : {}}>
                                                                            { this.state.translations ? this.state.translations['vacant-cot-alerts.first_name'] : '' }
                                                                        </div>
                                                                        <input type="text" className="data-row-field" value={ firstName || '' } onChange={(event) => this.setState({customer: {...this.state.customer, [`CH_Director_First_Name_${groupNumber}`]: event.target.value} })} />
                                                                    </div>
                                                                    <div className="data-row">
                                                                        <div className="data-row-title" style={this.state.errors.directors ? {color: 'red'} : {}}>
                                                                            { this.state.translations ? this.state.translations['vacant-cot-alerts.last_name'] : '' }
                                                                        </div>
                                                                        <input type="text" className="data-row-field" value={ lastName || '' } onChange={(event) => this.setState({customer: {...this.state.customer, [`CH_Director_Second_Name_${groupNumber}`]: event.target.value}, errors: {...this.state.errors, directors: null} })} />
                                                                    </div>
                                                                    <div className="data-row">
                                                                        <div className="data-row-title" style={this.state.errors.directors ? {color: 'red'} : {}}>
                                                                            { this.state.translations ? this.state.translations['vacant-cot-alerts.telephone'] : '' }
                                                                        </div>
                                                                        <input type="text" className="data-row-field" value={ this.state.customer ? this.state.customer.Landline ? this.state.customer.Landline : this.state.customer.Mobile ? this.state.customer.Mobile : '' : '' } onChange={(event) => this.setState({customer: {...this.state.customer, Landline: event.target.value }})} />
                                                                    </div>
                                                                </div>
                                                                { Object.keys(this.state.addUserDisplay.customContacts).includes(groupNumber) ? 
                                                                    <i onClick={() => this.setState(prevState => ({ 
                                                                        customer: {
                                                                            ...prevState.customer, 
                                                                            [`CH_Director_First_Name_${groupNumber}`]: null, 
                                                                            [`CH_Director_Second_Name_${groupNumber}`]: null
                                                                        },
                                                                        addUserDisplay: {
                                                                            indexToDisplay: null,
                                                                            customContacts: {
                                                                                ...prevState.addUserDisplay.customContacts,
                                                                                [groupNumber]: false
                                                                            }
                                                                        }
                                                                    }))} className="fa-solid fa-minus" style={{cursor: 'pointer'}}></i>
                                                                : null}
                                                            </div>
                                                        )
                                                    }
                                                })}

                                                {Object.keys(this.state.addUserDisplay.customContacts).length > 0 && !this.state.addUserDisplay.indexToDisplay && Object.values(this.state.addUserDisplay.customContacts).some(value => value === false) &&
                                                    <div className='add-user' onClick={() => this.setState(prevState => ({ addUserDisplay: {...prevState.addUserDisplay, indexToDisplay: Object.keys(this.state.addUserDisplay.customContacts).find(key => this.state.addUserDisplay.customContacts[key] === false) }}))}>
                                                        <div style={this.state.errors.directors ? {color: 'red'} : {}}>
                                                            <i className="fa-solid fa-user-plus fa-xl"></i>
                                                        </div>
                                                        <p style={this.state.errors.directors ? {color: 'red'} : {}}>Add Contact</p>
                                                    </div>    
                                                }


                                            </div>
                                        :
                                            ''
                                    }
                                </div>
                                <div style={{display: 'flex', justifyContent: 'flex-end', gap: '15px'}}>
                                    <button style={{fontWeight: "100"}} className="button compact cancel" onClick={() => { 
                                        this.handleGoBack();
                                    }}>
                                        Cancel
                                    </button>
                                    <button className="button background-primary colour-white" onClick={() => this.submit()}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </Container>
                        <Footer />
                        <ReactModal 
                            isOpen={this.state.postcodeModal} 
                            onRequestClose={() => this.setState({postcodeModal: false})}
                            className="card bulk-allocate"
                            contentLabel="Postcode search"
                            style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)', zIndex: '99999' } }}>
                                <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                                <h3>Postcode Search</h3>
                                <input autoFocus value={this.state.customPostcode} onChange={_ => this.setState({customPostcode: _.target.value})} style={{border: '1px solid gray', width: '100%', borderRadius: 3}} />
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                                    <button className='button compact smaller-text background-primary' onClick={() => {this.getABPPostcodeData(); this.setState({postcodeModal: false})}}>Search</button> 
                                </div>
                                </div>
                        </ReactModal>
                        <ReactModal 
                            isOpen={this.state.confirmationModal} 
                            // onRequestClose={() => this.setState({postcodeModal: false})}
                            className="card bulk-allocate"
                            contentLabel="Confirmation modal"
                            style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)', zIndex: '99999' }, content: {borderTop: this.state.errors.confirmation ? '5px solid var(--red)' : '5px solid var(--primary)'} }}>
                                <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                                    <h3>Submission {this.state.errors.confirmation ? "Failed" : "Successful"}</h3>
                                    {
                                        this.state.errors.confirmation ? 
                                            <p>Error Creating Account - please create manually in Gentrack</p>
                                        :
                                            <>
                                                <p style={{whiteSpace: "pre-line"}}>Account Created - for SPIDs: {'\n'}
                                                    - Water: {this.state.customer?.Full_SPID} {'\n'}
                                                    - Waste: {this.state.customer?.Other_SPID}</p> 
                                            </>
                                    }
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                                        <button className={`button compact smaller-text ${this.state.errors.confirmation ? "background-red" : "background-primary"} colour-white`} onClick={() => {this.state.errors.confirmation ? this.setState({confirmationModal: false}) : this.handleGoBack()}}>Okay</button> 
                                    </div>
                                </div>
                        </ReactModal>
                    </div>
                )
            }
        }
    )
)


function CustomInput(props) {
    return (
        <input style={{width: '100%'}} value={props.value.toUpperCase()} onClick={props.onClick} onChange={null} />
    )
  }