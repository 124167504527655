import React, { Component, useEffect } from 'react'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import Container from '../../../components/container/container'
import request from '../../../helpers/request'
import endpoints, { ADMIN_COMPANIES_COMPANY } from '../../../helpers/endpoints'
import { view } from '@risingstack/react-easy-state'
import './GapSitesAlerts.scss';
import PropertyMap from '../../../components/property-map/property-map'

import { Link, withRouter, matchPath } from 'react-router-dom'
import session from '../../../stores/session'
import hasPermission from '../../../helpers/permissions';
import useWSProduct from '../../../helpers/useWSProduct'
import ReactModal from 'react-modal'

export default view(
    withRouter(
        class GapSitesAlertsWorkflow extends Component {
            constructor(props) {
                super(props)

                this.state = {
                    match: matchPath(props.history.location.pathname, {
                        path: '/portal/gap-sites-alerts/workflow/:ABP_UPRN?'
                    }),
                    accountNumber: '',
                    customer: null,
                    showMap: true,
                    purchasable: null,
                    shouldRefreshMap: false,
                    workflow: [],
                    workflowIndex: null,
                    workflowLength: 0,
                    translations: {},
                    exclusionReason: 'null',
                    detailsModalIsOpen: false,
                    matchedAddress: null,
                    ABP_ADDRESS_ID: null,
                    addressData: null,
                    occupierDetails: {},
                    category: null,
                    state: null,
                    rejectionReason: null,
                    propertyType: null,
                    loading: false,
                }
            }

            componentDidMount() {
                useWSProduct('gap_sites_alerts');// eslint-disable-line
                request(true).get('/translate?key=gsa.').then(r => {
                    this.setState({
                        translations: r.data
                    });
                }).catch(e => {
                    console.error(e);
                })
                this.setState({accountNumber: this.state.match.params.ABP_UPRN ? this.state.match.params.ABP_UPRN : '', loading: true}, () => this.getWorkflow())
            }

            getWorkflow = () => {
                request(true).get(endpoints.GAP_SITES_ALERTS_WORKFLOW).then(r => {
                    this.setState({
                        workflow: r.data,
                        workflowIndex: 0,
                        workflowLength: r.data.length,
                        accountNumber: this.state.accountNumber ? this.state.accountNumber : r.data[0],
                    }, () => {
                        if (this.state.accountNumber) {
                            this.getData();
                        } else {
                            this.setState({loading: false})
                        }
                    });
                }).catch(e => {
                    console.error(e);
                    window.alert('Failed to get workflow');
                    this.setState({loading: false})
                });
            }

            componentWillUnmount() {
                useWSProduct('gap_sites_alerts', 'unlisten');// eslint-disable-line
                clearInterval(this.tokenRefresh);
            }

            getData = () => {
                if (!this.state.accountNumber) return
                request(true).get(`${endpoints.GAP_SITES_ALERTS_GET_CUSTOMER}?search=${this.state.accountNumber}`).then(r => {
                    if (!r.data || !r.data.customer) window.alert("No data found for UPRN " + this.state.accountNumber)
                    console.log(r.data.customer);
                    this.setState({
                        ABP_ADDRESS_ID: null,
                        matchedAddress: null,
                        customer: r.data.customer ? r.data.customer : null,
                        occupierDetails: r.data.occupier_details ? r.data.occupier_details : null,
                        addressData: r.data.addresses ? r.data.addresses : null,
                        showMap: true,
                        purchasable: r.data.customer && r.data.customer.Date_Bought === null,
                        shouldRefreshMap: true,
                        rejectionReason: r.data.customer.Rejection_Reason ? r.data.customer.Rejection_Reason : null,
                        state: r.data.customer.state ? r.data.customer.state : null
                    }, () => {
                        this.setState({
                            shouldRefreshMap: false,
                            loading: false
                        });
                    });
                }).catch(e => {
                    console.log(e);
                    window.alert('Failed to retrieve data');
                    this.setState({
                        ABP_ADDRESS_ID: null,
                        matchedAddress: null,
                        customer: null,
                        occupierDetails: null,
                        addressData: null,
                        showMap: true,
                        purchasable: false,
                        shouldRefreshMap: true,
                        rejectionReason: null,
                        state: null,
                        loading: false
                    })
                });
            }

            purchaseCustomer = () => {
                this.setState({loading: true}, () => {
                    request(true).post(`${endpoints.GAP_SITES_ALERTS_BUY_CUSTOMER}`, {
                        "customer": this.state.customer?.ABP_UPRN,
                    }).then(r => {
                        this.setState({occupierDetails: r.data?.customer, loading: false})
                    }).catch(e => {
                        console.log(e);
                        window.alert('Failed to purchase')
                        this.setState({loading: false})
                    })
                });
            }

            excludeCustomer = () => {
                if (!this.state.customer) return;
                this.setState({disableActions: true}, () => {
                    request(true).post(endpoints.GAP_SITES_ALERTS_EXCLUDE_CUSTOMER, {
                        customer: this.state.customer.ABP_UPRN
                    }).then(r => {
                        const customers = [].concat(this.state.workflow);
                        customers.splice(this.state.workflowIndex, 1);

                        this.setState({
                            workflow: customers,
                            workflowLength: customers.length,
                            accountNumber: customers[this.state.workflowIndex] || null,
                        }, () => {
                            this.getData();
                        });
                    }).catch(e => {
                        console.error(e);
                    });
                });
            }

            workflowNext = () => {
                if (this.state.workflowIndex + 1 > this.state.workflow.length) return
                this.setState({
                    workflowIndex: this.state.workflowIndex + 1,
                    accountNumber: this.state.workflow[this.state.workflowIndex + 1]
                }, () => {
                    this.getData();
                });
            }

            workflowPrev = () => {
                if (this.state.workflowIndex > this.state.workflow.length) return
                this.setState({
                    workflowIndex: this.state.workflowIndex - 1,
                    accountNumber: this.state.workflow[this.state.workflowIndex - 1]
                }, () => {
                    this.getData();
                });
            }

            setCategory = (newCategory) => {
                this.setState({category: newCategory})
            }

            setStatus = (newState) => {
                this.setState({state: newState})
            }

            setRejectionReason = (rejectionReason) => {
                this.setState({rejectionReason: rejectionReason})
            }

            setPropertyType = (propertyType) => {
                this.setState({propertyType: propertyType})
            }

            setMatchedAddress = (event) => {
                if (this.state.matchedAddress === event.target.name) {
                    this.setState({matchedAddress: null, ABP_ADDRESS_ID: null})
                } else {
                    this.setState({matchedAddress: event.target.name, ABP_ADDRESS_ID: event.target.id})
                }
            }

            setAccountNumber = (value) => {
                this.setState({accountNumber: value})
            }

            submitCustomer = () => {
                this.setState({
                    loading: true
                }, () => {
                    request(true).post(endpoints.GAP_SITES_ALERTS_UPDATE_CUSTOMER, {
                        Customer: this.state.customer.ABP_UPRN,
                        Category: this.state.category,
                        State: this.state.state,
                        RejectionReason: this.state.rejectionReason,
                        PropertyType: this.state.propertyType,
                        ABP_ADDRESS_ID: this.state.ABP_ADDRESS_ID
                    }).then(r => {
                        this.setState({loading: false})
                        window.alert('Details successfully updated')
                        if (this.state.state === "GAP Accepted" || this.state.state === "GAP Rejected") {
                            const customers = [].concat(this.state.workflow);
                            customers.splice(this.state.workflowIndex, 1);
    
                            if (this.state.workflowLength > 0) {
                                this.setState({
                                    workflow: customers,
                                    workflowLength: customers.length,
                                    accountNumber: customers[this.state.workflowIndex],
                                }, () => {
                                    this.getData();
                                });
                            } else {
                                this.props.history.push('/portal/gap-sites-alerts/workflow')
                                window.location.reload()
                            }
                        }
                    }).catch(e => {
                        window.alert('Failed to submit')
                        this.setState({loading: false})
                        console.log(e);
                    });
                })
            }

            render() {
                return (
                    <GenericPageContainer
                        title="Gap Sites Alerts - Workflow"
                        titleRight={
                        <div>
                            <Link className="navigate" to="/portal/gap-sites-alerts">
                                To Summary
                            </Link>
                            { hasPermission('GSA', 'DASHBOARD') ?
                                <Link className="navigate" to="/portal/gap-sites-alerts/dashboard">
                                    To Dashboard
                                </Link>
                        : null }
                        </div>
                        }
                        containerWidth={"90vw"}
                    >
                    <div className="gsa" style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                    { this.state.loading ?
                        <i style={{ marginTop: '10px', marginLeft: '10px', position: 'absolute', top: '15vh', left: '50vw' }} className='fas fa-loader fa-fw fa-spin fa-2x'></i>
                    : ''}
                        <div style={{display: 'grid', gridTemplateColumns: '7fr 4fr', gap: 10}}>
                            <table className='table borders squish smaller-text left' style={{tableLayout: 'fixed', width: '100%'}}>
                                <col width={180} />
                                <thead>
                                    <tr>
                                        <th colSpan={8}>{this.state.translations['gsa.Header_Summary']}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width={400}>{this.state.translations['gsa.UDPRN']}</td>
                                        <td colSpan={4}>
                                            <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                                                {/* <input disabled style={{border: 'none', backgroundColor: 'white'}} type="text" value={this.state.customer?.ABP_UPRN} /> */}
                                                <input style={{border: 'none'}} type="text" onChange={e => this.setAccountNumber(e.target.value)} value={this.state.accountNumber ? this.state.accountNumber : this.state.customer?.ABP_UPRN} />
                                                {
                                                    <>
                                                        <span className='fas fa-angle-left colour-primary' style={{cursor: 'pointer'}} onClick={this.state.loading || this.state.workflowIndex === 0 ? null : this.workflowPrev }></span>
                                                        <span>{this.state.workflowIndex + 1}/{this.state.workflowLength}</span>
                                                        <span className='fas fa-angle-right colour-primary' style={{cursor: 'pointer'}} onClick={this.state.loading || this.state.workflowIndex === this.state.workflowLength - 1 ? null : this.workflowNext}></span>
                                                        <span className='fas fa-search colour-primary' style={{cursor: 'pointer'}} onClick={!this.state.loading ? this.getData : null}></span>
                                                    </>
                                                }
                                            </div>
                                        </td>
                                        <td>{this.state.translations['gsa.category']}</td>
                                        <td colSpan={2}>
                                            {this.state.customer ? this.state.customer.Category : ''}
                                            {/* <select value={this.state.customer ? this.state.customer.Category : null} onChange={_ => this.setCategory(_.target.value)} style={{width: '100%', border: 'none'}}>
                                                <option value={"Address Matched"}>Address Matched</option>
                                                <option value={"Aligned Mismatch"}>Aligned Mismatch</option>
                                                <option value={"Bulk Meter"}>Bulk Meter</option>
                                                <option value={"New Connections"}>New Connections</option>
                                                <option value={"Split Property"}>Split Property</option>
                                                <option value={"Flat Above Shop"}>Flat Above Shop</option>
                                                <option value={"Potential Gap"}>Potential Gap</option>
                                                <option value={"Commercial"}>Commercial</option>
                                                <option value={"Commercial Input"}>Commercial Input</option>
                                                <option value={"Exclude"}>Exclude</option>
                                            </select> */}
                                        </td>
                                    </tr>
                                    <tr>
                                        {/* <td colSpan={2}></td> */}
                                        <td>{this.state.translations['gsa.Status']}</td>
                                        <td colSpan={2}>
                                            <select value={this.state.customer ? this.state.state ? this.state.state : this.state.customer.state : null} onChange={_ => this.setStatus(_.target.value)} style={{width: '100%', border: 'none', backgroundColor: 'var(--light-gray)'}}>
                                                <option value={"New"}>New</option>
                                                <option value={"In Progress"}>In Progress</option>
                                                <option value={"GAP Accepted"}>GAP Accepted</option>
                                                <option value={"GAP Rejected"}>GAP Rejected</option>
                                            </select>
                                        </td>
                                        {
                                            this.state.state === "GAP Rejected" ?
                                                <>
                                                    <td colSpan={2}>Rejection Reason</td>
                                                    <td colSpan={2}>
                                                        <select disabled={this.state.loading} value={this.state.customer ? this.state.rejectionReason ? this.state.rejectionReason : this.state.customer.state : null} onChange={_ => this.setRejectionReason(_.target.value != "Select Reason" ? _.target.value : null)} style={{width: '100%', border: 'none', backgroundColor: 'var(--light-gray)'}}>
                                                            <option value={""}>Select Reason</option>
                                                            <option value={"Bulk account"}>Bulk account</option>
                                                            <option value={"New Accounts Process"}>New Accounts Process</option>
                                                            <option value={"Property is billing - Address Quality"}>Property is billing - Address Quality</option>
                                                            <option value={"Property is billing - No Action Required"}>Property is billing - No Action Required</option>
                                                            <option value={"Query with IDenteq"}>Query with IDenteq</option>
                                                            <option value={"MOD/Private Supply"}>MOD/Private Supply</option>
                                                            <option value={"Other"}>Other</option>
                                                        </select>
                                                    </td>
                                                </>
                                            :
                                            this.state.state === "GAP Accepted" ?
                                                <>
                                                    <td colSpan={2}>Property Type</td>
                                                    <td colSpan={2}>
                                                        <select disabled={this.state.loading} value={this.state.customer ? this.state.propertyType ? this.state.propertyType : this.state.customer.state : null} onChange={_ => this.setPropertyType(_.target.value != "Select Type" ? _.target.value : null)} style={{width: '100%', border: 'none', backgroundColor: 'var(--light-gray)'}}>
                                                            <option value={""}>Select Type</option>
                                                            <option value={"DE"}>Detached</option>
                                                            <option value={"SD"}>Semi-Detached</option>
                                                            <option value={"TE"}>Terraced</option>
                                                            <option value={"OT"}>Other</option>
                                                        </select>
                                                    </td>
                                                </>
                                                :
                                                <>
                                                    <td colSpan={4}></td>
                                                </>
                                        }
                                        <td style={{textAlign: 'right'}} colSpan={'1'}>
                                            { 
                                                this.state.customer && this.state.state !== "New" && this.state.state !== null && !this.state.loading && (this.state.state === "GAP Rejected" ? this.state.rejectionReason : true) && (this.state.state === "GAP Accepted" ? this.state.propertyType : true) && hasPermission('GSA', 'PURCHASE') ?
                                                    <button className='button background-primary colour-white' style={{padding: '0'}} onClick={this.submitCustomer}>
                                                        Submit
                                                    </button>
                                                :
                                                    <button disabled={true} className='button disabled' style={{padding: '0'}}>
                                                        Submit
                                                    </button>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{fontWeight: 'bold'}} colSpan={3}>ABP Gap Address</td>
                                        <td style={{fontWeight: 'bold'}} colSpan={2}>Matched CT Address</td>
                                        <td style={{fontWeight: 'bold'}} colSpan={2}>{this.state.translations['gsa.occupier_details']}</td>
                                    </tr>
                                    <tr>
                                        <td>{this.state.translations['gsa.Sub_Building']}</td>
                                        <td colSpan={'2'}>{this.state.customer ? this.state.customer.SUB_BUILDING : null}</td>
                                        <td colSpan={'2'}>{this.state.customer ? this.state.customer.ct_sub_building : null}</td>
                                        <td>{this.state.translations['gsa.title']}</td>
                                        <td colSpan={'2'}>{this.state.customer ? this.state.occupierDetails?.Title : null}</td>
                                    </tr>
                                    <tr>
                                        <td>{this.state.translations['gsa.Building_Name']}</td>
                                        <td colSpan={'2'}>{this.state.customer ? this.state.customer.building_name : null}</td>
                                        <td colSpan={'2'}>{this.state.customer ? this.state.customer.ct_building_name : null}</td>
                                        <td>{this.state.translations['gsa.forename']}</td>
                                        <td colSpan={'2'}>{this.state.customer ? this.state.occupierDetails?.Forename : null}</td>
                                    </tr>
                                    <tr>
                                        <td>{this.state.translations['gsa.Building_Number']}</td>
                                        <td colSpan={'2'}>{this.state.customer ? this.state.customer.BUILDING_NUMBER : null}</td>
                                        <td colSpan={'2'}>{this.state.customer ? this.state.customer.ct_building_number : null}</td>
                                        <td>{this.state.translations['gsa.surname']}</td>
                                        <td colSpan={'2'}>{this.state.customer ? this.state.occupierDetails?.Surname : null}</td>
                                    </tr>
                                    <tr>
                                        <td>{this.state.translations['gsa.Street_Name']}</td>
                                        <td colSpan={'2'}>{this.state.customer ? this.state.customer.STREET_NAME : null}</td>
                                        <td colSpan={'2'}>{this.state.customer ? this.state.customer.ct_street_name : null}</td>
                                        <td>{this.state.translations['gsa.date_of_birth']}</td>
                                        <td colSpan={'2'}>{this.state.customer ? this.state.occupierDetails?.Date_of_Birth : null}</td>
                                    </tr>
                                    <tr>
                                        <td>{this.state.translations['gsa.Locality']}</td>
                                        <td colSpan={'2'}>{this.state.customer ? this.state.customer.LOCALITY : null}</td>
                                        <td colSpan={'2'}>{this.state.customer ? this.state.customer.ct_locality : null}</td>
                                        <td>{this.state.translations['gsa.landline']}</td>
                                        <td colSpan={'2'}>{this.state.customer ? this.state.occupierDetails?.Landline : null}</td>
                                    </tr>
                                    <tr>
                                        <td>{this.state.translations['gsa.Town_Name']}</td>
                                        <td colSpan={'2'}>{this.state.customer ? this.state.customer.TOWN_NAME : null}</td>
                                        <td colSpan={'2'}>{this.state.customer ? this.state.customer.ct_town_name : null}</td>
                                        <td>{this.state.translations['gsa.mobile']}</td>
                                        <td colSpan={'2'}>{this.state.customer ? this.state.occupierDetails?.Mobile : null}</td>
                                    </tr>
                                    <tr>
                                        <td>{this.state.translations['gsa.Post_Town']}</td>
                                        <td colSpan={'2'}>{this.state.customer ? this.state.customer.POST_TOWN : null}</td>
                                        <td colSpan={'2'}>{this.state.customer ? this.state.customer.ct_post_town : null}</td>
                                        <td>{this.state.translations['gsa.occupier_score']}</td>
                                        <td colSpan={'2'}>{ this.state.occupierDetails?.Occupier_Score ? `${this.state.occupierDetails.Occupier_Score} ${this.state.occupierDetails.Occupier_Score === "1" ? "Star" : "Stars"}` : null }</td>
                                    </tr>
                                    <tr>
                                        <td>{this.state.translations['gsa.Postcode']}</td>
                                        <td colSpan={'2'}>{this.state.customer ? this.state.customer.POSTCODE : null}</td>
                                        <td colSpan={'2'}>{this.state.customer ? this.state.customer.ct_postcode : null}</td>
                                        <td colSpan={'3'}>
                                            <div className='button-container'>
                                                <button 
                                                className={`button${!this.state.occupierDetails?.UPRN ? ' disabled' : ' active'}`} 
                                                disabled={!this.state.occupierDetails?.UPRN || this.state.loading} onClick={() => this.setState({detailsModalIsOpen: true})}>
                                                    {this.state.translations['gsa.occupier_footprint']}
                                                </button>
                                                <button 
                                                className={`button${!this.state.occupierDetails?.UPRN || (this.state.occupierDetails?.Date_Bought && this.state.occupierDetails.Date_Bought != "0000-00-00")  ? ' disabled' : ' active'}`} 
                                                disabled={(!this.state.occupierDetails?.UPRN || (this.state.occupierDetails?.Date_Bought && this.state.occupierDetails?.Date_Bought !== "0000-00-00") ) || this.state.loading} onClick={() => this.purchaseCustomer()}>
                                                    {this.state.translations['gsa.purchase_customer']}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            { this.state.showMap ?
                                <PropertyMap address={ this.state.customer ? this.state.customer.ABP_Address : null } />
                            :
                                <>
                                    <div className="map hidden" onClick={ () => { if (this.state.customer) { this.setState({ showMap: true })}} }>
                                        { this.state.customer ? 'Click to show map' : '' }
                                    </div>
                                </>
                            }
                        </div>
                        <div style={{display: 'grid', gridTemplateColumns: '17fr 16fr 14fr', gridAutoFlow: 'column', gap: 10}}>
                            <div style={{overflowX: 'auto', maxHeight: '50vh'}}>
                                <table className='table borders smaller-text squish'>
                                    <thead style={{position: 'sticky', top: '0px'}}>
                                        <tr>
                                            <th>Client Addresses</th>
                                            <th style={{width: '20%'}}>Status</th>
                                            <th style={{width: '10%'}}>Match</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{whiteSpace: 'nowrap'}}>
                                        {
                                            this.state.addressData  ? 
                                                this.state.addressData.client.map((address, index) => {
                                                    return (
                                                        <tr style={{height: '35px'}}>
                                                            <td style={{textAlign: 'left', fontSize: '12px'}}>{address.ClientAddress}</td>
                                                            <td style={{fontSize: '12px'}}>{typeof address.match !== 'undefined' ? address.match === 1 ? "Matched" : "Unmatched" : ""}</td>
                                                            <td><input type="checkbox" name={address.ClientAddress} id={address.ADDRESS_ID} onChange={ address.ClientAddress ? this.setMatchedAddress : null } checked={ 
                                                                this.state.matchedAddress ?
                                                                    address.ClientAddress === this.state.matchedAddress
                                                                :
                                                                    address.ClientAddress == this.state.customer?.Client_Address
                                                                }></input></td>
                                                        </tr>
                                                    )
                                                })
                                            :
                                                <tr><td></td><td></td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div style={{overflowX: 'auto', maxHeight: '50vh'}}>
                                <table className='table borders smaller-text squish'>
                                    <thead style={{position: 'sticky', top: '0px'}}>
                                        <tr>
                                            <th>ABP Addresses</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{whiteSpace: 'nowrap'}}>
                                        {
                                            this.state.addressData ? 
                                                this.state.addressData.abp.map((address, index) => {
                                                    return (
                                                        <tr style={{height: '35px'}}>
                                                            <td style={{textAlign: 'left', fontSize: '12px'}}>{address.ABP_Address}</td>
                                                        </tr>
                                                    )
                                                })
                                            :
                                                <tr><td></td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div style={{overflowX: 'auto', maxHeight: '50vh'}}>
                                <table className='table borders smaller-text squish'>
                                    <thead style={{position: 'sticky', top: '0px'}}>
                                        <tr>
                                            <th>Council Tax Addresses</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{whiteSpace: 'nowrap'}}>
                                        {
                                            this.state.addressData  ? 
                                                this.state.addressData.ct.map((address, index) => {
                                                    return (
                                                        <tr style={{height: '35px'}}>
                                                            <td style={{textAlign: 'left', fontSize: '12px'}}>{address.FullAddress}</td>
                                                        </tr>
                                                    )
                                                })
                                            :
                                                <tr><td></td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <ReactModal
                    className="card bulk-buy"
                    isOpen={this.state.detailsModalIsOpen}
                    onRequestClose={() => this.setState({detailsModalIsOpen: false})}
                    contentLabel="Customer details modal"
                    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' }, content: { width: '66vw' } }}
                    >
                        <div className='gsa-occupier-details'>
                            <table className="table borders squish smaller-text left">
                                <thead>
                                    <tr>
                                        <th>{this.state.translations['gsa.occupier_footprint']}</th>
                                        <th style={{width: '50%'}}>Evidence</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            Listed on electoral roll
                                        </td>
                                        <td>
                                            { this.state.occupierDetails ? this.state.occupierDetails.Electoral_Roll : '' }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Listed on BT register
                                        </td>
                                        <td>
                                            { this.state.occupierDetails ? this.state.occupierDetails.BT_Line : '' }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Count of secured loans
                                        </td>
                                        <td>
                                            { this.state.occupierDetails ? this.state.occupierDetails.Secured_Loans : '' }

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Named on Title Deed
                                        </td>
                                        <td>
                                            { this.state.occupierDetails ? this.state.occupierDetails.Land_Registry : '' }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            GAS redirect in place
                                        </td>
                                        <td>
                                            { this.state.occupierDetails ? this.state.occupierDetails.GAS_Redirect : '' }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            NCOA redirect in place
                                        </td>
                                        <td>
                                            { this.state.occupierDetails ? this.state.occupierDetails.NCOA_Redirect : '' }

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Count of bank accounts
                                        </td>
                                        <td>
                                            { this.state.occupierDetails ? this.state.occupierDetails.Bank_Accounts : '' }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Count of unsecured loans
                                        </td>
                                        <td>
                                            { this.state.occupierDetails ? this.state.occupierDetails.Unsecured_Loans : '' }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Count of utility accounts
                                        </td>
                                        <td>
                                            { this.state.occupierDetails ? this.state.occupierDetails.Utility_Accounts : '' }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Count of telco/media accounts
                                        </td>
                                        <td>
                                            { this.state.occupierDetails ? this.state.occupierDetails.Telco_Accounts : '' }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Count of home credit accounts
                                        </td>
                                        <td>
                                            { this.state.occupierDetails ? this.state.occupierDetails.Home_Credit_Accounts : '' }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table borders squish smaller-text left">
                                <thead>
                                    <tr>
                                        <th>{this.state.translations['gsa.occupier_footprint']}</th>
                                        <th style={{width: '50%'}}>Evidence</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        Count of mail order accounts
                                    </td>
                                    <td>
                                        { this.state.occupierDetails ? this.state.occupierDetails.Mail_Order : '' }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Count of revolving facilities
                                    </td>
                                    <td>
                                        { this.state.occupierDetails ? this.state.occupierDetails.Revolving_Facilities : '' }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Count of other facilities
                                    </td>
                                    <td>
                                        { this.state.occupierDetails ? this.state.occupierDetails.Other_Accounts : '' }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Count of recent credit searches
                                    </td>
                                    <td>
                                        { this.state.occupierDetails ? this.state.occupierDetails.Credit_Searches : '' }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Company House Director
                                    </td>
                                    <td>
                                        { this.state.occupierDetails ? this.state.occupierDetails.CH_Director : '' }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Data on Demand Loans
                                    </td>
                                    <td>
                                        { this.state.occupierDetails ? this.state.occupierDetails.DoD_Loans : '' }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Data on Demand Subscriptions
                                    </td>
                                    <td>
                                        { this.state.occupierDetails ? this.state.occupierDetails.DoD_Subscriptions : '' }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Latest date linked to property
                                    </td>
                                    <td>
                                        { this.state.occupierDetails ? this.state.occupierDetails.Latest_Date : '' }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Earliest date linked to property
                                    </td>
                                    <td>
                                        { this.state.occupierDetails ? this.state.occupierDetails.Earliest_Date : '' }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Occupier Score
                                    </td>
                                    <td>
                                        { this.state.occupierDetails?.Occupier_Score ? `${this.state.occupierDetails.Occupier_Score} ${this.state.occupierDetails.Occupier_Score === "1" ? "Star" : "Stars"}` : '' }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Occupier Score Change
                                    </td>
                                    <td>
                                        { this.state.occupierDetails ? this.state.occupierDetails.Occupier_Change : '' }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ReactModal>
                </GenericPageContainer>
                )
            }
        }
    )
);