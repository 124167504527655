import React, { Component } from 'react'
import Container from '../container/container'
import './style.scss';

export default function Footer(width) {
    const version = `${process.env.REACT_APP_VERSION} - ${process.env.REACT_APP_HASH.substring(0, 7)}`
    return (
        <Container width={width}>
            <div className="footer-wrapper">
                <div>
                    &copy; IDenteq LTD {new Date().getFullYear()}.
                </div>
                <div className="version" title={version}>
                    {version}
                </div>
            </div>
        </Container>
    )
}