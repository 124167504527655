import React, { Component } from "react";
import Modal from 'react-modal';
import session from "../../stores/session";
import './timeout.scss';

export default class IdleTimer extends Component
{
    constructor(props) {
        super(props);

        this.timeout = (props.timeout || 5) * (1000 * 60); // Timeout provided in minutes, variable is now in ms.
        // this.timeout = 1;

        this.state = {
            modalIsOpen: false,
            eventsBound: false,
            timer: null,
            eventListeners: {
                clicked: null,
                keydown: null,
                scroll: null
            }
        }
    }

    componentDidMount () {
        this._bindEvents();
    }

    componentWillUnmount () {
        this._unbindEvents();
    }

    render () {
        return (
            <Modal
                    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
                    className="card timeout-challenge"
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this._passChallenge}
                    contentLabel="Add A Company"
                    appElement={document.getElementById('root')}
            >
                <p>You have been inactive for the past { this.timeout / 60000 } minutes.</p>
                <p>You will be logged out after <span className="countdown">{ this.state.countdown }</span> seconds if you do not interact with the page.</p>
                <button className="button background-primary colour-white" onClick={this._passChallenge}>Stay Logged In</button>
            </Modal>
        );
    }

    _bindEvents = () => {
        const clicked = document.addEventListener('mousedown', this._handleActivity);
        const keydown = document.addEventListener('keydown', this._handleActivity);
        const scroll = document.addEventListener('scroll', this._handleActivity);

        this._resetTimer();

        this.setState({
            eventsBound: true,
            eventListeners: {
                clicked: clicked,
                keydown: keydown,
                scroll: scroll
            }
        });
    }

    _unbindEvents = () => {
        document.removeEventListener('mousedown', this._handleActivity);
        document.removeEventListener('keydown', this._handleActivity);
        document.removeEventListener('scroll', this._handleActivity);

        this.setState({
            eventsBound: false,
            eventListeners: {
                clicked: null,
                keydown: null,
                scroll: null
            }
        });
    }

    _handleActivity = () => {
        this._resetTimer();
    }

    _handleTimeout = () => {
        this._startChallenge();
    }

    _resetTimer = () => {
        clearTimeout(this.state.timer);

        const timer = setTimeout(this._handleTimeout, this.timeout);

        this.setState({
            timer: timer
        });
    }

    _startChallenge = () => {
        if (this.state.modalIsOpen === false) {
            this.intervalId = window.setInterval(this._interval, 1000);
        }

        this.setState({
            modalIsOpen: true,
            countdown: 10,
        });
    }

    _interval = () => {
        this.setState({
            countdown: this.state.countdown - 1
        }, () => {
            if (this.state.countdown < 1) {
                this._failChallenge();
            }
        })
    }

    _passChallenge = () => {
        window.clearInterval(this.intervalId);

        this._resetTimer();

        this.setState({
            modalIsOpen: false,
            countdown: 10,
            interval: null,
        })
    }

    _failChallenge = () => {
        clearInterval(this.state.interval);

        if (this.state.modalIsOpen) {
            session.reset();
            window.location.reload();
        }
    }
}
