import React from 'react'
import Container from '../container/container'
import Footer from '../footer/footer'
import Navigation from '../navigation/navigation'
import PageTitle from '../page-header/pageTitle'

export default function Page({
  title,
  children,
  className,
  titleRight
}) {
  return (
    <div className={['grid grid-gap-15', className].join(' ')}>
      <Navigation />
      <Container>
        <PageTitle title={title} titleRight={titleRight} />
        {children}
      </Container>
      <Footer />
    </div>
  )
}
