import React, { Component } from 'react'
import Navigation from '../../../components/navigation/navigation'
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import Container from '../../../components/container/container'
import Footer from '../../../components/footer/footer'
import hasPermission from '../../../helpers/permissions';
import request from '../../../helpers/request'
import endpoints from '../../../helpers/endpoints'
import { view } from '@risingstack/react-easy-state'
import './voidCOTAlerts.scss';

import { Link, withRouter } from 'react-router-dom'
import session from '../../../stores/session'
import HelpVideo from '../../../components/help-video/helpVideo'
import useWSProduct from '../../../helpers/useWSProduct'
import PropertyMap from '../../../components/property-map/property-map'
import PageTitle from '../../../components/page-header/pageTitle'

export default view(
    withRouter(
        class VoidCOTAlertsWorkflow extends Component {
            constructor(props) {
                super(props)

                this.state = {
                    accountNumber: '',
                    customer: null,
                    message: '',
                    showMap: true,
                    purchasable: null,
                    shouldRefreshMap: false,
                    workflow: [],
                    workflowIndex: null,
                    workflowLength: 0,
                    disableActions: false,
                    translations: null,
                    accountNumberIndex: 0
                }
            }

            componentDidMount() {
                useWSProduct('void_cot_alerts');// eslint-disable-line
                request(true).get('/translate?key=vca.').then(r => {
                    this.setState({
                        translations: r.data
                    });
                }).catch(e => {
                    console.error(e);
                })
                request(true).get(endpoints.VOID_COT_ALERTS_WORKFLOW).then(r => {
                    this.setState({
                        workflow: r.data,
                        workflowIndex: 0,
                        workflowLength: r.data.length,
                        accountNumber: r.data[0],
                    }, () => {
                        this.getData();
                    });
                }).catch(e => {
                    console.error(e);
                });
            }

            componentWillUnmount() {
                useWSProduct('void_cot_alerts', 'unlisten');// eslint-disable-line
            }

            getData = () => {
                request(true).get(`${endpoints.VOID_COT_ALERTS_GET_CUSTOMER}?search=${this.state.accountNumber}`).then(r => {
                    console.log(r.data.customer);
                    r.data = r.data.filter(item => item.customer.Date_Bought === null)
                    let accountNumberIndex = this.state.accountNumberIndex; 
                    if (accountNumberIndex == -1) accountNumberIndex = r.data.length - 1;
                    this.setState({
                        customer: r.data[accountNumberIndex].customer,
                        message: r.data[accountNumberIndex] && r.data[accountNumberIndex].customer ? '' : 'Customer Does Not Exist',
                        showMap: true,
                        purchasable: r.data[accountNumberIndex] && r.data[accountNumberIndex].customer && r.data[accountNumberIndex].customer.Date_Bought === null && r.data[accountNumberIndex].customer.Occupier_Score > 0,
                        shouldRefreshMap: true,
                        accountNumberIndex: accountNumberIndex
                    }, () => {
                        this.setState({
                            shouldRefreshMap: false,
                            disableActions: false,
                        });
                    });
                }).catch(e => {
                    //console.log(e);
                });
            }

            purchaseCustomer = () => {
                this.setState({disableActions: true}, () => {
                    request(true).post(`${endpoints.VOID_COT_ALERTS_BUY_CUSTOMER}`, {
                        "customer": this.state.customer.Account_Number,
                        "file_date": this.state.customer.File_Date,
                        "SPID_Address": this.state.customer.SPID_Address,
                        "AddressBase_Address": this.state.customer.AddressBase_Address
                    }).then(r => {
                        const customers = [].concat(this.state.workflow);
                        customers.splice(this.state.workflowIndex, 1);

                        this.setState({
                            workflow: customers,
                            workflowIndex: this.state.workflowIndex - 1,
                            workflowLength: customers.length,
                            customer: r.data.customer,
                            message: r.data.customer ? '' : 'Customer Does Not Exist',
                            showMap: true,
                            purchasable: r.data.customer.Date_Bought === null,
                            accountNumberIndex: this.state.accountNumber - 1,
                        });
                    }).catch(e => {
                        console.log(e);
                    })
                });
            }

            excludeCustomer = () => {
                if (!this.state.customer || !this.state.customer.Account_Number) return;
                this.setState({disableActions: true}, () => {
                    request(true).post(endpoints.VOID_COT_ALERTS_EXCLUDE_CUSTOMER, {
                        "customer": this.state.customer.Account_Number,
                        "file_date": this.state.customer.File_Date,
                        "SPID_Address": this.state.customer.SPID_Address,
                        "AddressBase_Address": this.state.customer.AddressBase_Address
                    }).then(r => {
                        const customers = [].concat(this.state.workflow);
                        customers.splice(this.state.workflowIndex, 1);

                        this.setState({
                            workflow: customers,
                            workflowLength: customers.length,
                            accountNumber: customers[this.state.workflowIndex],
                            accountNumberIndex: 0,
                        }, () => {
                            this.getData();
                        });
                    }).catch(e => {
                        console.error(e);
                    });
                });
            }

            sendToNFI = () => {
                request(true).post(endpoints.VOID_COT_ALERTS_NFI_BUY_CUSTOMER, {
                    "customer": this.state.customer.Account_Number,
                    "file_date": this.state.customer.File_Date,
                    "SPID_Address": this.state.customer.SPID_Address,
                    "AddressBase_Address": this.state.customer.AddressBase_Address
                }).then(r => {
                    if (r.status === 200) window.alert('Successfully sent to NFI');
                    this.setState({
                        customer: r.data.customer,
                        showMap: true,
                        purchasable: r.data.customer.Date_Bought === null
                    });
                }).catch(e => {
                    window.alert('Failed to send to NFI');
                    console.log(e);
                })
            }

            workflowNext = () => {
                if (this.state.workflowIndex + 1 > this.state.workflow.length) return
                this.setState({
                    accountNumberIndex: this.state.accountNumber == this.state.workflow[this.state.workflowIndex + 1] ? (this.state.accountNumberIndex + 1) : 0,
                    workflowIndex: this.state.workflowIndex + 1,
                    accountNumber: this.state.workflow[this.state.workflowIndex + 1]
                }, () => {
                    this.getData();
                });
            }

            workflowPrev = () => {
                if (this.state.workflowIndex > this.state.workflow.length) return
                this.setState({
                    accountNumberIndex: this.state.accountNumber == this.state.workflow[this.state.workflowIndex - 1] ? (this.state.accountNumberIndex - 1) : -1,
                    workflowIndex: this.state.workflowIndex - 1,
                    accountNumber: this.state.workflow[this.state.workflowIndex - 1]
                }, () => {
                    this.getData();
                });
            }

            render() {
                return (
                    <div className="grid grid-gap-15" id="void-cot-alerts-page">
                        <Navigation/>
                        <Container>
                            <PageTitle title={ this.props.history.location.pathname.indexOf('void') !== -1 ? 'Void COT Alerts - Workflow' : 'Occupier COT Alerts - Workflow' } titleRight={
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Link className="navigate" to={ this.props.history.location.pathname.indexOf('void') !== -1 ? '/portal/void-cot-alerts/' : '/portal/occupier-cot-alerts/' }>
                                        Overview
                                    </Link>
                                    <Link className="navigate" to={ this.props.history.location.pathname.indexOf('void') !== -1 ? '/portal/void-cot-alerts/search' : '/portal/occupier-cot-alerts/search' }>
                                        Search
                                    </Link>
                                    { (hasPermission('VOID_COT', 'WORKFLOW-COMBINED')) ?
                                        <Link className="navigate" to={ this.props.history.location.pathname.indexOf('void') !== -1 ? '/portal/void-cot-alerts/workflow-combined' : '/portal/occupier-cot-alerts/workflow-combined' }>
                                            Workflow Combined
                                        </Link>
                                    : null }
                                    { (hasPermission('VOID_COT', 'NFI-SEARCH')) ?
                                        <Link className="navigate" to={ this.props.history.location.pathname.indexOf('void') !== -1 ? '/portal/void-cot-alerts/nfi' : '/portal/occupier-cot-alerts/search' }>
                                            NFI Search
                                        </Link>
                                    : null }
                                    <Link className="navigate" to="/portal/void-cot-alerts/report">
                                        Report
                                    </Link>
                                </div>
                            } />
                        </Container>
                        <Container>
                            <div className="vcv">
                                <div className="primary-grid">
                                    <div className="left-grid">
                                        <div className="data-row with-buttons">
                                            <div className="data-row-title">
                                            { this.state.translations ? this.state.translations['vca.AccountNumber'] : '' }
                                            </div>
                                            <div style={{ position: 'relative' }}>
                                                <input disabled={true} type="text" className="data-row-field" name="account-number" value={ this.state.accountNumber } style={{ width: 'calc(100% - 15px)', height: '100%' }}/>
                                                <span className="message">{ this.state.workflowIndex + 1 }/{ this.state.workflowLength }</span>
                                            </div>
                                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '15px', marginLeft: '15px' }}>
                                                <button className="button background-primary colour-white" onClick={ this.workflowPrev } disabled={this.state.workflowIndex === 0}>
                                                    <i className="fas fa-angle-left"></i>
                                                </button>
                                                <button className="button background-primary colour-white" onClick={ this.workflowNext } disabled={this.state.workflowIndex === this.state.workflowLength - 1}>
                                                    <i className="fas fa-angle-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                        { this.state.customer && this.state.customer.Surname !== false ?
                                            <div className="name-grid">
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['vca.title'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? `${this.state.customer.Title}` : '' }/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['vca.forename'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? `${this.state.customer.Forename}` : '' }/>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['vca.surname'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? `${this.state.customer.Surname}` : '' }/>
                                                </div>
                                            </div>
                                        :
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['vca.Name'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? `${this.state.customer.Title}` : '' }/>
                                            </div>
                                        }
                                        <div className="left-grid-bottom">
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['vca.DateOfBirth'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Date_of_Birth : ''}/>
                                            </div>
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['vca.Landline'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Landline : '' }/>
                                            </div>
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['vca.Mobile'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Mobile : '' }/>
                                            </div>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                            { this.state.translations ? this.state.translations['vca.ClientAddress'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.SPID_Address : '' }/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                            { this.state.translations ? this.state.translations['vca.MatchedAddress'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.AddressBase_Address : '' }/>
                                        </div>
                                        <div className="left-grid-bottom">
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['vca.AddressScore'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Address_Quality : ''}/>
                                            </div>
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['vca.UPRN'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.UPRN : '' }/>
                                            </div>
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['vca.NewConnection'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.New_Connection : '' }/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right-grid">
                                        { (!hasPermission('VOID_COT', 'PURCHASE') && this.state.purchasable) ?
                                            <button className="buy-button disabled" disabled={true}>
                                                Purchase Customer
                                            </button>
                                        :
                                            <button className="buy-button" disabled={ !this.state.purchasable || this.state.disableActions } onClick={ this.purchaseCustomer }>
                                                { this.state.purchasable === true || this.state.purchasable === null ? "Purchase Customer" :
                                                    this.state.customer.Bought_By ?
                                                        `Purchased on ${this.state.customer.Date_Bought} by ${this.state.customer.Bought_By}`
                                                    :
                                                        this.state.customer.Date_Bought ?
                                                            `Purchased on ${this.state.customer.Date_Bought}`
                                                        :
                                                            'Unavailable for Purchase'
                                                }
                                            </button>
                                        }
                                        <button className="buy-button" onClick={this.excludeCustomer} disabled={ this.state.disableActions }>
                                                Exclude Customer
                                        </button>
                                        { (!hasPermission('VOID_COT', 'NFI-CHECK') || !this.state.purchasable) ? 
                                            <button className="buy-button disabled" disabled={true}>
                                                Send to NFI
                                            </button>
                                            :
                                            <button className="buy-button" onClick={ this.sendToNFI }>
                                                Send to NFI
                                            </button>
                                        }
                                        <div className="data-row">
                                            <div className="data-row-title">
                                            { this.state.translations ? this.state.translations['vca.Email'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Email : '' }/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                            { this.state.translations ? this.state.translations['vca.VoidAgeBand'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Voids_Age_Band : '' }/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                            { this.state.translations ? this.state.translations['vca.AccountType'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Type : ''}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="tertiary-grid">
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['vca.Bucket'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Bucket : ''}/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['vca.Freehold_Comp_Name'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Freehold_Comp_Name  : '' }/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['vca.Proprietor_Comp_Name'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Proprietor_Comp_Name : '' }/>
                                        </div>
                                </div>
                                <div className="secondary-grid">
                                    { this.state.showMap ?
                                        <PropertyMap address={ this.state.customer ? this.state.customer.SPID_Address : null } />
                                    :
                                        <>
                                            <div className="map hidden" onClick={ () => { if (this.state.customer) { this.setState({ showMap: true })}} }>
                                                { this.state.customer ? 'Click to show map' : '' }
                                            </div>
                                        </>
                                    }
                                    <table className="table borders">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Occupier Footprint
                                                </th>
                                                <th>
                                                    Evidence
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Listed on electoral roll
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Electoral_Roll : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Listed on BT register
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.BT_Line : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of secured loans
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Secured_Loans : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Named on Title Deed
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Land_Registry : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    GAS redirect in place
                                                </td>
                                                <td>
                                                { this.state.customer ? this.state.customer.GAS_Redirect : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    NCoA redirect in place
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.NCOA_Redirect : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of bank accounts
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Bank_Accounts : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of unsecured loans
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Unsecured_Loans : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of utility accounts
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Utility_Accounts : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of telco/media accounts
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Telco_Accounts : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of home credit accounts
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Home_Credit_Accounts : '' }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className="table borders">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Occupier Footprint
                                                </th>
                                                <th>
                                                    Evidence
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Count of mail order accounts
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Mail_Order : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of revolving facilities
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Revolving_Facilities : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of other facilities
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Other_Accounts : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Count of recent credit searches
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Credit_Searches : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Company House Director
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.CH_Director : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Data on Demand: Loans
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.DoD_Loans : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Data on Demand: Subscriptions
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.DoD_Subscriptions : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Latest date linked to property
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Latest_Date : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Earliest date linked to property
                                                </td>
                                                <td>
                                                    { this.state.customer ? (this.state.customer.Earliest_Date !== null ? this.state.customer.Earliest_Date : '-')  : '' }
                                                </td>
                                            </tr>
                                            {/* <tr>
                                                <td>
                                                    Latest date linked to property
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Latest_Date : '' }
                                                </td>
                                            </tr> */}
                                            {/* <tr>
                                                <td>
                                                    Date of most recent update
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Recent_Date : '' }
                                                </td>
                                            </tr> */}
                                            <tr>
                                                <td>
                                                    Occupier Score
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Occupier_Score + " Stars" : '' }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Occupier Score Change
                                                </td>
                                                <td>
                                                    { this.state.customer ? this.state.customer.Occupier_Change : '' }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Container>
                        <HelpVideo video={session.company.vca_video_url}/>
                        <Footer />
                    </div>
                )
            }
        }
    )
)

