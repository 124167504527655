import React from 'react';
import Navigation from '../../components/navigation/navigation'
import Footer from '../../components/footer/footer'
import Container from '../../components/container/container'
import request from '../../helpers/request';
import endpoints from '../../helpers/endpoints';
import Pagination from '../../components/pagination/pagination';
import { DateTimePicker } from '@material-ui/pickers';

import Moment from 'moment';
import Select from 'react-select';
import FileSaver from 'file-saver';

export default function Audits() {
    const [audits, setAudits] = React.useState([]);
    const [page, setPage] = React.useState({
        page: 1,
        last_page: null,
    });
    const [users, setUsers] = React.useState([]);
    const [companies, setCompanies] = React.useState([]);
    const [keys, setKeys] = React.useState([]);
    const [selectedCompanies, setSelectedCompanies] = React.useState([]);
    const [selectedUsers, setSelectedUsers] = React.useState([]);
    const [selectedKeys, setSelectedKeys] = React.useState([]);
    const [userKey, setUserKey] = React.useState(Math.random());
    const [fromTime, setFromTime] = React.useState(null);
    const [toTime, setToTime] = React.useState(null);

    const loadPage = React.useCallback((page = 1) => {
        request(true).get(`${endpoints.AUDITS}?${new URLSearchParams({page, companies: selectedCompanies, keys: selectedKeys, users: selectedUsers, from: fromTime, to: toTime})}`).then(r => {
            setAudits(r.data.data);
            setPage({
                page: r.data.current_page,
                last_page: r.data.last_page,
            });
        }).catch(e => {
            console.log(e);
        });
    }, [selectedCompanies, selectedKeys, selectedUsers, fromTime, toTime]);

    const downloadCSV = () => {
        request(true).get(`${endpoints.AUDITS}/csv?${new URLSearchParams({page, companies: selectedCompanies, keys: selectedKeys, users: selectedUsers, from: fromTime, to: toTime})}`).then(r => {
            const blob = new Blob([r.data], { type: 'text/csv;charset=utf-8'});
            FileSaver.saveAs(blob, 'audits.csv');
        }).catch(e => {
            console.log(e);
        });
    }

    const updatePage = page => {
        loadPage(page);
    }

    const loadUsers = React.useCallback(() => {
        request(true).get(`${endpoints.AUDITS}/users?${new URLSearchParams({companies: selectedCompanies})}`).then(r => {
            setUsers(r.data);
        }).catch(e => {
            console.error(e);
        });
    }, [selectedCompanies]);

    React.useEffect(() => {
        loadPage();
        loadUsers();
        loadCompanies();
        loadKeys();
    }, [loadPage, loadUsers]);

    const loadCompanies = () => {
        request(true).get(`${endpoints.AUDITS}/companies`).then(r => {
            setCompanies(r.data);
        }).catch(e => {
            console.error(e);
        });
    }

    const loadKeys = () => {
        request(true).get(`${endpoints.AUDITS}/keys`).then(r => {
            setKeys(r.data);
        }).catch(e => {
            console.error(e);
        });
    }

    React.useEffect(() => {
        loadUsers();
    }, [selectedCompanies, loadUsers]);

    React.useEffect(() => {
        loadPage();
    }, [selectedCompanies, selectedKeys, selectedUsers, fromTime, toTime, loadPage]);

    const updateCompanySelected = value => {
        setUserKey(Math.random());
        setSelectedUsers([]);

        if (value === null) {
            setSelectedCompanies([]);
            return;
        }

        setSelectedCompanies(value.map(i => i.value));
    }

    const updateUserSelected = value => {
        if (value === null) {
            setSelectedUsers([]);
            return;
        }

        setSelectedUsers(value.map(i => i.value));
    }

    const updateKeySelected = value => {
        if (value === null) {
            setSelectedKeys([]);
            return;
        }

        setSelectedKeys(value.map(i => i.value));
    }

    return (
      <div className="grid grid-gap-20">
        <Navigation/>
        <Container>
          <div className="flex middle" style={{ justifyContent: 'space-between' }}>
            <p className="colour-secondary font-weight-600" style={{fontSize: 20}}>Audits</p>
            <button className="button background-primary colour-white" onClick={downloadCSV}>Download CSV</button>
          </div>
        </Container>
        <div style={{display: 'grid', gridTemplateColumns: '1fr 4fr', margin: '1em', gap: '1em'}}>
          <div style={{backgroundColor: 'white', borderRadius: 5, padding: '1em', border: '1px solid var(--primary)'}}>
            <p className="colour-secondary font-weight-600" style={{fontSize: 20}}>Filters</p>
            <p className="font-weight-600">Event Type</p>
            <Select options={keys} isMulti onChange={ updateKeySelected }/>

            <p className="font-weight-600">User</p>
            <Select key={ userKey } options={users} isMulti onChange={ updateUserSelected }/>

            <p className="font-weight-600">Company</p>
            <Select options={companies} isMulti onChange={ updateCompanySelected }/>

            <p className="font-weight-600">Time</p>
            <DateTimePicker variant="inline" label="From:" value={fromTime} onChange={setFromTime}/>
            <DateTimePicker variant="inline" label="To:" value={toTime} onChange={setToTime}/>
          </div>
          <table className="table" style={{ maxWidth: '1800px', margin: '0 auto' }}>
            <thead>
              <tr>
                <th style={{ width: '200px' }}>Event</th>
                <th style={{ width: '300px' }}>Description</th>
                <th style={{ width: '300px' }}>Data</th>
                <th>User</th>
                <th>Company</th>
                <th style={{ width: '200px' }}>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              { audits.map(audit => (
                <tr key={audit.id}>
                  <td style={{ width: '200px' }}>
                    { audit.event }
                  </td>
                  <td style={{ width: '300px' }}>
                    { audit.info }
                  </td>
                  <td style={{ width: '300px' }}>
                    { ((typeof audit.data) === 'object') ? Object.keys(audit.data).map(key => {
                                            if (typeof audit.data === 'object') {
                                                return (
                                                  <p key={Math.random()}>{ key }: "{ JSON.stringify(audit.data[key]) }"</p>
                                                )
                                            }

                                            return (
                                              <p>{ key }: "{ audit.data[key] }"</p>
                                            )
                                        }) : '' }
                  </td>
                  <td>
                    { audit.user ? audit.user.name : 'N/A' }
                  </td>
                  <td>
                    { audit.company ? audit.company.name : 'N/A' }
                  </td>
                  <td style={{ width: '200px' }}>
                    { Moment(audit.created_at).format('D-M-Y h:mm a') }
                  </td>
                </tr>
                            ))}
            </tbody>
          </table>
        </div>

        <Pagination page={ page.page } pages={ page.last_page } pageChange={ updatePage } style={{margin: 'auto'}}/>
        <Footer/>
      </div>
    )
}
