import React, { Component } from 'react'
import './navigation.scss';
import logo from '../../assets/logo.svg';
import { NavLink, Link, withRouter } from 'react-router-dom';
import Select from 'react-select'
import request from '../../helpers/request'
import hasPermission from '../../helpers/permissions';
import endpoints from '../../helpers/endpoints'
import Modal from 'react-modal'

import sessionState from '../../stores/session';
import session from '../../stores/session';

export default withRouter(class Navigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menuOpen: false,
            telescopeUrl: sessionStorage.getItem('api-path') + `/loadtelescope`,
            logoUrl: sessionStorage.getItem('logo-url') || logo,
            error: false,
            new_user: {
                name: '',
                email: '',
            },
            companies: [],
            errors: {},
            draggedBookmarkIndex: -1,
            selectedTab: 'Add User',
            deleteUserConfirmation: false,
            users: [],
            selectedUser: '',
            selectInput: '',
            loading: false,
        }
    }

    toggleMenu = () => {
        this.setState({
            menuOpen: !this.state.menuOpen
        });
    }

    closeModal = () => {
        this.setState({
            modalIsOpen: false,
        })
    }

    getUsers = (value) => {
        this.setState({loading: true, users: []});
        request(true).get(`${sessionStorage.getItem('api-path')}/company-admin/${session.company.id}/users`, {
            doesCancel: true,
            params: {
                ...(value && {search: value})
            } 
        }).then(r => {
            this.setState({users: r.data.data, loading: false})
        }).catch(e => {
            console.log(e)
            window.alert("Failed to retrieve user list")
            this.setState({loading: false});
        })
    }

    openModal = () => {
        this.getUsers()
        this.setState({
            modalIsOpen: true,
        })
    }

    updateNewUserFormValue = (e) => {
        this.setState({
            new_user: {
                ...this.state.new_user,
                [e.target.name]: e.target.value ? e.target.value : undefined
            }
        })
    }

    newUserFormSubmit = () => {
        this.state.new_user.company_id = session.company.id
        request(true).post(endpoints.COMPANY_ADMIN_USERS, {
            ...this.state.new_user,
        }).then(r => {
            this.setState({
                new_user: { name: '', email: ''}
            });
            this.props.history.push(`/admin/portal/users/${r.data.user_id}`)
        }).catch(r => {
            if (r.response.data.error == "User email already in use") {
                window.alert("User email already in use");
                this.setState({
                    new_user: { name: '', email: ''}
                });
            } else {
                this.setState({
                    errors: r.response.data.errors
                });
            }
        });
    }

    onDragStart = (event, index) => {
        this.setState({ draggedBookmarkIndex: index })
    }

    onDragOver = (event) => {
        event.preventDefault();
    }

    onDrop = (event) => {
        event.preventDefault();

        const targetBookmarkIndex = Number(event.target.getAttribute('data-index'));
        const draggedBookmarkIndex = this.state.draggedBookmarkIndex;

        if (draggedBookmarkIndex !== -1 && draggedBookmarkIndex !== targetBookmarkIndex) {
            const updatedBookmarks = [...session.user.bookmarks];
            const draggedBookmark = updatedBookmarks.splice(draggedBookmarkIndex, 1)[0];
            updatedBookmarks.splice(targetBookmarkIndex, 0, draggedBookmark);

            updatedBookmarks.forEach((bookmark, index) => {
                bookmark.order = index + 1
            });

            request(true).post(endpoints.SET_BOOKMARK_ORDER, {
                bookmarks: updatedBookmarks,
            }).then(r => {
                session.fetch();
            })
        }
    }

    render() {
        return (
            <div className="navigation">
                <div className="left">
                    { sessionState.token ?
                        (sessionState.company ?
                            <Link to="/portal"><img src={this.state.logoUrl} alt="Logo" /></Link>
                        :
                            <Link to="/admin/portal"><img src={logo} alt="Logo" /></Link>
                        )
                    :
                        <Link to="/login"><img src={this.state.logoUrl} alt="Logo" /></Link>
                    }
                    <small>
                        { process.env.NODE_ENV !== "production" ? sessionStorage.getItem('frontend-name') : null }
                    </small>
                </div>
                {/* <div className="bookmarks">
                    {
                        session.user.bookmarks ? session.user.bookmarks.map((bookmark, index) => {
                            return(
                                <Link key={index} data-index={index} draggable={true} 
                                    onDragStart={event => this.onDragStart(event, index)} 
                                    onDragOver={this.onDragOver} 
                                    onDrop={this.onDrop} 
                                    onDragEnd={() => this.setState({ draggedBookmarkIndex: -1})} 
                                    style={{ opacity: this.state.draggedBookmarkIndex === index ? 0.25 : 1 }}
                                    to={bookmark.url}>{bookmark.pageName}</Link>
                            )
                        })
                        : null
                    }
                </div> */}
                <div className="right">
                    {
                        sessionState.token ? <>
                            <div className={ "user " + (this.state.menuOpen ? 'menu-open' : 'menu-closed') } onClick={this.toggleMenu}>
                                <div className="font-weight-500 colour-white">
                                    <p>{ session.user ? session.user.name : '' } </p>
                                    <div>
                                        <i className="far fa-chevron-left"></i>
                                    </div>
                                    <div className="account-menu">
                                        <Link to="/account">Account</Link>
                                        {
                                            !session.company ?
                                                <a onClick={(() => window.open(this.state.telescopeUrl))}>
                                                    Telescope
                                                </a>
                                            : ''
                                        }
                                        {
                                            hasPermission('ADMIN', 'CREATE-USER') ?
                                                <a onClick={this.openModal}>Manage Users</a>
                                            : null
                                        }
                                        {/* <Link to='/version-history'>Release Notes</Link> */}
                                        <Link to="/logout">Log Out</Link>
                                    </div>
                                </div>
                            </div>
                        </> : <>
                            <NavLink to="/login">Sign In</NavLink>
                        </>
                    }
                </div>
                <Modal
                        style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
                        className="card bulk-buy"
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.closeModal}
                        contentLabel="Add A User"
                        appElement={document.getElementById('company-page')}
                    >
                    <div className='grid grid-columns-2'>
                        <div onClick={() => this.setState({selectedTab: 'Add User'})} className={this.state.selectedTab === "Add User" ? 'selectedTab' : 'tab'}>Add User</div>
                        <div onClick={() => this.setState({selectedTab: 'Amend User'})} className={this.state.selectedTab === "Amend User" ? 'selectedTab' : 'tab'}>Amend User</div>
                    </div>
                    <div className="grid grid-gap-15">
                        {
                            this.state.selectedTab === "Add User" ?
                                <>
                                    <div className="form-row">
                                        <label htmlFor="name">Name:</label>
                                        <input onChange={this.updateNewUserFormValue} name="name" placeholder="Name" className="input" style={{marginTop: '0px'}} type="text" value={this.state.new_user.name} required/>
                                        { this.state.errors.name ? this.state.errors.name.map((value, index) => {
                                            return (
                                                <p key={index}>
                                                    { value }
                                                </p>
                                            )
                                        }) : <></>}
                                    </div>
                                    <div className="form-row">
                                        <label htmlFor="email">Email:</label>
                                        <input onChange={this.updateNewUserFormValue} name="email" placeholder="Email" className="input" style={{marginTop: '0px'}} type="text" value={this.state.new_user.email} required/>
                                        { this.state.errors.email ? this.state.errors.email.map((value, index) => {
                                            return (
                                                <p className="error" key={index}>
                                                    { value }
                                                </p>
                                            )
                                        }) : <></>}
                                    </div>
                                    <button onClick={this.newUserFormSubmit} className="button background-primary colour-white">Create</button>
                                    <p className="text-center">{ this.state.message }</p>
                                </>
                            : this.state.selectedTab === "Amend User" ?
                                this.state.deleteUserConfirmation && this.state.selectedUser ?
                                    <div>
                                        Please confirm you wish to delete the user: {this.state.users.find(user => user.id == this.state.selectedUser).email}
                                        <div className='grid-2'>
                                            <button onClick={() => this.setState({deleteUserConfirmation: false})} className="button background-primary colour-white">Cancel</button>
                                            <button onClick={() => {
                                                request(true).delete(`${sessionStorage.getItem('api-path')}/users/${this.state.selectedUser}`).then(r => {
                                                    window.alert('User deleted.')
                                                    this.closeModal()
                                                }).catch(e => {
                                                    console.log(e)
                                                    window.alert('Failed to delete user.')
                                                })
                                            }} className='button cancel'>Delete</button>
                                        </div>
                                    </div>
                                :
                                    <div className='form-row'>
                                        <label htmlFor='email'>Email:</label>
                                        <Select 
                                            onChange={(chosenOption) => this.setState({selectedUser: chosenOption.value})}
                                            onInputChange={(value) => {
                                                if (value !== this.state.selectInput) {
                                                    this.setState({selectInput: value})
                                                    this.getUsers(value);
                                                }
                                            }}
                                            options={
                                                this.state.users.map(user => {
                                                    return {
                                                        value: user.id, 
                                                        label: user.email}
                                                    }
                                                )
                                            }
                                            isLoading={this.state.loading}
                                            style={{marginTop: '0px'}}
                                        />
                                        <div className='grid-2' style={{marginTop: '1em'}}>
                                            <button onClick={() => this.props.history.push(`/admin/portal/users/${this.state.selectedUser}`)} className="button background-primary colour-white">Edit</button>
                                            <button onClick={() => this.setState({deleteUserConfirmation: true})} className="button cancel">Delete</button>
                                        </div>
                                    </div>
                            :
                                ''
                        }
                        </div>
                    </Modal>
            </div>
        )
    }
})
