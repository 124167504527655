import React, { Component } from 'react'

import './card.scss';

export default class Card extends Component {
    render() {
        return (
            <div className={`card ${this.props.className ? this.props.className : ''}`}>
                {this.props.children}
            </div>
        )
    }
}
