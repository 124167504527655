import React, { Component } from 'react'
import Navigation from '../components/navigation/navigation'
import Login from '../components/login/login'
import Footer from '../components/footer/footer'
import Container from '../components/container/container'
import HelpVideo from '../components/help-video/helpVideo'

export default class LoginPage extends Component {
    render() {
        return (
            <div className="grid grid-gap-20 page-wrapper">
                <Navigation />
                <Container>
                    <Login />
                    <HelpVideo video={sessionStorage.getItem('login-video-path')}/>
                </Container>
                <Footer />
            </div>
        )
    }
}
