import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import GenericPageContainer from '../../GenericPageElements/GenericPageContainer';
import endpoints from '../../helpers/endpoints';
import request from '../../helpers/request';

function ColourOption({ name, data, dbKey, onChange }) {
  return (
    <div key={'colour_picker_key_' + dbKey} style={{display: 'flex', flexDirection: 'column'}}>
      <label>{name}</label>
      <input type="color" value={data[dbKey]} onChange={e => onChange({ [dbKey]: e.target.value })} />
    </div>
  )
}

export default function BrandingPage() {
  const [bcList, setBCList] = useState([])
  const [currentBC, setCurrentBC] = useState(null);
  const onColourChange = e => {
    console.log(e)
    setCurrentBC(cur => ({
        ...cur,
        ...e,
      }))
  }
  const getData = () => {
    request(true).get(endpoints.ADMIN_BRANDING_INDEX).then(e => {
      setBCList(e.data)
    })
  }
  useEffect(() => {
    getData()
  }, []);
  const getRow = id => {
    request(true).get(endpoints.ADMIN_BRANDING_INDEX, {
      params: {
        id,
      },
    }).then(e => {
      setCurrentBC(e.data)
    })
  };
  const saveRow = () => {
    request(true).post(endpoints.ADMIN_BRANDING_POST, currentBC).then(e => {
      window.alert(e.statusText);
      getData()
    }).catch(e => {
      console.log(e.response)
      window.alert('Error', e)
    })
  }
  const deleteRow = id => {
    request(true).delete(endpoints.ADMIN_BRANDING_POST + `/${id}`).then(e => {
      window.alert(e.statusText)
      getData()
    }).catch(e => {
      window.alert('Error')
    })
  }
  const openNew = useCallback(() => {
      setCurrentBC(null)
      setTimeout(() => {
        setCurrentBC({
          colour__Primary__: '#33b1d1',
          colour__Secondary__: '#445468',
          colour__Background__: '#F7FCFF',
          colour__Gray__: '#A5A5A5',
          'colour__Light Gray__': '#F7F7F7',
          colour__White__: '#ffffff',
          colour__Black__: '#000000',
          colour__Logogreen__: '#009245',
          colour__Red__: '#f14014',
          colour__Yellow__: '#F19A14',
          colour__Blue__: '#118AB2',
          colour__Teal__: '#06D6A0',
          colour__Cobalt__: '#445469',
        })
      }, 500)
  }, [currentBC, setCurrentBC])
  return (
  <GenericPageContainer
    title="Branding"
    titleIconClass="fal fa-palette fa-2x colour-primary"
    titleRight={<button onClick={openNew}>New</button>}
  >
    <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 15}}>
      <div>
        <table className='table squish'>
          <thead>
            <tr>
              <td>ID</td>
              <td>Name</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody>
            {
              bcList.map(row => (
                <tr>
                  <td>{row.id}</td>
                  <td>{row.name}</td>
                  <td>
                    <button onClick={() => getRow(row.id)}>Edit</button>
                    <button onClick={() => deleteRow(row.id)}>Delete</button>
                  </td>
                </tr>
                ))
            }
          </tbody>
        </table>
      </div>
      {
          currentBC
            ? <>
              <div>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 15 }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label>Name</label>
                    <input type="text" className='input' value={currentBC.name} name="name" onChange={e => onColourChange({ [e.target.name]: e.target.value })} />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label>Logo</label>
                    <input type="text" className='input' value={currentBC.logo} name="logo" onChange={e => onColourChange({ [e.target.name]: e.target.value })} />
                  </div>
                </div>
                <hr />
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 5 }}>
                  <ColourOption data={currentBC} name="Primary" dbKey="colour__Primary__" onChange={onColourChange} />
                  <ColourOption data={currentBC} name="Secondary" dbKey="colour__Secondary__" onChange={onColourChange} />
                  <ColourOption data={currentBC} name="Background" dbKey="colour__Background__" onChange={onColourChange} />
                  {/*  */}
                  <ColourOption data={currentBC} name="Gray" dbKey="colour__Gray__" onChange={onColourChange} />
                  <ColourOption data={currentBC} name="Light Gray" dbKey="colour__Light Gray__" onChange={onColourChange} />
                  <ColourOption data={currentBC} name="White" dbKey="colour__White__" onChange={onColourChange} />
                  <ColourOption data={currentBC} name="Black" dbKey="colour__Black__" onChange={onColourChange} />
                  <ColourOption data={currentBC} name="Logo Green" dbKey="colour__Logogreen__" onChange={onColourChange} />
                  <ColourOption data={currentBC} name="Red" dbKey="colour__Red__" onChange={onColourChange} />
                  <ColourOption data={currentBC} name="Yellow" dbKey="colour__Yellow__" onChange={onColourChange} />
                  <ColourOption data={currentBC} name="Blue" dbKey="colour__Blue__" onChange={onColourChange} />
                  <ColourOption data={currentBC} name="Teal" dbKey="colour__Teal__" onChange={onColourChange} />
                  <ColourOption data={currentBC} name="Cobalt" dbKey="colour__Cobalt__" onChange={onColourChange} />
                </div>
                <br />
                <button onClick={saveRow}>Save</button>
              </div>
              </> : null
        }
    </div>
  </GenericPageContainer>)
}
