import React from 'react'
import Navigation from '../../components/navigation/navigation'
import Footer from '../../components/footer/footer'
import Container from '../../components/container/container'
import request from '../../helpers/request';
import { matchPath, withRouter } from 'react-router-dom';
import endpoints from '../../helpers/endpoints';
import Card from '../../components/card/card';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core'

export default withRouter(({ ...props }) => {
    const match = matchPath(props.history.location.pathname, {
        path: '/admin/portal/companies/:company/batch-processes',
    })
    const {params} = match;
    const companyId = params.company;
    const [companyConfig, setCompanyConfig] = React.useState(null);
    const [products, setProducts] = React.useState([]);
    const [productConfigs, setProductConfigs] = React.useState([]);

    const loadData = () => {
        request(true).get(endpoints.ADMIN_COMPANIES_COMPANY + '/' + companyId + '/products').then(r => {
            let productOfferings = r.data.products;
            request(true).get(endpoints.PRODUCTS).then(r => {
                productOfferings = productOfferings.filter(productOffering => r.data.find(realProduct => realProduct.id == productOffering.id).available)

                productOfferings = productOfferings.map(productOffering => ({
                    ...productOffering,
                    icon: r.data.find(realProduct => realProduct.id == productOffering.id).icon,
                }))
                setProducts(productOfferings)
            })
        })
        request(true).get(endpoints.ADMIN_COMPANIES_COMPANY + '/' + companyId + '/batch-config').then(r => {
            setCompanyConfig(r.data)
        })
        request(true).get(endpoints.ADMIN_COMPANIES_COMPANY + '/' + companyId + '/product-batch-configs').then(r => {
            setProductConfigs(r.data)
        })
    }

    const updateBatchConfigState = e => {
        const { name, value, type, checked } = e.target;
        setCompanyConfig(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value || '',
        }));
    };

    const batchConfigSubmit = () => {
        request(true).post(endpoints.ADMIN_COMPANIES_COMPANY + '/' + companyId + '/batch-config', {
            s3_api_var_path: companyConfig.s3_api_var_path,
            client: companyConfig.client,
            test_env: companyConfig.test_env,
            s3_local_path: companyConfig.s3_local_path,
            bd_portal: companyConfig.bd_portal,
            extra_baa_file: companyConfig.extra_baa_file,
        }).catch(e => {
            console.error(e);
        })
    }

    const updateProductConfigState = e => {
        const { name, value, type, checked } = e.target;

        const productId = parseInt(name.substr(0, name.indexOf('_')));
        const existingConfigIndex = productConfigs.findIndex(config => config?.product_offering_id === productId);

        if (existingConfigIndex !== -1) {
            const updatedConfig = { ...productConfigs[existingConfigIndex], [name.substr(name.indexOf('_') + 1)]: type === 'checkbox' ? checked : value };
            setProductConfigs(prevState => {
                const updatedConfigs = [...prevState];
                updatedConfigs[existingConfigIndex] = updatedConfig;
                return updatedConfigs;
            });
        } else {
            const newConfig = {
                product_offering_id: productId,
                [name.substr(name.indexOf('_') + 1)]: type === 'checkbox' ? checked : value,
            };
            setProductConfigs(prevState => [...prevState, newConfig]);
        }
    }

    const productConfigSubmit = item => {
        // const config = productConfigs.find(productConfig => productConfig.product_offering_id === item)
        request(true).post(endpoints.ADMIN_COMPANIES_COMPANY + '/' + companyId + '/product-batch-configs',
            productConfigs,
            ).catch(e => {
            console.error(e);
        })
    }

    React.useEffect(() => {
        loadData();
    }, []);

    return (
      <div className="grid grid-gap-20">
        <Navigation/>
        <Container>
          <div className="flex middle">
            <p className="colour-secondary font-weight-600" style={{fontSize: 20}}>Batch Processes - Company Config</p>
          </div>
          <Card>
            <div style={{marginBottom: '15px'}} className="grid grid-columns-2 grid-gap-15">
              <div className="form-row">
                <label htmlFor="s3_api_var_path">S3 API Variables Path:</label>
                <input onChange={updateBatchConfigState} name="s3_api_var_path" className="input" type="text" value={companyConfig?.s3_api_var_path} required/>
              </div>
              <div className="form-row">
                <label htmlFor="client">Client:</label>
                <input onChange={updateBatchConfigState} name="client" className="input" type="text" value={companyConfig?.client} required/>
              </div>
              <div className="form-row">
                <label htmlFor="test_env">Test Environment:</label>
                <input onChange={updateBatchConfigState} name="test_env" className="input" type="text" value={companyConfig?.test_env} required/>
              </div>
              <div className="form-row">
                <label htmlFor="s3_local_path">S3 Local Path:</label>
                <input onChange={updateBatchConfigState} name="s3_local_path" className="input" type="text" value={companyConfig?.s3_local_path} required/>
              </div>
              <div className="form-row">
                <label htmlFor="bd_portal">BD Portal</label>
                <div style={{textAlign: 'left'}}>
                  <Checkbox
                    key="bd_portal"
                    color='primary'
                    checked={ !!companyConfig?.bd_portal }
                    onChange={ updateBatchConfigState }
                    name="bd_portal"
                  />
                </div>
              </div>
              <div className="form-row">
                <label htmlFor="extra_baa_file">Extra BAA File</label>
                <div style={{textAlign: 'left'}}>
                  <Checkbox
                    key="extra_baa_file"
                    color='primary'
                    checked={ !!companyConfig?.extra_baa_file }
                    onChange={ updateBatchConfigState }
                    name="extra_baa_file"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-gap-15">
              <button onClick={ () => batchConfigSubmit() } className="button background-primary colour-white">Update</button>
            </div>
          </Card>
          <div className="flex middle">
            <p className="colour-secondary font-weight-600" style={{fontSize: 20}}>Batch Processes - Product Config</p>
          </div>
          {/* <div className='grid grid-columns-1 grid-gap-10' style={{marginBottom: '15px'}}>
                    <div className="form-row">
                        <strong>COT Portal Products</strong>
                        <table className="table borders permissions">
                            <thead>
                                <tr>
                                <th>Product</th>
                                    <th>Run BAA</th>
                                    <th>Run BCV</th>
                                    <th>Run CHA</th>
                                    <th>Run VaCA</th>
                                    <th>Run OV</th>
                                    <th>Run BOTV</th>
                                    <th>Run PPV</th>
                                    <th>Run PS</th>
                                    <th>Client Email Return</th>
                                </tr>
                            </thead>
                            <tbody>
                                { products.filter(product =>
                                    product.enabled === true && product.icon === "fak fa-id-house-icon" && product.product_key
                                ).sort((a, b) => {
                                    let nameA = a.name.toLowerCase();
                                    let nameB = b.name.toLowerCase();
                                    if (nameA < nameB) return -1;
                                    if (nameA > nameB) return 1;
                                    return 0;
                                }).map(product => {
                                    return (
                                        <tr>
                                            <td>
                                                { product.name }
                                            </td>
                                            {
                                                ["run_baa", "run_bcv", "run_cha", "run_vaca", "run_ov", "run_botv", "run_ppv", "run_ps", "client_email_return"].map((key) => {
                                                    const config = productConfigs?.find(config => config?.product_offering_id === product.id)
                                                    return (
                                                        <td className="item" key={`product-${product.key}-${key}`}>
                                                            <FormControlLabel
                                                                 key={`${product.id}_${key}`}
                                                                name={`${product.id}_${key}`}
                                                                control={<Checkbox
                                                                    key={`${product.id}_${key}`}
                                                                    color='primary'
                                                                    checked={ config && config[key] ? true : false }
                                                                    onChange={ updateProductConfigState }
                                                                    name={`${product.id}_${key}`} />}
                                                            />
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <button onClick={productConfigSubmit} className="button background-primary colour-white">Update</button>
                    </div>
                </div> */}
          <div className='grid grid-columns-1 grid-gap-10'>
            <div className="form-row">
              <strong>BD Portal Products</strong>
              <table className="table borders permissions">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Run BAA</th>
                    <th>Run BCV</th>
                    <th>Run CHA</th>
                    <th>Run VaCA</th>
                    <th>Run OV</th>
                    <th>Run OTV</th>
                    <th>Run PPV</th>
                    <th>Run OS</th>
                    <th>Run BGAP</th>
                    <th>UKS OV/CV</th>
                    <th>Client Email Return</th>
                  </tr>
                </thead>
                <tbody>
                  { products.filter(product => product.enabled === true && product.icon === 'fak fa-id-business-icon' && product.product_key,
                                ).sort((a, b) => {
                                    const nameA = a.name.toLowerCase();
                                    const nameB = b.name.toLowerCase();
                                    if (nameA < nameB) return -1;
                                    if (nameA > nameB) return 1;
                                    return 0;
                                }).map(product => (
                                  <tr>
                                    <td>
                                      { product.name }
                                    </td>
                                    {
                                                ['run_baa', 'run_bcv', 'run_cha', 'run_vaca', 'run_ov', 'run_otv', 'run_ppv', 'run_os', 'run_bgap', 'uks_ov_cv', 'client_email_return'].map(key => {
                                                    const config = productConfigs?.find(config => config?.product_offering_id === product.id)
                                                    if (key === 'uks_ov_cv') {
                                                        return (
                                                          <td className="item" key={`product-${product.key}-${key}`}>
                                                            <select value={ config ? config[key] : ''} key={`${product.id}_${key}`} name={`${product.id}_${key}`} onChange={updateProductConfigState}>
                                                              <option value={null} />
                                                              <option value="OV">OV</option>
                                                              <option value="CV">CV</option>
                                                            </select>
                                                          </td>
                                                        )
                                                    }
                                                        return (
                                                          <td className="item" key={`product-${product.key}-${key}`}>
                                                            <FormControlLabel
                                                              key={`${product.id}_${key}`}
                                                              name={`${product.id}_${key}`}
                                                              control={<Checkbox
                                                                key={`${product.id}_${key}`}
                                                                color='primary'
                                                                checked={ !!(config && config[key]) }
                                                                onChange={ updateProductConfigState }
                                                                name={`${product.id}_${key}`}
                                                              />}
                                                            />
                                                          </td>
                                                        )
                                                })
                                            }
                                  </tr>
                                    ))}
                </tbody>
              </table>
              <button onClick={productConfigSubmit} className="button background-primary colour-white">Update</button>
            </div>
          </div>
        </Container>
        <Footer/>
      </div>
    )
})
