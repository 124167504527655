import React, { useCallback, useMemo, useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import ReactSelect from 'react-select'
import endpoints from '../../../../helpers/endpoints'
import request from '../../../../helpers/request'
import { GenericTable } from '../../VacantCOTAlerts/sharedComponents'
import Button from '../../../../components/large-link-button/largeLinkButton'
import ReactModal from 'react-modal'
import Checkbox from '@material-ui/core/Checkbox';
import '../WholesalerGAPS.scss';
import hasPermission from '../../../../helpers/permissions'

export const Summary = ({workflow = false, translations = {}}) => {
  const [chosenPostcode, setChosenPostcode] = useState()
  const [workflowItems, setWorkflowItems] = useState([])
  const [voaAddresses, setVoaAddresses] = useState([])
  const [abpAddresses, setAbpAddresses] = useState([])
  const [selectedVoa, setSelectedVoa] = useState(null)
  const [selectedAbp, setSelectedAbp] = useState(null)
  const [abpMode, setAbpMode] = useState('unmatched');
  const [customAbpPostcode, setCustomAbpPostcode] = useState('');
  const [postcodeModal, setPostcodeModal] = useState(false);
  const [noMatchModal, setNoMatchModal] = useState(false);

  const getSummaryData = (workflowInput) => {
    setAbpAddresses([])
    setVoaAddresses([])
    if (chosenPostcode || workflowInput) {
      getAbpAddresses(workflowInput)
      getVoaAddresses(workflowInput)
    }
  }

  const getAbpAddresses = (workflowInput) => {
    setSelectedAbp(null)
    setAbpMode('unmatched')
    request(true).get(endpoints.WHOLESALER_GAPS_GET_ADDRESSES, {
      params: {
        postcode:  workflowInput || customAbpPostcode || chosenPostcode
      }
    }).then(r => {
      setAbpAddresses(r.data);
    }).catch(e => {
      setAbpAddresses([])
    })
  }

  const getVoaAddresses = (workflowInput) => {
    setSelectedVoa(null)

    request(true).get(endpoints.WHOLESALER_GAPS_GET_VOAS, {
      params: {
        stage: '2',
        postcode: workflowInput || chosenPostcode
      }
    }).then(r => {
      setVoaAddresses(r.data);
    }).catch(e => {
      setAbpAddresses([])
      setVoaAddresses([])
    })
  }

  useEffect(() => {
    setCustomAbpPostcode('')
  }, [chosenPostcode])

  useEffect(() => {
    if (workflow) {
      getWorkflowItems()
    }
  }, [])

  useEffect(() => {
    const addressToWork = voaAddresses.find(address => address.ABP_Status === "None_Found" || address.ABP_Status === "Invalid_Postcode"
    )
    if (workflow && !addressToWork && voaAddresses.length) {
      getWorkflowItems()
    }
  }, [voaAddresses])

  const getWorkflowItems = () => {
    request(true).get(`${endpoints.WHOLESALER_GAPS_WF_ITEMS}?stage=2`).then(e => {
      setWorkflowItems(e.data);
      if (e.data.length > 0) {
        setChosenPostcode(_cur && e.data[_cur - 1] ? e.data[_cur - 1] : e.data[0]);
        getSummaryData(_cur && e.data[_cur - 1] ? e.data[_cur - 1] : e.data[0]);
      } else {
        setAbpAddresses([])
        setVoaAddresses([])
        setChosenPostcode('')
      }
    })
  }

  const _cur = useMemo(() => {
    return workflowItems.findIndex(_ => _ === chosenPostcode);
  }, [workflowItems, chosenPostcode]);

  const getWorkflowNext = () => {
    if (_cur !== workflowItems.length - 1) {
      setChosenPostcode(workflowItems[_cur + 1]);
      getSummaryData(workflowItems[_cur + 1])
    } else {
      setChosenPostcode(workflowItems[0]);
      getSummaryData(workflowItems[0])
    }
  }

  const getWorkflowLast = () => {
    if (_cur !== 0) {
      setAbpAddresses([])
      setVoaAddresses([])
      setChosenPostcode(workflowItems[_cur - 1]);
      getSummaryData(workflowItems[_cur - 1])
    } else {
      setAbpAddresses([])
      setVoaAddresses([])
      setChosenPostcode(workflowItems[workflowItems.length - 1]);
      getSummaryData(workflowItems[workflowItems.length - 1])
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      getSummaryData()
    }
  }

  const submit = () => {
    request(true).post(endpoints.WHOLESALER_GAPS_SELECT_MATCH_VOA, {
      spid: chosenPostcode,
      abp: selectedAbp,
      voa: selectedVoa
    }).then(r => {
      getSummaryData()
      window.alert("VOA matched")
    }).catch(e => {
      window.alert("Server error - failed to submit match")
      console.log(e)
    })
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
      <div style={{display: 'grid', gap: 10}}>
        <table className='table borders squish smaller-text left' style={{tableLayout: 'fixed', width: '100%'}}>
          <col width={180} />
          <thead>
            <tr>
              <th colSpan={6}>{translations['WSG.Header_Summary']}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td width={400}>{translations['WSG.CF_Postcode']}</td>
              <td colSpan={5}>
                <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                  <input style={{border: 'none'}} type="text" onChange={e => setChosenPostcode(e.target.value)} value={chosenPostcode} onKeyDown={handleKeyDown} />
                    { workflow ? 
                      <>
                        <span className='fas fa-angle-left colour-primary' style={{cursor: 'pointer'}} onClick={getWorkflowLast}></span>
                        <span>{_cur + 1}/{workflowItems.length}</span>
                        <span className='fas fa-angle-right colour-primary' style={{cursor: 'pointer'}} onClick={getWorkflowNext}></span>
                      </>
                    : 
                      <span className='fas fa-search colour-primary' style={{cursor: 'pointer'}} onClick={() => getSummaryData()}></span>
                    }
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='WSG' style={{gridTemplateColumns: '4fr 2fr 1fr'}}>
        <div  style={{maxHeight: '90vh', overflowY: 'auto', display: 'block'}}>
          <table className='table borders squish smaller-text left' style={{tableLayout: 'auto', width: '100%'}}>
          <thead style={{position: 'sticky', top: '0'}}>
              <tr>
                <td colSpan={2}>{translations['WSG.VOA_Address']}</td>
                <td>Select</td>
                <td colSpan={2}>{translations['WSG.Matched_ABP_Address']}</td>
              </tr>
            </thead>
            <tbody>
            {
                voaAddresses.length > 0 ? 
                  voaAddresses
                  .sort((a, b) => {
                    return a.ABP_Status === 'None_Found' || a.ABP_Status === 'Invalid_Postcode' ? 1 : b.ABP_Status === 'None_Found' || b.ABP_Status === 'Invalid_Postcode' ? -1 : 0;
                  })
                  .filter(voa => {
                    return voa.Effective_Status !== "DELETED"
                  }).map(row => {
                    return (
                      <tr style={{height: '60px'}}>
                        <td colSpan={2}>{row.Full_Property_Identifier}</td>
                        <td>
                          {
                            row.ABP_Status === "None_Found" || row.ABP_Status === "Invalid_Postcode" ?
                              <input type="checkbox" name={ row.BA_Reference_Number } onChange={ () => setSelectedVoa( selectedVoa !== row.BA_Reference_Number ? row.BA_Reference_Number : null )} checked={ selectedVoa === row.BA_Reference_Number }/>
                            :
                              null
                          }
                        </td>
                        <td colSpan={2}>
                          { row.ABP_Status !== "None_Found" ? 
                            row.AddressBase_Address
                          : null}
                        </td>
                      </tr>
                    )
                  })
                :
                  <tr>
                    <td colSpan={2}></td>
                    <td></td>
                  </tr>
              }
            </tbody>
          </table>
        </div>
        <div  style={{maxHeight: '90vh', overflowY: 'auto', display: 'block'}}>
          <table className='table borders squish smaller-text left' style={{tableLayout: 'auto', width: '100%'}}>
            <thead style={{position: 'sticky', top: '0'}}>
              <tr>
                <td colSpan={2}>
                  <div style={{display: 'flex', justifyContent: 'center', gap: 10}}>
                    { abpMode === "unmatched" ?
                        translations['WSG.Unmatched_ABP_Address']
                      :
                        translations['WSG.Matched_ABP_Address']
                    } 
                    <div style={{display: 'flex', gap: 10}}>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10}}>
                        <i style={{cursor: 'pointer'}} 
                          class="fa-solid fa-repeat"
                          onClick={() => abpMode === 'unmatched' ? setAbpMode('matched') : setAbpMode('unmatched')}
                        ></i>
                        <i style={{cursor: 'pointer'}}
                          class="fa-solid fa-search"
                          onClick={() => setPostcodeModal(true)}
                        ></i>
                        { customAbpPostcode ? 
                          <i style={{cursor: 'pointer'}}
                            class="fa-solid fa-trash"
                            onClick={() => { setCustomAbpPostcode(null); getAbpAddresses(chosenPostcode); }}
                          ></i>
                        : ''}
                      </div>
                    </div>
                  </div>
                </td>
                <td>Selected</td>
              </tr>
            </thead>
            <tbody>
              { abpMode === "unmatched" ?
                  abpAddresses.length > 0 ? 
                    abpAddresses.filter(abpAddress => !voaAddresses
                      .filter(voa => {
                        return voa.Effective_Status !== "DELETED"
                      })
                      .some(voaAddress => voaAddress.ABP_UPRN == abpAddress.UPRN && voaAddress.AddressBase_Address == abpAddress.SINGLE_LINE_ADDRESS
                      )
                    ).map(row => {
                      return (
                        <tr style={{height: '60px'}}>
                          <td colSpan={2}>{row.SINGLE_LINE_ADDRESS}</td>
                          <td><input type="checkbox" name={ row.UPRN } onChange={ () => setSelectedAbp( selectedAbp !== row.UPRN ? row.UPRN : null )} checked={ selectedAbp === row.UPRN }/></td>
                        </tr>
                      )
                    })
                  :
                    <tr>
                      <td colSpan={2}></td>
                      <td></td>
                    </tr>
              :
                voaAddresses.length > 0 ? 
                  voaAddresses
                  .sort((a, b) => a.ABP_Status === 'None_Found' ? 1 : b.ABP_Status === 'None_Found' ? -1 : 0)
                  .filter(address => address.ABP_UPRN && address.ABP_UPRN !== "None")
                  .map(row => {
                    return (
                      <tr style={{height: '60px'}}>
                        <td colSpan={2}>
                          { row.ABP_Status !== "None_Found" ? 
                            row.AddressBase_Address
                          : null}
                        </td>
                        <td><input type="checkbox" name={ row.ABP_UPRN } onChange={ () => setSelectedAbp( selectedAbp !== row.ABP_UPRN ? row.ABP_UPRN : null )} checked={ selectedAbp === row.ABP_UPRN }/></td>
                      </tr>
                    )
                  })
                :
                  <tr>
                    <td colSpan={2}></td>
                    <td></td>
                  </tr>
              }
            </tbody>
          </table>
        </div>
        <div style={{display: 'grid', gap: '10px', height: 'fit-content'}}>
          {
            selectedAbp && selectedVoa && hasPermission('WHOLESALER_GAPS', 'PURCHASE') ?
              <button onClick={() => submit()} style={{height: 'fit-content', backgroundColor: 'var(--primary)'}} className='button compact smaller-text colour-white'>Submit</button>
            :
              <button style={{height: 'fit-content'}} disabled className='button compact smaller-text'>Submit</button>
          }
          <button onClick={() => setNoMatchModal(true)} style={{height: 'fit-content'}} disabled={selectedVoa && hasPermission('WHOLESALER_GAPS', 'PURCHASE') ? false : true} className={`button compact smaller-text ${selectedVoa && hasPermission('WHOLESALER_GAPS', 'PURCHASE')  ? 'cancel' : ''}`}>No Match</button>
        </div>
      </div>
      <ReactModal 
      isOpen={noMatchModal} 
      onRequestClose={() => setNoMatchModal(false)}
      className="card bulk-allocate"
      contentLabel="Wholesaler GAPs No Match"
      style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}>
        <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
          <h3>No Match Available</h3>
          <p>Please confirm there is no suitable match.</p>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
            <button className="button compact smaller-text cancel" onClick={ () => setNoMatchModal(false) }>Cancel</button>
            <button className="button compact smaller-text background-primary colour-white" onClick={() => {
              setNoMatchModal(false); 
              setSelectedAbp(null); 
              submit()}}
            >No Match</button> 
          </div>
        </div>
      </ReactModal>
      <ReactModal 
      isOpen={postcodeModal} 
      onRequestClose={() => setPostcodeModal(false)}
      className="card bulk-allocate"
      contentLabel="Wholesaler GAPs postcode"
      style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}>
        <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
          <h3>Postcode Search</h3>
          <input value={customAbpPostcode} onChange={_ => setCustomAbpPostcode(_.target.value.toUpperCase())} style={{border: '1px solid gray', width: '100%', borderRadius: 3}} />
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            <button className={`button compact smaller-text ${customAbpPostcode?.length >= 3 ? 'background-primary colour-white' : ''} `} disabled={customAbpPostcode?.length < 3} onClick={() => {getSummaryData(); setPostcodeModal(false)}}>Search</button> 
          </div>
        </div>
      </ReactModal>
    </div>
  )
}
