import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { matchPath, useHistory } from 'react-router';
import Select from 'react-select';
import Container from '../../components/container/container'
import Navigation from '../../components/navigation/navigation'
import request from '../../helpers/request';
import session from '../../stores/session';

export default function Grouping() {
    const history = useHistory();
    const match = matchPath(history.location.pathname, {
        path: '/admin/portal/groupings/:id',
    })
    const [grouping, setGrouping] = useState({});
    const [realProducts, setRealProducts] = useState([])
    const {register, handleSubmit, control, setValue, getValues} = useForm()

    useEffect(() => {
        if (session.groupSelectedProduct) {
          setGrouping(prevGroup => ({
            ...prevGroup,
            real_product_id: session.groupSelectedProduct,
          }));
        }
      }, [session.groupSelectedProduct]);

    const groupingId = useMemo(() => match.params.id, [match.params.id])

    const getOption = (id = groupingId) => {
        request(true).get(`/datagroupings/${id}`).then(data => {
            if (data.status === 200) {
                setGrouping(data.data);
                session.groupSelectedProduct = data.data?.real_product_id
            }
        })
    }

    useEffect(() => {
        getOption();
        getRealProducts()
    }, [groupingId])

    const getRealProducts = () => {
        request(true).get('/real-products').then(data => {
          if (data.status === 200) setRealProducts(data.data)
        })
    }

    const realProductSelect = useMemo(() => realProducts.map(_ => ({
                value: _.id,
                label: _.product_key,
            })), [realProducts])

    const createOrUpdate = data => {
        if (groupingId !== 'new') {
            request(true).post(`/datagroupings/${groupingId}`, data).then(e => {
                if (e.status === 200) {
                    alert(e.statusText)
                }
            }).catch(e => {
                if (e && e.response) {
                    window.alert(JSON.stringify(e.response.data))
                }
            })
        } else {
            request(true).post('/datagroupings', data).then(e => {
                alert(e.statusText)
                if (e.status === 201) {
                    history.replace(`/admin/portal/groupings/${e.data.id}`);
                }
            }).catch(e => {
                if (e && e.response) {
                    window.alert(JSON.stringify(e.response.data))
                }
            })
        }
        console.log(data)
    }

    useEffect(() => {
        if (groupingId !== 'new') {
            getOption(groupingId);
        }
        getRealProducts()
    }, [match.params.id])

    return (
        <div>
            <Navigation />
            <Container>
                <div className="flex middle">
                    <p className="colour-secondary font-weight-600" style={{ fontSize: 20 }}>Data Groupings - {groupingId !== 'new' ? grouping ? grouping.internal_name : 'Loading...' : 'Create'}</p>
                </div>
            </Container>
            <Container>
                {
                    groupingId === 'new' || grouping ? <>
                    <div className="grid grid-columns-2 grid-gap-15">
                        <form onSubmit={handleSubmit(createOrUpdate)}>
                            <p className="colour-secondary font-weight-600" style={{fontSize: 18}}>Grouping</p>
                            <div className="form-row">
                                <label>Internal Name (Not shown to customer)</label>
                                <input ref={register({required: true})} defaultValue={groupingId !== 'new' ? grouping.internal_name : undefined} name="internal_name" className="input" type="text" required />
                            </div>
                            <div className="form-row">
                                <label>Title (Shown to customer)</label>
                                <input ref={register({required: true})} defaultValue={groupingId !== 'new' ? grouping.title : undefined} name="title" className="input" type="text" required />
                            </div>
                            <div className="form-row">
                                <label>Target Column (Column to compare values)</label>
                                <input ref={register({required: true})} defaultValue={groupingId !== 'new' ? grouping.target_column : undefined} name="target_column" className="input" type="text" required />
                            </div>
                            <div className="form-row">
                                <label>Order Weight (Higher for first)</label>
                                <input ref={register({valueAsNumber: true})} defaultValue={groupingId ? grouping.order : undefined} name="order" className="input" type="number" min={0} />
                            </div>
                            <div>
                                <label>Real Product</label>
                                <Controller
                                  name="real_product_id"
                                  control={control}
                                  defaultValue={grouping.real_product_id || session.groupSelectedProduct || undefined}
                                  render={props => <Select
                                    options={realProductSelect}
                                    value={realProductSelect.find(_ => _.value === grouping.real_product_id)}
                                    isLoading={!realProductSelect || !realProductSelect.length}
                                    onChange={_ => { props.onChange(_.value); session.groupSelectedProduct = _.value; setGrouping({...grouping, real_product_id: _.value}) }}
                                  />}
                                />
                            </div>
                            <button type="submit" className="button compact background-primary colour-white">{groupingId === 'new' ? 'Create Data Grouping' : 'Update Data Grouping'}</button>
                        </form>
                        <div>
                            <p className="colour-secondary font-weight-600" style={{fontSize: 18}}>Grouping Values</p>
                            <br />
                            <br />
                            <div className="grid grid-columns-2 grid-gap-5">
                                {
                                    groupingId !== 'new' ? grouping.options && grouping.options.length ? grouping.options.map(option => (
                                            <GroupingsItem key={`option_${option.id}`} data={option} refresh={getOption} />
                                        )) : null : null
                                }
                                <GroupingsItem key="new_data_grouping_option" data={{ data_grouping_id: groupingId, order: 0 }} refresh={getOption} />
                            </div>
                        </div>
                    </div>
                    </> : null
                }
            </Container>
        </div>
    )
}

function GroupingsItem({data, refresh}) {
    const [groupingOption, setGroupingOption] = useState(data);

    useEffect(() => {
        setGroupingOption({...groupingOption, data_grouping_id: data.data_grouping_id})
    }, [data.data_grouping_id])

    const move = direction => {
        let newOrder = groupingOption.order;
        if (direction === 'up') {
            newOrder++;
        } else {
            newOrder--;
        }
        request(true).post(`/datagroupingsoption/${groupingOption.id}`, {
            ...groupingOption,
            order: newOrder,
        }).then(() => {
            refresh()
            setGroupingOption({...groupingOption, order: newOrder})
        })
    }
    const save = useCallback(() => {
        console.log(groupingOption)
        if (groupingOption.id) {
            // update
            request(true).post(`/datagroupingsoption/${groupingOption.id}`, groupingOption).then(e => {
                alert(e.statusText)
                refresh()
            })
        } else {
            // create
            request(true).post('/datagroupingsoption', groupingOption).then(e => {
                alert(e.statusText)
                refresh()
            })
        }
    }, [groupingOption])

    const deleteOption = () => {
        if (!window.confirm('Delete option?')) return
        request(true).delete('/datagroupingsoption/' + groupingOption.id).then(e => {
            alert(e.statusText)
            refresh()
        })
    }

    return (
        <div className="grid grid-gap-5">
            <div style={{display: 'grid', gridTemplateColumns: 'auto min-content', gap: 5}}>
                <input onChange={_ => setGroupingOption({ ...groupingOption, name: _.target.value })} defaultValue={groupingOption.name} className="input" type="text" required />
                {
                    groupingOption.id ? (
                        <div className="flex">
                            <div>
                                <button onClick={() => move('up')}><i className="fas fa-caret-right" /></button>
                                ({groupingOption.order})
                                <button onClick={() => move('down')}><i className="fas fa-caret-left" /></button>
                            </div>
                        </div>
                    ) : null
                }
            </div>
            <div className="grid grid-gap-5 grid-columns-2">
                <button onClick={save} className="button compact background-primary colour-white">{groupingOption.id ? 'Update' : 'Create'}</button>
                {groupingOption.id ? <button className="button compact background-yellow colour-white" onClick={e => deleteOption(e)}>Delete Option</button> : null}
            </div>
        </div>
    )
}
