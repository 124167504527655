import React, { Component } from 'react';
import endpoints from '../../helpers/endpoints';
import Navigation from '../../components/navigation/navigation'
import Footer from '../../components/footer/footer'
import Container from '../../components/container/container'
import Pagination from '../../components/pagination/pagination';
import Modal from 'react-modal';

import request from '../../helpers/request';
import { Link } from 'react-router-dom';

export default class Users extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            pagination: {
                page: 1,
                last_page: null,
            },
            new_user: {
                name: '',
                email: '',
            },
            errors: {},
            modalIsOpen: false,
        }
    }

    updatePage = page => {
        this.loadPage(page);
    }

    loadPage = (page = 1) => {
        request(true).get(endpoints.ADMIN_USERS_INDEX + '?page=' + page).then(e => {
            // console.log(e.data);
            this.setState({
                users: e.data.data,
                pagination: {
                    page: e.data.current_page,
                    last_page: e.data.last_page,
                },
            });
        }).catch(e => {
            // console.log(e.response);
        });
    }

    closeModal = () => {
        this.setState({
            modalIsOpen: false,
        });
    }

    openModal = () => {
        this.setState({
            modalIsOpen: true,
        });
    }

    newUserFormSubmit = () => {
        request(true).post(endpoints.ADMIN_USERS_INDEX, {
            ...this.state.new_user,
            company_id: null,
        }).then(r => {
            this.loadPage(1);
            this.setState({
                new_user: { name: '', email: '' },
            });
        }).catch(r => {
            this.setState({
                errors: r.response.data.errors,
            });
        });
    }

    updateNewUserFormValue = e => {
        this.setState({
            new_user: {
                ...this.state.new_user,
                [e.target.name]: e.target.value ? e.target.value : undefined,
            },
        })
    }

    componentDidMount() {
        this.loadPage();
    }

    render() {
        return (
            <div className="grid grid-gap-20" id="admin-page">
                <Navigation />
                <Container>
                    <div className="flex middle">
                        <p className="colour-secondary font-weight-600" style={{fontSize: 20}}>Users</p>
                        <button onClick={this.openModal} style={{ marginLeft: 'auto' }} className="button background-primary colour-white">Add A User</button>
                    </div>
                </Container>
                <Container>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            { this.state.users.map(user => (
                                    <tr key={user.id}>
                                        <td>{ user.name }</td>
                                        <td>{ user.subdomain }</td>
                                        <td className="actions">
                                            <Link to={ '/admin/portal/users/' + user.id }><i class="fa fa-search" aria-hidden="true" /></Link>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    <Pagination page={this.state.pagination.page} pages={this.state.pagination.last_page} pageChange={this.updatePage}/>
                </Container>
                <Modal
                  style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
                  className="card"
                  isOpen={this.state.modalIsOpen}
                  onRequestClose={this.closeModal}
                  contentLabel="Add A User"
                  appElement={document.getElementById('admin-page')}
                >
                <div className="grid grid-gap-15">
                        <div className="form-row">
                            <label htmlFor="name">Name:</label>
                            <input onChange={this.updateNewUserFormValue} name="name" placeholder="Name" className="input" type="text" value={this.state.new_user.name} required/>
                            { this.state.errors.name ? this.state.errors.name.map((value, index) => (
                                    <p key={index}>
                                        { value }
                                    </p>
                                )) : <></>}
                        </div>
                        <div className="form-row">
                            <label htmlFor="email">Email:</label>
                            <input onChange={this.updateNewUserFormValue} name="email" placeholder="Email" className="input" type="text" value={this.state.new_user.email} required/>
                            { this.state.errors.email ? this.state.errors.email.map((value, index) => (
                                    <p className="error" key={index}>
                                        { value }
                                    </p>
                                )) : <></>}
                        </div>
                        <button onClick={this.newUserFormSubmit} className="button background-primary colour-white">Create</button>
                        <p className="text-center">{ this.state.message }</p>
                    </div>
                </Modal>
                <Footer />
            </div>
        )
    }
}
