import session from '../stores/session';

export default function hasPermission(module, permission) {
    if (session.user.user_type === 'Team Leader' && module !== "ADMIN") {
        return true;
    } if ((session.user && session.user.user_type && session.user.user_type === 'Agent' && permission === 'WORKFLOW')) {
        return true;
    } if (session.user.permissions.indexOf(module + '-' + permission) !== -1) {
        return true
    }
        return false;
}
