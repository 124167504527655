import { view } from '@risingstack/react-easy-state'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PropertyMap from '../../../components/property-map/property-map'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import request from '../../../helpers/request'
import session from '../../../stores/session'
import { AddressBase, CMOSFormatted, CMOSLive, Summary, ValuationOffice } from './shared'

export default view(function BusinessAddressAlertWorkflow() {
  const [summaryData, setSummaryData] = useState({})
    
  const [translations, setTranslations] = useState({})
  useEffect(() => {
    request(true).get('/translate?key=BAA.')
    .then(e => {
      setTranslations(e.data)
    })
    }, [])
  return (
    <GenericPageContainer
      title="Address Validation - Workflow"
      titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
      titleRight={
        <div>
          <Link className="navigate" to="/portal/business-address-alert">
              To Summary
          </Link>
          <Link className="navigate" to="/portal/business-address-alert/search">
              To Search
          </Link>
        </div>
      }
    >
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <div style={{display: 'grid', gridTemplateColumns: '2fr 1fr', gap: 10}}>
          <Summary translations={translations} summaryData={summaryData} setSummaryData={setSummaryData} workflow={true} />
          <PropertyMap translations={translations} address={summaryData['CMOS_Address_Single_Line']} />
        </div>

        <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
          <CMOSLive translations={translations} summaryData={summaryData} />
          <AddressBase translations={translations} summaryData={summaryData} />
          <ValuationOffice translations={translations} summaryData={summaryData} />
          <CMOSFormatted translations={translations} summaryData={summaryData} />
        </div>
      </div>
    </GenericPageContainer>    
  )
})
