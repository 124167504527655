// Modules
import React, { Component } from 'react'
import hasPermission from './helpers/permissions.js';
import { BrowserRouter, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import Echo from 'laravel-echo'
import { view } from '@risingstack/react-easy-state';
import axios from 'axios';

// Local
import sessionState from './stores/session';
import session from './stores/session';
import endpoints from './helpers/endpoints';

// Pages

// Global Pages
import Reset from './pages/reset';
import LoginPage from './pages/login';
import Logout from './pages/logout';
import ActivatePage from './pages/activate';
import Account from './pages/account';
import DoReset from './pages/doReset';
import VersionHistory from './pages/version.js';

// User Pages
import PortalPage from './pages/portal';

// Admin
import Admin from './pages/admin/index';
import AdminCompanies from './pages/admin/companies';
import AdminCompany from './pages/admin/company';
import AdminUser from './pages/admin/user';
import Users from './pages/admin/users';
import ProductsPage from './pages/admin/products';
import ProductPage from './pages/admin/product';
import Audits from './pages/admin/audits';
import Usage from './pages/admin/usage.jsx';
import Translations from './pages/admin/translations';
import Tutorials from './pages/admin/tutorials';
import BatchProcesses from './pages/admin/batchProcesses';
import CompanySelect from './pages/admin/companySelect';
import DataFilters from './pages/admin/filters'
import DataFilter from './pages/admin/filter';

//
import ContactValidation from './pages/modules/CV/contactValidation';

//
import voidSalesAlertsSearch from './pages/modules/VSA/voidSalesAlertsSearch';
import voidSalesAlertsWorkflow from './pages/modules/VSA/voidSalesAlertsWorkflow';
import voidSalesAlerts from './pages/modules/VSA/voidSalesAlerts';

//
import proprietorValidationSearch from './pages/modules/ProprietorValidation/proprietorValidationSearch';
import proprietorValidationWorkflow from './pages/modules/ProprietorValidation/proprietorValidationWorkflow';
import proprietorValidation from './pages/modules/ProprietorValidation/proprietorValidation';

//
import occupiedCotAlerts from './pages/modules/OCA/occupiedCotAlerts';
import occupiedCotAlertsSearch from './pages/modules/OCA/occupiedCotAlertsSearch';
import occupiedCotAlertsWorkflow from './pages/modules/OCA/occupiedCotAlertsWorkflow';

//
import EnergyGoneAwayAlerts from './pages/modules/EGA/EnergyGoneAwayAlerts';
import EnergyGoneAwayAlertsSearch from './pages/modules/EGA/EnergyGoneAwayAlertsSearch';
import EnergyGoneAwayAlertsWorkflow from './pages/modules/EGA/EnergyGoneAwayAlertsWorkflow';

//
import LTAPAnalysis from './pages/modules/LTA/ltapAnalysis';
import LTAPAnalysisSearch from './pages/modules/LTA/ltapAnalysisSearch';
import LTAPAnalysisWorkflow from './pages/modules/LTA/ltapAnalysisWorkflow';

//
import voidCOTAlertsReport from './pages/modules/VCA/voidCOTAlertsReport';
import voidCOTAlertsWorkflow from './pages/modules/VCA/voidCOTAlertsWorkflow';
import voidCOTAlerts from './pages/modules/VCA/voidCOTAlerts';
import voidCOTAlertsSearch from './pages/modules/VCA/voidCOTAlertsSearch';
import voidCOTAlertsNFIData from './pages/modules/VCA/voidCOTAlertsNFIData'
import voidCOTAlertsWorkflowCombined from './pages/modules/VCA/voidCOTAlertsWorkflowCombined';

//
import previousOccupierWorkflow from './pages/modules/PreviousOccupier/previousOccupierWorkflow';
import previousOccupier from './pages/modules/PreviousOccupier/previousOccupier';
import previousOccupierSearch from './pages/modules/PreviousOccupier/previousOccupierSearch';
import previousOccupierNFIData from './pages/modules/PreviousOccupier/previousOccupierNFIData'

//
import occupierCOTAlertsReport from './pages/modules/OccupierCOTAlerts/occupierCOTAlertsReport';
import occupierCOTAlertsWorkflow from './pages/modules/OccupierCOTAlerts/occupierCOTAlertsWorkflow';
import occupierCOTAlerts from './pages/modules/OccupierCOTAlerts/occupierCOTAlerts';
import occupierCOTAlertsSearch from './pages/modules/OccupierCOTAlerts/occupierCOTAlertsSearch';

// Affordability Alerts
import AffordabilityAlerts from './pages/modules/AffordabilityAlerts/affordabilityAlerts';
import AffordabilityAlertsSearch from './pages/modules/AffordabilityAlerts/affordabilityAlertsSearch';
import affordabilityAlertsWorkflow from './pages/modules/AffordabilityAlerts/affordabilityAlertsWorkflow';

// AA
import AddressAlerts from './pages/modules/AA/addressAlerts'

// Debug
import VersionPage from './pages/version'

// Background
import IdleTimer from './components/idleTimer/IdleTimer';
import occupiedSaleAlert from './pages/modules/OSA/occupiedSaleAlert';
import occupiedSaleAlertSearch from './pages/modules/OSA/occupiedSaleAlertSearch';
import occupiedSaleAlertWorkflow from './pages/modules/OSA/occupiedSaleAlertWorkflow';

//VCA
import vacantCOTAlerts from './pages/modules/VacantCOTAlerts/vacantCOTAlerts';
import vacantCOTAlertsSearch from './pages/modules/VacantCOTAlerts/vacantCOTAlertsSearch';
import VacantCOTAlertsWorkflow from './pages/modules/VacantCOTAlerts/VacantCOTAlertsWorkflow';
import VacantCOTAlertsConfirmation from './pages/modules/VacantCOTAlerts/VacantCOTAlertsConfirmation';

//OTV
import occupierTelephoneValidation from './pages/modules/OccupierTelephoneValidation/occupierTelephoneValidation';
import occupierTelephoneValidationSearch from './pages/modules/OccupierTelephoneValidation/occupierTelephoneValidationSearch.js';
import occupierTelephoneValidationWorkflow from './pages/modules/OccupierTelephoneValidation/occupierTelephoneValidationWorkflow';
import occupierTelephoneValidationDashboard from './pages/modules/OccupierTelephoneValidation/occupierTelephoneValidationDashboard';

// CHA
import CompaniesHouseAlerts from './pages/modules/CompaniesHouseAlerts/CompaniesHouseAlerts';
import CompaniesHouseAlertsSearch from './pages/modules/CompaniesHouseAlerts/CompaniesHouseAlertsSearch';
import CompaniesHouseAlertsWorkflow from './pages/modules/CompaniesHouseAlerts/CompaniesHouseAlertsWorkflow';

//
import BusinessTeleAppend from './pages/modules/BusinessTeleAppend/businessTeleAppend';

//
import CotValidation from './pages/modules/CotValidation/cotValidation';
import CotValidationSearch from './pages/modules/CotValidation/cotValidationSearch';
import CotValidationWorkflow from './pages/modules/CotValidation/cotValidationWorkflow';
import CotValidationReport from './pages/modules/CotValidation/cotValidationReport';
import CotValidationManualReview from './pages/modules/CotValidation/cotValidationManualReview';

//
import HouseholdPropertySummary from './pages/modules/HouseholdProperty/HouseholdPropertySummary.jsx';
import HouseholdPropertySearch from './pages/modules/HouseholdProperty/HouseholdPropertySearch';
import HouseholdPropertyWorkflow from './pages/modules/HouseholdProperty/HouseholdPropertyWorkflow';
import HouseholdPropertyReport from './pages/modules/HouseholdProperty/HouseholdPropertyReport';

//
import BusinessAddressAlertSummary from './pages/modules/BusinessAddressAlert/BusinessAddressAlertSummary';
import BusinessAddressAlertSearch from './pages/modules/BusinessAddressAlert/BusinessAddressAlertSearch';
import BusinessAddressAlertWorkflow from './pages/modules/BusinessAddressAlert/BusinessAddressAlertWorkflow';

//
import WholesalerGAPSSummary from './pages/modules/WholesalerGAPS/Wholesaler/WholesalerGAPSSummary';
import WholesalerGAPSSearch from './pages/modules/WholesalerGAPS/Wholesaler/WholesalerGAPSSearch';
import WholesalerGAPSWorkflow from './pages/modules/WholesalerGAPS/Wholesaler/WholesalerGAPSWorkflow';

import WholesalerVoaGAPSSummary from './pages/modules/WholesalerGAPS/WholesalerVoa/WholesalerVoaGAPSSummary';
import WholesalerVoaGAPSSearch from './pages/modules/WholesalerGAPS/WholesalerVoa/WholesalerVoaGAPSSearch';
import WholesalerVoaGAPSWorkflow from './pages/modules/WholesalerGAPS/WholesalerVoa/WholesalerVoaGAPSWorkflow';

import CommercialGAPSSummary from './pages/modules/WholesalerGAPS/Commercial/CommercialGAPSSummary';
import CommercialGAPSSearch from './pages/modules/WholesalerGAPS/Commercial/CommercialGAPSSearch';
import CommercialGAPSWorkflow from './pages/modules/WholesalerGAPS/Commercial/CommercialGAPSWorkflow';

//
import NonHouseholdGAPSSummary from './pages/modules/NonHouseholdGAPS/NonHouseholdGAPSSummary';
import NonHouseholdGAPSSearch from './pages/modules/NonHouseholdGAPS/NonHouseholdGAPSSearch';
import NonHouseholdGAPSWorkflow from './pages/modules/NonHouseholdGAPS/NonHouseholdGAPSWorkflow';

//
import MixedUseManualMatchSummary from './pages/modules/MixedUseManualMatch/MixedUseManualMatchSummary.jsx';
import MixedUseManualMatchSearch from './pages/modules/MixedUseManualMatch/MixedUseManualMatchSearch.jsx';
import MixedUseManualMatchWorkflow from './pages/modules/MixedUseManualMatch/MixedUseManualMatchWorkflow.jsx';

//
import MixedUseSummary from './pages/modules/MixedUse/MixedUseSummary.jsx';
import MixedUseSearch from './pages/modules/MixedUse/MixedUseSearch.jsx';

//
import TariffValidationSummary from './pages/modules/TariffValidation/TariffValidationSummary';
import TariffValidationSearch from './pages/modules/TariffValidation/TariffValidationSearch';
import TariffValidationWorkflow from './pages/modules/TariffValidation/TariffValidationWorkflow';

//
import HomeMoverAlerts from './pages/modules/HomeMoverAlerts/HomeMoverAlerts';

//
import GapSitesAlerts from './pages/modules/GapSitesAlerts/GapSitesAlerts';
import GapSitesAlertsSummary from './pages/modules/GapSitesAlerts/GapSitesAlertsSummary.js';
import GapSitesAlertsWorkflow from './pages/modules/GapSitesAlerts/GapSitesAlertsWorkflow.js';
import GapSitesAlertsDashboard from './pages/modules/GapSitesAlerts/GapSitesAlertsDashboard.js';

//
import RealTimeValidation from './pages/modules/RealTimeValidation/RealTimeValidation';

//
import Groupings from './pages/admin/groupings';
import Grouping from './pages/admin/grouping';

//
import LoadingPage from './pages/loading';
import BrandingPage from './pages/admin/branding';
import IdenteqOccupierValidationSummary from './pages/modules/IdenteqOccupierValidation/IdenteqOccupierValidationSummary';
import IdenteqOccupierValidationSearch from './pages/modules/IdenteqOccupierValidation/IdenteqOccupierValidationSearch';
import IdenteqOccupierValidationWorkflow from './pages/modules/IdenteqOccupierValidation/IdenteqOccupierValidationWorkflow';
import OccupierValidationSummary from './pages/modules/OccupierValidation/OccupierValidationSummary';
import OccupierValidationSearch from './pages/modules/OccupierValidation/OccupierValidationSearch';
import OccupierValidationWorkflow from './pages/modules/OccupierValidation/OccupierValidationWorkflow';
import AzureLoginPage from './pages/azureLogin';
import VacantCOTAlertsDashboard from './pages/modules/VacantCOTAlerts/VacantCOTAlertsDashboard';
import OccupierValidationDashboard from './pages/modules/OccupierValidation/OccupierValidationDashboard';
import voidCOTAlertsPreviousOccupier from './pages/modules/VCA/voidCOTAlertsPreviousOccupier';



window.Pusher = require('pusher-js')

// window.Echo.channel('Platform.Products.contact_validation.refresh').notification((data) => console.log(data))

// window.Echo.pusher.config.auth.headers['Authorization'] = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3Q6ODAwMFwvYXV0aFwvdG9rZW4iLCJpYXQiOjE2MTAzNzEyMzAsImV4cCI6MTYxMDM4MjAzMCwibmJmIjoxNjEwMzcxMjMwLCJqdGkiOiJPejg2allVUW9FbXNkdWYwIiwic3ViIjoxLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.8Oq4LINTzfDMA_sn02NeVUkz-9BSXg1nuYl-wSCqphw'

export default view(class Router extends Component {
    state = {
        loaded: false,
        stage: 'Detecting Environment'
    }

    componentDidMount() {
        const a = axios.create({
            baseURL: endpoints.BASE
        });

        a.get(endpoints.DOMAIN + '?domain=' + window.location.hostname, {
            timeout: 7000
        }).then(e => {
            this.setState({ stage: 'Configuring App' })
            if (e.data.api_url) {
                sessionStorage.setItem('api-path', e.data.api_url);
                window.Echo = new Echo({
                    broadcaster: 'pusher',
                    key: 'IDENTEQ_PLATFORM_KEY',
                    wsHost: process.env.REACT_APP_WEBSOCKET_API_ENDPOINT ?? 'test.identeq-app.local',
                    wsPort: 6002,
                    wssPort: 6002,
                    forceTLS: false,
                    disableStats: true
                })
            }

            if (e.data.login_video_url) {
                sessionStorage.setItem('login-video-path', e.data.login_video_url);
            }

            if (e.data.frontend_name) {
                sessionStorage.setItem('frontend-name', e.data.frontend_name);
            }

            if (e.data.branding) {
                document.title = `IDenteq ${e.data.branding.name}`
                console.log('Got branding')
                const vars = document.querySelector(':root')
                Object.keys(e.data.branding).filter(_ => _.indexOf('colour__') !== -1).forEach(colour => {
                    const c = colour.replace('colour', '').replaceAll('_', '').replaceAll(' ', '-').toLocaleLowerCase();
                    console.log(`--${c}`, e.data.branding[colour])
                    vars.style.setProperty(`--${c}`, e.data.branding[colour])
                })
                // --primary: var(--primary);
                // --secondary: #445468;
                // --background: #F7FCFF;
                // --gray: #A5A5A5;
                // --light-gray: #F7F7F7;
                // --white: #ffffff;
                // --black: #000000;
                // --logogreen: #009245;
                // --red: #f14014;
                // --yellow: #F19A14;
                // --blue: #118AB2;
                // --teal: #06D6A0;
                // --cobalt: #445469;

                if (e.data.branding.logo) {
                    sessionStorage.setItem('logo-url', e.data.branding.logo)
                }
                sessionState.company.can_azure_login = e.data.can_azure_login
            }

            console.log('Retrieved API Path: ' + sessionStorage.getItem('api-path'));

            setTimeout(() => {
                this.setState({
                    loaded: true
                })
            }, 1000);
        }).catch(() => {
            this.setState({
                stage: 'Failed to get environment, contact support'
            })
        })
    }

    render() {
        return (
            <div className="wrapper">
                <BrowserRouter>
                    <Switch>
                        {
                            !this.state.loaded ? <Route component={() => <LoadingPage stage={this.state.stage} />} /> : null
                        }
                        <Route path="/login/azure" component={AzureLoginPage} />
                        <Route path="/login" component={LoginPage} />
                        <Route path="/activate" component={ActivatePage} />
                        <Route path="/reset" component={Reset} />
                        <Route path="/auth/reset" component={DoReset} />
                        <Route path="/version" component={VersionPage} />

                        {/* If not logged in redirect */}
                        {
                            !sessionState.token ? <Redirect to={`/login?${window.location.pathname}`} /> : ''
                        }

                        <ProtectedRoute path="/version-history" component={VersionHistory} />

                        <ProtectedRoute path="/account" admin={null} exact={true} component={Account}/>

                        <ProtectedRoute path="/portal/address-alerts" exact={true} component={AddressAlerts} />

                        <ProtectedRoute path="/portal/contact-validation" exact={true} component={ContactValidation} />

                        <ProtectedRoute path="/portal/business-tele-append" exact={true} component={BusinessTeleAppend} />

                        <ProtectedRoute path="/portal/void-cot-alerts" exact={true} component={voidCOTAlerts} />
                        <ProtectedRoute path="/portal/void-cot-alerts/search" exact={true} component={voidCOTAlertsSearch} />
                        <ProtectedRoute path="/portal/void-cot-alerts/workflow" exact={true} component={voidCOTAlertsWorkflow} />
                        <ProtectedRoute path="/portal/void-cot-alerts/report" exact={true} component={voidCOTAlertsReport} />
                        <ProtectedRoute path="/portal/void-cot-alerts/nfi" exact={true} component={voidCOTAlertsNFIData} />
                        <ProtectedRoute path="/portal/void-cot-alerts/workflow-combined" exact={true} component={voidCOTAlertsWorkflowCombined} />

                        <ProtectedRoute path="/portal/previous-occupier" exact={true} component={previousOccupier} />
                        <ProtectedRoute path="/portal/previous-occupier/search" exact={true} component={previousOccupierSearch} />
                        <ProtectedRoute path="/portal/previous-occupier/workflow" exact={true} component={previousOccupierWorkflow} />
                        <ProtectedRoute path="/portal/previous-occupier/nfi" exact={true} component={previousOccupierNFIData} />

                        <ProtectedRoute path="/portal/occupier-cot-alerts" exact={true} component={occupierCOTAlerts} />
                        <ProtectedRoute path="/portal/occupier-cot-alerts/search" exact={true} component={occupierCOTAlertsSearch} />
                        <ProtectedRoute path="/portal/occupier-cot-alerts/workflow" exact={true} component={occupierCOTAlertsWorkflow} />
                        <ProtectedRoute path="/portal/occupier-cot-alerts/report" exact={true} component={occupierCOTAlertsReport} />

                        <ProtectedRoute path="/portal/void-sales-alerts" exact={true} component={voidSalesAlerts} />
                        <ProtectedRoute path="/portal/void-sales-alerts/search" exact={true} component={voidSalesAlertsSearch} />
                        <ProtectedRoute path="/portal/void-sales-alerts/workflow" exact={true} component={voidSalesAlertsWorkflow} />

                        <ProtectedRoute path="/portal/proprietor-validation" exact={true} component={proprietorValidationSearch} />
                        {/* <ProtectedRoute path="/portal/proprietor-validation/search" exact={true} component={proprietorValidationSearch} /> */}
                        {/* <ProtectedRoute path="/portal/proprietor-validation/workflow" exact={true} component={proprietorValidationWorkflow} /> */}

                        <ProtectedRoute path="/portal/occupied-sale-alert" exact={true} component={occupiedSaleAlert} />
                        <ProtectedRoute path="/portal/occupied-sale-alert/search" exact={true} component={occupiedSaleAlertSearch} />
                        <ProtectedRoute path="/portal/occupied-sale-alert/workflow" exact={true} component={occupiedSaleAlertWorkflow} />

                        <ProtectedRoute path="/portal/affordability-alerts" exact={true} component={AffordabilityAlerts} />
                        <ProtectedRoute path="/portal/affordability-alerts/search" exact={true} component={AffordabilityAlertsSearch} />
                        <ProtectedRoute path="/portal/affordability-alerts/workflow" exact={true} component={affordabilityAlertsWorkflow} />

                        <ProtectedRoute path="/portal/vacant-cot-alerts" exact={true} component={vacantCOTAlerts} />
                        <ProtectedRoute path="/portal/vacant-cot-alerts/search" exact={true} component={vacantCOTAlertsSearch} />
                        <ProtectedRoute path="/portal/vacant-cot-alerts/workflow" exact={true} component={VacantCOTAlertsWorkflow} />
                        <ProtectedRoute path="/portal/vacant-cot-alerts/dashboard" exact={true} component={VacantCOTAlertsDashboard} />
                        <ProtectedRoute path="/portal/vacant-cot-alerts/confirmation" exact={true} component={VacantCOTAlertsConfirmation} />

                        <ProtectedRoute path="/portal/occupier-telephone-validation" exact={true} component={occupierTelephoneValidation} />
                        <ProtectedRoute path="/portal/occupier-telephone-validation/search" exact={true} component={occupierTelephoneValidationSearch} />
                        <ProtectedRoute path="/portal/occupier-telephone-validation/workflow" exact={true} component={occupierTelephoneValidationWorkflow} />
                        <ProtectedRoute path="/portal/occupier-telephone-validation/dashboard" exact={true} component={occupierTelephoneValidationDashboard} />
                        
                        <ProtectedRoute path="/portal/companies-house-alerts" exact={true} component={CompaniesHouseAlerts} />
                        <ProtectedRoute path="/portal/companies-house-alerts/search" exact={true} component={CompaniesHouseAlertsSearch} />
                        <ProtectedRoute path="/portal/companies-house-alerts/workflow" exact={true} component={CompaniesHouseAlertsWorkflow} />
                        
                        <ProtectedRoute path="/portal/cleanse-validation" exact={true} component={CotValidation} />
                        <ProtectedRoute path="/portal/cleanse-validation/search" exact={true} component={CotValidationSearch} />
                        <ProtectedRoute path="/portal/cleanse-validation/workflow" exact={true} component={CotValidationWorkflow} />
                        <ProtectedRoute path="/portal/cleanse-validation/report" exact={true} component={CotValidationReport} />
                        <ProtectedRoute path="/portal/cleanse-validation/manual-review" exact={true} component={CotValidationManualReview} />

                        <ProtectedRoute path="/portal/home-mover-alerts" exact={true} component={HomeMoverAlerts} />

                        <ProtectedRoute path="/portal/gone-away-alerts" exact={true} component={occupiedCotAlerts} />
                        <ProtectedRoute path="/portal/gone-away-alerts/search" exact={true} component={occupiedCotAlertsSearch} />
                        <ProtectedRoute path="/portal/gone-away-alerts/workflow" exact={true} component={occupiedCotAlertsWorkflow} />

                        <ProtectedRoute path="/portal/energy-gone-away-alerts" exact={true} component={EnergyGoneAwayAlerts} />
                        <ProtectedRoute path="/portal/energy-gone-away-alerts/search" exact={true} component={EnergyGoneAwayAlertsSearch} />
                        <ProtectedRoute path="/portal/energy-gone-away-alerts/workflow" exact={true} component={EnergyGoneAwayAlertsWorkflow} />
                        
                        <ProtectedRoute path="/portal/ltap-analysis" exact={true} component={LTAPAnalysis} />
                        <ProtectedRoute path="/portal/ltap-analysis/search" exact={true} component={LTAPAnalysisSearch} />
                        <ProtectedRoute path="/portal/ltap-analysis/workflow" exact={true} component={LTAPAnalysisWorkflow} />

                        <ProtectedRoute path="/portal/real-time-validation" exact={true} component={RealTimeValidation} />

                        <ProtectedRoute path="/portal/household-property" exact={true} component={HouseholdPropertySummary} />
                        <ProtectedRoute path="/portal/household-property/search" exact={true} component={HouseholdPropertySearch} />
                        <ProtectedRoute path="/portal/household-property/workflow" exact={true} component={HouseholdPropertyWorkflow} />
                        <ProtectedRoute path="/portal/household-property/report" exact={true} component={HouseholdPropertyReport} />

                        <ProtectedRoute path="/portal/business-address-alert" exact={true} component={BusinessAddressAlertSummary} />
                        <ProtectedRoute path="/portal/business-address-alert/search" exact={true} component={BusinessAddressAlertSearch} />
                        <ProtectedRoute path="/portal/business-address-alert/workflow" exact={true} component={BusinessAddressAlertWorkflow} />

                        <ProtectedRoute path="/portal/wholesaler-gaps" exact={true} component={WholesalerGAPSSummary} />
                        <ProtectedRoute path="/portal/wholesaler-gaps/search" exact={true} component={WholesalerGAPSSearch} />
                        <ProtectedRoute path="/portal/wholesaler-gaps/workflow" exact={true} component={WholesalerGAPSWorkflow} />

                        <ProtectedRoute path="/portal/wholesaler-voa-gaps" exact={true} component={WholesalerVoaGAPSSummary} />
                        <ProtectedRoute path="/portal/wholesaler-voa-gaps/search" exact={true} component={WholesalerVoaGAPSSearch} />
                        <ProtectedRoute path="/portal/wholesaler-voa-gaps/workflow" exact={true} component={WholesalerVoaGAPSWorkflow} />

                        <ProtectedRoute path="/portal/commercial-gaps" exact={true} component={CommercialGAPSSummary} />
                        <ProtectedRoute path="/portal/commercial-gaps/search" exact={true} component={CommercialGAPSSearch} />
                        <ProtectedRoute path="/portal/commercial-gaps/workflow" exact={true} component={CommercialGAPSWorkflow} />

                        <ProtectedRoute path="/portal/non-household-gaps" exact={true} component={NonHouseholdGAPSSummary} />
                        <ProtectedRoute path="/portal/non-household-gaps/search" exact={true} component={NonHouseholdGAPSSearch} />
                        <ProtectedRoute path="/portal/non-household-gaps/workflow" exact={true} component={NonHouseholdGAPSWorkflow} />

                        <ProtectedRoute path="/portal/mixed-use-manual-match" exact={true} component={MixedUseManualMatchSummary} />
                        <ProtectedRoute path="/portal/mixed-use-manual-match/search" exact={true} component={MixedUseManualMatchSearch} />
                        <ProtectedRoute path="/portal/mixed-use-manual-match/workflow" exact={true} component={MixedUseManualMatchWorkflow} />

                        <ProtectedRoute path="/portal/mixed-use" exact={true} component={MixedUseSummary} />
                        <ProtectedRoute path="/portal/mixed-use/search" exact={true} component={MixedUseSearch} />

                        <ProtectedRoute path="/portal/tariff-validation" exact={true} component={TariffValidationSummary} />
                        <ProtectedRoute path="/portal/tariff-validation/search" exact={true} component={TariffValidationSearch} />
                        <ProtectedRoute path="/portal/tariff-validation/workflow" exact={true} component={TariffValidationWorkflow} />

                        <ProtectedRoute path="/portal/gap-sites-alerts" exact={true} component={GapSitesAlertsSummary} />
                        <ProtectedRoute path="/portal/gap-sites-alerts/dashboard" exact={true} component={GapSitesAlertsDashboard} />
                        <ProtectedRoute path="/portal/gap-sites-alerts/workflow/:ABP_UPRN?" exact={true} component={GapSitesAlertsWorkflow} />

                        <ProtectedRoute path="/portal/occupier-validation" exact={true} component={OccupierValidationSummary} />
                        <ProtectedRoute path="/portal/occupier-validation/search" exact={true} component={OccupierValidationSearch} />
                        <ProtectedRoute path="/portal/occupier-validation/workflow" exact={true} component={OccupierValidationWorkflow} />
                        <ProtectedRoute path="/portal/occupier-validation/dashboard" exact={true} component={OccupierValidationDashboard} />
                        

                        <ProtectedRoute path="/portal/identeq-occupier-validation" exact={true} component={IdenteqOccupierValidationSummary} />
                        <ProtectedRoute path="/portal/identeq-occupier-validation/search" exact={true} component={IdenteqOccupierValidationSearch} />
                        <ProtectedRoute path="/portal/identeq-occupier-validation/workflow" exact={true} component={IdenteqOccupierValidationWorkflow} />

                        <ProtectedRoute path="/portal" exact={true} component={PortalPage} />

                        <ProtectedRoute path="/admin/portal/company-select/:configuration" admin={true} exact={true} component={CompanySelect} />
                        <ProtectedRoute path="/admin/portal/companies/:company/translate" admin={true} exact={true} component={Translations} />
                        <ProtectedRoute path="/admin/portal/companies/:company/tutorials" admin={true} exact={true} component={Tutorials} />
                        <ProtectedRoute path="/admin/portal/companies/:company/batch-processes" admin={true} exact={true} component={BatchProcesses} />
                        <ProtectedRoute path="/admin/portal/companies/:company" admin={true} exact={true} component={AdminCompany} />
                        <ProtectedRoute path="/admin/portal/companies" admin={true} exact={true} component={AdminCompanies} />

                        <ProtectedRoute path="/admin/portal/branding/:id" admin={true} exact={true} component={BrandingPage} />
                        <ProtectedRoute path="/admin/portal/branding" admin={true} exact={true} component={BrandingPage} />

                        <ProtectedRoute path="/admin/portal/users/:user" admin={sessionState.company && hasPermission('ADMIN', 'CREATE-USER') ? false : true} exact={true} component={AdminUser} />
                        <ProtectedRoute path="/admin/portal/users" admin={true} exact={true} component={Users} />

                        <ProtectedRoute path="/admin/portal/products" admin={true} exact={true} component={ProductsPage} />
                        <ProtectedRoute path="/admin/portal/products/:product" admin={true} exact={true} component={ProductPage} />

                        <ProtectedRoute path="/admin/portal/filters/:id" admin={true} exact={true} component={DataFilter} />
                        <ProtectedRoute path="/admin/portal/filters" admin={true} exact={true} component={DataFilters} />

                        <ProtectedRoute path="/admin/portal/groupings/:id" admin={true} exact={true} component={Grouping} />
                        <ProtectedRoute path="/admin/portal/groupings" admin={true} exact={true} component={Groupings} />

                        <ProtectedRoute path="/admin/portal/audits" admin={true} exact={true} component={Audits} />

                        <ProtectedRoute path="/admin/portal/usage" admin={true} exact={true} component={Usage} routeTarget={"Usage"} />
                        <ProtectedRoute path="/admin/portal/Purchases" admin={true} exact={true} component={Usage} routeTarget={"Purchases"} />

                        <ProtectedRoute path="/admin/portal" admin={true} exact={true} component={Admin} />

                        <Route path="/logout" component={Logout} />
                        { sessionState.company ?
                            <Redirect to="/portal" />
                        :
                            <Redirect to="/admin/portal" />
                        }
                    </Switch>
                </BrowserRouter>
            </div>
        )
    }
})

const ProtectedRoute = withRouter(view(class ProtectedRoute extends Component {
    constructor(props) {
        super(props);

        const data = Object.assign({}, props);
        delete data.component;

        this.state = {
            authenticated: null,
            props: data
        }
    }

    componentDidMount() {
        const alive = sessionState.isAlive();
        const admin = this.props.admin ?? false;

        if (alive === true) {
            if (admin === null) {
                this.updateAuthState(true);
            }

            this.updateAuthState((sessionState.company === null) === admin);
        } else if (alive === false) {
            this.updateAuthState(false);
        } else {
            alive.then(r => {
                if (admin === null) {
                    this.updateAuthState(true);
                }

                this.updateAuthState((sessionState.company === null) === admin);
            });
        }
    }

    updateAuthState = (auth) => {
        this.setState({
            authenticated: auth
        }, () => {
            if (this.state.authenticated === false) {
                sessionState.reset();
                this.props.history.push('/login');
            }
        });
    }

    render() {
        return (
            <Route {...this.state.props} render={ props => {
                return (
                    (
                        this.state.authenticated === true ? (
                            <>
                                <IdleTimer timeout={sessionState?.company?.idle_timeout || 20}/>
                                <this.props.component routeTarget={this.props.routeTarget} />
                            </>
                        ) : <></>
                    )
                )
            }}/>
        );
    }
}))
