import React, { useCallback, useMemo, useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import ReactSelect from 'react-select'
import endpoints from '../../../helpers/endpoints'
import request from '../../../helpers/request'
import { GenericTable } from '../VacantCOTAlerts/sharedComponents'
import Button from '../../../components/large-link-button/largeLinkButton'
import ReactModal from 'react-modal'

export const Summary = ({summaryData, setLowerSummary, setSummaryData, workflow = false, translations = {}}) => {
  const [spid, setSpid] = useState()
  const [type, setType] = useState('Supply')
  const [workflowItems, setWorkflowItems] = useState([])
  const getSummaryData = useCallback(() => {
    setSummaryData({})
    request(true).get(endpoints.BUSINESS_ADDRESS_ALERTS_SEARCH_BY_SPID, {
      params: {
        spid,
        type
      }
    }).then(e => {
      setSummaryData(e.data)
    }).catch(e => window.alert('Could not find SPID.'))
  }, [spid, type, setSummaryData])

  useEffect(() => {
    window.reloadSummaryData = getSummaryData
  }, [getSummaryData])

  const getWorkflowItems = () => {
    request(true).get(endpoints.BUSINESS_ADDRESS_ALERTS_WF_ITEMS, {
      params: {
        type
      }
    }).then(e => {
      setWorkflowItems(e.data);
      if (e.data.length > 0) {
        setSpid(e.data[0])
      }
    })
  }

  const _cur = useMemo(() => {
    return workflowItems.findIndex(_ => _ === spid);
  }, [workflowItems, spid]);

  const getWorkflowNext = () => {
    if (_cur !== workflowItems.length - 1) {
      setSpid(workflowItems[_cur + 1]);
    }
  }

  const getWorkflowLast = () => {
    if (_cur !== 0) {
      setSpid(workflowItems[_cur - 1]);
    }
  }

  const buyWorkflowItem = () => {
    request(true).post(endpoints.BUSINESS_ADDRESS_ALERTS_PURCHASE, {
      spid,
      type
    }).then(e => {
      window.alert('OK')
      getWorkflowItems()
    }).catch(() => {
      window.alert('Could not purchase SPID');
      getWorkflowItems()
    });
  }

  const excludeWorkflowItem = () => {
    request(true).post(endpoints.BUSINESS_ADDRESS_ALERTS_EXCLUDE, {
      spid,
      type
    }).then(e => {
      window.alert('OK')
      getWorkflowItems()
    }).catch(() => {
      window.alert('Could not exclude SPID');
      getWorkflowItems()
    });
  }

  useEffect(() => {
    if (workflow) {
      getWorkflowItems();
    }
  }, [type])

  useEffect(() => {
    if (workflow) {
      setSpid('')
      setWorkflowItems([])
      setSummaryData({})
      getWorkflowItems()
    }
  }, [type])

  useEffect(() => {
    if (workflow && spid) {
      getSummaryData()
    }
  }, [spid])
  return (
    <table className='table borders squish smaller-text left' style={{tableLayout: 'fixed', width: '100%'}}>
    {/* <input type='text' /> */}
      <col width={180} />
      <thead>
        <tr>
          <th colSpan={6}>{translations['BAA.Header_Summary']}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td width={400}>{translations['BAA.SPID']}</td>
          <td colSpan={2}>
            <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
              <input style={{border: 'none'}} type="text" onChange={e => setSpid(e.target.value)} value={spid} />
              {
                workflow ? <>
                  <span className='fas fa-angle-left colour-primary' style={{cursor: 'pointer'}} onClick={getWorkflowLast}></span>
                  <span>{_cur + 1}/{workflowItems.length}</span>
                  <span className='fas fa-angle-right colour-primary' style={{cursor: 'pointer'}} onClick={getWorkflowNext}></span>
                  <span className='fas fa-circle-check colour-primary' title="Bi-Lat Issued" style={{cursor: 'pointer'}} onClick={buyWorkflowItem}></span>
                  <span className='fas fa-circle-xmark colour-primary' title="Bi-Lat Not Issued" style={{cursor: 'pointer'}} onClick={excludeWorkflowItem}></span>
                </> : <>
                  <span className='fas fa-search colour-primary' style={{cursor: 'pointer'}} onClick={getSummaryData}></span>
                </>
              }
            </div>
          </td>
          <td>Type</td>
          <td colSpan={2}>
            <select onChange={_ => setType(_.target.value)} style={{width: '100%', border: 'none'}}>
              <option>Supply</option>
              <option>Registered</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>{translations['BAA.Address_Status']}</td>
          <td colSpan={'5'}>
            {summaryData['Address_Status']}
          </td>
        </tr>
        <tr>
          <td>{translations['BAA.CMOS_UPRN']}</td>
          <td width='(100/4)%'>{summaryData['CMOS_UPRN']}</td>
          <td width='(100/4)%'>{translations['BAA.Matched_UPRN']}</td>
          <td width='(100/4)%'>{summaryData['Matched_UPRN']}</td>
          <td width='(100/4)%'>{translations['BAA.Status_UPRN']}</td>
          <td width='(100/4)%'>{summaryData['Status_UPRN']}</td>
        </tr>
        <tr>
          <td>{translations['BAA.CMOS_VOA_No']}</td>
          <td width='(100/4)%'>{summaryData['CMOS_VOA_No']}</td>
          <td width='(100/4)%'>{translations['BAA.Matched_VOA_No']}</td>
          <td width='(100/4)%'>{summaryData['Matched_VOA_No']}</td>
          <td width='(100/4)%'>{translations['BAA.Status_VOA_No']}</td>
          <td width='(100/4)%'>{summaryData['Status_VOA_No']}</td>
        </tr>
        <tr>
          <td>{translations['BAA.CMOS_PAF']}</td>
          <td width='(100/4)%'>{summaryData['CMOS_PAF']}</td>
          <td width='(100/4)%'>{translations['BAA.Matched_PAF']}</td>
          <td width='(100/4)%'>{summaryData['Matched_PAF']}</td>
          <td width='(100/4)%'>{translations['BAA.Status_PAF']}</td>
          <td width='(100/4)%'>{summaryData['Status_PAF']}</td>
        </tr>
        <tr>
          <td>{translations['BAA.CMOS_Address_Single_Line']}</td>
          <td colSpan={'5'}>{summaryData['CMOS_Address_Single_Line']}</td>
        </tr>
        <tr>
          <td>{translations['BAA.CMOS_UPRN_Single_Line']}</td>
          <td colSpan={'5'}>{summaryData['CMOS_UPRN_Single_Line']}</td>
        </tr>
        <tr>
          <td>{translations['BAA.Matched_UPRN_Single_Line']}</td>
          <td colSpan={'5'}>{summaryData['Matched_UPRN_Single_Line']}</td>
        </tr>
        <tr>
          <td>{translations['BAA.CMOS_VOA_Single_Line']}</td>
          <td colSpan={'5'}>{summaryData['CMOS_VOA_Single_Line']}</td>
        </tr>
        <tr>
          <td>{translations['BAA.Matched_VOA_Single_Line']}</td>
          <td colSpan={'5'}>{summaryData['Matched_VOA_Single_Line']}</td>
        </tr>
      </tbody>
    </table>
  )
}

export const CMOSLive = ({ translations, summaryData: data }) => {
  const rows = useMemo(() => {
    return [
      [translations['BAA.CL_VOA_BA_Reference'], 'CL_VOA_BA_Reference'],
      [translations['BAA.CL_UPRN'], 'CL_UPRN'],
      [translations['BAA.CL_PAF_Address_Key'], 'CL_PAF_Address_Key'],
      [translations['BAA.CL_Free_Descriptor'], 'CL_Free_Descriptor'],
      [translations['BAA.CL_Secondary_Addressable_Object'], 'CL_Secondary_Addressable_Object'],
      [translations['BAA.CL_Primary_Addressable_Object'], 'CL_Primary_Addressable_Object'],
      [translations['BAA.CL_Address_Line_1'], 'CL_Address_Line_1'],
      [translations['BAA.CL_Address_Line_2'], 'CL_Address_Line_2'],
      [translations['BAA.CL_Address_Line_3'], 'CL_Address_Line_3'],
      [translations['BAA.CL_Address_Line_4'], 'CL_Address_Line_4'],
      [translations['BAA.CL_Address_Line_5'], 'CL_Address_Line_5'],
      [translations['BAA.CL_Postcode'], 'CL_Postcode'],
    ]
  }, [translations])
  return (
    <div>
      <GenericTable style={{height: 'unset'}} biggerSecondRow={false} firstColWidth={150} title={translations['BAA.Header_CmosLive']} rows={rows} extraClass="squish smaller-text left" evidence={false} data={data} />
    </div>
  )
}

export const AddressBase = ({ translations, summaryData: data }) => {
  const [addresses, setAddresses] = useState([])
  const [postcode, setPostcode] = useState();
  const [index, setIndex] = useState(0);
  const [selectedMatch, setSelectedMatch] = useState(false)
  const [postcodeModal, setPostcodeModal] = useState(false)

  const cycleIndex = useCallback((change = 0) => {
    if (index + change <= -1) return
    if (index + change >= addresses.length) return
    setIndex(index + change)
    setSelectedMatch(false)
  }, [index, setIndex, setSelectedMatch, addresses])

  const getData = useCallback((customPostcode) => {
    if (!customPostcode && (!data.Type || (!data.ABP_Postcode && data.Address_Status === 'Invalid Postcode'))) return
    setAddresses([])
    setSelectedMatch(false)
    request(true).get(endpoints.BUSINESS_ADDRESS_ALERTS_GET_ADDRESSES, {
      params: {
        postcode: customPostcode || postcode || data.ABP_Postcode || data.CL_Postcode
      }
    })
    .then(e => {
      const _data = e.data;
      setAddresses(_data)
      if (_data.length > 0) {
        setPostcode(_data[0].POSTCODE)
      }
      if (!data.ABP_UPRN) {
        setIndex(-1)
      } else {
        const displayFirst = _data.findIndex(_ => _.UPRN == data.Matched_UPRN);
        setIndex(displayFirst);
      }
    })
    .catch(_ => { window.alert('Could not get Address Base data.'); setAddresses([]); })
  }, [data, postcode, setPostcode]);

  useEffect(() => {
    setPostcode()
    setIndex(-1)
    setAddresses([])
    getData()
    setSelectedMatch(false)
  }, [data])

  const tableData = useMemo(() => {
    const selected = addresses.length > -1 ? addresses[index] : null;
    if (!selected ) return {}
    selected.STATUS = selected.CHANGE_CODE === 'D' ? 'Deleted' : 'Active';
    setPostcode(selected.POSTCODE)
    return selected
  }, [addresses, index]);

  const originalPostcode = useMemo(() => {
    return data.ABP_Postcode || data.CL_Postcode
  }, [data])

  const selectMatch = useCallback(() => {
    setSelectedMatch(true)
    request(true).get(endpoints.BUSINESS_ADDRESS_ALERTS_SELECT_MATCH, {
      params: {
        abp_urpn: tableData.UPRN,
        account_id: data.SPID,
        abp_or_voa: 'abp',
        ba_reference: 0
      }
    })
  }, [data, tableData, setSelectedMatch])
  
  const rows = useMemo(() => {
    return [
      [translations['BAA.UPRN'], 'UPRN'],
      [translations['BAA.STATUS'], 'STATUS'],
      [translations['BAA.UDPRN'], 'UDPRN'],
      [translations['BAA.ORGANISATION'], 'ORGANISATION'],
      [translations['BAA.SUB_BUILDING'], 'SUB_BUILDING'],
      [translations['BAA.BUILDING_NAME'], 'BUILDING_NAME'],
      [translations['BAA.BUILDING_NUMBER'], 'BUILDING_NUMBER'],
      [translations['BAA.STREET_NAME'], 'STREET_NAME'],
      [translations['BAA.LOCALITY'], 'LOCALITY'],
      [translations['BAA.TOWN_NAME'], 'TOWN_NAME'],
      [translations['BAA.POST_TOWN'], 'POST_TOWN'],
      [translations['BAA.Postcode'], 'POSTCODE'],
      ['', () => <button className={`button compact smaller-text ${selectedMatch ? 'background-primary' : ''}`} onClick={() => selectMatch()}>Select Match</button>]
    ]
  }, [translations, selectMatch, selectedMatch])

  return [
    <GenericTable 
      biggerSecondRow={false} 
      firstColWidth={150} 
      title={<>
        <div style={{display: 'flex', alignItems: 'center'}}>
          {translations['BAA.Header_AddressBase']}
          <div style={{marginLeft: 'auto', display: 'flex', gap: 10}}>
            {
              postcode !== originalPostcode ? <>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <span className='fas fa-trash-undo' onClick={() => {setPostcode(originalPostcode);getData(originalPostcode)}} title="Restore original postcode search"></span>
                </div>
              </> : null
            }
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <span className='fas fa-search' onClick={() => {setPostcodeModal(true)}} title="Search for a different postcode"></span>
            </div>
            {
              addresses.length ? <>
                <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <span className='fas fa-angle-left' onClick={() => cycleIndex(-1)}></span>
                  <span style={{minWidth: 60, textAlign: 'center'}}>{index + 1}/{addresses.length}</span>
                  <span className='fas fa-angle-right' onClick={() => cycleIndex(1)}></span>
                </span>
              </> : null
            }
          </div>
        </div> 
      </>} rows={rows} extraClass="squish smaller-text left" evidence={false} data={tableData} />,
      <ReactModal 
      isOpen={postcodeModal} 
      onRequestClose={() => setPostcodeModal(false)}
      className="card bulk-allocate"
      contentLabel="Bulk Allocate COT Alert Data"
      style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}>
        <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
          <h3>Postcode Search</h3>
          <input value={postcode} onChange={_ => setPostcode(_.target.value)} style={{border: '1px solid gray', width: '100%', borderRadius: 3}} />
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            <button className='button compact smaller-text background-primary' onClick={() => {getData(postcode || undefined); setPostcodeModal(false)}}>Search</button> 
          </div>
        </div>
      </ReactModal>
  ]
}

export const ValuationOffice = ({ translations, summaryData: data }) => {
  const [voas, setVoas] = useState([])
  const [postcode, setPostcode] = useState();
  const [index, setIndex] = useState(0);
  const [selectedMatch, setSelectedMatch] = useState(false)
  const [postcodeModal, setPostcodeModal] = useState(false)

  const cycleIndex = (change = 0) => {
    if (index + change <= -1) return
    if (index + change >= voas.length) return
    setIndex(index + change)
    setSelectedMatch(false)
  }
  const getData = (external) => {
    setSelectedMatch(false)
    if (!external && (!data.Type || (!data.ABP_Postcode && data.Address_Status === 'Invalid Postcode'))) return
    request(true).get(data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? endpoints.BUSINESS_ADDRESS_ALERTS_GET_SAA : endpoints.BUSINESS_ADDRESS_ALERTS_GET_VOAS, {
      params: {
        postcode: external || postcode || data.VO_Postcode || data.CL_Postcode
      }
    }).then(e => {
      const _data = e.data
      setVoas(_data);
      if (data.Matched_VOA_No) setIndex(_data.findIndex(_ => _.BA_Reference_Number === data.Matched_VOA_No) != -1 ? _data.findIndex(_ => _.BA_Reference_Number === data.Matched_VOA_No) : 1);
    }).catch(() => {
      window.alert('Could not get Valudation Office data');
      setVoas([])
    })
  }
  useEffect(() => {
    setPostcode()
    setIndex(-1)
    setVoas([])
    getData()
    setSelectedMatch(false)
  }, [data])
  const selectedVOA = useMemo(() => {
    const selected = voas.length > -1 ? voas[index] : null;
    if (!selected ) return {}
    selected.Source = data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? 'SAA' : 'Business Rates'
    selected.Deletion_Date = selected.Effective_Status === 'DELETED' ? selected.Current_From_Date : ''
    setPostcode(selected.Postcode)
    return selected
  }, [voas, index, data, setPostcode]);

  const selectMatch = useCallback(() => {
    setSelectedMatch(true)
    request(true).get(endpoints.BUSINESS_ADDRESS_ALERTS_SELECT_MATCH, {
      params: {
        abp_urpn: 0,
        account_id: data.SPID,
        abp_or_voa: 'voa',
        ba_reference: selectedVOA.BA_Reference_Number
      }
    })
  }, [data, selectedVOA, setSelectedMatch])

  const originalPostcode = useMemo(() => {
    return data.VO_Postcode || data.CL_Postcode
  }, [data])
  const rows = useMemo(() => {
    return [
      [translations['BAA.BA_Reference_Number'], 'BA_Reference_Number'],
      [translations['BAA.Effective_Status'], data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? 'OCC_STATUS' : 'Effective_Status'],
      [translations['BAA.Source'], 'Source'],
      [translations['BAA.Number_Or_Name'], data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? 'PAON' : 'Number_Or_Name'],
      [translations['BAA.Street'], data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? 'STREET' : 'Street'],
      [translations['BAA.Sub_Street_level_1'], 'Sub_Street_level_1'],
      [translations['BAA.Town'], data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? 'TOWN' : 'Town'],
      [translations['BAA.Postal_District'], data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? "ADMIN_AREA" : 'Postal_District'],
      [translations['BAA.County'], data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? "UNITARY_AUTHORITY" : 'County'],
      [translations['BAA.Postcode'], data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? "BR_Postcode" : 'Postcode'],
      [translations['BAA.Effective_Status'], data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? 'OCC_STATUS' : 'Effective_Status'],
      [translations['BAA.Deletion_Date'], 'Deletion_Date'],
      ['', () => <button className={`button compact smaller-text ${selectedMatch ? 'background-primary' : ''}`} onClick={() => selectMatch()}>Select Match</button>]
    ]
  }, [translations, selectMatch, selectedMatch])
  return [
    <GenericTable biggerSecondRow={false} firstColWidth={150} title={<>
      <div style={{display: 'flex', alignItems: 'center'}}>
        {translations['BAA.Header_Valuation']}
        <div style={{marginLeft: 'auto', display: 'flex', gap: 10}}>
          {
            postcode !== originalPostcode ? <>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <span className='fas fa-trash-undo' onClick={() => {setPostcode(originalPostcode);getData(originalPostcode)}} title="Restore original postcode search"></span>
              </div>
            </> : null
          }
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <span className='fas fa-search' onClick={() => {setPostcodeModal(true)}} title="Search for a different postcode"></span>
          </div>
          {
            voas.length ? <>
              <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <span className='fas fa-angle-left' onClick={() => cycleIndex(-1)}></span>
                <span style={{minWidth: 60, textAlign: 'center'}}>{index + 1}/{voas.length}</span>
                <span className='fas fa-angle-right' onClick={() => cycleIndex(1)}></span>
              </span>
            </> : null
          }
        </div>
      </div> 
    </>} rows={rows} extraClass="squish smaller-text left" evidence={false} data={selectedVOA} />,
    <ReactModal 
    isOpen={postcodeModal} 
    onRequestClose={() => setPostcodeModal(false)}
    className="card bulk-allocate"
    contentLabel="Bulk Allocate COT Alert Data"
    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}>
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <h3>Postcode Search</h3>
        <input value={postcode} onChange={_ => setPostcode(_.target.value)} style={{border: '1px solid gray', width: '100%', borderRadius: 3}} />
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <button className='button compact smaller-text background-primary' onClick={() => {getData(postcode || undefined); setPostcodeModal(false)}}>Search</button> 
        </div>
      </div>
    </ReactModal>
  ]
}

export const CMOSFormatted = ({ translations, summaryData: data }) => {
  const rows = useMemo(() => {
    return [
      [translations['BAA.CF_VOA_BA_Reference'], 'CF_VOA_BA_Reference'],
      [translations['BAA.CF_UPRN'], 'CF_UPRN'],
      [translations['BAA.CF_PAF_Address_Key'], 'CF_PAF_Address_Key'],
      [translations['BAA.CF_Free_Descriptor'], 'CF_Free_Descriptor'],
      [translations['BAA.CF_Secondary_Addressable_Object'], 'CF_Secondary_Addressable_Object'],
      [translations['BAA.CF_Primary_Addressable_Object'], 'CF_Primary_Addressable_Object'],
      [translations['BAA.CF_Address_Line_1'], 'CF_Address_Line_1'],
      [translations['BAA.CF_Address_Line_2'], 'CF_Address_Line_2'],
      [translations['BAA.CF_Address_Line_3'], 'CF_Address_Line_3'],
      [translations['BAA.CF_Address_Line_4'], 'CF_Address_Line_4'],
      [translations['BAA.CF_Address_Line_5'], 'CF_Address_Line_5'],
      [translations['BAA.CF_Postcode'], 'CF_Postcode'],
      ['', ({data}) => <button className='button compact smaller-text' onClick={() => window.reloadSummaryData()}>Update Address</button>]
    ]
  }, [translations, window.reloadSummaryData])
  return (
    <GenericTable biggerSecondRow={false} firstColWidth={150} title={translations['BAA.Header_CmosFormatted']} rows={rows} extraClass="squish smaller-text left" evidence={false} data={data} />
  )
}