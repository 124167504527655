import React, { Component, useEffect } from 'react'
import { view } from '@risingstack/react-easy-state'
import Navigation from '../../../components/navigation/navigation';
import Container from '../../../components/container/container';
import Footer from '../../../components/footer/footer';
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import './EnergyGoneAwayAlerts.scss';
import { Link, withRouter } from 'react-router-dom';
import request from '../../../helpers/request';
import hasPermission from '../../../helpers/permissions';
import endpoints from '../../../helpers/endpoints';
import { BarChart, XAxis, YAxis, Legend, Bar, ResponsiveContainer, CartesianGrid, Tooltip } from 'recharts';
import S3Modal from '../../../components/s3Modal/S3Modal';
import session from '../../../stores/session';
import BulkAllocate from '../../../components/cot-alert-bulk-allocate/bulkAllocate';
import HelpVideo from '../../../components/help-video/helpVideo';
import useWSProduct from '../../../helpers/useWSProduct';
import ProductChartInfo from '../../../components/product-chart-info/product-info-chart';
import ProductDataFilter from '../../../components/product-data-filter/product-data-filter';
import DataGroupings from '../../../components/data-groupings/data-groupings';
import Modal from 'react-modal';
import PageTitle from '../../../components/page-header/pageTitle';
import GenericDataChart from '../../../GenericPageElements/GenericDataChart'
import GenericProductFilterContainer from '../../../GenericPageElements/GenericProductFilterContainer'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'

const localised = Intl.NumberFormat('en-GB');

class Label extends Component {
    render() {
        const {
            x, y, payload,
        } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <g transform={`translate(-40, 0)`}>
                    { 1 <= payload.value ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-4" role="img" viewBox="0 0 576 512"><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"/></svg>
                    :
                        <svg width="16px" height="16px" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="star" className="svg-inline--fa fa-star fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 403.5 142.5 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z"></path></svg>
                    }
                </g>
                <g transform={`translate(-24, 0)`}>
                    { 2 <= payload.value ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-4" role="img" viewBox="0 0 576 512"><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"/></svg>
                    :
                        <svg width="16px" height="16px" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="star" className="svg-inline--fa fa-star fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 403.5 142.5 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z"></path></svg>
                    }
                </g>
                <g transform={`translate(-8, 0)`}>
                    { 3 <= payload.value ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-4" role="img" viewBox="0 0 576 512"><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"/></svg>
                    :
                        <svg width="16px" height="16px" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="star" className="svg-inline--fa fa-star fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 403.5 142.5 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z"></path></svg>
                    }
                </g>
                <g transform={`translate(8, 0)`}>
                    { 4 <= payload.value ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-4" role="img" viewBox="0 0 576 512"><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"/></svg>
                    :
                        <svg width="16px" height="16px" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="star" className="svg-inline--fa fa-star fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 403.5 142.5 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z"></path></svg>
                    }
                </g>
                <g transform={`translate(24, 0)`}>
                    { 5 <= payload.value ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-4" role="img" viewBox="0 0 576 512"><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"/></svg>
                    :
                        <svg width="16px" height="16px" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="star" className="svg-inline--fa fa-star fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 403.5 142.5 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z"></path></svg>
                    }
                </g>
            </g>
        );
    }
}

export default view(
    withRouter(
        class EnergyGoneAwayAlerts extends Component {
            constructor(props) {
                super(props);

                this.state = {
                    dataReady: false,
                    dates: [],
                    date: undefined,
                    data: null,
                    graphData: [],
                    group: 'Type',
                    filters: {},
                    filterArrays: {
                        IMD: {},
                        Voids_Age_Band: {},
                        occupied_Score: {},
                        Consumption: {},
                    },
                    files: [],
                    modalIsOpen: false,
                    modalMode: null,
                    modalMessage: null,
                    bulkBuyModalIsOpen: false,
                    bulkAllocateModalIsOpen: false,
                    function: 'count',

                    groupings: [],
                }
            }

            getDates = () => {
                return request(true).get(endpoints.ENERGY_GONE_AWAY_ALERTS_GET_DATES).then(r => {
                    this.setState({
                        dates: r.data.dates,
                        date: r.data.dates[0].raw
                    });
                }).catch(e => {
                    //console.log(e);
                });
            }

            getGroupings = () => {
                return request(true).get(endpoints.ENERGY_GONE_AWAY_ALERTS_GROUPINGS).then(e => {
                    this.setState({
                        groupings: e.data,
                        group: e.data.find(_ => _.default)
                    })
                })
            }

            setGroup = (field) => {
                this.setState({
                    group: field
                }, () => {
                    this.query();
                });
            }

            setDate = (ev) => {
                this.setState({
                    date: ev.target.value
                }, () => {
                    this.query();
                });
            }

            filter = (field, value) => {
                this.setState({
                    filters: {
                        ...this.state.filters,
                        [field]: value
                    }
                }, () => {
                    this.query();
                });
            }

            filterArray = (field, value) => {
                this.setState({
                    filterArrays: {
                        ...this.state.filterArrays,
                        [field]: {
                            ...this.state.filterArrays[field],
                            [value]: !this.state.filterArrays[field][value]
                        }
                    }
                }, () => {
                    const newFilter = [];

                    Object.keys(this.state.filterArrays[field]).forEach(key => {
                        if (this.state.filterArrays[field][key]) {
                            newFilter.push(key);
                        }
                    });

                    this.setState({
                        filters: {
                            ...this.state.filters,
                            [field]: newFilter
                        }
                    }, () => {
                        this.query();
                    });
                })
            }

            query = () => {
                this.setState({
                    dataReady: false,
                }, () => {
                    request(true).get(endpoints.ENERGY_GONE_AWAY_ALERTS_SUMMARY, {
                        params: {
                            date: this.state.date,
                            filter: this.state.filters,
                            group: this.state.group?.target_column,
                            function: this.state.function
                        },
                        doesCancel: true,
                    }).then(r => {
                        this.setState({
                            data: r.data,
                            dataReady: true
                        })
                    });
                })
            }
            
            setFunction = (f) => {
                this.setState({
                    function: f.split('-')[1]
                }, () => {
                    this.query()
                })
            }

            clearFilters = () => {
                this.setState({
                    filters: {
                        Type: null,
                        Consumption: [],
                        New_Connection: null,
                        IMD: [],
                        Voids_Age_Band: [],
                        occupied_Score: [],
                        Assigned: null,
                    },
                    filterArrays: {
                        IMD: {},
                        Voids_Age_Band: {},
                        occupied_Score: {},
                        Consumption: {},
                    }
                }, () => {
                    this.query();
                });
            }

            componentDidMount() {
                request(true).get('/translate?key=ega.').then(r => {
                    this.setState({
                        translations: r.data
                    });
                }).catch(e => {
                    console.error(e);
                })
                Promise.all([this.getDates(), this.getGroupings()]).finally(() => {
                    this.query()
                })
                useWSProduct('energy_gone_away_alerts');// eslint-disable-line
            }

            openUploadModal = () => {
                this.setState({
                    modalIsOpen: true,
                    modalMode: 'upload',
                })
            }

            openDownloadModal = () => {
                this.setState({
                    modalIsOpen: true,
                    modalMode: 'download',
                });
            }

            closeModal = () => {
                this.setState({
                    modalIsOpen: false,
                })
            }

            openBulkBuyModal = () => {
                this.setState({
                    bulkBuyModalIsOpen: true
                });
            }

            closeBulkBuyModal = () => {
                this.setState({
                    bulkBuyModalIsOpen: false
                });
            }

            openBulkAllocateModal = () => {
                this.setState({
                    bulkAllocateModalIsOpen: true
                });
            }

            closeBulkAllocateModal = () => {
                this.setState({
                    bulkAllocateModalIsOpen: false
                });
            }

            componentWillUnmount() {
                useWSProduct('energy_gone_away_alerts', 'unlisten');// eslint-disable-line
            }

            render () {
                const totals = [0, 0, 0, 0, 0, 0, 0];
                const prefix = this.state.function === 'sum' ? '£' : ''

                return (
                    <GenericPageContainer title="Energy Gone Away Alerts" titleRight={
                        <div style={{ display: 'flex' }}>
                            {hasPermission('EGA', 'DOWNLOAD') ?
                                <button className="navigate" onClick={this.openDownloadModal}>Download Return File</button>
                                : null}
                            {hasPermission('EGA', 'UPLOAD') ?
                                <button className="navigate" onClick={this.openUploadModal}>Issue Input File</button>
                                : null}
                            <Link className="navigate" to="/portal/energy-gone-away-alerts/search">
                                To Search
                            </Link>
                            {(hasPermission('EGA', 'WORKFLOW')) ?
                                <Link className="navigate" to={this.props.history.location.pathname.indexOf('void') !== -1 ? '/portal/void-cot-alerts/workflow' : '/portal/energy-gone-away-alerts/workflow'}>
                                    To Workflow
                                </Link>
                                : null}
                        </div>
                    }>
                        <Container>
                            <div className="ega">
                                <div className="data-row file-date">
                                    <div className="data-row-title">
                                        {this.state.translations ? this.state.translations['ega.file_date'] : ''}
                                    </div>
                                    <div className="data-row-field">
                                        <select onChange={this.setDate} value={this.state.date}>
                                            {this.state.dates.map(item => {
                                                return (
                                                    <option key={item.raw} value={item.raw}>
                                                        {item.pretty}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className='grid-overview'>
                                    <GenericDataChart
                                        dataReady={this.state.dataReady}
                                        data={this.state.data}
                                        selectedGroupBy={this.state.group}
                                        setSelectectGroupBy={this.setGroup}
                                        groupingsEndpoint={`${endpoints.ENERGY_GONE_AWAY_ALERTS_GROUPINGS}`}
                                        sizes={{chart: 500, chartContainer: 500, chartXAxisFontSize: 12, wrapperStyle: {paddingTop: '40px'}}}
                                        xAxisProps={{interval: 0}}
                                        prefix={prefix}
                                        parentHandler={this.setFunction}
                                        chartFunctions={[{type: 'count', display: 'No'}, {type: 'sum', display: '£'}]}
                                        displayModeInitial='graph-count'
                                    />
                                    <GenericProductFilterContainer clearFilters={this.clearFilters} style={{height: 'unset', position: 'sticky', top: 10}}>
                                        <ProductDataFilter applyFilter={this.filter} filterState={this.state.filters} dataFilterEndpoint={endpoints.ENERGY_GONE_AWAY_ALERTS_DATA_FILTERS} />
                                        <div className='grid grid-columns-2 grid-gap-5 mta'>
                                            {(hasPermission('EGA', 'BULK-BUY')) ?
                                                <button className="button bulk-buy-button" onClick={this.openBulkBuyModal} style={{marginTop: 15}}>
                                                    Bulk Buy
                                                </button>
                                                : ''}
                                            {(hasPermission('EGA', 'ALLOCATE')) ?
                                                <button className="button bulk-buy-button" onClick={this.openBulkAllocateModal} style={{marginTop: 15}}>
                                                    Allocate
                                                </button>
                                                : ''}
                                        </div>
                                    </GenericProductFilterContainer>
                                </div>
                            </div>
                        </Container>
                        <S3Modal showModal={this.state.modalIsOpen} closeModal={this.closeModal} modalMode={this.state.modalMode} listingUrl={endpoints.ENERGY_GONE_AWAY_ALERTS_FILE_UPLOAD} uploadUrl={endpoints.ENERGY_GONE_AWAY_ALERTS_FILE_GET} />
                        <BulkBuy stepOneType="gone away accounts" stepTwoType="confidence" stepThreeType="gone away" workflowUsersEndpoint={endpoints.ENERGY_GONE_AWAY_ALERTS_WORKFLOW_USERS} bulkEndpoint={endpoints.ENERGY_GONE_AWAY_ALERTS_SUMMARY_BULK} modalIsOpen={this.state.bulkBuyModalIsOpen} closeModal={this.closeBulkBuyModal} filters={this.state.filters} date={this.state.date} />
                        <BulkAllocate workflowUsersEndpoint={endpoints.ENERGY_GONE_AWAY_ALERTS_WORKFLOW_USERS} bulkEndpoint={endpoints.ENERGY_GONE_AWAY_ALERTS_SUMMARY_BULK} modalIsOpen={this.state.bulkAllocateModalIsOpen} closeModal={this.closeBulkAllocateModal} filters={this.state.filters} date={this.state.date} />
                        <HelpVideo video={session.company.EGA_video_url} />
                    </GenericPageContainer>
                )
            }
        }
    )
)
function BulkBuy ({ modalIsOpen, closeModal, filters, date, workflowUsersEndpoint, bulkEndpoint, stepOneType, stepTwoType, stepThreeType, ...props }) {
    const [step, setStep] = React.useState(1);
    const [bulkCount, setBulkCount] = React.useState(0);
    const [newOccCount, setNewOccCount] = React.useState(0)
    const [bulkLimit, setBulkLimit] = React.useState(0);
    const [newOccLimit, setNewOccLimit] = React.useState(0);
    const [buyNowEnabled, setBuyNowEnabled] = React.useState(true);
    const [error, setError] = React.useState(undefined);

    useEffect(() => {
        setStep(1);
    }, [modalIsOpen, setStep]);

    useEffect(() => {
        if (step === 1 && modalIsOpen) {
          request(true).get(bulkEndpoint, {
                params: {
                    date: date,
                    filter: filters,
                }
            }).then(r => {
                setBulkCount(r.data[0]);
                setBulkLimit(r.data[0]);
                setNewOccCount(r.data[1]);
                setNewOccLimit(r.data[1]);
            }).catch(e => {
                console.log(e);
            })
        }
    }, [step, modalIsOpen, filters, date, bulkEndpoint]);

    const confirmPurchase = () => {
        console.log('click');
        setBuyNowEnabled(false);
        buy();
    }

    const buy = () => {
        if (bulkLimit <= 0) return;
        request(true).post(bulkEndpoint, {
            date: date,
            filter: filters,
            // limit: [bulkLimit,newOccLimit]
            limit: bulkLimit
        }).then(r => {
            window.location.reload();
        }).catch(e => {
            console.log(e);
        });
    }

    const formChange = (value, source, dest) => {
        value = Number(value)
        if (value === "") return dest(0)

        if (value > source) {
            value = source;
        }

        dest(value);
    }

    const firstBuyStep = () => {
        console.log('firstBuyStep', bulkLimit, bulkLimit === 0, bulkLimit === '0')
        if (bulkLimit === 0) return setError('You cannot buy 0 customers, you must buy 1 or more.')
        setError(undefined)
        
        if (newOccCount >= bulkLimit) {
            setNewOccLimit(bulkLimit)
        }

        setStep(3)
    }

    const secondBuyStep = () => {
        if (newOccLimit > newOccCount) return setError('You cannot buy more new occupiers than are available.')
        setStep(3);
    }

    return (
        <Modal
                    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
                    className="card bulk-buy"
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Bulk Buy COT Alert Data"
                    appElement={document.getElementById('root')}
            >
            { step === 1 ?
                <div key="bb_step1">
                    <p>
                        The selected data set includes { bulkCount } { stepOneType || 'customers' }. How many would you like to purchase?
                    </p>
                    <small>
                        If you reduce this number then { stepOneType || 'customers'} with higher { stepTwoType || 'occupier' } scores will be purchased first.
                    </small>
                    <small>
                        Customers with an { stepThreeType || 'occupier' } score of 0 cannot be purchased.
                    </small>
                    {error ? <p className="colour-yellow"><strong>{error}</strong></p> : null} 
                    <div className="input">
                        <label>Purchase Limit:</label>
                        <input type="number" name="bulk-limit" max={ bulkCount } value={ bulkLimit } onChange={ (e) => formChange(e.target.value, bulkCount, setBulkLimit) }/>
                    </div>
                    <div className="grid-2">
                        <button className="button cancel" onClick={ closeModal }>Cancel</button>
                        <button className="button confirm" onClick={ () => firstBuyStep() }>Confirm</button>
                    </div>
                </div>
            : null}

            {
                step === 2 ?
                <div key="bb_step1">
                    <p>
                        The selected data set includes { newOccCount > bulkLimit ? bulkLimit : newOccCount } new occupiers. How many would you like to purchase?
                    </p>
                    <div className="input">
                        <label>Purchase Limit:</label>
                        <input type="number" name="bulk-limit" max={ newOccCount > bulkLimit ? bulkLimit : newOccCount } value={ newOccLimit } onChange={ (e) => formChange(e.target.value, newOccCount > bulkLimit ? bulkLimit : newOccCount, setNewOccLimit) }/>
                    </div>
                    <div className="grid-2">
                        <button className="button cancel" onClick={ closeModal }>Cancel</button>
                        <button className="button confirm" onClick={ () => secondBuyStep() }>Confirm</button>
                    </div>
                </div> : null
            }

            { step === 3 ?
                <>
                    <p>
                        You have selected { bulkLimit } account{bulkLimit > 1 ? 's' : ''} for purchase, <br />please press 'Buy Now' to complete this transaction.
                    </p>
                    <div className="grid-2">
                        <button className="button cancel" onClick={ closeModal }>Cancel</button>
                        <button className="button confirm" onClick={ confirmPurchase } disabled={ !buyNowEnabled }>
                            {
                                !buyNowEnabled ?
                                    <div className="spinner">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </div>
                                : 'Buy Now'
                            }
                        </button>
                    </div>
                </> : null
            }
        </Modal>
    )
}
