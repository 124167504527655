import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import React from 'react'

export default function PropertyMap(props) {
    const [center, setCenter] = React.useState({});
    const [reload, setReload] = React.useState(false)
    const [current, setCurrent] = React.useState('')

    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY
    })

    const updateMap = () => {
        setReload(true)
        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({address: props.address}, (res, status) => {
            if (status === "OK") {
                setCenter(res[0].geometry.location);
                setReload(false)
            }
        });
    }

    React.useEffect(() => {
        if (isLoaded && (props.address !== current)) {
            updateMap()
            setCurrent(props.address)
        }
    }, [props, isLoaded])

    return (
        !reload ? isLoaded ?
        <>
            <GoogleMap
                mapContainerClassName="map"
                zoom={ 18 }
                center={ center }
            >
                <Marker position={ center }
                />
            </GoogleMap>
        </> : loadError ? <p>Error Loading Map</p> : <div style={{backgroundColor: 'lightgray', height: '100%'}}></div> : <div style={{backgroundColor: 'lightgray', height: '100%'}}></div>
    )
}