import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ProductDataFilter from "../../../components/product-data-filter/product-data-filter";
import GenericDataChart from "../../../GenericPageElements/GenericDataChart";
import GenericPageContainer from "../../../GenericPageElements/GenericPageContainer";
import GenericProductFilterContainer from "../../../GenericPageElements/GenericProductFilterContainer";
import endpoints from "../../../helpers/endpoints";
import hasPermission from '../../../helpers/permissions';
import request from "../../../helpers/request";
import S3Modal from "../../../components/s3Modal/S3Modal";
import session from "../../../stores/session";
import GenericDataRow from "../../../GenericPageElements/GenericDataRow";
import { view } from "@risingstack/react-easy-state";
import { Link } from "react-router-dom";
import BulkBuy from '../../../components/cot-alert-bulk-buy/bulkBuy';
import BulkAllocate from '../../../components/cot-alert-bulk-allocate/bulkAllocate';
import { CustomizedAxisTickMultiLine } from "../CompaniesHouseAlerts/CompaniesHouseAlerts";
import './HouseholdProperty.scss';

export default view(function HouseholdPropertyReport() {
  const [filters, setFilters] = useState({});
  const [selectedGroupBy, setSelectectGroupBy] = useState("WS_Outcome");
  const [data, setData] = useState(null);
  const [dates, setDates] = useState([]);
  const [date, setDate] = useState(null);
  const [S3Model, setS3Model] = useState(null);
  const [bulkModel, setBulkModel] = useState('')
  
  const applyFilter = (column, value) => {
    setFilters((current) => {
      return {
        ...current,
        [column]: value,
      };
    });
  };

  useEffect(() => {
    request(true)
      .get(endpoints.HOUSEHOLD_PROPERTY_REPORT_DATES)
      .then((e) => {
        setDates(e.data.dates);
        if (e.data.dates.length > 0) setDate(e.data.dates[0].raw);
      });
  }, []);

  useEffect(() => {
    if (!date) return;
    request(true)
      .get(endpoints.HOUSEHOLD_PROPERTY_REPORT_DATA, {
        params: {
          date: date,
          filter: filters,
          group: selectedGroupBy,
        },
      })
      .then((e) => {
        setData(e.data);
      });
  }, [filters, selectedGroupBy, date]);

  return [
    <GenericPageContainer
      title="Household Property - Report"
      titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
      titleRight={
        <div>
          <Link className="navigate" to="/portal/household-property/">
            To Summary
          </Link>
          <Link className="navigate" to="/portal/household-property/search">
              To Search
          </Link>
          { (hasPermission('HOUSEHOLD_PROPERTY', 'WORKFLOW')) ?
            <Link className="navigate" to="/portal/household-property/workflow">
                To Workflow
            </Link>
          : null }
          {hasPermission('HOUSEHOLD_PROPERTY', 'DOWNLOAD') ? (
            <button className="navigate" onClick={() => setS3Model("download")}>
              Download Return File
            </button>
          ) : null}
          {hasPermission('HOUSEHOLD_PROPERTY', 'UPLOAD') ? (
            <button className="navigate" onClick={() => setS3Model("upload")}>
              Issue Input File
            </button>
          ) : null}
        </div>
      }
    >
      <div style={{maxWidth: '20vw', marginBottom: 15}}>
      <GenericDataRow title={"File Date"} contentColour={"white"}>
        <select
          onChange={(_) => setDate(_.target.value)}
          value={date}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            backgroundColor: "white",
            paddingLeft: "10px",
          }}
        >
          {dates.map((item) => {
            return (
              <option key={item.raw} value={item.raw}>
                {item.pretty}
              </option>
            );
          })}
        </select>
      </GenericDataRow>
      </div>
      <div className='HP' style={{gridTemplateColumns: 'auto'}}>
        <GenericDataChart
          dataReady={!!data}
          data={data}
          selectedGroupBy={selectedGroupBy}
          setSelectectGroupBy={setSelectectGroupBy}
          groupingsEndpoint={endpoints.HOUSEHOLD_PROPERTY_REPORT_GROUPINGS}
          sizes={{chart: 600, chartContainer: 625, chartXAxisFontSize: 12, wrapperStyle: { paddingTop: '40px' }}}
          xAxisProps={{interval: 0}}
          customBarTick={CustomizedAxisTickMultiLine}
        />
      </div>
    </GenericPageContainer>,
    <S3Modal
      closeModal={() => setS3Model(null)}
      modalMode={S3Model}
      showModal={!!S3Model}
      listingUrl={endpoints.HOUSEHOLD_PROPERTY_FILES}
      uploadUrl={endpoints.HOUSEHOLD_PROPERTY_FILES}
    />,
  ];
})
