import React from 'react';

export default function LoadingPage({ stage }) {
  const version = `${process.env.REACT_APP_VERSION} - ${process.env.REACT_APP_HASH.substring(0, 7)}`

  return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <i className='fas fa-loader fa-fw fa-spin fa-3x' />
      <p>{stage || 'Loading'}</p>
      <small style={{color: '#bbb'}}>{version}</small>
    </div>
  </div>;
}
