import React from 'react'

import styles from './GenericDataRow.module.scss'

export const GenericDataRowContent = ({children, contentColour}) => {
  return (
    <div className={styles['data-row-field']} style={{backgroundColor: contentColour || undefined}}>
      {children}
    </div>
  )
}

export const GenericDataRowTitle = ({title}) => {
  return (
    <div className={styles['data-row-title']}>{title}</div>
  )
}

export default function GenericDataRow({
  title,
  children,
  contentColour,
  style = {}
}) {
  return (
    <div className={styles.container} style={style}>
      <div className={styles['data-row-title']}>{title}</div>
      <GenericDataRowContent contentColour={contentColour}>
        {children}
      </GenericDataRowContent>
    </div>
  )
}
