import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Page from "../../../components/page/page";
import ProductDataFilter from "../../../components/product-data-filter/product-data-filter";
import GenericDataChart from "../../../GenericPageElements/GenericDataChart";
import GenericPageContainer from "../../../GenericPageElements/GenericPageContainer";
import GenericProductFilterContainer from "../../../GenericPageElements/GenericProductFilterContainer";
import endpoints from "../../../helpers/endpoints";
import hasPermission from '../../../helpers/permissions';
import request from "../../../helpers/request";
import S3Modal from "../../../components/s3Modal/S3Modal";
import session from "../../../stores/session";
import { view } from "@risingstack/react-easy-state";
import GenericDataRow from "../../../GenericPageElements/GenericDataRow";
import './HomeMoverAlerts.scss';

const HomeMoverAlertsS3Buttons = () => {
  return <div></div>;
};

export default view(function HomeMoverAlerts() {
  const [filters, setFilters] = useState({});
  const [selectedGroupBy, setSelectectGroupBy] = useState("Type");
  const [groupings, setGroupings] = useState([]);
  const [group, setGroup] = useState(undefined);
  const [data, setData] = useState(null);
  const [dates, setDates] = useState([]);
  const [date, setDate] = useState(null);
  const [S3Model, setS3Model] = useState(null);

  const applyFilter = (column, value) => {
    setFilters((current) => {
      return {
        ...current,
        [column]: value,
      };
    });
  };

  useEffect(() => {
    request(true)
      .get(endpoints.HOME_MOVER_ALERTS_DATES)
      .then((e) => {
        setDates(e.data.dates);
        if (e.data.dates.length > 0) setDate(e.data.dates[0].raw);
      });
  }, []);

  useEffect(() => {
    request(true)
      .get(endpoints.HOME_MOVER_ALERTS_GROUPINGS)
      .then(e => {
        setGroupings(e.data);
        setGroup(e.data.find(_ => _.default))
      })
  }, [])

  useEffect(() => {
    if (!date) return;
    request(true)
      .get(endpoints.HOME_MOVER_ALERTS_DATA, {
        params: {
          date: date,
          filter: filters,
          group: group?.target_column,
        },
        doesCancel: true,
      })
      .then((e) => {
        setData(e.data);
      });
  }, [filters, group, date]);

  return [
    <GenericPageContainer
      title={"Home Mover Alerts"}
      titleRight={
        <div>
          {hasPermission('HOME_MOVER', 'DOWNLOAD') ? (
            <button className="navigate" onClick={() => setS3Model("download")}>
              Download Return File
            </button>
          ) : null}
          {hasPermission('HOME_MOVER', 'UPLOAD') ? (
            <button className="navigate" onClick={() => setS3Model("upload")}>
              Issue Input File
            </button>
          ) : null}
        </div>
      }
    >
      <div style={{maxWidth: '20vw', marginBottom: 15}}>
      <GenericDataRow title={"File Date"} contentColour={"white"}>
        <select
          onChange={(_) => setDate(_.target.value)}
          value={date}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            backgroundColor: "white",
            paddingLeft: "10px",
          }}
        >
          {dates.map((item) => {
            return (
              <option key={item.raw} value={item.raw}>
                {item.pretty}
              </option>
            );
          })}
        </select>
      </GenericDataRow>
      </div>
      <div class="HMA">
        <GenericDataChart
          dataReady={!!data}
          data={data}
          selectedGroupBy={group}
          setSelectectGroupBy={setGroup}
          groupingsEndpoint={endpoints.HOME_MOVER_ALERTS_GROUPINGS}
          sizes={{chart: 600, chartContainer: 625, chartXAxisFontSize: 12}}
        />
        <GenericProductFilterContainer clearFilters={() => {}}>
          <ProductDataFilter
            filterState={filters}
            applyFilter={applyFilter}
            dataFilterEndpoint={endpoints.HOME_MOVER_ALERTS_FILTERS}
          />
        </GenericProductFilterContainer>
      </div>
    </GenericPageContainer>,
    <S3Modal
      closeModal={() => setS3Model(null)}
      modalMode={S3Model}
      showModal={!!S3Model}
      listingUrl={endpoints.HOME_MOVER_ALERTS_FILES}
      uploadUrl={endpoints.HOME_MOVER_ALERTS_FILES}
    />,
  ];
});
