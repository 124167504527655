import React from 'react'
import endpoints from '../../../helpers/endpoints';
import request from '../../../helpers/request';

export function GenericTable({ 
    data, rows, title, 
    extraClass, style = {}, evidence = true, 
    subtitle = 'Evidence', align, 
    firstColWidth, biggerSecondRow = true,
    leftOfTitleContent, openTwilioModal = null
 }) {
  return (
    <table className={`table borders ${extraClass}`} style={{ height: '100%', ...style }}>
      <thead>
        <tr>
            {
                leftOfTitleContent ? <>
                    <th>
                        {leftOfTitleContent}
                    </th>
                </> : null
            }
          <th style={{textAlign: leftOfTitleContent ? 'center' : 'start' }} colSpan={evidence ? 1 : 2}>
            {title}
          </th>
          {
            evidence ? <>
              <th>
                {subtitle}
              </th>
            </> : null
          }
        </tr>
      </thead>
      <tbody>
        {
          rows.map((row, i) => {
            const Value = row[1]
            if (row[0] === "Phone") {
                return (
                <tr key={`gtable_${Math.random()}_${i}`}>
                    <td style={{ height: biggerSecondRow ? i === 1 ? 30 : undefined : undefined, textAlign: align, width: i === 0 ? firstColWidth || undefined : undefined }}>{row[0]}</td>
                    <td style={{ minWidth: evidence ? 'unset' : 60 }}>{data ? (typeof Value === 'string') ?  data[Value] : <Value data={data}></Value> : null}</td>
                </tr>
                )
            } 
                return (
                  <tr key={`gtable_${Math.random()}_${i}`}>
                    <td style={{ height: biggerSecondRow ? i === 1 ? 30 : undefined : undefined, textAlign: align, width: i === 0 ? firstColWidth || undefined : undefined }}>{row[0]}</td>
                    <td style={{ minWidth: evidence ? 'unset' : 60 }}>{data ? (typeof Value === 'string') ?  data[Value] ? data[Value] : '' : <Value data={data}></Value> : null}</td>
                  </tr>
                )
          })
        }
      </tbody>
    </table>
  )
}

export function DVSAEvidence({data, openTwilioModal}) {
    const rows = [['Company', 'DVSA_Name'],['Address', 'DVSA_Address'],['Phone', 'DVSA_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="DVSA" openTwilioModal={openTwilioModal} />
    )
}

export function CharityCommisionEvidence({data, openTwilioModal}) {
    const rows = [['Name', 'CC_Name'],['Address', 'CC_Address'],['Phone', 'CC_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="Trip Advisor" openTwilioModal={openTwilioModal} />
    )
}

export function BTDirectoryEvidence({data, openTwilioModal}) {
    const rows = [['Company', 'BT_Name'],['Address', 'BT_Address'],['Phone', 'BT_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="BT Directory" openTwilioModal={openTwilioModal} />
    )
}

export function FoodStandardsEvidence({data, openTwilioModal}) {
    const rows = [['Company', 'FSA_Name'],['Address', 'FSA_Address'],['Rating Date', 'FSA_Rating_Date']];
    return (
        <GenericTable data={data} rows={rows} title="Food Standards Agency" openTwilioModal={openTwilioModal} />
    )
}

export function GoogleEvidence({data, openTwilioModal}) {
    const rows = [['Company','Google_Company'],['Address','Google_Address'],['Business Status', 'Google_Status'],['Phone','Google_Phone'],['First Review Date','Google_First_Date'],['Last Review Date','Google_Date']];
    return (
        <GenericTable data={data} rows={rows} title="Google" openTwilioModal={openTwilioModal} />
    )
}

export function CompaniesHouseEvidence({data, openTwilioModal}) {
    const rows = [['Company','Comp_Hse_Company'],['Address','Comp_Hse_Address'],['Business Status','Comp_Hse_Status'],['SIC','Comp_Hse_SIC'],['Incorporated Date','Comp_Hse_Inc_Date'],['Last Statement Date','Comp_Hse_LS_Date']];
    return (
        <GenericTable data={data} rows={rows} title="Companies House" openTwilioModal={openTwilioModal} />
    )
}

export function YellComEvidence({data, openTwilioModal}) {
    const rows = [['Company','Yell_Company'],['Address','Yell_Address'],['Business Status','Yell_Status'],['Phone','Yell_Phone'],['Website','Yell_Website'],['Email','Yell_Email']];
    return (
        <GenericTable data={data} rows={rows} title="Yell.Com" openTwilioModal={openTwilioModal} />
    )
}

export function AddressEvidence({data, openTwilioModal}) {
    const rows = [['Company','AD_Name'],['UPRN Address','AddressBase_Address'],['UPRN','UPRN'],['VOA Number','AD_VOA_No'],['VOA End Date','AD_VOA_End'],['CT Start Date','AD_CT_Start']];
    return (
        <GenericTable data={data} rows={rows} title="Address Data" openTwilioModal={openTwilioModal} />
    )
}

export function CQCEvidence({data, openTwilioModal}) {
    const rows = [['Company', 'CQC_Name'],['Address', 'CQC_Address'],['Phone', 'CQC_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="Quality Care Comm" openTwilioModal={openTwilioModal} />
    )
}

export function JustEatEvidence({data, openTwilioModal}) {
    const rows = [['Company', 'JE_Company'],['Address', 'JE_Address'],['Phone', 'JE_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="Just Eat" openTwilioModal={openTwilioModal} />
    )
}

export function One92Evidence({data, openTwilioModal}) {
    const rows = [['Company', 'One92_Company'],['Address', 'One92_Address'],['Phone', 'One92_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="192.com" openTwilioModal={openTwilioModal} />
    )
}

export function FacebookEvidence({data, openTwilioModal}) {
    const rows = [['Company', 'FB_Company'],['Address', 'FB_Address'],['Phone', 'FB_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="Facebook" openTwilioModal={openTwilioModal} />
    )
}

export function TitleOwnerRow({ state }) {
    return (
        <div style={{gridColumnStart: 1, gridColumnEnd: 3, gridGap: '15px', display: 'grid'}}>
            <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['vacant-cot-alerts.registered_address'] : '' }
                </div>
                <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Registered_Address : '' }/>
            </div>
            <div className="left-grid-bottom" style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 15}}>
                <div className="data-row">
                    <div className="data-row-title">
                    { state.translations ? state.translations['vacant-cot-alerts.title-number-owner'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.LR_Name_No ? `${state.customer.LR_Name_No}` : '' : '' }/>
                </div>
                <div className="data-row">
                    <div className="data-row-title">
                    { state.translations ? state.translations['vacant-cot-alerts.title-owner-address'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.LR_Address ? `${state.customer.LR_Address}` : '' : '' }/>
                </div>
            </div>
        </div>
    )
}

export function LeftColumn({ state, children }) {
    return (
        <>
            {
                children
            }
            <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['vacant-cot-alerts.client_address'] : '' }
                </div>
                <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.SPID_Address : '' }/>
            </div>
            <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['vacant-cot-alerts.cmos_uprn_address'] : '' }
                </div>
                <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.CMOS_UPRN_Single_Line ? state.customer.CMOS_UPRN_Single_Line : '' : ''} />
            </div>
            <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['vacant-cot-alerts.cmos_voa_address'] : '' }
                </div>
                <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.CMOS_VOA_Single_Line ? state.customer.CMOS_VOA_Single_Line : '' : '' } /> 
            </div>
            <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['vacant-cot-alerts.matched_address'] : '' }
                </div>
                <div style={{display: 'grid', cursor: 'pointer'}} onClick={(e) => {window.blur(); if (state.customer?.AddressBase_Address) window.open(`https://www.google.com/maps/search/?api=1&query=${state.customer ? state.customer.AddressBase_Address : ''}`)}} >
                    <input style={{cursor: 'pointer'}} disabled={true} type="text" className="data-row-field colour-blue" value={ state.customer ? state.customer.AddressBase_Address : '' }/>
                </div>
            </div>
            <div className="left-grid-bottom">
            <div className="data-row" style={{gridColumnStart: 1, gridColumnEnd: 3}}>
                    <div className="data-row-title">
                    { state.translations ? state.translations['vacant-cot-alerts.company_name'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Company_Name ? `${state.customer.Company_Name}` : '' : '' }/>
                </div>
                <div className="data-row">
                    <div className="data-row-title">
                    { state.translations ? state.translations['vacant-cot-alerts.company_number'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Company_Number ? `${state.customer.Company_Number}` : '' : '' }/>
                </div>
            </div>
            <div className="left-grid-bottom" style={{gridTemplateColumns: '1fr 1fr'}}> 
                <div className="data-row">
                    <div className="data-row-title">
                    { state.translations ? state.translations['vacant-cot-alerts.website'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Website ? `${state.customer.Website}` : '' : '' }/>
                </div>
                <div className="data-row">
                    <div className="data-row-title">
                    { state.translations ? state.translations['vacant-cot-alerts.landline'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Landline ? `${state.customer.Landline}` : '' : '' }/>
                </div>
            </div>
            <div className="left-grid-bottom" style={{gridTemplateColumns: '1fr 1fr'}}> 
                <div className="data-row">
                    <div className="data-row-title">
                        { state.translations ? state.translations['vacant-cot-alerts.sic_code'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={state.customer ? state.customer.SIC_Code : '' }/>
                </div>
                <div className="data-row">
                    <div className="data-row-title">
                    { state.translations ? state.translations['vacant-cot-alerts.mobile'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Mobile ? `${state.customer.Mobile}` : '' : '' }/>
                </div>
            </div>
        </>
    )
}

export function RightColumn({ state, children, setWholesaler, setAdcGroup, setFileDate }) {
    return (
        <div className="right-grid">
            {
                children
            }
            {/* <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['vacant-cot-alerts.type'] : '' }
                </div>
                <input type="text" className="data-row-field" disabled={true} value={state.customer ? state.customer.Type : ''}/>
            </div> */}

            {
                state.workflow ? 
                    <div className="data-row">
                        <div className="data-row-title">
                            File Date
                        </div>
                        <select style={{height: 28, border: '1px solid var(--primary)', borderRadius: 5}} onChange={setFileDate} value={state.selectedDate}>
                            <option value=''>All Dates</option>
                            {
                                state.dates.map(date => {
                                    return (
                                        <option value={date.raw}>{date.pretty}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                :
                    <div className="data-row">
                        <div className="data-row-title">
                            { state.translations ? state.translations['vacant-cot-alerts.adc_group'] : '' }
                        </div>
                        <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.ADC_Group : '' }/>
                    </div>
            }
            {
                state.workflow ?
                    <div className="data-row">
                        <div className="data-row-title">
                            { state.translations ? state.translations['vacant-cot-alerts.adc_group'] : '' }
                        </div>
                        <select style={{height: 28, border: '1px solid var(--primary)', borderRadius: 5}} onChange={setAdcGroup} value={state.selectedAdcGroup}>
                            <option value="">All ADC Groups</option>
                            <option value="0.0-0.5">0.0-0.5</option>
                            <option value="0.5-1.0">0.5-1.0</option>
                            <option value="1-1.5">1-1.5</option>
                            <option value="1.5-2.0">1.5-2.0</option>
                            <option value="2.0+">2.0+</option>
                        </select>
                    </div>
                :
                    <div className="data-row">
                        <div className="data-row-title">
                            Assigned to
                        </div>
                        <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.assigned_to : '' }/>
                    </div>
            }
            {
                state.workflow ? 
                    <div className="data-row">
                        <div className="data-row-title">
                            { state.translations ? state.translations['vacant-cot-alerts.wholesaler'] : '' }
                        </div>
                        <select style={{height: 28, border: '1px solid var(--primary)', borderRadius: 5}} onChange={setWholesaler} value={state.selectedWholesaler ? state.selectedWholesaler : state.customer?.Wholesaler ?? "New"}>
                            <option value=''>All Wholesalers</option>
                            {
                                state.wholesalers.map(wholesaler => {
                                    return (
                                        <option value={wholesaler.value}>{wholesaler.label}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                :
                    <div className="data-row">
                        <div className="data-row-title">
                            { state.translations ? state.translations['vacant-cot-alerts.wholesaler'] : '' }
                        </div>
                        <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Wholesaler : '' }/>
                    </div>
            }
            <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['vacant-cot-alerts.occupier_score'] : '' }
                </div>
                <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Occupier_Score : '' }/>
            </div>
            <div className="data-row">
                    <div className="data-row-title">
                        { state.translations ? state.translations['vacant-cot-alerts.address_score'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Address_Score : ''}/>
                </div>
                <div className="data-row">
                    <div className="data-row-title">
                        { state.translations ? state.translations['vacant-cot-alerts.uprn'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.UPRN : '' }/>
                </div>
        </div>
    )
}

// const MapsLink = ({ children, address, className }) => {
//     const [center, setCenter] = React.useState({});
//     const [reload, setReload] = React.useState(false)
//     const [current, setCurrent] = React.useState('')

//     const newChild = useMemo(() => {
//         const child = React.cloneElement(children, {
//             key: new Date().getTime(),
//             style: {
//                 cursor: 'pointer'
//             },
//             onClick: () => alert('open')
//         });
//         // child.style.cursor = 'pointer';
//         return child
//     }, [children])

//     const {isLoaded, loadError} = useLoadScript({
//         googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY
//     })

//     React.useEffect(() => {
//         if (isLoaded && (address !== current)) {
//             updateMap()
//             setCurrent(address)
//         }
//     }, [address, isLoaded])

//     const updateMap = () => {
//         setReload(true)
//         const geocoder = new window.google.maps.Geocoder();

//         geocoder.geocode({address: address}, (res, status) => {
//             if (status === "OK") {
//                 setCenter(res[0].geometry.location);
//                 setReload(false)
//             }
//         });
//     }

//     return (
//         !reload ? isLoaded ? newChild : children : children
//     )
// }
