import React, { Component, useMemo } from "react";
import session from "../../stores/session";
import { view } from "@risingstack/react-easy-state";
import request from "../../helpers/request";
import endpoints from "../../helpers/endpoints";
import { useState } from 'react';

export default view(function WelcomeText() {
  const [displayHistoryMsg, setDisplayHistoryMsg] = useState(1)

  request(true).get(endpoints.ADMIN_VERSION_HISTORY_SEEN_CHANGES).then(r => {
    setDisplayHistoryMsg(r.data);
  });
  

  const currentGreeting = useMemo(() => {
    const currentHour = new Date().getHours();

    if (currentHour >= 18) {
      return "Good evening";
    } if (currentHour >= 12) {
      return "Good afternoon";
    } 
      return "Good morning";
  }, []);
  return (
    <div className="font-align-center">
      <p
        className="font-weight-600 font-size-2 colour-secondary"
        style={{ padding: "0 1em 0 1em" }}
      >
        {currentGreeting}, {session.user.name}!
      </p>
      <p className="colour-gray">Welcome to the {session.company?.branding?.name}.</p>
      { displayHistoryMsg == 0 ? 
        <a href="/version-history" className="colour-gray">Click here to see new changes. <i style={{marginLeft: '5px'}} class="fa-regular fa-clipboard"></i></a>
          :
        null
      }
    </div>
  );
})
