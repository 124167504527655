import React, { useCallback, useMemo, useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import ReactSelect from 'react-select'
import endpoints from '../../../helpers/endpoints'
import request from '../../../helpers/request'
import { GenericTable } from '../VacantCOTAlerts/sharedComponents'
import Button from '../../../components/large-link-button/largeLinkButton'
import ReactModal from 'react-modal'
import hasPermission from '../../../helpers/permissions'

export const Summary = ({summaryData, setSummaryData, workflow = false, translations = {}}) => {
  const [spid, setSpid] = useState()
  const [search, setSearch] = useState()
  const [retOutcome, setRetOutcome] = useState('')
  const [wsOutcome, setWsOutcome] = useState('')
  const [wholesalerNotes, setWholesalerNotes] = useState('')
  const [retailerNotes, setRetailerNotes] = useState('')
  const [workflowItems, setWorkflowItems] = useState([])
  const getSummaryData = useCallback(() => {
    setSearch()
    setSummaryData({})
    setRetOutcome('')
    setWsOutcome('')
    request(true).get(endpoints.HOUSEHOLD_PROPERTY_SEARCH_BY_SPID, {
      params: {
        spid
      }
    }).then(e => {
      setSummaryData(e.data ?? {})
      setWholesalerNotes(e.data.Wholesaler_Notes ?? '')
      setRetailerNotes(e.data.Retailer_Notes ?? '')
    }).catch(e => window.alert('Could not find SPID.'))
  }, [spid, setSummaryData])

  useEffect(() => {
      window.reloadSummaryData = getSummaryData
  }, [getSummaryData])

  const getWorkflowItems = () => {
    request(true).get(endpoints.HOUSEHOLD_PROPERTY_WF_ITEMS).then(e => {
      setWorkflowItems(e.data);
      if (e.data.length > 0) {
        setSpid(e.data[0])
      }
    })
  }

  useEffect(() => {
      window.reloadWorkflow = getWorkflowItems
  }, [getWorkflowItems])

  const _cur = useMemo(() => {
    return workflowItems.findIndex(_ => _ === spid);
  }, [workflowItems, spid]);

  const getWorkflowNext = () => {
    if (_cur !== workflowItems.length - 1) {
      setSpid(workflowItems[_cur + 1]);
    }
  }

  const getWorkflowLast = () => {
    if (_cur !== 0) {
      setSpid(workflowItems[_cur - 1]);
    }
  }

  const updateOutcome = () => {
    request(true).post(endpoints.HOUSEHOLD_PROPERTY_UPDATE_OUTCOMES, {
      SPID: spid,
      WS_Outcome: wsOutcome,
      Ret_Outcome: retOutcome
    }).then(e => {
      // window.alert(e.data);
    }).catch(e => {
      console.log(e)
      window.alert("Update failed.")
    })
  }

  const submitNotes = (noteType) => {
    request(true).post(endpoints.HOUSEHOLD_PROPERTY_SUBMIT_NOTES, {
      SPID: spid,
      notes: noteType === "Wholesaler" ? wholesalerNotes : retailerNotes,
      noteType: noteType
    }).then(e => {
      // window.alert(e.data);
      window.alert("Note submitted")
    }).catch(e => {
      console.log(e)
      window.alert("Update failed.")
    })
  }

  useEffect(() => {
    if (retOutcome || wsOutcome) {
      updateOutcome()
    }
  }, [retOutcome, wsOutcome])

  useEffect(() => {
    if (workflow) {
      getWorkflowItems();
    }
  }, [])

  useEffect(() => {
    if (spid) {
      getSummaryData()
    }
  }, [spid])
  return (
    <div>
      <table className='table borders squish smaller-text left' style={{tableLayout: 'fixed', width: '100%'}}>
      {/* <input type='text' /> */}
        <col width={180} />
        <thead>
          <tr>
            <th colSpan={10}>{translations['BAA.Header_Summary']}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td width={400}>{translations['BAA.SPID']}</td>
            <td colSpan={2}>
              <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                <input style={{border: 'none'}} type="text" onChange={e => setSearch(e.target.value)} value={search ? search : spid} />
                {
                  workflow ? <>
                    <span className='fas fa-angle-left colour-primary' style={{cursor: 'pointer'}} onClick={getWorkflowLast}></span>
                    <span>{_cur + 1}/{workflowItems.length}</span>
                    <span className='fas fa-angle-right colour-primary' style={{cursor: 'pointer'}} onClick={getWorkflowNext}></span>
                  </> : <>
                  </>
                }
                <span className='fas fa-search colour-primary' style={{cursor: 'pointer'}} onClick={() => setSpid(search)}></span>
              </div>
            </td>
            <td>{translations['BAA.Match_Category']}</td>
            <td colSpan={'2'}>
              {summaryData['Address_Status']}
            </td>
            <td>{translations['BAA.Retailer_Assessment']}</td>
            <td colSpan={1}>
              <select disabled={!hasPermission('HOUSEHOLD_PROPERTY', 'RETAILER')} value={retOutcome ? retOutcome : summaryData.Ret_Outcome ?? 'New'} onChange={(event) => setRetOutcome(event.target.value)} style={{paddingLeft: 0}}>
                <option value="New">New</option>
                <option value="In Progress">In Progress</option>
                <option value="Reject">Reject</option>
                <option value="Deregister">Deregister</option>
                <option value="Deregistered">Deregistered</option>
              </select>
            </td>
            <td>{translations['BAA.Wholsaler_Assessment']}</td>
            <td colSpan={1}>
              <select disabled={!hasPermission('HOUSEHOLD_PROPERTY', 'WHOLESALER')} value={wsOutcome ? wsOutcome : summaryData.WS_Outcome} onChange={(event) => setWsOutcome(event.target.value)} style={{paddingLeft: 0}}>
                <option value="New">New</option>
                <option value="In Progress">In Progress</option>
                <option value="Reject">Reject</option>
                <option value="Deregister">Deregister</option>
                <option value="Deregistered">Deregistered</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>{translations['BAA.CMOS_UPRN']}</td>
            <td width='(100/4)%'>{summaryData['CMOS_UPRN']}</td>
            <td width='(100/4)%'>{translations['BAA.Matched_UPRN']}</td>
            <td width='(100/4)%'>{summaryData['Matched_UPRN']}</td>
            <td width='(100/4)%'>{translations['BAA.Status_UPRN']}</td>
            <td width='(100/4)%'>{summaryData['Status_UPRN']}</td>
            <td>{translations['BAA.Retailer']}</td>
            <td>{summaryData['Retailer']}</td>
            <td>{translations['BAA.Wholesaler']}</td>
            <td>{summaryData['Wholesaler']}</td>
          </tr>
          <tr>
            <td>{translations['BAA.CMOS_VOA_No']}</td>
            <td width='(100/4)%'>{summaryData['CMOS_VOA_No']}</td>
            <td width='(100/4)%'>{translations['BAA.Matched_VOA_No']}</td>
            <td width='(100/4)%'>{summaryData['Matched_VOA_No']}</td>
            <td width='(100/4)%'>{translations['BAA.Status_VOA_No']}</td>
            <td width='(100/4)%'>{summaryData['Status_VOA_No']}</td>
            <td colSpan={2} rowSpan={7} style={{height: '100%'}}>
              <div style={{position: 'relative'}}>
                <textarea disabled={!hasPermission('HOUSEHOLD_PROPERTY', 'RETAILER')} onChange={(event) => setRetailerNotes(event.target.value.slice(0, 500))} value={retailerNotes ?? ''} rows={12} style={{height: '100%', width: '98%', resize: 'none'}}/>
                <div style={{ position: 'absolute', bottom: 10, right: 5, color: 'gray', fontSize: 12 }}>
                  {retailerNotes.length}/500
                </div>
              </div>
              <button 
                disabled={!hasPermission('HOUSEHOLD_PROPERTY', 'RETAILER')  || retailerNotes.length < 0} 
                className={`button compact smaller-text ${hasPermission('HOUSEHOLD_PROPERTY', 'RETAILER') && retailerNotes.length > 0 ? 'background-primary colour-white' : ''}`}
                onClick={() => submitNotes('Retailer')}
                >Submit Notes</button>
            </td>
            <td colSpan={2} rowSpan={7} style={{height: '100%'}}>
              <div style={{position: 'relative'}}>
                <textarea disabled={!hasPermission('HOUSEHOLD_PROPERTY', 'WHOLESALER')} onChange={(event) => setWholesalerNotes(event.target.value.slice(0, 500))} value={wholesalerNotes ?? ''} rows={12} style={{height: '100%', width: '98%', resize: 'none'}}/>
                <div style={{ position: 'absolute', bottom: 10, right: 5, color: 'gray', fontSize: 12 }}>
                  {wholesalerNotes.length}/500
                </div>
              </div>
              <button 
                disabled={!hasPermission('HOUSEHOLD_PROPERTY', 'WHOLESALER') || wholesalerNotes.length < 0} 
                className={`button compact smaller-text ${hasPermission('HOUSEHOLD_PROPERTY', 'WHOLESALER') && wholesalerNotes.length > 0 ? 'background-primary colour-white' : ''}`}
                onClick={() => submitNotes('Wholesaler')}
              >Submit Notes</button>
            </td>
          </tr>
          <tr>
            <td>{translations['BAA.CMOS_PAF']}</td>
            <td width='(100/4)%'>{summaryData['CMOS_PAF']}</td>
            <td width='(100/4)%'>{translations['BAA.Matched_PAF']}</td>
            <td width='(100/4)%'>{summaryData['Matched_PAF']}</td>
            <td width='(100/4)%'>{translations['BAA.Status_PAF']}</td>
            <td width='(100/4)%'>{summaryData['Status_PAF']}</td>
          </tr>
          <tr>
            <td>{translations['BAA.CMOS_Address_Single_Line']}</td>
            <td colSpan={'5'}>{summaryData['CMOS_Address_Single_Line']}</td>
          </tr>
          <tr>
            <td>{translations['BAA.CMOS_UPRN_Single_Line']}</td>
            <td colSpan={'5'}>{summaryData['CMOS_UPRN_Single_Line']}</td>
          </tr>
          <tr>
            <td>{translations['BAA.Matched_UPRN_Single_Line']}</td>
            <td colSpan={'5'}>{summaryData['Matched_UPRN_Single_Line']}</td>
          </tr>
          <tr>
            <td>{translations['BAA.CMOS_VOA_Single_Line']}</td>
            <td colSpan={'5'}>{summaryData['CMOS_VOA_Single_Line']}</td>
          </tr>
          <tr>
            <td>{translations['BAA.Matched_VOA_Single_Line']}</td>
            <td colSpan={'5'}>{summaryData['Matched_VOA_Single_Line']}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export const CMOSLive = ({ translations, summaryData: data }) => {
  const rows = useMemo(() => {
    return [
      [translations['BAA.CL_VOA_BA_Reference'], 'CL_VOA_BA_Reference'],
      [translations['BAA.CL_UPRN'], 'CL_UPRN'],
      [translations['BAA.CL_PAF_Address_Key'], 'CL_PAF_Address_Key'],
      [translations['BAA.CL_Free_Descriptor'], 'CL_Free_Descriptor'],
      [translations['BAA.CL_Secondary_Addressable_Object'], 'CL_Secondary_Addressable_Object'],
      [translations['BAA.CL_Primary_Addressable_Object'], 'CL_Primary_Addressable_Object'],
      [translations['BAA.CL_Address_Line_1'], 'CL_Address_Line_1'],
      [translations['BAA.CL_Address_Line_2'], 'CL_Address_Line_2'],
      [translations['BAA.CL_Address_Line_3'], 'CL_Address_Line_3'],
      [translations['BAA.CL_Address_Line_4'], 'CL_Address_Line_4'],
      [translations['BAA.CL_Address_Line_5'], 'CL_Address_Line_5'],
      [translations['BAA.CL_Postcode'], 'CL_Postcode'],
    ]
  }, [translations])
  return (
    <div>
      <GenericTable style={{height: '100%'}} biggerSecondRow={false} firstColWidth={150} title={translations['BAA.Header_CmosLive']} rows={rows} extraClass="squish smaller-text left" evidence={false} data={data} />
    </div>
  )
}

export const AddressBase = ({ translations, summaryData: data, selectedMatches, setSelectedMatches }) => {
  const [addresses, setAddresses] = useState([]);
  const [postcodeModal, setPostcodeModal] = useState(false);
  const [customPostcode, setCustomPostcode] = useState('');
  const [clearMatchModal, setClearMatchModal] = useState(false);
  const [index, setIndex] = useState(0);

  const cycleIndex = (change = 0) => {
    if (index + change <= -1) return
    if (index + change >= addresses.length) return
    setIndex(index + change)
  }

  const getData = (customInput) => {
    if (!customPostcode && (!data.Type || (!data.ABP_Postcode && data.Address_Status === 'Invalid Postcode'))) return;
    setAddresses([]);
    request(true)
      .get(endpoints.HOUSEHOLD_PROPERTY_GET_ADDRESSES, {
        params: {
          postcode: customInput || customPostcode || data.ABP_Postcode || data.CL_Postcode
        }
      })
      .then(e => {
        const _data = e.data;
        setAddresses(_data);
        if (data.ABP_UPRN) {
          const displayFirst = _data.findIndex(_ => _.UPRN === parseInt(data.Matched_UPRN))
          setIndex(displayFirst)
          setSelectedMatches((prevMatches) => {
            return {
              ...prevMatches,
              abp: parseInt(data.Matched_UPRN)
            };
          });
        }
      })
      .catch(_ => { window.alert('Could not get Address Base data.'); setAddresses([]); });
  };

  useEffect(() => {
    setAddresses([])
    setIndex(-1)
    if (data.SPID) {
      setIndex(-1)
      setCustomPostcode('');
      getData(data.ABP_Postcode || data.CL_Postcode)
    }
  }, [data.SPID]);

  useEffect(() => {
    if (customPostcode === '') {
      getData();
    }
  }, [customPostcode])

  const originalPostcode = useMemo(() => {
    return data.ABP_Postcode || data.CL_Postcode;
  }, [data.ABP_Postcode, data.CL_Postcode]);

  const selectMatch = useCallback(() => {
    if (!addresses[index]?.UPRN) return
    if (selectedMatches.abp === addresses[index].UPRN) {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          abp: null
        };
      });
    } else {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          abp: addresses[index].UPRN
        };
      });
    }
  }, [addresses, selectedMatches, setSelectedMatches, index])

  return (
    <div>
      <table className='table borders squish smaller-text left' style={{height: '100%'}}>
        <thead>
          <tr>
            <th style={{ textAlign: 'start' }} colSpan={2}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {translations['BAA.Header_AddressBase']}
                <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                  {customPostcode && (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <span className='fas fa-trash-undo' onClick={() => { setCustomPostcode(''); getData(); }} title="Restore original postcode search"></span>
                    </div>
                  )}
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <span className='fas fa-search' onClick={() => setPostcodeModal(true)} title="Search for a different postcode"></span>
                  </div>
                  {
                    addresses.length ? <>
                      <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <span className='fas fa-angle-left' onClick={() => cycleIndex(-1)}></span>
                        <span style={{minWidth: 60, textAlign: 'center'}}>{index + 1}/{addresses.length}</span>
                        <span className='fas fa-angle-right' onClick={() => cycleIndex(1)}></span>
                      </span>
                    </> : null
                  }
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{translations['BAA.UPRN']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.UPRN ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['BAA.STATUS']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.STATUS ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['BAA.UDPRN']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.UDPRN ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['BAA.ORGANISATION']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.ORGANISATION ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['BAA.SUB_BUILDING']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.SUB_BUILDING ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['BAA.BUILDING_NAME']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.BUILDING_NAME ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['BAA.BUILDING_NUMBER']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.BUILDING_NUMBER ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['BAA.STREET_NAME']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.STREET_NAME ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['BAA.LOCALITY']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.LOCALITY ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['BAA.TOWN_NAME']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.TOWN_NAME ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['BAA.POST_TOWN']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.POST_TOWN ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['BAA.Postcode']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.POSTCODE ?? '' }</td>
          </tr>
          <tr>
            <td style={{minWidth: '60px'}}><button className={`button compact smaller-text ${data.Matched_UPRN ? 'cancel colour-white' : ''}`} onClick={() => setClearMatchModal(data.Matched_UPRN ? true : false) }>Clear Match</button></td>
            <td style={{minWidth: '60px'}}><button className={`button compact smaller-text ${selectedMatches.abp && selectedMatches.abp === addresses[index]?.UPRN ? 'background-primary colour-white' : ''}`} onClick={() => selectMatch()}>Select Match</button></td>
          </tr>
        </tbody>
      </table>
      <ReactModal
        isOpen={postcodeModal}
        onRequestClose={() => setPostcodeModal(false)}
        className="card bulk-allocate"
        contentLabel="Bulk Allocate COT Alert Data"
        style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <h3>Postcode Search</h3>
          <input value={customPostcode ? customPostcode : originalPostcode} onChange={_ => setCustomPostcode(_.target.value.toUpperCase())} style={{ border: '1px solid gray', width: '100%', borderRadius: 3 }} />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <button className={`button compact smaller-text ${customPostcode?.length >= 3 ? 'background-primary colour-white' : ''}`} disabled={customPostcode?.length < 3} onClick={() => { getData(customPostcode); setPostcodeModal(false); }}>Search</button>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={clearMatchModal}
        onRequestClose={() => setClearMatchModal(false)}
        className="card bulk-allocate"
        contentLabel="Bulk Allocate COT Alert Data"
        style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <h3>Clear existing match</h3>
          <p>Please confirm you wish to clear the following matched UPRN for ABP on this SPID.</p>
          <ul className='spid-list'>
            <li>SPID: {data.SPID}</li>
            <li>ABP UPRN: {data.Matched_UPRN}</li>
          </ul>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
            <button className="button compact smaller-text cancel" onClick={ () => setClearMatchModal(false) }>Cancel</button>
            <button className="button compact smaller-text background-primary colour-white" onClick={() => {
              request(true).post(endpoints.HOUSEHOLD_PROPERTY_CLEAR_MATCH, {
                spid: data.SPID,
                matchType: 'abp'
              }).then(r => {
                window.reloadSummaryData()
                setClearMatchModal(false); 
              })
            }}
            >Confirm</button> 
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export const ValuationOffice = ({ translations, summaryData: data, selectedMatches, setSelectedMatches }) => {
  const [voas, setVoas] = useState([])
  const [postcodeModal, setPostcodeModal] = useState(false);
  const [customPostcode, setCustomPostcode] = useState('');
  const [clearMatchModal, setClearMatchModal] = useState(false);
  const [index, setIndex] = useState(0);

  const cycleIndex = (change = 0) => {
    if (index + change <= -1) return
    if (index + change >= voas.length) return
    setIndex(index + change)
  }

  const getData = (external) => {
    if (!customPostcode && (!data.Type || (!data.ABP_Postcode && data.Address_Status === 'Invalid Postcode'))) return
    setVoas([])
    request(true).get(data.Wholesaler === "SCOTTISH-W" ? endpoints.HOUSEHOLD_PROPERTY_GET_SAA : endpoints.HOUSEHOLD_PROPERTY_GET_VOAS, {
      params: {
        postcode: external || customPostcode || data.VO_Postcode || data.CL_Postcode
      }
    }).then(e => {
      const _data = e.data
      setVoas(_data);
      if (data.Matched_VOA_No) {
        const displayFirst = _data.findIndex(_ => _.BA_Reference_Number === data.Matched_VOA_No)
        setIndex(displayFirst);
        setSelectedMatches((prevMatches) => {
          return {
            ...prevMatches,
            voa: data.Matched_VOA_No
          };
        });
      }
    }).catch(() => {
      window.alert('Could not get Valuation Office data');
      setVoas([])
    })
  }

  useEffect(() => {
    setVoas([])
    setIndex(-1)
    if (data.SPID) {
      setIndex(-1)
      setCustomPostcode('')
      getData(data.ABP_Postcode || data.CL_Postcode)
    }
  }, [data.SPID])

  // const selectedVOA = useMemo(() => {
  //   const selected = voas.length > -1 ? voas[index] : null;
  //   if (!selected ) return {}
  //   selected.Source = data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? 'SAA' : 'Business Rates'
  //   selected.Deletion_Date = selected.Effective_Status === 'DELETED' ? selected.Current_From_Date : ''
  //   setPostcode(selected.Postcode)
  //   return selected
  // }, [voas, index, data, setPostcode]);

  useEffect(() => {
    if (customPostcode === '') {
      getData();
    }
  }, [customPostcode])

  const selectMatch = useCallback(() => {
    if (!voas[index]?.BA_Reference_Number) return
    if (selectedMatches.voa === voas[index].BA_Reference_Number) {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          voa: null
        };
      });
    } else {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          voa: voas[index].BA_Reference_Number
        };
      });
    }
  }, [voas, selectedMatches, setSelectedMatches, index])

  const originalPostcode = useMemo(() => {
    return data.VO_Postcode || data.CL_Postcode
  }, [data.ABP_Postcode, data.CL_Postcode])

  return [
    <table className='table borders squish smaller-text left' style={{height: '100%'}}>
      <thead>
        <tr>
          <th style={{ textAlign: 'start' }} colSpan={2}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {translations['BAA.Header_Valuation']}
              <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                {customPostcode && (
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <span className='fas fa-trash-undo' onClick={() => { setCustomPostcode(''); getData(); }} title="Restore original postcode search"></span>
                  </div>
                )}
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <span className='fas fa-search' onClick={() => setPostcodeModal(true)} title="Search for a different postcode"></span>
                </div>
                {
                  voas.length ? <>
                    <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <span className='fas fa-angle-left' onClick={() => cycleIndex(-1)}></span>
                      <span style={{minWidth: 60, textAlign: 'center'}}>{index + 1}/{voas.length}</span>
                      <span className='fas fa-angle-right' onClick={() => cycleIndex(1)}></span>
                    </span>
                  </> : null
                }
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{translations['BAA.BA_Reference_Number']}</td>
          <td style={{minWidth: '60px'}}>{ voas[index]?.BA_Reference_Number ?? '' }</td>
        </tr>
        <tr>
          <td>{translations['BAA.Effective_Status']}</td>
          <td style={{minWidth: '60px'}}>{ data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? voas[index]?.OCC_STATUS ?? '' : voas[index]?.Effective_Status ?? '' }</td>
        </tr>
        <tr>
          <td>{translations['BAA.Source']}</td>
          <td style={{minWidth: '60px'}}>{ voas[index]?.Source ?? '' }</td>
        </tr>
        <tr>
          <td>{translations['BAA.Number_Or_Name']}</td>
          <td style={{minWidth: '60px'}}>{ data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? voas[index]?.PAON ?? '' : voas[index]?.Number_Or_Name ?? '' }</td>
        </tr>
        <tr>
          <td>{translations['BAA.Street']}</td>
          <td style={{minWidth: '60px'}}>{ data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? voas[index]?.STREET ?? '' : voas[index]?.Street ?? '' }</td>
        </tr>
        <tr>
          <td>{translations['BAA.Sub_Street_level_1']}</td>
          <td style={{minWidth: '60px'}}>{ voas[index]?.Sub_Street_level_1 ?? '' }</td>
        </tr>
        <tr>
          <td>{translations['BAA.Town']}</td>
          <td style={{minWidth: '60px'}}>{ data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? voas[index]?.TOWN ?? '' : voas[index]?.Town ?? '' }</td>
        </tr>
        <tr>
          <td>{translations['BAA.Postal_District']}</td>
          <td style={{minWidth: '60px'}}>{ data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? voas[index]?.ADMIN_AREA ?? '' : voas[index]?.Postal_District ?? '' }</td>
        </tr>
        <tr>
          <td>{translations['BAA.County']}</td>
          <td style={{minWidth: '60px'}}>{ data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? voas[index]?.UNITARY_AUTHORITY ?? '' : voas[index]?.County ?? '' }</td>
        </tr>
        <tr>
          <td>{translations['BAA.Postcode']}</td>
          <td style={{minWidth: '60px'}}>{ data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? voas[index]?.BR_Postcode ?? '' : voas[index]?.Postcode ?? '' }</td>
        </tr>
        <tr>
          <td>{translations['BAA.Effective_Status']}</td>
          <td style={{minWidth: '60px'}}>{ data.Wholesaler === "SCOTTISH-W" || data.Wholesaler === "SW" ? voas[index]?.OCC_STATUS ?? '' : voas[index]?.Effective_Status ?? '' }</td>
        </tr>
        <tr>
          <td>{translations['BAA.Deletion_Date']}</td>
          <td style={{minWidth: '60px'}}>{ voas[index]?.Deletion_Date ?? '' }</td>
        </tr>
        <tr>
          <td style={{minWidth: '60px'}}><button className={`button compact smaller-text ${data.Matched_VOA_No ? 'cancel colour-white' : ''}`} onClick={() => setClearMatchModal(data.Matched_VOA_No ? true : false) }>Clear Match</button></td>
          <td style={{minWidth: '60px'}}><button className={`button compact smaller-text ${selectedMatches.voa && selectedMatches.voa === voas[index]?.BA_Reference_Number ? 'background-primary colour-white' : ''}`} onClick={() => selectMatch()}>Select Match</button></td>
        </tr>
      </tbody>
    </table>,
    <ReactModal 
    isOpen={postcodeModal} 
    onRequestClose={() => setPostcodeModal(false)}
    className="card bulk-allocate"
    contentLabel="Bulk Allocate COT Alert Data"
    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}>
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <h3>Postcode Search</h3>
        <input value={customPostcode} onChange={_ => setCustomPostcode(_.target.value)} style={{border: '1px solid gray', width: '100%', borderRadius: 3}} />
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <button className='button compact smaller-text background-primary' onClick={() => {getData(customPostcode || undefined); setPostcodeModal(false)}}>Search</button> 
        </div>
      </div>
    </ReactModal>,
    <ReactModal
      isOpen={clearMatchModal}
      onRequestClose={() => setClearMatchModal(false)}
      className="card bulk-allocate"
      contentLabel="Bulk Allocate COT Alert Data"
      style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <h3>Clear existing match</h3>
        <p>Please confirm you wish to clear the following matched Reference for VOA on this SPID.</p>
        <ul className='spid-list'>
          <li>SPID: {data.SPID}</li>
          <li>VOA Reference: {data.Matched_VOA_No}</li>
        </ul>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
          <button className="button compact smaller-text cancel" onClick={ () => setClearMatchModal(false) }>Cancel</button>
          <button className="button compact smaller-text background-primary colour-white" onClick={() => {
            request(true).post(endpoints.HOUSEHOLD_PROPERTY_CLEAR_MATCH, {
              spid: data.SPID,
              matchType: 'voa'
            }).then(r => {
              window.reloadSummaryData()
              setClearMatchModal(false); 
            })
          }}
          >Confirm</button> 
        </div>
      </div>
    </ReactModal>
  ]
}

export const CouncilTax = ({ translations, summaryData: data, selectedMatches, setSelectedMatches }) => {
  const [addresses, setAddresses] = useState([])
  const [postcodeModal, setPostcodeModal] = useState(false);
  const [customPostcode, setCustomPostcode] = useState('');
  const [clearMatchModal, setClearMatchModal] = useState(false);
  const [index, setIndex] = useState(0);

  const cycleIndex = (change = 0) => {
    if (index + change <= -1) return
    if (index + change >= addresses.length) return
    setIndex(index + change)
  }

  const getData = useCallback((customPostcode) => {
    if (!customPostcode && (!data.Type || (!data.ABP_Postcode && data.Address_Status === 'Invalid Postcode'))) return
    setAddresses([])
    request(true).get(endpoints.HOUSEHOLD_PROPERTY_GET_CT, {
      params: {
        postcode: customPostcode || data.ABP_Postcode || data.CL_Postcode
      }
    })
    .then(e => {
      const _data = e.data;
      setAddresses(_data)
      if (data.CT_Reference_Number) {
        const displayFirst = _data.findIndex(_ => _.au_reference_number === data.CT_Reference_Number) 
        setIndex(displayFirst);
        setSelectedMatches((prevMatches) => {
          return {
            ...prevMatches,
            ct: data.CT_Reference_Number
          };
        });
      }
    })
    .catch(_ => { window.alert('Could not get Council Tax data.'); setAddresses([]); })
  }, [data, customPostcode, setCustomPostcode]);

  useEffect(() => {
    setAddresses([])
    setIndex(-1)
    if (data.SPID) {
      setIndex(-1)
      setCustomPostcode('')
      getData(data.ABP_Postcode || data.CL_Postcode)
    }
  }, [data.SPID])

  const tableData = useMemo(() => {
    const selected = addresses.length > -1 ?  addresses[index] : null;
    if (!selected ) return {}
    selected.STATUS = selected.CHANGE_CODE === 'D' ? 'Deleted' : 'Active';
    // setCustomPostcode(selected.Postcode)
    if (selected.address) {
      const addressLines = parseAddress(selected.address)
      addressLines.forEach((line, index) => {
        selected['CT_Address_Line_' + (index + 1)] = line
      })
    }
    return selected
  }, [addresses, index]);

  const originalPostcode = useMemo(() => {
    return data.VO_Postcode || data.CL_Postcode
  }, [data.ABP_Postcode, data.CL_Postcode])

  const selectMatch = useCallback(() => {
    if (!addresses[index]?.au_reference_number) return
    if (selectedMatches.ct === addresses[index].au_reference_number) {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          ct: null
        };
      });
    } else {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          ct: addresses[index].au_reference_number  
        };
      });
    }
  }, [addresses, selectedMatches, setSelectedMatches, index])

  function parseAddress(address) {
    const addressLines = address.split(", ");

    return addressLines;
}

  const rows = useMemo(() => {
    return [
      [translations['BAA.CT_Reference'], 'au_reference_number'],
      [translations['BAA.CT_Band'], 'council_tax_band'],
      [translations['BAA.CT_Mixed_Use_Property'], 'mixed_use_property'],
      [translations['BAA.CF_Free_Descriptor'], 'CT_Free_Descriptor'],
      [translations['BAA.CF_Secondary_Addressable_Object'], 'CT_Secondary_Addressable_Object'],
      [translations['BAA.CF_Primary_Addressable_Object'], 'CT_Primary_Addressable_Object'],
      [translations['BAA.CF_Address_Line_1'], 'CT_Address_Line_1'],
      [translations['BAA.CF_Address_Line_2'], 'CT_Address_Line_2'],
      [translations['BAA.CF_Address_Line_3'], 'CT_Address_Line_3'],
      [translations['BAA.CF_Address_Line_4'], 'CT_Address_Line_4'],
      [translations['BAA.CT_Band'], 'council_tax_band'],
      [translations['BAA.CF_Postcode'], 'postcode'],
      ['', () => <button className={`button compact smaller-text ${selectedMatches.ct && selectedMatches.ct === addresses[index?.au_reference_number] ? 'background-primary colour-white' : ''}`} onClick={() => selectMatch()}>Select Match</button>]
    ]
  }, [translations, window.reloadSummaryData, index])
  return (
    <div>
      <table className='table borders squish smaller-text left' style={{height: '100%'}}>
        <thead>
          <tr>
            <th style={{ textAlign: 'start' }} colSpan={2}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {translations['BAA.Council_Tax']}
                <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                  {customPostcode && (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <span className='fas fa-trash-undo' onClick={() => { setCustomPostcode(''); getData(); }} title="Restore original postcode search"></span>
                    </div>
                  )}
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <span className='fas fa-search' onClick={() => setPostcodeModal(true)} title="Search for a different postcode"></span>
                  </div>
                  {
                    addresses.length ? <>
                      <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <span className='fas fa-angle-left' onClick={() => cycleIndex(-1)}></span>
                        <span style={{minWidth: 60, textAlign: 'center'}}>{index + 1}/{addresses.length}</span>
                        <span className='fas fa-angle-right' onClick={() => cycleIndex(1)}></span>
                      </span>
                    </> : null
                  }
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{translations['BAA.CT_Reference']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.au_reference_number ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['BAA.CT_Band']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.council_tax_band ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['BAA.CT_Mixed_Use_Property']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.mixed_use_property ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['BAA.CF_Free_Descriptor']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.CT_Free_Descriptor ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['BAA.CF_Secondary_Addressable_Object']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.CT_Secondary_Addressable_Object ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['BAA.CF_Primary_Addressable_Object']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.CT_Primary_Addressable_Object ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['BAA.CF_Address_Line_1']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.CT_Address_Line_1 ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['BAA.CF_Address_Line_2']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.CT_Address_Line_2 ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['BAA.CF_Address_Line_3']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.CT_Address_Line_3 ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['BAA.CF_Address_Line_4']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.CT_Address_Line_4 ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['BAA.CT_Band']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.council_tax_band ?? '' }</td>
          </tr>
          <tr>
            <td>{translations['BAA.CF_Postcode']}</td>
            <td style={{minWidth: '60px'}}>{ addresses[index]?.postcode ?? '' }</td>
          </tr>
          <tr>
            <td style={{minWidth: '60px'}}><button className={`button compact smaller-text ${data.CT_Reference_Number ? 'cancel colour-white' : ''}`} onClick={() => setClearMatchModal(data.CT_Reference_Number ? true : false) }>Clear Match</button></td>
            <td style={{minWidth: '60px'}}><button className={`button compact smaller-text ${selectedMatches.ct && selectedMatches.ct === addresses[index]?.au_reference_number ? 'background-primary colour-white' : ''}`} onClick={() => selectMatch()}>Select Match</button></td>
          </tr>
        </tbody>
      </table>
      <ReactModal 
      isOpen={postcodeModal} 
      onRequestClose={() => setPostcodeModal(false)}
      className="card bulk-allocate"
      contentLabel="Bulk Allocate COT Alert Data"
      style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}>
        <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
          <h3>Postcode Search</h3>
          <input value={customPostcode} onChange={_ => setCustomPostcode(_.target.value)} style={{border: '1px solid gray', width: '100%', borderRadius: 3}} />
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            <button className='button compact smaller-text background-primary' onClick={() => {getData(customPostcode || undefined); setPostcodeModal(false)}}>Search</button> 
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={clearMatchModal}
        onRequestClose={() => setClearMatchModal(false)}
        className="card bulk-allocate"
        contentLabel="Bulk Allocate COT Alert Data"
        style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <h3>Clear existing match</h3>
          <p>Please confirm you wish to clear the following matched Reference for Council Tax on this SPID.</p>
          <ul className='spid-list'>
            <li>SPID: {data.SPID}</li>
            <li>CT Reference: {data.CT_Reference_Number}</li>
          </ul>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
            <button className="button compact smaller-text cancel" onClick={ () => setClearMatchModal(false) }>Cancel</button>
            <button className="button compact smaller-text background-primary colour-white" onClick={() => {
              request(true).post(endpoints.HOUSEHOLD_PROPERTY_CLEAR_MATCH, {
                spid: data.SPID,
                matchType: 'ct'
              }).then(r => {
                window.reloadSummaryData()
                setClearMatchModal(false); 
              })
            }}
            >Confirm</button> 
          </div>
        </div>
      </ReactModal>
    </div>
  )
}

export const CMOSFormatted = ({ translations, summaryData, setSummaryData, selectedMatches, workflow }) => {
  const updateAddress = () => {
    request(true).post(endpoints.HOUSEHOLD_PROPERTY_SELECT_MATCH, {
      spid: summaryData.SPID,
      ba_reference: selectedMatches.voa ?? '',
      abp_uprn: selectedMatches.abp ?? '',
      ct_reference: selectedMatches.ct ?? ''
    }).then(r => {
      window.reloadSummaryData()
    })
  }
  const rows = useMemo(() => {
    return [
      [translations['BAA.CF_VOA_BA_Reference'], 'CF_VOA_BA_Reference'],
      [translations['BAA.CF_UPRN'], 'ABP_UPRN'],
      [translations['BAA.CF_PAF_Address_Key'], 'CF_PAF_Address_Key'],
      [translations['BAA.CT_Reference'], 'CT_Reference_Number'],
      [translations['BAA.CF_Secondary_Addressable_Object'], 'CF_Secondary_Addressable_Object'],
      [translations['BAA.CF_Primary_Addressable_Object'], 'CF_Primary_Addressable_Object'],
      [translations['BAA.CF_Address_Line_1'], 'CF_Address_Line_1'],
      [translations['BAA.CF_Address_Line_2'], 'CF_Address_Line_2'],
      [translations['BAA.CF_Address_Line_3'], 'CF_Address_Line_3'],
      [translations['BAA.CF_Address_Line_4'], 'CF_Address_Line_4'],
      [translations['BAA.CT_Band'], 'Council_Tax_Band'],
      [translations['BAA.CF_Postcode'], 'CF_Postcode'],
      ['', ({summaryData}) => <button 
        // className='button compact smaller-text' 
        className={`button compact smaller-text ${selectedMatches.abp || selectedMatches.voa || selectedMatches.ct ? 'background-primary colour-white' : ''}`} 
        disabled={(!selectedMatches.abp && !selectedMatches.voa && !selectedMatches.ct)}
        onClick={() => updateAddress()}>Update Address</button>]
    ]
  }, [translations, updateAddress])
  return (
    <GenericTable biggerSecondRow={false} firstColWidth={150} title={translations['BAA.Header_CmosFormatted']} rows={rows} extraClass="squish smaller-text left" evidence={false} data={summaryData} />
  )
}