import React, { Component, useEffect } from 'react'
import Navigation from '../../../components/navigation/navigation'
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import Container from '../../../components/container/container'
import Footer from '../../../components/footer/footer'
import request from '../../../helpers/request'
import hasPermission from '../../../helpers/permissions';
import endpoints from '../../../helpers/endpoints'
import { view } from '@risingstack/react-easy-state'
import './occupierTelephoneValidation.scss';

import { Link, withRouter } from 'react-router-dom'
import session from '../../../stores/session'
import HelpVideo from '../../../components/help-video/helpVideo'
import useWSProduct from '../../../helpers/useWSProduct'

// import {DeedPDFViewer} from './voidSalesAlertsSearch'
import {GoogleEvidence,AddressEvidence,YellComEvidence,CompaniesHouseEvidence,RightColumn, LeftColumn, FoodStandardsEvidence, BTDirectoryEvidence, CharityCommisionEvidence, DVSAEvidence, CQCEvidence, JustEatEvidence, One92Evidence, FacebookEvidence, TitleOwnerRow, MDS} from './sharedComponents'
import ProductIcons from '../../../helpers/productIcons'
import ReactModal from 'react-modal'
import PropertyMap from '../../../components/property-map/property-map'
import PageTitle from '../../../components/page-header/pageTitle'
import Twilio from '../../../components/twilio/twilio'

export default view(
    withRouter(
        class OccupierTelephoneValidationWorkflow extends Component {
            constructor(props) {
                super(props)

                this.state = {
                    accountNumber: '',
                    customer: null,
                    message: '',
                    showMap: true,
                    purchasable: null,
                    shouldRefreshMap: false,
                    workflow: [],
                    workflowIndex: null,
                    workflowLength: 0,
                    disableActions: false,
                    translations: {},
                    exclusionReason: 'null',
                    exclusionFeedback: '',
                    workflowModalIsOpen: 'null',
                    confirmationModalIsOpen: false,
                    customerNumber: null,
                    twilioHeight: '500',
                    statusFilter: "None",
                    MDSData: null,
                    MDSDataReady: true,
                    twillioHidden: false,
                    customMDSPostcode: ''
                }
            }

            componentDidMount() {
                const div = document.getElementById('otv-workflow');
                this.setState({twilioHeight: div ? div.clientHeight : null})
                useWSProduct('void_sales_alerts');// eslint-disable-line
                request(true).get('/translate?key=otv.').then(r => {
                    this.setState({
                        translations: r.data
                    });
                }).catch(e => {
                    console.error(e);
                })
                this.getWorkflow();
            }

            getWorkflow = () => {
                request(true).get(endpoints.OCCUPIER_TELEPHONE_VALIDATION_WORKFLOW + "?statusFilter=" + this.state.statusFilter).then(r => {
                    this.setState({
                        workflow: r.data,
                        workflowIndex: 0,
                        workflowLength: r.data.length,
                        accountNumber: r.data[0],
                    }, () => {
                        this.getData();
                    });
                }).catch(e => {
                    console.error(e);
                });
            }

            clearCompanyData = () => {
                request(true).post(endpoints.OCCUPIER_TELEPHONE_VALIDATION_CLEAR_COMPANY_DATA, {
                    customer: this.state.customer.SPID
                }).then(r => {
                    console.log(r.data.customer);
                    if (r.data.customer && r.data.customer.correctAddress == null) { r.data.customer.correctAddress = r.data.customer.AddressBase_Address}
                    this.setState({
                        customer: r.data.customer,
                        message: r.data.customer ? '' : 'Customer Does Not Exist',
                        showMap: true,
                        purchasable: r.data.customer && r.data.customer.Date_Bought === null,
                        shouldRefreshMap: true
                    }, () => {
                        this.setState({
                            shouldRefreshMap: false,
                            disableActions: false,
                        });
                    });
                }).catch(e => {
                    console.error(e);
                });
            }

            setCustomerNumber = (customerNumber) => {
                this.setState({
                    customerNumber: customerNumber,
                })
            }

            accountNumberChange = (event) => {
                this.setState({
                    accountNumber: event.target.value
                });
            }

            componentWillUnmount() {
                useWSProduct('void_sales_alerts', 'unlisten');// eslint-disable-line
                clearInterval(this.tokenRefresh);
            }

            getData = () => {
                this.setState({customerNumber: null, MDSData: null, MDSDataReady: false})
                request(true).get(`${endpoints.OCCUPIER_TELEPHONE_VALIDATION_GET_CUSTOMER}?search=${this.state.accountNumber}`).then(r => {
                    console.log(r.data.customer);
                    if (r.data.customer && r.data.customer.correctAddress == null) { r.data.customer.correctAddress = r.data.customer.AddressBase_Address}
                    this.setState({
                        customer: r.data.customer,
                        message: r.data.customer ? '' : 'Customer Does Not Exist',
                        showMap: true,
                        purchasable: r.data.customer && r.data.customer.Date_Bought === null,
                        shouldRefreshMap: true
                    }, () => {
                        this.setState({
                            shouldRefreshMap: false,
                            disableActions: false,
                        });
                    });
                    this.getMDSData(r);
                }).catch(e => {
                    //console.log(e);
                });
            }

            getMDSData = (r) => {
                let postcode
                if (r && r.data.customer) {
                    postcode = r.data.customer.AddressBase_Address.match(/[A-Za-z0-9]{1,4}\s[A-Za-z0-9]{1,4}$/)[0]
                } else if (this.state.customMDSPostcode) {
                    postcode = this.state.customMDSPostcode
                } else {
                    return
                }
                request(true).get(endpoints.OCCUPIER_TELEPHONE_VALIDATION_MDS_SPID, {
                    params: {
                        postcode: postcode
                    }
                }).then(r => {
                    this.setState({MDSData: r.data, MDSDataReady: true})
                }).catch(e => {
                    console.log(e);
                })
            }

            purchaseCustomer = (occupierPurchaseType) => {
                this.setState({disableActions: true}, () => {
                    request(true).post(`${endpoints.OCCUPIER_TELEPHONE_VALIDATION_BUY_CUSTOMER}`, {
                        "customer": this.state.customer.SPID,
                        "occupier_purchase_type": occupierPurchaseType,
                    }).then(r => {
                        const customers = [].concat(this.state.workflow);
                        customers.splice(this.state.workflowIndex, 1);

                        this.setState({
                            workflow: customers,
                            workflowIndex: this.state.workflowIndex - 1,
                            workflowLength: customers.length,
                            customer: r.data.customer,
                            message: r.data.customer ? '' : 'Customer Does Not Exist',
                            showMap: true,
                            purchasable: r.data.customer.Date_Bought === null
                        });
                        request(true).get(endpoints.OCCUPIER_TELEPHONE_VALIDATION_WORKFLOW).then(r => {
                            this.setState({
                                workflow: r.data,
                                workflowIndex: 0,
                                workflowLength: r.data.length,
                                accountNumber: r.data[0],
                                workflowModalIsOpen: 'null',
                            }, () => {
                                this.getData();
                            });
                        }).catch(e => {
                            console.error(e);
                        });
                    }).catch(e => {
                        console.log(e);
                    })
                });
            }

            openWorkflowBuyModal = () => {
                this.setState({
                    workflowModalIsOpen: 'true'
                })
            }

            excludeCustomer = () => {
                if (!this.state.customer || !this.state.customer.SPID) return;
                this.setState({disableActions: true}, () => {
                    request(true).post(endpoints.OCCUPIER_TELEPHONE_VALIDATION_EXCLUDE_CUSTOMER, {
                        customer: this.state.customer.SPID,
                        Occupier_Exclusion_Reason: this.state.exclusionReason,
                        feedback: this.state.exclusionFeedback,
                    }).then(r => {
                        const customers = [].concat(this.state.workflow);
                        customers.splice(this.state.workflowIndex, 1);

                        this.setState({
                            workflow: customers,
                            workflowLength: customers.length,
                            accountNumber: customers[this.state.workflowIndex],
                            exclusionReason: 'null'
                        }, () => {
                            this.getData();
                        });
                    }).catch(e => {
                        console.error(e);
                    });
                });
            }

            workflowNext = () => {
                if (this.state.workflowIndex + 1 === this.state.workflow.length) {
                    this.setState({
                        workflowIndex: 0,
                        accountNumber: this.state.workflow[0]
                    }, () => {
                        this.getData();
                    });    
                } else {
                    this.setState({
                        workflowIndex: this.state.workflowIndex + 1,
                        accountNumber: this.state.workflow[this.state.workflowIndex + 1]
                    }, () => {
                        this.getData();
                    });
                }
            }

            workflowPrev = () => {
                if (this.state.workflowIndex === 0) {
                    this.setState({
                        workflowIndex: this.state.workflow.length - 1,
                        accountNumber: this.state.workflow[this.state.workflow.length - 1]
                    }, () => {
                        this.getData();
                    });    
                } else {
                    this.setState({
                        workflowIndex: this.state.workflowIndex - 1,
                        accountNumber: this.state.workflow[this.state.workflowIndex - 1]
                    }, () => {
                        this.getData();
                    });
                }
            }

            updateStatus = (event) => {
                if (!this.state.accountNumber) return
                this.setState({
                    customer: {
                        ...this.state.customer,
                        status: event.target.value
                    }
                }, () => {
                    request(true).post(`${endpoints.OCCUPIER_TELEPHONE_VALIDATION_UPDATE_STATUS}`, {
                        customer: this.state.customer.SPID,
                        status: this.state.customer.status
                    }).catch(e => {
                        console.log(e);
                    })
                })
            }

            setStatusFilter = (event) => {
                this.setState({statusFilter: event.target.value}, () => {
                    this.getWorkflow();
                })
            }

            updateCustomerNumber = (number) => {
                this.setState({customerNumber: number})
            }

            updateCustomer = (customer) => {
                this.setState({customer: customer})
            }

            updateCustomerProperty = (event, property) => {
                this.setState({customer: { ...this.state.customer, [property]: event.target.value}})
            }

            render() {
                return (
                    <div className="grid grid-gap-15" id="void-sales-alerts-page">
                        <Navigation/>
                        <Container width={"97.5vw"}>
                            <PageTitle title="Occupier Telephone Validation - Workflow" iconClass={ProductIcons.BusinessIcon.props.className} titleRight={
                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    { (this.state.customer?.SPID && hasPermission('OCCUPIER_TELEPHONE_VALIDATION', 'PURCHASE')) ?
                                        <button className="navigate" onClick={() => this.setState({confirmationModalIsOpen: true})}>
                                            Clear Data
                                        </button>
                                    : null }
                                    <Link className="navigate" to="/portal/occupier-telephone-validation/">
                                        To Overview
                                    </Link>
                                    <Link className="navigate" to="/portal/occupier-telephone-validation/search">
                                        To Search
                                    </Link>
                                    { (hasPermission('OCCUPIER_TELEPHONE_VALIDATION', 'DASHBOARD')) ?
                                        <Link className="navigate" to="/portal/occupier-telephone-validation/dashboard">
                                            To Dashboard
                                        </Link>
                                    : null }
                                </div>
                            } />
                        </Container>
                        <Container width={"97.5vw"}>
                            <div style={{gridTemplateColumns: this.state.twillioHidden ? '1fr min-content' : '4fr 1fr'}} className="vacantCOTAlerts">
                                <div id='otv-workflow' style={{display: 'grid', gridGap: '15px'}}>
                                    <div className="primary-grid">
                                        <div className="left-grid">
                                            <div className="data-row with-buttons">
                                                <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['otv.spid'] : '' }
                                                </div>
                                                <div style={{ position: 'relative' }}>
                                                    <input disabled={true} type="text" className="data-row-field" name="account-number" value={ this.state.accountNumber } style={{ width: 'calc(100% - 15px)', height: '100%' }}/>
                                                    <span className="message">{ this.state.workflowIndex + 1 }/{ this.state.workflowLength }</span>
                                                </div>
                                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridGap: '15px', marginLeft: '15px', width: 'min-content' }}>
                                                    <button className="button background-primary colour-white" onClick={ this.workflowPrev }>
                                                        <i className="fas fa-angle-left"></i>
                                                    </button>
                                                    <button className="button background-primary colour-white" onClick={ this.workflowNext }>
                                                        <i className="fas fa-angle-right"></i>
                                                    </button>
                                                    <select onChange={(event) => this.setStatusFilter(event)} style={{border: '1px solid var(--primary)', borderRadius: 5, width: 'min-content'}}>
                                                        <option value="None">Status Filter</option>
                                                        <option value="New">New</option>
                                                        <option value="In progress">In progress</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <LeftColumn state={this.state} setCustomerNumber={this.setCustomerNumber} updateCustomerProperty={this.updateCustomerProperty} />
                                        </div>
                                        <RightColumn state={this.state}>
                                        { (!hasPermission('OCCUPIER_TELEPHONE_VALIDATION', 'PURCHASE') && this.state.purchasable) ?
                                                <button className="buy-button disabled" disabled={true}>
                                                    Occupier Moved In
                                                </button>
                                            :
                                                <button className="buy-button" disabled={ !this.state.purchasable || this.state.disableActions } onClick={ this.openWorkflowBuyModal }>
                                                    {
                                                        this.state.customer && this.state.customer.Purchase_Type === 'VaCA_ACCOUNT_PURCHASED' ? <>
                                                            {
                                                                this.state.customer.Bought_By ?
                                                                    `Moved in on ${this.state.customer.Date_Bought} by ${this.state.customer.Bought_By}`
                                                                :
                                                                    this.state.customer.Date_Bought ?
                                                                        `Move in on ${this.state.customer.Date_Bought}` : ''
                                                            }
                                                        </> : this.state.customer && this.state.customer.Purchase_Type === 'VaCA_ACCOUNT_PURCHASED' ? <>
                                                            Excluded
                                                        </> : <>
                                                            {
                                                                this.state.purchasable === true || this.state.purchasable === null ? "Occupier Moved In" : 'Unavailable for Move in'
                                                            }
                                                        </>
                                                    }
                                                    
                                                </button>
                                            }
                                            {/* <button className="buy-button" onClick={this.excludeCustomer} disabled={ this.state.disableActions }>
                                                Occupier Excluded
                                            </button> */}
                                            <select style={{ height: 28, marginTop: 7, borderRadius: 5, backgroundColor: 'var(--secondary)', color: 'white', fontWeight: 'bold' }} onChange={_ => this.setState({exclusionReason: _.target.value})} value={this.state.exclusionReason}>
                                                <option value={'null'}>Excluded</option>
                                                <option value={'No Answer'}>No Answer</option>
                                                <option value={'Refusal to confirm details'}>Refusal to confirm details</option>
                                                <option value={'No Telephone Number'}>No Telephone Number</option>
                                                <option value={'Potentially Vacant'}>Potentially Vacant</option>
                                                <option value={'Address DQI'}>Address DQI</option>
                                                <option value={'Previous Occupier'}>Previous Occupier</option>
                                                <option value={'Adjacent Occupier'}>Adjacent Occupier</option>
                                                <option value={'Insufficient Information'}>Insufficient Information</option>
                                                <option value={'Other'}>Other</option>
                                            </select>
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    Status
                                                </div>
                                                <select style={{height: 28, border: '1px solid var(--primary)', borderRadius: 5}} onChange={this.updateStatus} value={this.state.customer?.status ?? "New"}>
                                                    <option value="New">New</option>
                                                    <option value="In progress">In progress</option>
                                                </select>
                                            </div>
                                        </RightColumn>
                                        <TitleOwnerRow state={this.state} updateCustomerProperty={this.updateCustomerProperty} />

                                    </div>
                                    <div className="secondary-grid">
                                        <div style={{display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%', marginBottom: 5}}>
                                            <GoogleEvidence data={this.state.customer} setCustomerNumber={this.setCustomerNumber}  updateCustomerProperty={this.updateCustomerProperty}  />
                                            <CompaniesHouseEvidence data={this.state.customer} setCustomerNumber={this.setCustomerNumber}  updateCustomerProperty={this.updateCustomerProperty}  />
                                            <YellComEvidence data={this.state.customer} setCustomerNumber={this.setCustomerNumber}  updateCustomerProperty={this.updateCustomerProperty}  />
                                            <AddressEvidence data={this.state.customer} setCustomerNumber={this.setCustomerNumber}  updateCustomerProperty={this.updateCustomerProperty}  />
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%'}}>
                                            <FoodStandardsEvidence data={this.state.customer} setCustomerNumber={this.setCustomerNumber}  updateCustomerProperty={this.updateCustomerProperty}  />
                                            <BTDirectoryEvidence data={this.state.customer} setCustomerNumber={this.setCustomerNumber}  updateCustomerProperty={this.updateCustomerProperty}  />
                                            <CharityCommisionEvidence data={this.state.customer} setCustomerNumber={this.setCustomerNumber}  updateCustomerProperty={this.updateCustomerProperty}  />
                                            <DVSAEvidence data={this.state.customer} setCustomerNumber={this.setCustomerNumber}  updateCustomerProperty={this.updateCustomerProperty}  />
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%'}}>
                                            <CQCEvidence data={this.state.customer} setCustomerNumber={this.setCustomerNumber}  updateCustomerProperty={this.updateCustomerProperty}  />
                                            <JustEatEvidence data={this.state.customer} setCustomerNumber={this.setCustomerNumber}  updateCustomerProperty={this.updateCustomerProperty}  />
                                            <One92Evidence data={this.state.customer} setCustomerNumber={this.setCustomerNumber}  updateCustomerProperty={this.updateCustomerProperty}  />
                                            <FacebookEvidence data={this.state.customer} setCustomerNumber={this.setCustomerNumber}  updateCustomerProperty={this.updateCustomerProperty}  />
                                        </div>
                                        <div style={{height: 'unset'}}>
                                        <MDS data={this.state.MDSData} MDSDataReady={this.state.MDSDataReady} customPostcode={this.state.customMDSPostcode} setCustomPostcode={(postcode) => this.setState({customMDSPostcode: postcode})} getData={() => this.getMDSData()} />
                                        </div>
                                    </div>
                                </div>
                                <Twilio 
                                    module="occupier_telephone_validation" 
                                    accountNumber={this.state.accountNumber} 
                                    customer={this.state.customer} 
                                    updateCustomer={this.updateCustomer} 
                                    customerNumber={this.state.customerNumber} 
                                    updateCustomerNumber={this.updateCustomerNumber} 
                                    height={this.state.twilioHeight} 
                                    surveyEndpoint={endpoints.OCCUPIER_TELEPHONE_VALIDATION_SUBMIT_ANSWERS} 
                                    twillioHidden={this.state.twillioHidden}
                                    setTwillioHidden={(mode) => this.setState({twillioHidden: mode})}
                                />
                            </div>
                        </Container>
                        <HelpVideo video={session.company.vacant_workflow_video_url}/>
                        <ReactModal 
                        className="card bulk-buy"
                        isOpen={this.state.exclusionReason !== 'null'}>
                            <p>You have selected the following exclusion reason:</p>
                            <p><strong>{this.state.exclusionReason}</strong></p>
                            <div style={{ display: 'grid', marginBottom: '1em' }}>
                                <p>Additional Feedback:</p>
                                <textarea onChange={(event) => this.setState({exclusionFeedback: event.target.value.slice(0, 500)})} value={this.state.exclusionFeedback ?? ''} rows={5} style={{ resize: 'none' }}/>
                                <div style={{ marginLeft: 'auto', color: 'gray', fontSize: 12 }}>
                                {this.state.exclusionFeedback?.length ?? 0}/500
                                </div>
                            </div>
                            <div className='grid grid-columns-2 grid-gap-5'>
                                <button className='button compact background-red colour-white' onClick={() => this.setState({exclusionReason: 'null'})}>Cancel</button>
                                <button className='button compact background-primary colour-white' onClick={() => this.excludeCustomer()}>Confirm</button>
                            </div>
                        </ReactModal>
                        <ReactModal
                        className="card bulk-buy"
                        isOpen={this.state.workflowModalIsOpen !== 'null'}>
                            <p>You have selected to move in an occupier </p>
                            <p>Select either Cancel, IDenteq for identified name or Other for an other name </p>

                            <div className='grid grid-columns-3 grid-gap-5'>
                                <button className='button compact background-red colour-white' onClick={() => this.setState({workflowModalIsOpen: 'null'})}>Cancel</button>
                                <button className='button compact background-primary colour-white' onClick={() => this.purchaseCustomer('IDenteq')}>IDenteq</button>
                                <button className='button compact background-primary colour-white' onClick={() => this.purchaseCustomer('Other')}>Other</button>
                            </div>
                        </ReactModal>
                        <ReactModal
                        className="card bulk-buy"
                        isOpen={this.state.confirmationModalIsOpen}>
                            <h3 style={{textAlign: 'center'}}>Clear data</h3>
                            <p>Are you sure you wish to clear all company data?</p>
                            <p>This will clear everything bar the ABP data, and uneditable data on the right of the screen.</p>

                            <div className='grid grid-columns-2 grid-gap-5'>
                                <button className='button compact background-red colour-white' onClick={() => this.setState({confirmationModalIsOpen: false})}>No</button>
                                <button className='button compact background-primary colour-white' onClick={() => {
                                    this.clearCompanyData() 
                                    this.setState({confirmationModalIsOpen: false})
                                }}>Yes</button>
                            </div>
                        </ReactModal>
                        <Footer width={"97.5vw"} />
                    </div>
                )
            }
        }
    )
)

// function Map({ shouldRefreshMap, ...props }) {
//     const [center, setCenter] = React.useState({});
//     const [loaded, setLoaded] = React.useState(false);
//     const [zoom, setZoom] = React.useState(0);
//     const [refreshMap, setRefreshMap] = React.useState(false);
//     const [showMap, setShowMap] = React.useState(true);
//     const { isLoaded, loadError } = useLoadScript({
//         googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY
//     });

//     const onLoad = React.useCallback(map => {
//         setLoaded(true);
//         //console.log(process.env.REACT_APP_MAPS_API_KEY);
//     }, []);

//     useEffect(() => {
//         if (loaded) {
//             const geocoder = new window.google.maps.Geocoder();

//             geocoder.geocode({address: props.address}, (res, status) => {
//                 if (status === "OK") {
//                     setCenter(res[0].geometry.location);
//                     setZoom(18);
//                 }
//             });
//         }
//     }, [props.address, loaded])

//     useEffect(() => {
//         if (shouldRefreshMap !== refreshMap) {
//             setRefreshMap(shouldRefreshMap);
//             setShowMap(!shouldRefreshMap);
//         }
//     }, [shouldRefreshMap, refreshMap]);

//     return (
//         showMap && isLoaded && !loadError ? <>
//             <GoogleMap
//                 mapContainerClassName="map"
//                 onLoad={ onLoad }
//                 zoom={ zoom }
//                 center={ center }
//             >
//                 <Marker position={ center }/>
//             </GoogleMap>
//         </> : loadError ? <div>Error loading map.</div> : null
//     )
// }
