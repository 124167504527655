import React from 'react'
import session from '../../stores/session'
import { useHistory } from 'react-router-dom'
import request from '../../helpers/request';
import endpoints from '../../helpers/endpoints';

export default function PageTitle({
  title,
  titleRight,
  iconClass = 'colour-primary fak fa-id-house-icon fa-2x'
}) {
  const history = useHistory();
  return (
    <div className="flex middle">
      <span className="fa-lg fa-stack">
          <i className={iconClass}></i>
      </span>
      <div
          className="colour-secondary font-weight-600"
          style={{ fontSize: 20, width: '100%', display: 'flex', justifyContent: 'space-between' }}
      >
          <div style={{alignSelf: 'center'}}>
            {title}
            {/* { history.location.pathname ?
              <i onClick={() => request(true).post(endpoints.BOOKMARKS, {'location': history.location.pathname}).then((r) => { session.fetch() })} 
                class={ session.user.bookmarks.some(bookmark => bookmark.url == history.location.pathname.replace(/\/$/, '')) ? "fa-solid fa-bookmark bookmark-button" : "fa-regular fa-bookmark bookmark-button"}>
              </i>
            :
              null
            } */}
          </div>
          {titleRight}
      </div>
    </div>
  )
}
