import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import Page from '../../../components/page/page'
import ProductDataFilter from '../../../components/product-data-filter/product-data-filter'
import S3Modal from '../../../components/s3Modal/S3Modal'
import endpoints from '../../../helpers/endpoints'
import request from '../../../helpers/request'
import hasPermission from '../../../helpers/permissions';
import session from '../../../stores/session'
import ProductChartInfo from '../../../components/product-chart-info/product-info-chart'

import './cotValidation.scss';

export function CotValidationTitle({
  showS3 = true,
  showOverviewLink = true,
  showReportLink = true,
  showManualReviewLink = true,
  showAllocate = false,
  setModalMode,
}) {
  return (
    <div style={{display: 'flex'}}>
      {
        showAllocate && hasPermission('COT_VALIDATION', 'DOWNLOAD') ?
          <button style={{backgroundColor: '#445468'}} className="navigate" onClick={() => setModalMode('allocate')}>Allocate</button>
        : null  
      }
      {
        showOverviewLink ? <>
          <Link className="navigate" to="/portal/cleanse-validation">To Overview</Link>
        </> : null
      }
      {
        showS3 ? <>
        { (hasPermission('COT_VALIDATION', 'DOWNLOAD')) ?
          <button className="navigate" onClick={() => setModalMode('download')}>Download Return File</button>
        : null }
        { (hasPermission('COT_VALIDATION', 'UPLOAD')) ?
          <button className="navigate" onClick={() => setModalMode('upload')}>Issue Input File</button>
        : null }
        </> : null
      }
      {
        !showOverviewLink ? <>
          <Link className="navigate" to="/portal/cleanse-validation/search">To Search</Link>
        </> : null
      }
      {
      showReportLink ? <>
          <Link className="navigate" to="/portal/cleanse-validation/report">To Report</Link>
        </> : null
      }
      {
      showManualReviewLink && hasPermission('COT_VALIDATION', 'MANUAL-REVIEW') ? <>
          <Link className="navigate" to="/portal/cleanse-validation/manual-review">To Manual Review</Link>
        </> : null
      }
      {/* <Link className="navigate" to="/portal/cleanse-validation/workflow">To Workflow</Link> */}
    </div>
  )
}

function CotValidationValidated({ data, validated }) {
  const columns = ['Month', '', '', 'Full_Match'];
  return (
    <div>
      <h2 style={{display: 'flex', justifyContent: 'space-between'}}>Validated - Customer <ProductChartInfo path={window.location.pathname} style={{margin: 0, justifyContent: 'space-between', cursor: 'pointer', color: 'var(--primary)', paddingLeft: '15px'}} /></h2>
      <table className='table smaller-text squish'>
        <thead>
          <tr>
            <th colSpan={5} style={{backgroundColor: '#F7FCFF', borderRadius: 0}}></th>
            <th colSpan={3} style={{borderTopLeftRadius: 5}}>Forename</th>
            <th colSpan={3} style={{borderLeft: '1px solid white'}}>Surname</th>
            <th colSpan={3} style={{borderTopRightRadius: 5, borderLeft: '1px solid white'}}>DOB</th>
          </tr>
          <tr>
            <th width={60}>Month</th>
            <th>Volume</th>
            <th>Validated</th>
            <th>%&nbsp;Validated</th>
            <th>Full Match</th>
            <th style={{borderLeft: '1px solid white'}}>Match</th>
            <th>Change</th>
            <th>Append</th>
            <th style={{borderLeft: '1px solid white'}}>Match</th>
            <th>Change</th>
            <th>Append</th>
            <th style={{borderLeft: '1px solid white'}}>Match</th>
            <th>Change</th>
            <th style={{borderRadius: 0}}>Append</th>
          </tr>
        </thead>
        <tbody>
          {
            data.map(row => {
              return (
                <tr>
                  <td>{row.Month}</td>
                  <td>{row.Volume}</td>
                  <td>{row.Validated_Count}</td>
                  <td>{(row.Validated_Percent * 100).toFixed(0)}%</td>
                  <td>{row.Full_Match}</td>
                  <td>{row.Forename_Match}</td>
                  <td>{row.Forename_Change}</td>
                  <td>{row.Forename_Append}</td>
                  <td>{row.Surname_Match}</td>
                  <td>{row.Surname_Change}</td>
                  <td>{row.Surname_Append}</td>
                  <td>{row.DOB_Match}</td>
                  <td>{row.DOB_Change}</td>
                  <td>{row.DOB_Append}</td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
}

function CotValidationValidatedContact({ data, validated }) {
  const columns = ['Month', '', '', 'Full_Match'];
  return (
    <div>
      <h2>Validated - Contact Details</h2>
      <table className='table smaller-text squish'>
        <thead>
          <tr>
            <th colSpan={5} style={{backgroundColor: '#F7FCFF', borderRadius: 0}}></th>
            <th colSpan={3} style={{borderTopLeftRadius: 5}}>Landline</th>
            <th colSpan={3} style={{borderLeft: '1px solid white'}}>Mobile</th>
            <th colSpan={3} style={{borderTopRightRadius: 5, borderLeft: '1px solid white'}}>E-mail</th>
          </tr>
          <tr>
            <th width={60}>Month</th>
            <th>Volume</th>
            <th>Validated</th>
            <th>%&nbsp;Validated</th>
            <th>Full Match</th>
            <th style={{borderLeft: '1px solid white'}}>Match</th>
            <th>Change</th>
            <th>Append</th>
            <th style={{borderLeft: '1px solid white'}}>Match</th>
            <th>Change</th>
            <th>Append</th>
            <th style={{borderLeft: '1px solid white'}}>Match</th>
            <th>Change</th>
            <th style={{borderRadius: 0}}>Append</th>
          </tr>
        </thead>
        <tbody>
          {
            data.map(row => {
              return (
                <tr>
                  <td>{row.Month}</td>
                  <td>{row.Volume}</td>
                  <td>{row.Validated_Count}</td>
                  <td>{(row.Validated_Percent * 100).toFixed(0)}%</td>
                  <td>{row.Full_Match}</td>
                  <td>{row.Landline_Match}</td>
                  <td>{row.Landline_Change}</td>
                  <td>{row.Landline_Append}</td>
                  <td>{row.Mobile_Match}</td>
                  <td>{row.Mobile_Change}</td>
                  <td>{row.Mobile_Append}</td>
                  <td>{row.Email_Match}</td>
                  <td>{row.Email_Change}</td>
                  <td>{row.Email_Append}</td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
}

function CotValidationUnvalidated({ data, validated }) {
  return (
    <div>
      <h2>Unvalidated</h2>
      <table className='table smaller-text squish'>
        <thead>
          <tr>
            <th width={60}>Month</th>
            <th>Volume</th>
            <th>Unvalidated</th>
            <th>%&nbsp;Unvalidated</th>
            <th>Deceased</th>
            <th>Gone Away</th>
            <th>Unvalidated Month&nbsp;1</th>
            <th>Unvalidated Month&nbsp;2</th>
            <th>Unvalidated Month&nbsp;3</th>
            <th>Unvalidated Month&nbsp;3+</th>
          </tr>
        </thead>
        <tbody>
          {
            data.map(row => {
              return (
                <tr>
                  <td>{row.Month}</td>
                  <td>{row.Volume}</td>
                  <td>{row.Unvalidated_Count}</td>
                  <td>{(row.Unvalidated_Percent * 100).toFixed(0)}%</td>
                  <td>{row.Residency_Status_Deceased}</td>
                  <td>{row.Residency_Status_Gone_Away}</td>
                  <td>{row.Unvalidated_Age_1}</td>
                  <td>{row.Unvalidated_Age_2}</td>
                  <td>{row.Unvalidated_Age_3}</td>
                  <td>{row.Unvalidated_Age_3p}</td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
}

const LoadingSpinner = () => {
  return (
    <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', margin: '0 auto'}}>
      <i className="fas fa-spinner fa-pulse fa-4x colour-gray"></i>
    </div>
  );
};

export default function CotValidation() {
  const [data, setData] = useState([])
  const [dataReady, setDataReady] = useState(false)
  const [filterState, setFilterState] = useState({})
  const [modalMode, setModalMode] = useState(null)

  const addFilter = (field, value) => {
    setFilterState({
      ...filterState,
      [field]: value
    })
  }

  const getData = () => {
    setDataReady(false)
    request(true).get(endpoints.COT_VALIDATION_GET_DATA, {
      params: {
        filter: JSON.stringify(filterState)
      },
      doesCancel: true,
    }).then(e => {
      setData(e.data)
      setDataReady(true)
    })
  }

  useEffect(() => {
    getData();
  }, [filterState])

  // useEffect(() => {
  //   getData();
  // }, [])

  return [
    <Page title={'Cleanse & Validation'} titleRight={<CotValidationTitle setModalMode={setModalMode} showOverviewLink={false} />}>
      <div className='CotValidation'>
        <div className='primary-grid'>
          <div className='left'>
            {dataReady ? (
              <>
                <CotValidationValidated data={data} />
                <CotValidationValidatedContact data={data} />
                <CotValidationUnvalidated data={data} />
              </>
            ) : <LoadingSpinner />}
          </div>
          <div className='right'>
            <div className="filters">
                <div className="filter-title">
                    {/* <i className="fas fa-caret-up"></i> */}
                    <div className="title">Filters</div>
                    <div className="clear" onClick={ () => setFilterState({}) }>Clear All</div>
                </div>
                <ProductDataFilter dataFilterEndpoint={endpoints.COT_VALIDATION_DATA_FILTERS} filterState={filterState} applyFilter={addFilter} />
            </div>
          </div>
        </div>
      </div>
      
    </Page>,
    <S3Modal closeModal={() => setModalMode(null)} listingUrl={endpoints.COT_VALIDATION_FILE_GET} modalMode={modalMode} showModal={!!modalMode} uploadUrl={endpoints.COT_VALIDATION_FILE_UPLOAD} />
  ]
}
