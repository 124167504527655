import React, { Component } from 'react'
import {ReactComponent as HouseIcon} from '../../assets/House Icon.svg';

import './kpiCard.scss';

export default class KpiCard extends Component {
    render() {
        return (
            <div className="kpi-card grid center middle ">
                <div className="font-align-center font-weight-500 no-margin colour-secondary">
                    {
                        this.props.primary ? <p className="font-size-2">{this.props.primary}</p> : ''
                    }
                    {
                        this.props.title ? <p className="kpi-title">{this.props.title}</p> : ''
                    }
                    {
                        this.props.secondary ? <p className={`font-size-2 ${this.props.secondary === undefined ? 'opacity-0' : ''}`}>{this.props.secondary || '-'}</p> : null
                    }
                </div>
                <HouseIcon className="float-house"/>
            </div>
        )
    }
}
