import React from 'react';

import './container.scss';



export default function Container({ children, width }) {
    return <div className="container">
        <div style={{ width }}>
            {children}
        </div>
    </div>;
}
