import React from 'react'
import Navigation from '../../components/navigation/navigation'
import Footer from '../../components/footer/footer'
import Container from '../../components/container/container'
import request from '../../helpers/request';
import { matchPath, withRouter } from 'react-router-dom';

export default withRouter(({ ...props }) => {
    const match = matchPath(props.history.location.pathname, {
        path: '/admin/portal/companies/:company/translate',
    })
    const {params} = match;
    const [companyId] = React.useState(params.company);
    const [key, setKey] = React.useState('vsa.');
    const [translations, setTranslations] = React.useState([]);
    const keys = [
        ['aa.', 'Adress Alerts'],
        ['tvaa.', 'Affordability Alerts'],
        ['BAA.', 'Business Address Alerts'],
        ['bta.', 'Business Tele Append'],
        ['cha.', 'Companies House Alerts'],
        ['cv.', 'Contact Validation'],
        ['cotval.', 'COT Validation'],
        ['gaa', 'Gone Away Alerts'],
        ['gsa.', 'Gap Sites Alerts'],
        ['hma.', 'Home Mover Alerts'],
        ['ova.', 'Occupied COT Alerts'],
        ['oca.', 'Occupier COT Alerts'],
        ['otv.', 'Occupier Telephone Validation'],
        ['ov.', 'Occupier Validation'],
        ['osa.', 'Occupied Sale Alerts'],
        ['po.', 'Previous Occupier'],
        ['proprietor-validation.', 'Proprietor Validation'],
        // ['rtv.', 'Proprietor Validation'],
        ['tv.', 'Tariff Validation'],
        ['vacant-cot-alerts.', 'Vacant COT Alerts'],
        ['vca.', 'Void COT Alerts'],
        ['vsa.', 'Void Sale Alerts'],
    ]

    const loadTranslations = React.useCallback(() => {
        request(true).get(`/translate/${companyId}?key=${key}`).then(r => {
            setTranslations(r.data);
        }).catch(e => {
            console.error(e);
        });
    }, [companyId, key]);

    const onInputChange = e => {
        const trans = [].concat(translations);
        trans[e.target.name].override = e.target.value;
        setTranslations(trans);
    }

    const submit = () => {
        const data = {};

        for (const index in translations) {
            if (translations[index].override.trim() !== '') {
                data[translations[index].id] = translations[index].override.trim();
            }
        }

        request(true).post(`/translate/${companyId}?key=${key}`, { data }).then(r => {
            console.log(r);
        }).catch(e => {
            console.error(e);
        });
    }

    React.useEffect(() => {
        loadTranslations();
    }, [key, loadTranslations]);

    return (
        <div className="grid grid-gap-20">
            <Navigation/>
            <Container>
                <div className="grid" style={{ gridGap: '15px', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr' }}>
                    { keys.map(key => (
                            <button className="button background-primary colour-white" onClick={ () => setKey(key[0]) }>
                                { key[1] }
                            </button>
                        ))}
                </div>
                <table className="table" style={{ marginTop: '15px' }}>
                    <thead>
                        <tr>
                            <th>Key</th>
                            <th>Default</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        { translations.map((item, index) => (
                                <tr key={`translation-${index}`}>
                                    <td>
                                        { item.key }
                                    </td>
                                    <td>
                                        { item.default }
                                    </td>
                                    <td>
                                        <input type="text" onChange={ onInputChange } name={index} value={item.override}/>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                <button style={{ width: '15%' }} className="button background-primary colour-white" onClick={ submit }>
                    Update
                </button>
            </Container>
            <Footer/>
        </div>
    )
})
