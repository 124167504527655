import React from 'react'
import { useState, useEffect } from 'react'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import endpoints from '../../../helpers/endpoints';
import request from '../../../helpers/request';
import {
  CompanyInfo,
  GoogleEvidence,
  CompaniesHouseEvidence,
  YellEvidence,
  AddressDataEvidence,
  FSAEvidence,
  BTEvidence,
  TripAdvisorEvidence,
  DVSAEvidence,
  CQCEvidence, 
  JustEatEvidence, 
  One92Evidence, 
  FacebookEvidence
} from './shared'
import Select from 'react-select'
import GenericDataRow, { GenericDataRowTitle } from '../../../GenericPageElements/GenericDataRow';
import CircularProgress from '@mui/material/CircularProgress';
import PropertyMap from '../../../components/property-map/property-map'
import ProductIcons from '../../../helpers/productIcons'
import ProductChartInfo from '../../../components/product-chart-info/product-info-chart';

export default function RealTimeValidation() {
  const [data, setData] = useState({});
  const [postcode, setPostcode] = useState();
  const [addresses, setAddresses] = useState([]);
  const [selectedUPRN, setSelectedUPRN] = useState('');
  const [loading, setLoading] = useState(false);
  const [translations, setTranslations] = useState({})
  useEffect(() => {
    request(true).get('/translate?key=vacant-cot-alerts.')
    .then(e => {
      setTranslations(e.data)
    })
    }, [])
  const searchPostcodes = () => {
    setLoading(true)
    request(true).get(endpoints.REAL_TIME_VALIDATION_SEARCH, {
      params: {
        postcode
      }
    }).then(e => {
      if (!e.data.length) window.alert('No Results Found.');
      setAddresses(e.data)
      setData({})
      setLoading(false)
    }).catch(e => {
      setAddresses([])
      setData({})
      setLoading(false)
      window.alert('No Results Found.')
    })
  }
  const lookupAddress = (ev) => {
    setLoading(true)
    setSelectedUPRN(ev.value)
    request(true).get(endpoints.REAL_TIME_VALIDATION_LOOKUP, {
      params: {
        uprn: ev.value
      }
    }).then(e => {
      if (!e.data) window.alert('No Results Found.');
      setLoading(false)
      setData(e.data)
    }).catch(e => {
      setLoading(false)
      setData({})
      window.alert('No Results Found.')
    })
  }
  return (
    <GenericPageContainer title="Property Search"
    titleIconClass={ProductIcons.BusinessIcon.props.className}
    titleRight={<div>
        {
          loading ? <CircularProgress size={22} /> : null
        }
      </div>}>
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <div style={{display: 'grid', gridTemplateColumns: '1fr 3fr max-content', gap: 10}}>
          <div style={{display: 'grid', gridTemplateColumns: 'auto min-content', gap: 10, alignItems: 'center'}}>
            <GenericDataRow title={translations['vacant-cot-alerts.postcode']} contentColour={'var(--white)'}>
              <input disabled={loading} style={{width: '100%', outline: 'none', border: 'none', backgroundColor: 'var(--white)'}} value={postcode} onChange={e => setPostcode(e.target.value)} />
            </GenericDataRow>
            <span className='fas fa-search colour-primary' style={{cursor: 'pointer'}} onClick={searchPostcodes}></span>
          </div>
          <GenericDataRow title={translations['vacant-cot-alerts.address']} contentColour={'var(--white)'}>
            <select disabled={loading} value={selectedUPRN} style={{width: '100%', border: 'none'}} onChange={_ => lookupAddress(_.target)}>
              <option value=""></option>
              {
                addresses.map(address => {
                  return (
                    <option value={address.value}>{address.label}</option>
                  )
                })
              }
            </select>
          </GenericDataRow>
          <div style={{display: 'flex', justifyContent: 'space-between'}} onClick={() => {setPostcode(''); setData({}); setAddresses([])}}>
            <button className='navigate'>Clear</button>
            <ProductChartInfo path={window.location.pathname} style={{margin: 0, cursor: 'pointer', color: 'var(--primary)', paddingLeft: '15px'}} />
          </div>
        </div>
        <CompanyInfo translations={translations} data={data} />
        <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
          <GoogleEvidence data={data} />
          <CompaniesHouseEvidence data={data} />
          <YellEvidence data={data} />
          <AddressDataEvidence data={data} />
        </div>
        <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
          <FSAEvidence data={data} />
          <BTEvidence data={data} />
          <TripAdvisorEvidence data={data} />
          <DVSAEvidence data={data} />
        </div>

        <div style={{display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%'}}>
            <CQCEvidence data={data} />
            <JustEatEvidence data={data} />
            <One92Evidence data={data} />
            <FacebookEvidence data={data} />
        </div>

        <div className="secondary-grid" style={{gridTemplateColumns: '1fr 3fr', gridGap: '15px', gap: '15px', display: 'grid', minHeight: '300px'}}>
            <table className="table borders squish super-small-text extra-row-padding">
              <thead>
                  <tr>
                      <th style={{textAlign: 'left'}}>
                          Days
                      </th>
                      <th>
                          Opening Times
                      </th>
                  </tr>
              </thead>
              <tbody>
                {
                    ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(day => {
                        return (
                            <tr>
                                <td style={{textAlign: "left", width: "100px"}}>{ day }</td>
                                <td style={{minWidth: "unset"}}>{ data?.Google_Opening_Hours ? JSON.parse(data.Google_Opening_Hours.replaceAll('\'', '"'))[day] : null }</td>
                            </tr>
                        )
                    })
                }
            </tbody>
          </table>
            <div style={{height: 'auto'}}>
                <PropertyMap address={ data ? data.AddressBase_Address : null } />
            </div>
          </div>
      </div>
    </GenericPageContainer>
  )
}
