import React, { Component } from 'react'
import './pagination.scss';

export default class Pagination extends Component {
    createList = () => {
        const children = [];

        if (this.props.pages < 10) {
            for (let i = 1; i <= this.props.pages; i++) {
                children.push(
                    <ul className={ i === this.props.page ? 'page page-selected' : 'page' } onClick={ () => this.props.pageChange(i) } key={i}>
                        { i }
                    </ul>
                )
            }
        } else {
            if (this.props.page !== 1) {
                children.push(
                    <ul className={ 1 === this.props.page ? 'page page-selected' : 'page' } onClick={ () => this.props.pageChange(1) } key={1}>
                        1
                    </ul>
                )

                if (this.props.page !== 2) {
                    children.push(
                        <ul className="page" onClick={ () => this.props.pageChange(this.props.page - 1) } key={this.props.page - 1}>
                            &lt;
                        </ul>
                    );
                }
            }

            children.push(
                <ul className="page page-selected" onClick={ () => this.props.pageChange(this.props.page) } key={this.props.page}>
                    { this.props.page }
                </ul>
            )

            if (this.props.page + 1 < this.props.pages) {
                children.push(
                    <ul className="page" onClick={ () => this.props.pageChange(this.props.page + 1) } key={this.props.page + 1}>
                        &gt;
                    </ul>
                )
            }

            if (this.props.page !== this.props.pages) {
                children.push(
                    <ul className="page" onClick={ () => this.props.pageChange(this.props.pages) } key={this.props.pages}>
                        { this.props.pages }
                    </ul>
                )
            }
        }

        return children;
    }

    render() {
        return (
            <ul className="pagination">
                { this.createList() }
            </ul>
        )
    }
}
