import React, { Component } from 'react'
import Navigation from '../../components/navigation/navigation'
import Footer from '../../components/footer/footer'
import Container from '../../components/container/container'
import Pagination from '../../components/pagination/pagination';

import request from '../../helpers/request';
import endpoints from '../../helpers/endpoints';
import { Link, withRouter, matchPath } from 'react-router-dom';

export default withRouter(class CompanySelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            match: matchPath(props.history.location.pathname, {
                path: '/admin/portal/company-select/:configuration',
            }),
            companies: [],
            pagination: {
                page: 1,
                last_page: null,
            },
            destination: null,
            errors: {},
        }
    }

    updatePage = page => {
        this.loadPage(page);
    }

    loadPage = (page = 1) => {
        request(true).get(endpoints.ADMIN_COMPANIES_INDEX + '?page=' + page).then(e => {
            // console.log(e.data);
            this.setState({
                companies: e.data.data,
                pagination: {
                    page: e.data.current_page,
                    last_page: e.data.last_page,
                },
            });
        }).catch(e => {
            // console.log(e.response);
        });
    }

    componentDidMount() {
        this.loadPage();
    }

    render() {
        return (
            <div className="grid grid-gap-20">
                <Navigation />
                <Container>
                    <div className="flex middle">
                        <p className="colour-secondary font-weight-600" style={{fontSize: 20}}>Companies</p>
                    </div>
                </Container>
                <Container>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Company ID</th>
                                <th>Company Name</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            { this.state.companies.map(company => (
                                    <tr key={company.id}>
                                        <td>{ company.id }</td>
                                        <td>{ company.name }</td>
                                        <td className="actions">
                                            <Link to={ `/admin/portal/companies/${company.id}/${this.state.match.params.configuration}` }><i class="fa fa-search" aria-hidden="true" /></Link>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    <Pagination page={this.state.pagination.page} pages={this.state.pagination.last_page} pageChange={this.updatePage}/>
                </Container>
                <Footer />
            </div>
        )
    }
})
