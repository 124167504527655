import React, { useMemo, useState } from 'react'
import { DataRow, matchColour, Title } from '../CompaniesHouseAlerts/sharedComponents'
import { GenericTable } from '../VacantCOTAlerts/sharedComponents'
import Button from '../../../components/large-link-button/largeLinkButton'
import Checkbox from '@material-ui/core/Checkbox';
import DatePicker from 'react-datepicker'

export function CotValidationOccupierFootprint({ data }) {
  const rows = [
    [
      'Listed on elctoral roll',
      'Electoral_Roll'
    ],
    [
      'Listed on BT register',
      'BT_Line'
    ],
    [
      'Count of secured loans',
      'Secured_Loans'
    ],
    [
      'Gas redirect in place',
      'GAS_Redirect'
    ],
    [
      'NCoA redirect in place',
      'NCOA_Redirect'
    ],
    [
      'Credit footprint in place',
      'CAIS_Count'
    ],
    [
      'Listed on Council Tax',
      'Council_Tax'
    ],
    [
      'Listed for Benefits',
      'Benefits'
    ]
  ]
  return (
    <GenericTable extraClass={'smaller-text squish'} data={data} rows={rows} title="Occupied Footprint" />
  )
}

export function CotValidationAccountDetails({ data }) {
  const rows = [
    [
      'Unvalidated age band',
      'U_Age_Band'
    ],
    [
      'COT in date',
      'COT_In_Date'
    ],
    [
      'COT in method',
      'COT_Method'
    ],
    [
      'COT in Source',
      'COT_Source'
    ],
    [
      'Debt Band',
      'Debt_Band'
    ],
    [
      'Account Type',
      'Account_Type'
    ],
    [
      'Consumption',
      'Consumption'
    ],
    [
      'IMD',
      'IMD'
    ]
  ]
  return (
    <GenericTable extraClass={'smaller-text squish'} data={data} rows={rows} title="Occupied Footprint" evidence={false} />
  )
}

export function CotValidationMatchStatus({ data: customer }) {
  return (
    <div style={{display: 'flex', gap: 10, flexDirection: 'column'}}>
      <Title title="Match Status" />
      <div style={{display: 'grid', gridAutoRows: '1fr', gap: 10, height: '100%'}}>
          <Title colour={customer ? matchColour(customer.Full_Status) : null} title={customer ? customer.Full_Status || 'N/A' : 'N/A'} disabled={true} />
          <Title colour={customer ? matchColour(customer.Forename_Status) : null} title={customer ? customer.Forename_Status || 'N/A' : 'N/A'} disabled={true} />
          <Title colour={customer ? matchColour(customer.Surname_Status) : null} title={customer ? customer.Surname_Status || 'N/A' : 'N/A'} disabled={true} />
          <Title colour={customer ? matchColour(customer.DOB_Status) : null} title={customer ? customer.DOB_Status || 'N/A' : 'N/A'} disabled={true} />
          <Title colour={customer ? matchColour(customer.Landline_Status) : null} title={customer ? customer.Landline_Status || 'N/A' : 'N/A'} disabled={true} />
          <Title colour={customer ? matchColour(customer.Mobile_Status) : null} title={customer ? customer.Mobile_Status || 'N/A' : 'N/A'} disabled={true} />
          <Title colour={customer ? matchColour(customer.Email_Status) : null} title={customer ? customer.Email_Status || 'N/A' : 'N/A'} disabled={true} />
      </div>
    </div>
  )
}

export function CotValidationMatchedDetails({ data: customer, translations, children }) {
  return (
    <div style={{display: 'flex', gap: 10, flexDirection: 'column'}}>
      <Title title="Matched Details" />
      <div style={{display: 'grid', gridAutoRows: '1fr', gap: 10, height: '100%'}}>
        {children}
        <DataRow translations={translations} customer={customer} value={'Forename_Matched'} title={'cotval.forename'} style={{height: 37}} />
        <DataRow translations={translations} customer={customer} value={'Surname_Matched'} title={'cotval.surname'} />
        <DataRow translations={translations} customer={customer} value={'DOB_Matched'} title={'cotval.date_of_birth'} />
        <DataRow translations={translations} customer={customer} value={'Landline_Matched'} title={'cotval.landline'} />
        <DataRow translations={translations} customer={customer} value={'Mobile_Matched'} title={'cotval.mobile'} />
        <DataRow translations={translations} customer={customer} value={'Email_Matched'} title={'cotval.email'} />
      </div>
  </div>
  )
}

export function CotValidationClientDetails({ data: customer, translations, children }) {
  return (
    <div style={{display: 'flex', gap: 10, flexDirection: 'column'}}>
      <Title title="Client Details" />
      <div style={{display: 'grid', gridAutoRows: '1fr', gap: 10, height: '100%'}}>
        {children}
        <DataRow translations={translations} customer={customer} value={'Forename_Client'} title={'cotval.forename'} />
        <DataRow translations={translations} customer={customer} value={'Surname_Client'} title={'cotval.surname'} />
        <DataRow translations={translations} customer={customer} value={'DOB_Client'} title={'cotval.date_of_birth'} />
        <DataRow translations={translations} customer={customer} value={'Landline_Client'} title={'cotval.landline'} />
        <DataRow translations={translations} customer={customer} value={'Mobile_Client'} title={'cotval.mobile'} />
        <DataRow translations={translations} customer={customer} value={'Email_Client'} title={'cotval.email'} />
      </div>
  </div>
  )
}

export function SearchRow({ rowTitle, value, onChange, doAction }) {
  return (
    <div className="data-row with-buttons">
      <div className="data-row-title">
        {rowTitle}
      </div>
      <div>
          <input type="text" className="data-row-field" name="account-number" value={ value } onChange={ e => onChange(e.target.value) }  style={{ width: 'calc(100% - 15px)', height: '100%' }}/>
      </div>
      <div style={{ marginLeft: '15px' }}>
          <button className="button background-primary colour-white" onClick={ doAction }>
              <i className="fas fa-search"></i>
          </button>
      </div>
  </div>
  )
}

export function GenericCheckBoxTable({
  data, rows, title,
  extraClass, style = {}, evidence = true,
  subtitle = 'Evidence', align, 
  firstColWidth, biggerSecondRow = true,
  leftOfTitleContent, checkBoxFunction,
  checkboxes = true, isChecked,
  rowsEditable = false, gatherInput,
  inputField
 }) {
  const handleCheck = (event) => {
    const id = event.target.id;
    const source = id.substring(0, id.indexOf('_'))
    const column = id.substring(id.indexOf('_') + 1)
    checkBoxFunction(source, data, column) 
  }

  const handleInput = (event) => {
    if (event instanceof Date || (event && event.target && event.target && !event.target.value.match(/[^a-zA-Z'-]/g))) {
      gatherInput(event)
    }
  }

  return (
    <table className={`table borders ${extraClass}`} style={{ height: '100%', borderCollapse: 'collapse', ...style }}>
      <thead>
        <tr>
            {
                leftOfTitleContent ? <>
                    <th>
                        {leftOfTitleContent}
                    </th>
                </> : null
            }
          <th style={{textAlign: leftOfTitleContent ? 'center' : 'start' }} colSpan={evidence ? 1 : 2}>
            {title}
          </th>
          {
            evidence ? <>
              <th>
                {subtitle}
              </th>
            </> : null
          }
        </tr>
      </thead>
      <tbody>
        {
          rows.map((row, i) => {
            const Value = row[1]
            return (
              <tr key={`gtable_${Math.random()}_${i}`} style={{height: '50px'}}>
                <td style={{ height: '100%', textAlign: align, width: i === 0 ? firstColWidth || undefined : undefined }}>{row[0]}</td>
                <td style={{ minWidth: evidence ? 'unset' : 60, display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '42px'}}>
                { rowsEditable && (typeof Value === 'string') ?
                  row[0] === "Date of Birth" ? 
                    <DatePicker customInput={<CustomInput value={data[row[1]]} />} dateFormat="ddMMMyyyy" showYearDropdown yearDropdownItemNumber={100} scrollableYearDropdown maxDate={new Date()} showIcon={true} selected={data[row[1]] ? typeof data[row[1]] == "string"  ? Date.parse(data[row[1]]) : data[row[1]] : null} onChange={(date) => gatherInput(date)}></DatePicker>
                  :
                    <input 
                      autoFocus={inputField == `input_${Value}` ? true : false}
                      key={`input_${Value}`} 
                      id={`input_${Value}`} 
                      onChange={(event) => handleInput(event)} value={data[Value] || ''}></input>
                :
                  data ? (typeof Value === 'string') ?  data[Value] : <Value data={data}></Value> : null }
                  {checkboxes && data[Value] && typeof Value !== "function" ?  
                    <Checkbox 
                      id={Value}
                      color='primary'
                      checked={ isChecked[Value.substring(Value.indexOf('_') + 1)] === Value.substring(0, Value.indexOf('_')) ? true : false }
                      // checked={ isChecked[Value.substring(Value.indexOf('_') + 1)] ? true : false }
                      onChange={ (event) => { handleCheck(event)} }
                    />
                  : null }
                </td>
              </tr>
            )
          })
        }
      </tbody>
    </table>
  )
}


export function CotValidationManualReviewClient({ translations, data, updateSelected, isChecked }) {
  const rows = [
    [translations['cotval.title'], 'Client_Title'],
    [translations['cotval.forename'], 'Client_Forename'],
    [translations['cotval.middlename'], 'Client_Middle_Name'],
    [translations['cotval.surname'], 'Client_Surname'],
    [translations['cotval.date_of_birth'], 'Client_Date_Of_Birth'],
    ['', () => <button className={`button compact smaller-text background-primary`} onClick={() => updateSelected("Client", data)} >Select Match</button>]
  ]
  return (
    <GenericCheckBoxTable biggerSecondRow={false} firstColWidth={150} title="Client" rows={rows} extraClass="squish smaller-text left" evidence={false} data={data} checkBoxFunction={updateSelected} isChecked={isChecked} />
  )
}

export function CotValidationManualReviewExp({ translations, data, updateSelected, isChecked }) {
  const rows = [
      [translations['cotval.title'], 'Exp_Title'],
      [translations['cotval.forename'], 'Exp_Forename'],
      [translations['cotval.middlename'], 'Exp_Middle_Name'],
      [translations['cotval.surname'], 'Exp_Surname'],
      [translations['cotval.date_of_birth'], 'Exp_Date_Of_Birth'],
      ['', () => <button className={`button compact smaller-text background-primary`} onClick={() => updateSelected("Exp", data)} >Select Match</button>]
  ]
  return (
    <GenericCheckBoxTable biggerSecondRow={false} firstColWidth={150} title="Experian" rows={rows} extraClass="squish smaller-text left" evidence={false} data={data} checkBoxFunction={updateSelected} isChecked={isChecked} />
  )
}

export function CotValidationManualReviewTu({ translations, data, updateSelected, isChecked, gatherInput, inputField }) {
  const rows = [
      [translations['cotval.title'], 'TU_Title'],
      [translations['cotval.forename'], 'TU_Forename'],
      [translations['cotval.middlename'], 'TU_Middle_Name'],
      [translations['cotval.surname'], 'TU_Surname'],
      [translations['cotval.date_of_birth'], 'TU_Date_Of_Birth'],
      ['', () => <button className={`button compact smaller-text background-primary`} onClick={() => updateSelected("TU", data)}>Select Match</button>]
    ]
  return (
    <GenericCheckBoxTable biggerSecondRow={false} firstColWidth={150} title="TransUnion" rows={rows} extraClass="squish smaller-text left" evidence={false} data={data} checkBoxFunction={updateSelected} isChecked={isChecked} rowsEditable={true} gatherInput={gatherInput} inputField={inputField} />
  )
}

export function CotValidationManualReviewSelected({ translations, data, selected, updateAppData, isChecked }) {
  const rows = [
      [translations['cotval.title'], 'App_Title'],
      [translations['cotval.forename'], 'App_Forename'],
      [translations['cotval.middlename'], 'App_Middle_Name'],
      [translations['cotval.surname'], 'App_Surname'],
      [translations['cotval.date_of_birth'], 'App_Date_Of_Birth'],
      ['', () => <button style={{textWrap: 'nowrap'}} className={`button compact smaller-text ${(data.Client_Date_Of_Birth ? isChecked.Date_Of_Birth : true) && isChecked.Surname && isChecked.Forename && isChecked.Title ? 'background-primary' : ''}`} onClick={() => updateAppData() }>Update Address</button>]
    ]
  return (
    <GenericCheckBoxTable biggerSecondRow={false} firstColWidth={150} title="Selected Match" rows={rows} extraClass="squish smaller-text left" evidence={false} data={selected} checkboxes={false}/>
  )
}

function CustomInput(props) {
  return (
    <input value={props.value.toUpperCase()} onClick={props.onClick} onChange={null} />
  )
}