import React, { useCallback } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import endpoints from '../../../helpers/endpoints'
import request from '../../../helpers/request'
import ReactModal from 'react-modal'
import hasPermission from '../../../helpers/permissions'

const LTAP_BLUE = "#176B99"

export const Summary = ({ 
  summaryData, 
  setSummaryData, 
  getWorkflowItems, 
  status, 
  setStatus, 
  clearSummaryData, 
  translations = {}, 
  workflow = false 
}) => {

  const updateStatus = (value) => {
    request(true).post(endpoints.LTAP_ANALYSIS_BUY_CUSTOMER, {
      propRef: summaryData['ltap_property_reference'],
      clientAddressId: summaryData['client_address_id'],
      status: value
    }).then(e => {
      setStatus('null')
      if (workflow) {
        clearSummaryData()
        setSummaryData(e.data.customer ?? {})
        getWorkflowItems()
      } else {
        clearSummaryData()
      }
    }).catch(e => window.alert('Could not find Property Reference.'))
  }
  
  const alreadyBought = !!summaryData["date_bought"]
  const notEmpty = !!summaryData["ltap_property_reference"]
  
  return (
    <>
      <div>
        <table className='table borders squish smaller-text left' style={{ tableLayout: 'fixed', width: '100%' }}>
          <col width={180} />
          <thead>
            <tr>
              <th colSpan={3}>{translations["lta.summary"]}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={1}>{translations["lta.category"]}</td>
              <td colSpan={2}>{summaryData["category"]}</td>
            </tr>
            <tr>
              <td colSpan={1}>{translations["lta.property_status"]}</td>
              <td colSpan={2}>{summaryData["ltap_property_occupied_status"]}</td>
            </tr>
            <tr>
              <td colSpan={1}>{translations["lta.responsibility"]}</td>
              <td colSpan={2}>{summaryData["ltap_responsibility_for_charges"]}</td>
            </tr>
            <tr>
              <td colSpan={1}>{translations["lta.landlord_name"]}</td>
              <td colSpan={2}>{summaryData["landlord_name"]}</td>
            </tr>
            <tr>
              <td colSpan={1}>{translations["lta.owner_name"]}</td>
              <td colSpan={2}>{summaryData["ltap_owner_company_name"]}</td>
            </tr>
            <tr>
              <td colSpan={1}>{translations["lta.status"]}</td>
              <td colSpan={2}>
                {hasPermission("LTAP_ANALYSIS", "PURCHASE") && !alreadyBought && notEmpty ?
                  <select style={{ height: 28, borderRadius: 5, backgroundColor: 'var(--primary)', color: 'white', fontWeight: 'bold' }} onChange={(e) => setStatus(e.target.value)} value={summaryData["status"] || status}>
                    <option value={'null'}>Select Status</option>
                    <option value={'In progress'}>In progress</option>
                    <option value={'Move in completed'}>Move in completed</option>
                    <option value={'Move out completed'}>Move out completed</option>
                    <option value={'No action taken'}>No action taken</option>
                  </select>
                  :
                  <select disabled className="disabled" style={{ height: 28, borderRadius: 5, fontWeight: 'bold' }} value={summaryData["status"] || status}>
                    <option value={'null'}>No status</option>
                    <option value={'In progress'}>In progress</option>
                    <option value={'Move in completed'}>Move in completed</option>
                    <option value={'Move out completed'}>Move out completed</option>
                    <option value={'No action taken'}>No action taken</option>
                  </select>
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <ReactModal
        className="card bulk-buy"
        isOpen={status !== 'null'}>
        <p>You have selected the following status</p>
        <p>{status}</p>
        <div className='grid grid-columns-2 grid-gap-5'>
          <button className='button compact background-red colour-white' onClick={() => setStatus('null')}>Cancel</button>
          <button className='button compact background-primary colour-white' onClick={() => updateStatus(status)}>Confirm</button>
        </div>
      </ReactModal>
    </>
  )
}

export const AddressTable = ({
  summaryData, 
  setSummaryData, 
  workflowIndex, 
  setWorkflowIndex, 
  _cur, 
  propRef, 
  setPropRef, 
  workflowItems, 
  getWorkflowItems, 
  clientId, 
  setClientId, 
  workflow = false, 
  translations = {}
}) => {
  const [propRefSearch, setPropRefSearch] = useState()
  const [clientSearch, setClientSearch] = useState()
  const [abpStreetName, setAbpStreetName] = useState('')
  const [clientStreetName, setClientStreetName] = useState('')
  
  // Use current ltap_property_reference/client_address_id and get data from row
  const getSummaryData = useCallback((clientAddressId = null) => {
    setSummaryData({})
    request(true).get(endpoints.LTAP_ANALYSIS_SEARCH, {
      params: {
        propRef,
        clientAddressId
      }
    }).then(e => {
      setSummaryData(e.data.customer ?? {})
      setAbpStreetName((e.data.customer?.abp_building_number || '') + ' ' + e.data.customer?.abp_street_name)
      setClientStreetName((e.data.customer?.client_building_number || '') + ' ' + e.data.customer?.client_thoroughfare)
    }).catch(e => window.alert('Property not found'))
  }, [propRef, setSummaryData])

  useEffect(() => {
      window.reloadSummaryData = getSummaryData
  }, [getSummaryData])

  useEffect(() => {
      window.reloadWorkflow = getWorkflowItems
  }, [getWorkflowItems])

  const getWorkflowNext = () => {
    if (_cur !== workflowItems.length - 1) {
      setPropRef(workflowItems[_cur + 1]);
      setWorkflowIndex(workflowIndex + 1)
    }
  }

  const getWorkflowPrev = () => {
    if (_cur !== 0) {
      setPropRef(workflowItems[_cur - 1]);
      setWorkflowIndex(workflowIndex - 1)
    }
  }

  useEffect(() => {
    if (workflow) {
      getWorkflowItems();
    }
  }, [])

  useEffect(() => {
    if (propRef) {
      getSummaryData()
    }
  }, [propRef])
  
  useEffect(() => {
    if (clientId) {
      getSummaryData(clientId)
    }
  }, [clientId])
  
  useEffect(() => {
    setPropRefSearch(summaryData["ltap_property_reference"] || '' )
    if (!workflow) {
      setClientSearch(summaryData["client_address_id"] || '')
    }
  }, [summaryData])

  return (
    <div>
      <table className='table borders squish smaller-text left' style={{tableLayout: 'fixed', width: '100%'}}>
      {/* <input type='text' /> */}
        <col width={180} />
        <thead>
          <tr style={{borderCollapse: "collapse"}}>
            <th colSpan={1} style={{textAlign: "center", borderInline: "1px solid white"}}></th>
            <th colSpan={3} style={{textAlign: "center", borderInline: "1px solid white", backgroundColor: LTAP_BLUE}}>{translations["lta.ltap_property_address"]}</th>
            <th colSpan={3} style={{textAlign: "center", borderInline: "1px solid white"}}>{translations["lta.abp_address"]}</th>
            <th colSpan={3} style={{textAlign: "center", borderInline: "1px solid white"}}>{translations["lta.client_address"]}</th>
            <th colSpan={3} style={{textAlign: "center", borderInline: "1px solid white", backgroundColor: LTAP_BLUE}}>{translations["lta.landlord_address"]}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td width={400}>{translations["lta.ltap_property_reference"]}</td> {/* ltap_property_reference */}
            <td colSpan={3}>
              <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                <input style={{border: 'none'}} type="text" onChange={e => setPropRefSearch(e.target.value)} value={propRefSearch} />
                {
                  workflow ? <>
                    <span className='fas fa-angle-left colour-primary' style={{cursor: 'pointer'}} onClick={getWorkflowPrev}></span>
                    <span>{workflowIndex}/{workflowItems.length}</span>
                    <span className='fas fa-angle-right colour-primary' style={{cursor: 'pointer'}} onClick={getWorkflowNext}></span>
                  </> : <>
                  </>
                }
                <span className='fas fa-search colour-primary' style={{cursor: 'pointer'}} onClick={() => setPropRef(propRefSearch)}></span>
              </div>
            </td>
            <td>{translations["lta.abp_uprn"]}</td>
            <td colSpan={2}>
              {summaryData['abp_uprn']}
            </td>
            <td>{translations["lta.client_address_id"]}</td>
            <td colSpan={2}>
              <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                <input style={{border: 'none'}} type="text" onChange={e => setClientSearch(e.target.value)} value={clientSearch}/>
                <span className='fas fa-search colour-primary' style={{cursor: 'pointer'}} onClick={() => setClientId(clientSearch)}></span>
              </div>
            </td>
            <td>{translations["lta.landlord_id"]}</td>
            <td colSpan={2}>
              {summaryData['ltap_landlord_id']}
            </td>
          </tr>
          <tr>
            <td colSpan={1}>{translations["lta.sub_building"]}</td>
            <td colSpan={3}>{summaryData["ltap_address_line_1"]}</td>
            <td colSpan={3}>{summaryData["abp_sub_building"]}</td>
            <td colSpan={3}>{summaryData["client_sub_building_name"]}</td>
            <td colSpan={3}>{summaryData["ltap_landlord_address_line_1"]}</td>
          </tr>
          <tr>
            <td colSpan={1}>{translations["lta.building_name"]}</td>
            <td colSpan={3}>{summaryData["ltap_address_line_2"]}</td>
            <td colSpan={3}>{summaryData["abp_building_name"]?.trim()}</td>
            <td colSpan={3}>{summaryData["client_building_name"]?.trim()}</td>
            <td colSpan={3}>{summaryData["ltap_landlord_address_line_2"]}</td>
          </tr>
          <tr>
            <td colSpan={1}>{translations["lta.street_name"]}</td>
            <td colSpan={3}>{summaryData["ltap_address_line_3"]}</td>
            <td colSpan={3}>{Object.keys(summaryData).length > 0 ? abpStreetName : ''}</td>
            <td colSpan={3}>{Object.keys(summaryData).length > 0 ? clientStreetName : ''}</td>
            <td colSpan={3}>{summaryData["ltap_landlord_address_line_3"]}</td>
          </tr>
          <tr>
            <td colSpan={1}>{translations["lta.town"]}</td>
            <td colSpan={3}>{summaryData["ltap_address_post_town"]}</td>
            <td colSpan={3}>{summaryData["abp_town_name"]}</td>
            <td colSpan={3}>{summaryData["client_post_town"]}</td>
            <td colSpan={3}>{summaryData["ltap_landlord_address_post_town"]}</td>
          </tr>
          <tr>
            <td colSpan={1}>{translations["lta.postcode"]}</td>
            <td colSpan={3}>{summaryData["ltap_address_postcode"]}</td>
            <td colSpan={3}>{summaryData["abp_postcode"]}</td>
            <td colSpan={3}>{summaryData["client_postcode"]}</td>
            <td colSpan={3}>{summaryData["ltap_landlord_address_postcode"]}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export const ClientTenants = ({ translations, summaryData: data, maxTenants = 5 }) => {
  return (
    <div>
      <table className='table borders squish smaller-text left' style={{tableLayout: 'fixed', width: '100%'}}>
        <col width={180} />
        <thead>
          <tr>
            <th colSpan={1} style={{textAlign: "center", borderInline: "1px solid white"}}>{translations["lta.client_tenants"]}</th>
            <th colSpan={3} style={{textAlign: "center", borderInline: "1px solid white"}}>{translations["lta.tenant"] + " 1"}</th>
            <th colSpan={3} style={{textAlign: "center", borderInline: "1px solid white"}}>{translations["lta.tenant"] + " 2"}</th>
            <th colSpan={3} style={{textAlign: "center", borderInline: "1px solid white"}}>{translations["lta.tenant"] + " 3"}</th>
            <th colSpan={3} style={{textAlign: "center", borderInline: "1px solid white"}}>{translations["lta.tenant"] + " 4"}</th>
            <th colSpan={3} style={{textAlign: "center", borderInline: "1px solid white"}}>{translations["lta.tenant"] + " 5"}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={1}>{translations["lta.client_first_name"]}</td>
            {Array(maxTenants).fill(0).map((_, index) => (
              <>
                <td colSpan={3}>{data["client_first_name_" + (index + 1)]}</td>
              </>
            ))}
          </tr>
          <tr>
            <td colSpan={1}>{translations["lta.client_last_name"]}</td>
            {Array(maxTenants).fill(0).map((_, index) => (
              <>
                <td colSpan={3}>{data["client_last_name_" + (index + 1)]}</td>
              </>
            ))}
          </tr>
          <tr>
            <td colSpan={1}>{translations["lta.client_move_in_date"]}</td>
            {Array(maxTenants).fill(0).map((_, index) => (
              <>
                <td colSpan={3}>{data["client_move_in_date_" + (index + 1)]}</td>
              </>
            ))}
          </tr>
          <tr>
            <td colSpan={1}>{translations["lta.client_move_out_date"]}</td>
            {Array(maxTenants).fill(0).map((_, index) => (
              <>
                <td colSpan={3}>{data["client_move_out_date_" + (index + 1)]}</td>
              </>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export const MoveInOutTenants = ({ translations, summaryData: data, maxTenants = 5, mode }) => {
  
  // Move in clients have a start date but no end date; move out clients have an end date. Will have a dedicated column for this in future
  
  const [tenants, setTenants] = useState([])
  
  useEffect(() => {
    if (Object.keys(data).length > 0) {
      parseTenants()
    } else {
      setTenants([])
    }
  }, [data])
  
  const parseTenants = () => {
    // This will work as long as tenant columns are 'ltap_tenant_1` for example
    const groupedTenants = Object.keys(data)
      .filter(column => {
        return column.startsWith('ltap_tenant') || column.startsWith('tenant_move_status')
      })
      .reduce((acc, item) => {
        const lastUnderscoreIndex = item.lastIndexOf('_');
        const key = item.slice(0, lastUnderscoreIndex);
        const num = item.slice(lastUnderscoreIndex + 1);
        const index = parseInt(num, 10) - 1

        // Convert flat data into array of objects per tenant
        if (!acc[index]) acc[index] = {}
        acc[index][key] = data[item]
        return acc
      }, [])
      .filter(tenant => {
        // Filter tenants that aren't move in or move out
        if (mode === 'in') {
          return tenant.tenant_move_status === 'Move in'
        }
        return tenant.tenant_move_status !== 'Move in' // 'Move out'
      })
    setTenants(groupedTenants)
  }
  
  return (
    <div>
      <table className='table borders squish smaller-text left' style={{tableLayout: 'fixed', width: '100%'}}>
        <col width={180} />
        <thead>
          <tr style={{backgroundColor: LTAP_BLUE}}>
            <th colSpan={1} style={{textAlign: "center", borderInline: "1px solid white"}}>{mode === 'in' ? translations["lta.move_in_tenants"] : translations["lta.move_out_tenants"]}</th>
            <th colSpan={3} style={{textAlign: "center", borderInline: "1px solid white"}}>{translations["lta.tenant"] + " 1"}</th>
            <th colSpan={3} style={{textAlign: "center", borderInline: "1px solid white"}}>{translations["lta.tenant"] + " 2"}</th>
            <th colSpan={3} style={{textAlign: "center", borderInline: "1px solid white"}}>{translations["lta.tenant"] + " 3"}</th>
            <th colSpan={3} style={{textAlign: "center", borderInline: "1px solid white"}}>{translations["lta.tenant"] + " 4"}</th>
            <th colSpan={3} style={{textAlign: "center", borderInline: "1px solid white"}}>{translations["lta.tenant"] + " 5"}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={1}>{translations["lta.move_tenant_name"]}</td>
            {Array(maxTenants).fill(0).map((_, index) => (
              <>
                <td colSpan={3}>{`${tenants[index]?.ltap_tenant_title || ''} ${tenants[index]?.ltap_tenant_first_name || ''} ${tenants[index]?.ltap_tenant_middle_name || ''} ${tenants[index]?.ltap_tenant_surname || ''}`}</td>
              </>
            ))}
          </tr>
          <tr>
            <td colSpan={1}>{translations["lta.move_tenant_email"]}</td>
            {Array(maxTenants).fill(0).map((_, index) => (
              <>
                <td colSpan={3}>{`${tenants[index]?.ltap_tenant_email_address || ''}`}</td>
              </>
            ))}
          </tr>
          <tr>
            <td colSpan={1}>{translations["lta.move_tenant_phone"]}</td>
            {Array(maxTenants).fill(0).map((_, index) => (
              <>
                <td colSpan={3}>{`${tenants[index]?.ltap_tenant_telephone_number || ''}`}</td>
              </>
            ))}
          </tr>
          <tr>
            <td colSpan={1}>{translations["lta.move_tenant_effective_from_date"]}</td>
            {Array(maxTenants).fill(0).map((_, index) => (
              <>
                <td colSpan={3}>{`${tenants[index]?.ltap_tenant_start || ''}`}</td>
              </>
            ))}
          </tr>
          <tr>
            <td colSpan={1}>{translations["lta.move_tenant_effective_to_date"]}</td>
            {Array(maxTenants).fill(0).map((_, index) => (
              <>
                <td colSpan={3}>{`${tenants[index]?.ltap_tenant_end || ''}`}</td>
              </>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  )
}