import React, { Component, useCallback, useEffect, useState } from 'react'
import { view } from '@risingstack/react-easy-state'
import Navigation from '../../../components/navigation/navigation';
import Container from '../../../components/container/container';
import Footer from '../../../components/footer/footer';
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import './occupierTelephoneValidation.scss';
import { Link, withRouter } from 'react-router-dom';
import request from '../../../helpers/request';
import hasPermission from '../../../helpers/permissions';
import endpoints from '../../../helpers/endpoints';
import { BarChart, XAxis, YAxis, Legend, Bar, ResponsiveContainer, CartesianGrid, Tooltip } from 'recharts';
import S3Modal from '../../../components/s3Modal/S3Modal';
import session from '../../../stores/session';
import BulkBuy from '../../../components/cot-alert-bulk-buy/bulkBuy';
import BulkAllocate from '../../../components/cot-alert-bulk-allocate/bulkAllocate';
import HelpVideo from '../../../components/help-video/helpVideo';
import useWSProduct from '../../../helpers/useWSProduct';
import ProductChartInfo from '../../../components/product-chart-info/product-info-chart';
import ProductDataFilter from '../../../components/product-data-filter/product-data-filter';
import { Label } from '../VCA/voidCOTAlerts';
import DataGroupings from '../../../components/data-groupings/data-groupings';
import ProductIcons from '../../../helpers/productIcons';
import Modal from 'react-modal';
import PageTitle from '../../../components/page-header/pageTitle';

const localised = Intl.NumberFormat('en-GB');

const UnallocateModal = ({
    modalIsOpen = false,
    closeModal,
    GET_ENDPOINT,
    POST_ENDPOINT,
    filters = {},
    date = '',
}) => {
    const [unallocateCount, setUnallocateCount] = useState(0)

    const getData = useCallback(() => {
        request(true).get(GET_ENDPOINT, {
            params: {
                filters,
                date
            }
        }).then(e => {
            if (e.status === 200) {
                setUnallocateCount(e.data.count)
            }
        })
    }, [filters, GET_ENDPOINT, date]);

    const confirmUnallocate = useCallback(() => {
        request(true).post(POST_ENDPOINT, {
            filters,
            date
        }).then(e => {
            if (e.status === 200) {
                closeModal()
            }
        })
    }, [filters, GET_ENDPOINT, date]);

    useEffect(() => {
        if (!modalIsOpen) return
        getData()
    }, [modalIsOpen])

    return (
        <Modal
                    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
                    className="card bulk-allocate"
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel=""
                    appElement={document.getElementById('root')}
            >
            <div className='flex-column grid-gap-5'>
                <p>The selected data set includes {unallocateCount} accounts.</p>
                <small>Please press confirm to unallocate these accounts.</small>
                <div className='grid grid-columns-2 grid-gap-5'>
                    <button className='button compact background-red colour-white' onClick={closeModal}>Cancel</button>
                    <button className='button compact background-primary colour-white' onClick={() => confirmUnallocate()}>Confirm</button>
                </div>
            </div>
        </Modal>
    )
}

export default view(
    withRouter(
        class OccupierTelephoneValidation extends Component {
            constructor(props) {
                super(props);

                this.state = {
                    dataReady: false,
                    dates: [],
                    date: undefined,
                    data: {
                        keys: [],
                        data: [],
                    },
                    graphData: [],
                    group: 'Type',
                    filters: {
                        Deed_Type: null,
                        Consumption: [],
                        New_Connection: null,
                        IMD: [],
                        Voids_Age_Band: [],
                        Occupier_Score: [],
                        Assigned: null,
                    },
                    filterArrays: {
                        IMD: {},
                        Voids_Age_Band: {},
                        Occupier_Score: {},
                        Consumption: {},
                    },
                    tableOrGraph: 'graph',
                    colours: [
                        "#445469",
                        "var(--primary)",
                        "#0d73b2",
                        "#f19a14",
                        "#d13358",
                        "#b24c0d",
                        "#DFBE99",
                        "#C879FF",
                        "#DEC5E3",
                        "#0D1821",
                        "#F9CB40",
                        "#5ab530",
                        "#c6c44e",
                        "#f04a38",
                        "#e5f333",
                        "#1eb5c4",
                        "#500fc6",
                        "#ea0fa9"
                    ],
                    files: [],
                    modalIsOpen: false,
                    modalMode: null,
                    modalMessage: null,
                    bulkBuyModalIsOpen: false,
                    bulkAllocateModalIsOpen: false,

                    dataFilters: [],
                    groupings: [],

                    unallocateModalIsOpen: false
                }
            }

            getDates = () => {
                return request(true).get(endpoints.OCCUPIER_TELEPHONE_VALIDATION_GET_DATES).then(r => {
                    this.setState({
                        dates: r.data.dates,
                        date: r.data.dates[0].raw
                    });
                }).catch(e => {
                    //console.log(e);
                });
            }

            getGroupings = () => {
                return request(true).get(endpoints.OCCUPIER_TELEPHONE_VALIDATION_GROUPINGS).then(e => {
                    this.setState({
                        groupings: e.data,
                        group: e.data.find(_ => _.default)
                    })
                })
            }

            setGroup = (field) => {
                this.setState({
                    group: field
                }, () => {
                    this.query();
                });
            }

            setDate = (ev) => {
                this.setState({
                    date: ev.target.value
                }, () => {
                    this.query();
                });
            }

            filter = (field, value) => {
                this.setState({
                    filters: {
                        ...this.state.filters,
                        [field]: value
                    }
                }, () => {
                    this.query();
                });
            }

            filterArray = (field, value) => {
                this.setState({
                    filterArrays: {
                        ...this.state.filterArrays,
                        [field]: {
                            ...this.state.filterArrays[field],
                            [value]: !this.state.filterArrays[field][value]
                        }
                    }
                }, () => {
                    const newFilter = [];

                    Object.keys(this.state.filterArrays[field]).forEach(key => {
                        if (this.state.filterArrays[field][key]) {
                            newFilter.push(key);
                        }
                    });

                    this.setState({
                        filters: {
                            ...this.state.filters,
                            [field]: newFilter
                        }
                    }, () => {
                        this.query();
                    });
                })
            }

            query = () => {
                this.setState({
                    dataReady: false
                }, () => {
                    request(true).get(endpoints.OCCUPIER_TELEPHONE_VALIDATION_SUMMARY, {
                        params: {
                            date: this.state.date,
                            filter: this.state.filters,
                            group: this.state.group?.target_column
                        },
                        doesCancel: true,
                    }).then(r => {
                        this.setState({
                            data: r.data,
                            dataReady: true
                        }, () => {
                            this.generateGraphData();
                        });
                    });
                })
            }

            generateGraphData = () => {
                //console.log(this.state.data);
                const data = [
                    {
                        name: 'DQI'
                    },
                    {
                        name: 0
                    },
                    {
                        name: 1
                    },
                    {
                        name: 2
                    },
                    {
                        name: 3
                    },
                    {
                        name: 4
                    },
                    {
                        name: 5
                    }
                ];

                this.state.data.keys.map(key => {
                    console.log(this.state.data, key)
                    Object.keys(this.state.data.data[key]).map((item, index) => {
                        data[data.findIndex(_ => _.name == item)][key] = this.state.data.data[key][item];

                        return this.state.data.data[key][item];
                    });

                    return key;
                });

                console.log('Graph Data', data);

                this.setState({
                    graphData: data
                });
            }

            clearFilters = () => {
                this.setState({
                    filters: {
                        Type: null,
                        Consumption: [],
                        New_Connection: null,
                        IMD: [],
                        Voids_Age_Band: [],
                        Occupier_Score: [],
                        Assigned: null,
                    },
                    filterArrays: {
                        IMD: {},
                        Voids_Age_Band: {},
                        Occupier_Score: {},
                        Consumption: {},
                    }
                }, () => {
                    this.query();
                });
            }

            setTableOrGraph = (ev) => {
                this.setState({
                    tableOrGraph: ev.target.value
                });
            }

            componentDidMount() {
                Promise.all([this.getDates(), this.getGroupings()]).finally(() => {
                    this.query();
                })
                useWSProduct('void_sales_alerts');// eslint-disable-line
            }

            openUploadModal = () => {
                this.setState({
                    modalIsOpen: true,
                    modalMode: 'upload',
                })
            }

            openDownloadModal = () => {
                this.setState({
                    modalIsOpen: true,
                    modalMode: 'download',
                });
            }

            closeModal = () => {
                this.setState({
                    modalIsOpen: false,
                })
            }

            openBulkBuyModal = () => {
                this.setState({
                    bulkBuyModalIsOpen: true
                });
            }

            closeBulkBuyModal = () => {
                this.setState({
                    bulkBuyModalIsOpen: false
                });
            }

            openBulkAllocateModal = () => {
                this.setState({
                    bulkAllocateModalIsOpen: true
                });
            }

            closeBulkAllocateModal = () => {
                this.setState({
                    bulkAllocateModalIsOpen: false
                });
            }

            componentWillUnmount() {
                useWSProduct('void_sales_alerts', 'unlisten');// eslint-disable-line
            }

            render () {
                const totals = Array(8).fill(0);

                return (
                    <div className="grid grid-gap-15" id="void-sales-alerts-page">
                        <Navigation/>
                        <Container>
                            <PageTitle title="Occupier Telephone Validation" iconClass={ProductIcons.BusinessIcon.props.className} titleRight={
                                <div style={{display: 'flex'}}>
                                    { hasPermission('OCCUPIER_TELEPHONE_VALIDATION', 'DOWNLOAD') ?
                                        <button className="navigate" onClick={this.openDownloadModal}>Download Return File</button>
                                    : null }
                                    { hasPermission('OCCUPIER_TELEPHONE_VALIDATION', 'UPLOAD') ?
                                        <button className="navigate" onClick={this.openUploadModal}>Issue Input File</button>
                                    : null }
                                    <Link className="navigate" to="/portal/occupier-telephone-validation/search">
                                        To Search
                                    </Link>
                                    { (hasPermission('OCCUPIER_TELEPHONE_VALIDATION', 'WORKFLOW')) ?
                                        <Link className="navigate" to="/portal/occupier-telephone-validation/workflow">
                                            To Workflow
                                        </Link>
                                    : null }
                                    { (hasPermission('OCCUPIER_TELEPHONE_VALIDATION', 'DASHBOARD')) ?
                                        <Link className="navigate" to="/portal/occupier-telephone-validation/dashboard">
                                            To Dashboard
                                        </Link>
                                    : null }
                                </div>
                            } />
                        </Container>
                        <Container>
                            <div className="vacantCOTAlerts">
                                <div className="grid-overview">
                                    <div className="left">
                                        <div className="data-row file-date">
                                            <div className="data-row-title">
                                                File Date
                                            </div>
                                            <div className="data-row-field">
                                                <select onChange={this.setDate} value={ this.state.date }>
                                                    { this.state.dates.map(item => {
                                                        return (
                                                            <option key={item.raw} value={item.raw}>
                                                                {item.pretty}
                                                            </option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="table-format">
                                            <div className={`data-row group-list ${this.state.group}-active`}>
                                                <div className="data-row-title">
                                                    Group by:
                                                </div>
                                                <div className="data-row-field">
                                                    <DataGroupings groupings={this.state.groupings} group={this.state.group} setGroup={this.setGroup} />
                                                </div>
                                            </div>
                                            <div className="data-row display-type">
                                                <select onChange={ this.setTableOrGraph } value={ this.state.tableOrGraph }>
                                                    <option value="table">
                                                        Table
                                                    </option>
                                                    <option value="graph">
                                                        Graph
                                                    </option>
                                                </select>
                                                <ProductChartInfo path={window.location.pathname} />
                                            </div>
                                        </div>
                                        <div className={`OCCUPIER_TELEPHONE_VALIDATION-table-or-graph using-${this.state.tableOrGraph}`}>
                                            { this.state.tableOrGraph === 'table' ?
                                                <table className="table borders">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th colSpan="8" style={{ borderTopRightRadius: '5px' }}>
                                                                Occupier Score
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th style={{width: 80}}>
                                                                { this.state.group.title ?? "" }
                                                            </th>
                                                            <th className="low">
                                                                DQI
                                                            </th>
                                                            <th className="low">
                                                                <i className="fal fa-star"></i>
                                                                <i className="fal fa-star"></i>
                                                                <i className="fal fa-star"></i>
                                                                <i className="fal fa-star"></i>
                                                                <i className="fal fa-star"></i>
                                                            </th>
                                                            <th className="low">
                                                                <i className="fas fa-star"></i>
                                                                <i className="fal fa-star"></i>
                                                                <i className="fal fa-star"></i>
                                                                <i className="fal fa-star"></i>
                                                                <i className="fal fa-star"></i>
                                                            </th>
                                                            <th className="low">
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star"></i>
                                                                <i className="fal fa-star"></i>
                                                                <i className="fal fa-star"></i>
                                                                <i className="fal fa-star"></i>
                                                            </th>
                                                            <th className="low">
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star"></i>
                                                                <i className="fal fa-star"></i>
                                                                <i className="fal fa-star"></i>
                                                            </th>
                                                            <th className="low">
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star"></i>
                                                                <i className="fal fa-star"></i>
                                                            </th>
                                                            <th className="low">
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star"></i>
                                                            </th>
                                                            <th className="no-radius bold">
                                                                Total
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { this.state.data.keys.map(item => {
                                                            const total = this.state.data.data[item]['DQI'] + 
                                                            this.state.data.data[item]['0'] + 
                                                            this.state.data.data[item]['1'] +
                                                            this.state.data.data[item]['2'] +
                                                            this.state.data.data[item]['3'] +
                                                            this.state.data.data[item]['4'] +
                                                            this.state.data.data[item]['5']

                                                            console.log('total', total)

                                                            totals[0] += this.state.data.data[item]['DQI'];
                                                            totals[1] += this.state.data.data[item]['0'];
                                                            totals[2] += this.state.data.data[item]['1'];
                                                            totals[3] += this.state.data.data[item]['2'];
                                                            totals[4] += this.state.data.data[item]['3'];
                                                            totals[5] += this.state.data.data[item]['4'];
                                                            totals[6] += this.state.data.data[item]['5'];
                                                            totals[7] += total;
                                                            console.log(totals)

                                                            return (
                                                                <tr key={`summary-data-${Math.random()}`}>
                                                                    <td>
                                                                        { item !== '' ? item : 'Null' }
                                                                    </td>
                                                                    <td>
                                                                        { localised.format(this.state.data.data[item]['DQI']) }
                                                                    </td>
                                                                    <td>
                                                                        { localised.format(this.state.data.data[item]['0']) }
                                                                    </td>
                                                                    <td>
                                                                        { localised.format(this.state.data.data[item]['1']) }
                                                                    </td>
                                                                    <td>
                                                                        { localised.format(this.state.data.data[item]['2']) }
                                                                    </td>
                                                                    <td>
                                                                        { localised.format(this.state.data.data[item]['3']) }
                                                                    </td>
                                                                    <td>
                                                                        { localised.format(this.state.data.data[item]['4']) }
                                                                    </td>
                                                                    <td>
                                                                        { localised.format(this.state.data.data[item]['5']) }
                                                                    </td>
                                                                    <td className="bold">
                                                                        { localised.format(total) }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        <tr key={`summary-data-${Math.random()}`} className="bold">
                                                            <td>
                                                                Total
                                                            </td>
                                                            <td>
                                                                { localised.format(totals[0]) }
                                                            </td>
                                                            <td>
                                                                { localised.format(totals[1]) }
                                                            </td>
                                                            <td>
                                                                { localised.format(totals[2]) }
                                                            </td>
                                                            <td>
                                                                { localised.format(totals[3]) }
                                                            </td>
                                                            <td>
                                                                { localised.format(totals[4]) }
                                                            </td>
                                                            <td>
                                                                { localised.format(totals[5]) }
                                                            </td>
                                                            <td>
                                                                { localised.format(totals[6]) }
                                                            </td>
                                                            <td>
                                                                { localised.format(totals[7]) }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            :
                                                this.state.dataReady ?
                                                    <ResponsiveContainer>
                                                        <BarChart
                                                            data={this.state.graphData}
                                                            margin={{ bottom: 20 }}
                                                            barSize={50}
                                                            >

                                                            <Legend  iconType="circle" iconSize={11}/>
                                                            <XAxis dataKey="name" tick={ _ => _.payload && _.payload.value !== null && typeof _.payload.value === 'number' ? <Label {..._} /> : <text x={_.x - 70} y={_.y + 15}>{_.payload.value}</text> } />
                                                            <YAxis type="number" allowDecimals={false} tick={{ fontSize: 10 }} tickFormatter={ item => { return localised.format(item) }}/>
                                                            <CartesianGrid />
                                                            { this.state.data.keys.map((item, index) => {
                                                                return (
                                                                    <Bar key={ Math.random() } dataKey={item} fill={ this.state.colours[index] } stackId="a" />
                                                                )
                                                            })}
                                                            <Tooltip cursor={false} formatter={ (value, name, props) => { return localised.format(value) }}/>

                                                        </BarChart>
                                                    </ResponsiveContainer>
                                                : ''
                                            }
                                        </div>
                                    </div>
                                    <div className="right">
                                        <div className="filters">
                                            <div className="filter-title">
                                                {/* <i className="fas fa-caret-up"></i> */}
                                                <div className="title">Filters</div>
                                                <div className="clear" onClick={ this.clearFilters }>Clear All</div>
                                            </div>
                                            <ProductDataFilter applyFilter={this.filter} filterState={this.state.filters} dataFilterEndpoint={endpoints.OCCUPIER_TELEPHONE_VALIDATION_DATA_FILTERS} />
                                            
                                        </div>
                                        <div className='grid grid-columns-2 grid-gap-5 mta'>
                                            { (hasPermission('OCCUPIER_TELEPHONE_VALIDATION', 'BULK-BUY')) ?
                                                <button className="button bulk-buy-button compact" onClick={this.openBulkBuyModal}>
                                                    Create Download File
                                                </button>
                                            : '' }
                                            <div className='grid grid-gap-5'>
                                                { (hasPermission('OCCUPIER_TELEPHONE_VALIDATION', 'ALLOCATE')) ?
                                                    <button className="button bulk-buy-button compact" onClick={this.openBulkAllocateModal}>
                                                        Allocate
                                                    </button>
                                                : '' }
                                                { (hasPermission('OCCUPIER_TELEPHONE_VALIDATION', 'UNALLOCATE')) ?
                                                    <button className="button bulk-buy-button compact" onClick={() => this.setState({unallocateModalIsOpen:true})}>
                                                        Unallocate
                                                    </button>
                                                : '' }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                        <S3Modal showModal={this.state.modalIsOpen} closeModal={this.closeModal} modalMode={this.state.modalMode} listingUrl={endpoints.OCCUPIER_TELEPHONE_VALIDATION_FILE_UPLOAD} uploadUrl={endpoints.OCCUPIER_TELEPHONE_VALIDATION_FILE_GET}/>
                        <BulkBuy
                            SentenceFirst={({count}) => <>The selected data set includes {count} accounts to be downloaded. Press the 'Create File' button to create the download file and you will receive an email notification once this is available. Alternatively press the 'Cancel' button to go back to the summary screen.</>}
                            SentenceSecond={() => <></>}
                            buyButtonText="Create File" completeOnFirstStep={true} workflowUsersEndpoint={endpoints.OCCUPIER_TELEPHONE_VALIDATION_WORKFLOW_USERS} bulkEndpoint={endpoints.OCCUPIER_TELEPHONE_VALIDATION_SUMMARY_BULK} modalIsOpen={this.state.bulkBuyModalIsOpen} closeModal={this.closeBulkBuyModal} filters={this.state.filters} date={this.state.date}/>
                        <BulkAllocate workflowUsersEndpoint={endpoints.OCCUPIER_TELEPHONE_VALIDATION_WORKFLOW_USERS} bulkEndpoint={endpoints.OCCUPIER_TELEPHONE_VALIDATION_SUMMARY_BULK} modalIsOpen={this.state.bulkAllocateModalIsOpen} closeModal={this.closeBulkAllocateModal} filters={this.state.filters} date={this.state.date}/>
                        <HelpVideo video={session.company.vacant_summary_video_url}/>
                        <UnallocateModal
                            modalIsOpen={this.state.unallocateModalIsOpen} 
                            closeModal={() => this.setState({ unallocateModalIsOpen: false })}
                            date={this.state.date}
                            filters={this.state.filters}
                            GET_ENDPOINT={endpoints.OCCUPIER_TELEPHONE_VALIDATION_UNALLOCATE_GET}
                            POST_ENDPOINT={endpoints.OCCUPIER_TELEPHONE_VALIDATION_UNALLOCATE_POST}
                        />
                        <Footer/>
                    </div>
                )
            }
        }
    )
)
