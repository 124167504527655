import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import './scss/main.scss';
import Router from './router';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { init as initApm } from '@elastic/apm-rum'

if (process.env.NODE_ENV === "production") {
  // const apm = initApm({

  //   // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  //   serviceName: 'identeq-app',
  
  //   // Set custom APM Server URL (default: http://localhost:8200)
  //   serverUrl: 'https://identeq-monitoring.production.hetzner.servers.cool',
  
  //   // Set service version (required for sourcemap feature)
  //   serviceVersion: process.env.REACT_APP_VERSION,

  //   environment: process.env.REACT_APP_BASE_URL || ''
  // })
}

ReactDOM.render(
  <React.StrictMode>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Router />
    </MuiPickersUtilsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
