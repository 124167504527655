import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ProductDataFilter from "../../../components/product-data-filter/product-data-filter";
import GenericDataChart from "../../../GenericPageElements/GenericDataChart";
import GenericPageContainer from "../../../GenericPageElements/GenericPageContainer";
import GenericProductFilterContainer from "../../../GenericPageElements/GenericProductFilterContainer";
import endpoints from "../../../helpers/endpoints";
import request from "../../../helpers/request";
import S3Modal from "../../../components/s3Modal/S3Modal";
import hasPermission from "../../../helpers/permissions";
import GenericDataRow from "../../../GenericPageElements/GenericDataRow";
import { view } from "@risingstack/react-easy-state";
import { Link } from "react-router-dom";
import BulkBuy from '../../../components/cot-alert-bulk-buy/bulkBuy';
import BulkAllocate from '../../../components/cot-alert-bulk-allocate/bulkAllocate';
import { CustomizedAxisTickMultiLine } from "../CompaniesHouseAlerts/CompaniesHouseAlerts";
import './MixedUseManualMatch.scss'; 
import { useHistory } from 'react-router-dom';
import ReactModal from 'react-modal'

export default view(function MixedUseManualMatchSummary() {
  const history = useHistory();
  const [filters, setFilters] = useState({});
  const [selectedGroupBy, setSelectectGroupBy] = useState("Match_Status");
  const [groupings, setGroupings] = useState([]);
  const [group, setGroup] = useState(undefined);
  const [data, setData] = useState(null);
  const [dates, setDates] = useState([]);
  const [date, setDate] = useState(null);
  const [postcode, setPostcode] = useState(history.location?.state?.postcode || null );
  const [S3Model, setS3Model] = useState('');
  const [postcodeModal, setPostcodeModal] = useState(false);
  const [bulkModel, setBulkModel] = useState('');
  
  const applyFilter = (column, value) => {
    setFilters((current) => {
      return {
        ...current,
        [column]: value,
      };
    });
  };

  useEffect(() => {
    request(true)
      .get(`${endpoints.MIXED_USE_MANUAL_MATCH_DATES}`)
      .then((e) => {
        setDates(e.data.dates);
        if (e.data.dates.length > 0) setDate(e.data.dates[0].raw);
      });
  }, []);

  useEffect(() => {
    request(true)
      .get(endpoints.MIXED_USE_MANUAL_MATCH_GROUPINGS)
      .then(e => {
        setGroupings(e.data);
        setGroup(e.data.find(_ => _.default))
      })
  }, [])

  useEffect(() => {
    getData();
  }, [filters, group, date]);

  const getData = () => {
    if (!date) return;
    setData(null)
    request(true)
      .get(`${endpoints.MIXED_USE_MANUAL_MATCH_DATA}`, {
        params: {
          date: date,
          filter: filters,
          group: group?.target_column,
        },
        doesCancel: true,
      })
      .then((e) => {
        setData(e.data);
      });
  }

  return [
    <GenericPageContainer
      title="Mixed Use Manual Match - Summary"
      titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
      titleRight={
        <div>
          <button className="navigate" onClick={() => history.push({pathname: "/portal/mixed-use-manual-match/search", state: { postcode: postcode }})}>To Search</button>
          { hasPermission('MIXED_USE_MANUAL_MATCH', 'WORKFLOW') ?
            <button className="navigate" onClick={() => history.push({pathname: "/portal/mixed-use-manual-match/workflow", state: { postcode: postcode }})}>To Workflow</button>
          : null }
        </div>
      }
    >
      <div style={{maxWidth: '20vw', marginBottom: 15}}>
      <GenericDataRow title={"File Date"} contentColour={"white"}>
        <select
          onChange={(_) => setDate(_.target.value)}
          value={date}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            backgroundColor: "white",
            paddingLeft: "10px",
          }}
        >
          {dates.map((item) => {
            return (
              <option key={item.raw} value={item.raw}>
                {item.pretty}
              </option>
            );
          })}
        </select>
      </GenericDataRow>
      </div>
      <div className='MU'>
        <GenericDataChart
          dataReady={!!data}
          data={data}
          selectedGroupBy={group}
          setSelectectGroupBy={setGroup}
          groupingsEndpoint={`${endpoints.MIXED_USE_MANUAL_MATCH_GROUPINGS}`}
          sizes={{chart: 600, chartContainer: 625, chartXAxisFontSize: 12, wrapperStyle: { paddingTop: '40px' }}}
          xAxisProps={{interval: 0}}
          customBarTick={CustomizedAxisTickMultiLine}
          hideLegend={true}
        />
        <GenericProductFilterContainer clearFilters={() => setFilters({})}>
          <ProductDataFilter
            filterState={filters}
            applyFilter={applyFilter}
            dataFilterEndpoint={`${endpoints.MIXED_USE_MANUAL_MATCH_FILTERS}`}
          />
          <div className='grid grid-columns-2 grid-gap-5 mta'>
              <button className="button bulk-buy-button compact smaller-text" onClick={() => setPostcodeModal(true)}>
                  Input Postcode
              </button>
              { hasPermission('MIXED_USE_MANUAL_MATCH', 'ALLOCATE') ?
                  <button className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('allocate')}>
                      Allocate
                  </button>
              : '' }
          </div>
        </GenericProductFilterContainer>
      </div>
    </GenericPageContainer>,
    <S3Modal
      closeModal={() => setS3Model(null)}
      modalMode={S3Model}
      showModal={!!S3Model}
      listingUrl={endpoints.MIXED_USE_MANUAL_MATCH_FILES}
      uploadUrl={endpoints.MIXED_USE_MANUAL_MATCH_FILES}
    />,
    <ReactModal 
    isOpen={postcodeModal} 
    onRequestClose={() => setPostcodeModal(false)}
    className="card bulk-allocate"
    contentLabel="Mixed Use Postcode Search"
    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}>
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <h3>Postcode Search</h3>
        <input autoFocus value={postcode} onChange={_ => /^[a-zA-Z0-9]{0,3}$/.test(_.target.value) ? setPostcode(_.target.value.toUpperCase()) : null} style={{border: '1px solid gray', width: '100%', borderRadius: 3, marginBottom: '1em'}} />
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
          <button className={`button compact smaller-text ${postcode ? "background-primary colour-white" : ''}`} disabled={postcode ? false : true} onClick={() => {
            // getData(postcode || undefined); 
            setPostcodeModal(false)
            setFilters(prevFilters => ({ ...prevFilters, postcode: postcode + '%' }));
          }}
          >Search</button> 
        </div>
      </div>
    </ReactModal>,
    <BulkAllocate workflowUsersEndpoint={endpoints.MIXED_USE_MANUAL_MATCH_WF_USERS} bulkEndpoint={endpoints.MIXED_USE_MANUAL_MATCH_BULK_BASE} urlParams={{'postcode': postcode ?? ''}} modalIsOpen={bulkModel === 'allocate'} closeModal={() => setBulkModel('')} filters={filters} date={date} />,
  ];
})
