import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import BulkBuy from '../../../components/cot-alert-bulk-buy/bulkBuy';
import BulkAllocate from '../../../components/cot-alert-bulk-allocate/bulkAllocate';
import ProductDataFilter from '../../../components/product-data-filter/product-data-filter'
import S3Modal from '../../../components/s3Modal/S3Modal'
import GenericDataChart from '../../../GenericPageElements/GenericDataChart'
import GenericDataRow from '../../../GenericPageElements/GenericDataRow'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import GenericProductFilterContainer from '../../../GenericPageElements/GenericProductFilterContainer'
import endpoints from '../../../helpers/endpoints'
import hasPermission from '../../../helpers/permissions';
import request from '../../../helpers/request'
import session from '../../../stores/session'
import { Label } from '../OccupierCOTAlerts/occupierCOTAlerts'
import { CustomizedAxisTickMultiLine } from "../CompaniesHouseAlerts/CompaniesHouseAlerts";
import './IdenteqOccupierValidation.scss';

export default function IdenteqOccupierValidationSummary() {
  const [groupBy, setGroupBy] = useState('Type');
  const [groupings, setGroupings] = useState([]);
  const [group, setGroup] = useState(undefined);
  const [data, setData] = useState(null);
  const [filters, setFilters] = useState({})
  const [date, setDate] = useState(null);
  const [dates, setDates] = useState([])
  const [bulkModel, setBulkModel] = useState('')
  const [S3Model, setS3Model] = useState(null);
  const [translations, setTranslations] = useState({})
  useEffect(() => {
    request(true).get('/translate?key=ov.')
    .then(e => {
      setTranslations(e.data)
    })
    }, [])
  const applyFilter = (column, value) => {
    setFilters((current) => {
      return {
        ...current,
        [column]: value,
      };
    });
  };

  useEffect(() => {
    request(true)
      .get(endpoints.IDENTEQ_OCCUPIER_VALIDATION_GROUPINGS)
      .then(e => {
        setGroupings(e.data);
        setGroup(e.data.find(_ => _.default))
      })
  }, [])

  const getData = useCallback(() => {
    request(true).get(endpoints.IDENTEQ_OCCUPIER_VALIDATION_DATA, {
      params: {
        date: date,
        filter: filters,
        group: group?.target_column,
      },
      doesCancel: true,
    })
    .then((e) => {
      setData(e.data);
    });
  }, [group, filters, date])

  useEffect(() => {
    getData()
  }, [group, filters, date]);

  useEffect(() => {
    request(true)
      .get(endpoints.IDENTEQ_OCCUPIER_VALIDATION_DATES)
      .then((e) => {
        setDates(e.data.dates);
        if (e.data.dates.length > 0) setDate(e.data.dates[0].raw);
      });
  }, []);
  return [
    <GenericPageContainer
      title={'Identeq Occupier Validation'}
      titleIconClass='fak fa-id-business-icon fa-2x colour-primary'
      titleRight={<div>
        <Link className="navigate" to="/portal/identeq-occupier-validation/search">
            To Search
        </Link>
        { (hasPermission('IDENTEQ_OCCUPIER_VALIDATION', 'WORKFLOW')) ?
            <Link className="navigate" to="/portal/identeq-occupier-validation/workflow">
                To Workflow
            </Link>
        : null }
        {hasPermission('IDENTEQ_OCCUPIER_VALIDATION', 'DOWNLOAD') ? (
          <button className="navigate" onClick={() => setS3Model("download")}>
            Download Return File
          </button>
        ) : null}
        {hasPermission('IDENTEQ_OCCUPIER_VALIDATION', 'UPLOAD') ? (
          <button className="navigate" onClick={() => setS3Model("upload")}>
            Issue Input File
          </button>
        ) : null}
      </div>}
    >
    <div style={{maxWidth: '20vw', marginBottom: 15}}>
    <GenericDataRow title={translations['ov.file-date']} contentColour={"white"}>
      <select
        onChange={(_) => setDate(_.target.value)}
        value={date}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          backgroundColor: "white",
          paddingLeft: "10px",
        }}
      >
        {dates.map((item) => {
          return (
            <option key={item.raw} value={item.raw}>
              {item.pretty}
            </option>
          );
        })}
      </select>
    </GenericDataRow>
    </div>
      <div class="identeq-occupier-validation">
      <GenericDataChart
          groupingsEndpoint={endpoints.IDENTEQ_OCCUPIER_VALIDATION_GROUPINGS}
          data={data}
          dataReady={!!data}
          selectedGroupBy={group}
          setSelectectGroupBy={setGroup}
          overrideTableHeader="Occupier Banding"
          sizes={{chart: 600, chartContainer: 625, chartXAxisFontSize: 12, wrapperStyle: { paddingTop: '40px' }}}
          xAxisProps={{interval: 0}}
          customBarTick={CustomizedAxisTickMultiLine}
      ></GenericDataChart>
      <GenericProductFilterContainer>
          <ProductDataFilter
            filterState={filters}
            applyFilter={applyFilter}
            dataFilterEndpoint={endpoints.IDENTEQ_OCCUPIER_VALIDATION_FILTERS}
          />
          <div className='grid grid-columns-2 grid-gap-5 mta'>
              { (hasPermission('IDENTEQ_OCCUPIER_VALIDATION', 'BULK-BUY')) ?
                  <button className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('purchase')}>
                      Create Download File
                  </button>
              : '' }
              { (hasPermission('IDENTEQ_OCCUPIER_VALIDATION', 'ALLOCATE')) ?
                  <button className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('allocate')}>
                      Allocate
                  </button>
              : '' }
          </div>
      </GenericProductFilterContainer>
      </div>
    </GenericPageContainer>,
    <S3Modal
      closeModal={() => setS3Model(null)}
      modalMode={S3Model}
      showModal={!!S3Model}
      listingUrl={endpoints.IDENTEQ_OCCUPIER_VALIDATION_FILES}
      uploadUrl={endpoints.IDENTEQ_OCCUPIER_VALIDATION_FILES}
    />,
    <BulkBuy
    SentenceFirst={({count}) => <>The selected data set includes {count} accounts to be downloaded. Press the 'Create File' button to create the download file and you will receive an email notification once this is available. Alternatively press the 'Cancel' button to go back to the summary screen.</>}
    SentenceSecond={() => <></>}
    buyButtonText="Create File" completeOnFirstStep={true} workflowUsersEndpoint={endpoints.IDENTEQ_OCCUPIER_VALIDATION_WF_USERS} bulkEndpoint={endpoints.IDENTEQ_OCCUPIER_VALIDATION_BULK_BASE} modalIsOpen={bulkModel === 'purchase'} closeModal={() => setBulkModel('')} filters={filters} date={date} />,
    <BulkAllocate workflowUsersEndpoint={endpoints.IDENTEQ_OCCUPIER_VALIDATION_WF_USERS} bulkEndpoint={endpoints.IDENTEQ_OCCUPIER_VALIDATION_BULK_BASE} modalIsOpen={bulkModel === 'allocate'} closeModal={() => setBulkModel('')} filters={filters} date={date} />,
  ]
}
